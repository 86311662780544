/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface LeaderboardViewColumnDTO
 */
export interface LeaderboardViewColumnDTO {
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    color?: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof LeaderboardViewColumnDTO
     */
    columnType: AttributeTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardViewColumnDTO
     */
    pinned: boolean;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardViewColumnDTO
     */
    width: number;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    aggregationMode?: LeaderboardViewColumnDTOAggregationModeEnum;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    displayMode?: LeaderboardViewColumnDTODisplayModeEnum;
}


/**
 * @export
 */
export const LeaderboardViewColumnDTOAggregationModeEnum = {
    Last: 'last',
    Min: 'min',
    Max: 'max',
    Average: 'average',
    Variance: 'variance'
} as const;
export type LeaderboardViewColumnDTOAggregationModeEnum = typeof LeaderboardViewColumnDTOAggregationModeEnum[keyof typeof LeaderboardViewColumnDTOAggregationModeEnum];

/**
 * @export
 */
export const LeaderboardViewColumnDTODisplayModeEnum = {
    Scientific: 'scientific',
    Decimal: 'decimal',
    Auto: 'auto'
} as const;
export type LeaderboardViewColumnDTODisplayModeEnum = typeof LeaderboardViewColumnDTODisplayModeEnum[keyof typeof LeaderboardViewColumnDTODisplayModeEnum];


/**
 * Check if a given object implements the LeaderboardViewColumnDTO interface.
 */
export function instanceOfLeaderboardViewColumnDTO(value: object): value is LeaderboardViewColumnDTO {
    if (!('columnType' in value) || value['columnType'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('pinned' in value) || value['pinned'] === undefined) return false;
    if (!('width' in value) || value['width'] === undefined) return false;
    return true;
}

export function LeaderboardViewColumnDTOFromJSON(json: any): LeaderboardViewColumnDTO {
    return LeaderboardViewColumnDTOFromJSONTyped(json, false);
}

export function LeaderboardViewColumnDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardViewColumnDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'color': json['color'] == null ? undefined : json['color'],
        'columnType': AttributeTypeDTOFromJSON(json['columnType']),
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'id': json['id'],
        'pinned': json['pinned'],
        'width': json['width'],
        'aggregationMode': json['aggregationMode'] == null ? undefined : json['aggregationMode'],
        'displayMode': json['displayMode'] == null ? undefined : json['displayMode'],
    };
}

export function LeaderboardViewColumnDTOToJSON(value?: LeaderboardViewColumnDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'color': value['color'],
        'columnType': AttributeTypeDTOToJSON(value['columnType']),
        'displayName': value['displayName'],
        'id': value['id'],
        'pinned': value['pinned'],
        'width': value['width'],
        'aggregationMode': value['aggregationMode'],
        'displayMode': value['displayMode'],
    };
}

