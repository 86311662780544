import React from 'react';
import { usePrevious } from 'react-use';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

type ScrollIntoViewProps = React.HTMLAttributes<HTMLDivElement> &
  React.PropsWithChildren<{
    enabled?: boolean;
    asChild?: boolean;
  }>;

export const ScrollIntoView = ({ enabled, asChild, children, ...props }: ScrollIntoViewProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const lastEnabled = usePrevious(enabled);

  React.useLayoutEffect(() => {
    if (lastEnabled === false && enabled && ref?.current) {
      scrollIntoViewIfNeeded(ref.current, false);
    }
  }, [enabled, lastEnabled, ref]);

  if (asChild) {
    if (React.Children.count(children) !== 1) {
      throw new Error('The `asChild` prop only works when `children` is a single React element.');
    }

    const child = React.Children.only(children) as React.ReactElement;
    return React.cloneElement(child, { ...props, ref });
  }

  return (
    <div {...props} ref={ref}>
      {children}
    </div>
  );
};
