import React from 'react';
import { Transition } from 'react-transition-group';

import { bemBlock } from '../../modules/bem';
import { Button } from '../button/Button';
import { Icon } from '../icon/Icon';
import { Layout } from '../layout/Layout';
import { Section } from '../section/Section';

import './Collapse.less';

const block = bemBlock('collapse');

type CollapseProps = Omit<React.ComponentProps<typeof Section>, 'title'> & {
  wrap?: React.ComponentProps<typeof Layout.Row>['wrap'];
  icon?: React.ComponentProps<typeof Icon>['glyph'];
  children: React.ReactNode;
  extra?: React.ReactNode;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  title: React.ReactNode;
  onToggle?: () => void;
  collapsed?: boolean;
  id: string;
};

export function Collapse({
  id,
  title,
  icon = 'caret-down',
  onToggle,
  collapsed,
  extra,
  wrap,
  children,
  mountOnEnter = true,
  unmountOnExit = true,
  ...props
}: CollapseProps) {
  return (
    <Section {...props} className={block({ modifiers: { collapsed } })}>
      <Layout.Row
        className={block({ element: 'panel', modifiers: { collapsed } })}
        spacedChildren="sm"
        alignItems="center"
        span="shrink"
        wrap={wrap}
      >
        <Layout.Row span="grow" alignItems="center" justifyContent="start">
          {onToggle ? (
            <Button
              aria-expanded={!collapsed}
              className={block('toggle')}
              variant="secondary-text"
              onClick={onToggle}
              aria-controls={id}
              withPadding="none"
              size="w-full"
            >
              <Icon
                className={block({ element: 'chevron', modifiers: { collapsed } })}
                color="text-subdued"
                glyph={icon}
                fixedWidth
                size="lg"
              />
              {title}
            </Button>
          ) : (
            title
          )}
        </Layout.Row>
        <Layout.Row span="shrink" justifyContent="end">
          {extra}
        </Layout.Row>
      </Layout.Row>
      <Transition
        exit
        appear
        in={!collapsed}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        timeout={{ exit: 250, enter: 0 }}
      >
        {(state) => (
          <div id={id} className={block({ element: 'content', modifiers: { state } })}>
            <div>{children}</div>
          </div>
        )}
      </Transition>
    </Section>
  );
}
