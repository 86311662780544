// Libs
import React from 'react';
import { AutoSizer, Index, List, ListRowProps, ListRowRenderer } from 'react-virtualized';
import { isFunction, isNumber, isString } from 'lodash';

import { bemBlock } from '../../modules/bem';
import { Dropdown } from '../dropdown/Dropdown';
import { DropdownItem } from '../dropdown-item/DropdownItem';
import { DropdownMenu } from '../dropdown-menu/DropdownMenu';
import { LayoutElement } from '../layout-element/LayoutElement';
import { LegacyEmblem } from '../legacy-emblem/LegacyEmblem';
import { MultiselectToggle } from '../multiselect-toggle/MultiselectToggle';

// Module
import './Multiselect.less';

const block = bemBlock('n-multiselect');

export type RenderMenuItemProps<T = string> = {
  item: T;
  props: MultiselectProps<T>;
  state: Record<'text', string>;
} & Omit<ListRowProps, 'index'>;

type DefaultProps<T = string> = {
  filterComparator: (item: T, text: string) => void;
  renderSelectedItem: (option: T) => React.ReactElement | null;
  renderMenuTitle: (props: MultiselectProps<T>) => React.ReactElement | null;
  renderMenuItem: ({ item, props, state }: RenderMenuItemProps<T>) => React.ReactElement | null;
  itemHeight: number;
  titleHeight: number;
  selectedItems: T[];
  overscanRowCount: number;
  'data-role': string;
};

export type MultiselectProps<T = string> = {
  className?: string;
  disabled?: boolean;
  text?: string;
  placeholder?: string;
  items: T[];
  error?: boolean;
  selectedLimit?: number;
  onTextChange?: (text: string) => void;
  onSelectionChange?: (item: T, event: React.MouseEvent) => void;
  onMenuExpand?: () => void;
  onItemAdd?: (text: string) => void;
  onItemRemove?: () => void;
} & Partial<DefaultProps<T>>;

export class Multiselect<T = string> extends React.PureComponent<
  MultiselectProps<T> & DefaultProps<T>
> {
  public static Toggle = MultiselectToggle;

  public static defaultProps: DefaultProps = {
    filterComparator: () => true,
    renderSelectedItem: (option) => <LegacyEmblem key={option}>{option}</LegacyEmblem>,
    renderMenuTitle: () => null,
    renderMenuItem: ({ item, props /* , state, isScrolling, isVisible, key, parent, style */ }) => (
      <DropdownItem
        key={item}
        onClick={(event) => props.onSelectionChange && props.onSelectionChange(item, event)}
        alignItems="center"
        spacedChildren="sm"
        selected={props.selectedItems ? props.selectedItems.includes(item) : false}
        label={item}
      />
    ),
    itemHeight: 28,
    titleHeight: 28,
    selectedItems: [],
    overscanRowCount: 10,
    'data-role': 'multiselect',
  };

  state = {
    text: '',
  };

  onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onTextChange } = this.props;
    const text = event.target.value;

    if (!isString(this.props.text)) {
      this.setState({ text });
    }

    if (isFunction(onTextChange)) {
      onTextChange(text);
    }
  };

  onItemAdd = () => {
    const { onItemAdd } = this.props;

    const text = this.getFilterText();
    const disabled = this.getDisabled();

    if (isFunction(onItemAdd) && !disabled) {
      onItemAdd(text);
    }

    if (!isString(this.props.text)) {
      this.setState({ text: '' });
    }
  };

  onKeyDown = (event: React.KeyboardEvent) => {
    const { onItemRemove } = this.props;

    const text = this.getFilterText();

    if (event.key === 'Enter') {
      this.onItemAdd();
    }

    if (event.key === 'Backspace' && text === '') {
      if (isFunction(onItemRemove)) {
        onItemRemove();
      }
    }
  };

  getFilterText() {
    return isString(this.props.text) ? this.props.text : this.state.text;
  }

  getDisabled() {
    const { disabled, selectedLimit, selectedItems } = this.props;

    return disabled || (isNumber(selectedLimit) && selectedItems.length >= selectedLimit);
  }

  render() {
    const {
      className,
      error,
      items,
      placeholder,
      selectedItems,
      filterComparator,
      renderSelectedItem,
      renderMenuItem,
      renderMenuTitle,
      onMenuExpand,
      itemHeight,
      titleHeight,
      overscanRowCount,
      'data-role': dataRole,
    } = this.props;

    const text = this.getFilterText();
    const disabled = this.getDisabled();
    const filteredItems = items.filter((item) => filterComparator(item, text));

    const toggleElement = (
      <MultiselectToggle
        className={className}
        error={error}
        text={text}
        placeholder={selectedItems.length === 0 ? placeholder : ' '}
        iconDisabled={filteredItems.length === 0}
        onKeyDown={this.onKeyDown}
        onChange={this.onTextChange}
        data-role={`${dataRole}-toggle`}
      >
        {selectedItems.map(renderSelectedItem)}
      </MultiselectToggle>
    );

    const title = renderMenuTitle(this.props);

    const rowRenderer: ListRowRenderer = ({ index, ...options }) => {
      if (index === 0 && title) {
        return title;
      }

      return renderMenuItem({
        item: filteredItems[title ? index - 1 : index],
        props: this.props,
        state: this.state,
        ...options,
      });
    };

    const rowHeight: (params: Index) => number = ({ index }) =>
      index === 0 && title ? titleHeight : itemHeight;
    const rowCount = title ? filteredItems.length + 1 : filteredItems.length;
    // the AutoSizer component requires the parent to have a fixed height
    // hence either use the height of all elements or the maximum height of a dropdown menu (see: DropdownMenu.less):
    // 500px - (inner padding + border) = 490px
    const height = Math.min(490, filteredItems.length * itemHeight + (title ? titleHeight : 0));

    return (
      <Dropdown
        disabled={disabled}
        toggle={toggleElement}
        autoWidthMenu="width"
        onBlur={this.onItemAdd}
        onExpand={onMenuExpand}
      >
        {filteredItems.length > 0 && !disabled && (
          <DropdownMenu
            overflow={{ vertical: 'hidden', horizontal: 'hidden' }}
            data-role={`${dataRole}-dropdown-menu`}
          >
            <LayoutElement height={height}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    className={block({ element: 'list', modifiers: { withCustomScrollbar: true } })}
                    rowRenderer={rowRenderer}
                    rowHeight={rowHeight}
                    rowCount={rowCount}
                    height={height}
                    width={width}
                    overscanRowCount={overscanRowCount}
                  />
                )}
              </AutoSizer>
            </LayoutElement>
          </DropdownMenu>
        )}
      </Dropdown>
    );
  }
}
