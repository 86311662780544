/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';
import type { ExperimentStateDTO } from './ExperimentStateDTO';
import {
    ExperimentStateDTOFromJSON,
    ExperimentStateDTOFromJSONTyped,
    ExperimentStateDTOToJSON,
} from './ExperimentStateDTO';

/**
 * 
 * @export
 * @interface ExperimentStateAttributeDTO
 */
export interface ExperimentStateAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof ExperimentStateAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof ExperimentStateAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {ExperimentStateDTO}
     * @memberof ExperimentStateAttributeDTO
     */
    value: ExperimentStateDTO;
}



/**
 * Check if a given object implements the ExperimentStateAttributeDTO interface.
 */
export function instanceOfExperimentStateAttributeDTO(value: object): value is ExperimentStateAttributeDTO {
    if (!('attributeName' in value) || value['attributeName'] === undefined) return false;
    if (!('attributeType' in value) || value['attributeType'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function ExperimentStateAttributeDTOFromJSON(json: any): ExperimentStateAttributeDTO {
    return ExperimentStateAttributeDTOFromJSONTyped(json, false);
}

export function ExperimentStateAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentStateAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'value': ExperimentStateDTOFromJSON(json['value']),
    };
}

export function ExperimentStateAttributeDTOToJSON(value?: ExperimentStateAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeName': value['attributeName'],
        'attributeType': AttributeTypeDTOToJSON(value['attributeType']),
        'value': ExperimentStateDTOToJSON(value['value']),
    };
}

