/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeDefinitionDTO } from './AttributeDefinitionDTO';
import {
    AttributeDefinitionDTOFromJSON,
    AttributeDefinitionDTOFromJSONTyped,
    AttributeDefinitionDTOToJSON,
} from './AttributeDefinitionDTO';
import type { LeaderboardEntryGroupDTO } from './LeaderboardEntryGroupDTO';
import {
    LeaderboardEntryGroupDTOFromJSON,
    LeaderboardEntryGroupDTOFromJSONTyped,
    LeaderboardEntryGroupDTOToJSON,
} from './LeaderboardEntryGroupDTO';
import type { AttributesDTO } from './AttributesDTO';
import {
    AttributesDTOFromJSON,
    AttributesDTOFromJSONTyped,
    AttributesDTOToJSON,
} from './AttributesDTO';

/**
 * 
 * @export
 * @interface LeaderboardEntriesSearchResultDTO
 */
export interface LeaderboardEntriesSearchResultDTO {
    /**
     * The entries matching the given criteria.
     * @type {Array<AttributesDTO>}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    entries: Array<AttributesDTO>;
    /**
     * The groups of entities matching given criteria.
     * @type {Array<LeaderboardEntryGroupDTO>}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    groups?: Array<LeaderboardEntryGroupDTO>;
    /**
     * The total number of entries matching the given criteria.
     * @type {number}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    matchingItemCount: number;
    /**
     * The column suggestions to the user, most recommended columns first.
     * @type {Array<AttributeDefinitionDTO>}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    suggestions?: Array<AttributeDefinitionDTO>;
    /**
     * The total number of group entries.
     * @type {number}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    totalGroupCount?: number;
}

/**
 * Check if a given object implements the LeaderboardEntriesSearchResultDTO interface.
 */
export function instanceOfLeaderboardEntriesSearchResultDTO(value: object): value is LeaderboardEntriesSearchResultDTO {
    if (!('entries' in value) || value['entries'] === undefined) return false;
    if (!('matchingItemCount' in value) || value['matchingItemCount'] === undefined) return false;
    return true;
}

export function LeaderboardEntriesSearchResultDTOFromJSON(json: any): LeaderboardEntriesSearchResultDTO {
    return LeaderboardEntriesSearchResultDTOFromJSONTyped(json, false);
}

export function LeaderboardEntriesSearchResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardEntriesSearchResultDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(AttributesDTOFromJSON)),
        'groups': json['groups'] == null ? undefined : ((json['groups'] as Array<any>).map(LeaderboardEntryGroupDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'suggestions': json['suggestions'] == null ? undefined : ((json['suggestions'] as Array<any>).map(AttributeDefinitionDTOFromJSON)),
        'totalGroupCount': json['totalGroupCount'] == null ? undefined : json['totalGroupCount'],
    };
}

export function LeaderboardEntriesSearchResultDTOToJSON(value?: LeaderboardEntriesSearchResultDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entries': ((value['entries'] as Array<any>).map(AttributesDTOToJSON)),
        'groups': value['groups'] == null ? undefined : ((value['groups'] as Array<any>).map(LeaderboardEntryGroupDTOToJSON)),
        'matchingItemCount': value['matchingItemCount'],
        'suggestions': value['suggestions'] == null ? undefined : ((value['suggestions'] as Array<any>).map(AttributeDefinitionDTOToJSON)),
        'totalGroupCount': value['totalGroupCount'],
    };
}

