import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutSeparator, LayoutSeparatorProps } from '../layout-separator/LayoutSeparator';

import './DropdownSeparator.less';

const block = bemBlock('dropdown-separator');

export const DropdownSeparator = (props: LayoutSeparatorProps) => (
  <LayoutSeparator {...props} className={block({ extra: props.className })} />
);
