import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Card } from '../card/Card';
import { SimpleTooltip } from '../tooltip/SimpleTooltip';

import ModalButton from './ModalButton';

import './ModalFooter.less';

export interface ModalFooterProps {
  className?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  primaryButtonRole?: string;
  secondaryButtonRole?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  primaryButtonDisabledReason?: string;
  secondaryButtonDisabledReason?: string;
}

const block = bemBlock('n-modal-footer');

const ModalFooter: React.FC<ModalFooterProps> = ({
  className,
  children,
  primaryButtonLabel = 'OK',
  secondaryButtonLabel = 'Cancel',
  primaryButtonRole,
  secondaryButtonRole,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  primaryButtonDisabledReason,
  secondaryButtonDisabledReason,
}) => {
  const cssClass = block({ extra: className });

  if (children) {
    return <Card.Footer className={cssClass}>{children}</Card.Footer>;
  }

  const primaryButtonElement = onPrimaryButtonClick ? (
    <SimpleTooltip content={primaryButtonDisabled ? primaryButtonDisabledReason : undefined}>
      <ModalButton
        className={block('button')}
        variant="primary"
        data-role={primaryButtonRole}
        onClick={onPrimaryButtonClick}
        disabled={primaryButtonDisabled}
      >
        {primaryButtonLabel}
      </ModalButton>
    </SimpleTooltip>
  ) : null;

  const secondaryButtonElement = onSecondaryButtonClick ? (
    <SimpleTooltip content={secondaryButtonDisabled ? secondaryButtonDisabledReason : undefined}>
      <ModalButton
        className={block('button')}
        variant="basic"
        data-role={secondaryButtonRole}
        onClick={onSecondaryButtonClick}
        disabled={secondaryButtonDisabled}
      >
        {secondaryButtonLabel}
      </ModalButton>
    </SimpleTooltip>
  ) : null;

  return (
    <Card.Footer className={cssClass}>
      {primaryButtonElement}
      {secondaryButtonElement}
    </Card.Footer>
  );
};

export default ModalFooter;
