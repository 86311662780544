// Libs
import React from 'react';
import logoImage from 'assets/logo/neptune-logo.svg';

import { bemBlock } from '@neptune/shared/venus-ui';

// App
import lightLogoImage from './assets/logo-light.svg';

// Module
import './KeycloakLogo.less';

const block = bemBlock('keycloak-logo');

type KeycloakLogoProps = {
  className?: string;
  size?: number;
  theme?: 'light' | 'dark';
};

export const KeycloakLogo: React.FC<KeycloakLogoProps> = ({ className, size, theme = 'dark' }) => {
  return (
    <header className={block({ extra: className })}>
      <a href="/" target="_blank">
        <img
          className={block('image')}
          src={theme === 'light' ? lightLogoImage : logoImage}
          height={size}
        />
      </a>
    </header>
  );
};
