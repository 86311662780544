import React from 'react';

import { bemBlock, Text, TextProps } from '@neptune/shared/venus-ui';

import './MiddleEllipsisRenderer.less';

type MiddleEllipsisRendererProps = {
  text: string;
  expandable?: boolean;
  firstPart: React.ReactNode;
  secondPart: React.ReactNode;
  'data-role'?: string;
} & Pick<
  TextProps<{}>,
  'size' | 'className' | 'fontFamily' | 'fontWeight' | 'lineHeight' | 'color'
>;

const block = bemBlock('middle-ellipsis-renderer');

export const MiddleEllipsisRenderer = ({
  text,
  color,
  firstPart,
  secondPart,
  className,
  fontWeight,
  fontFamily,
  lineHeight,
  expandable,
  size,
  'data-role': dataRole,
}: MiddleEllipsisRendererProps) => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  return (
    <Text
      className={block({
        extra: className,
      })}
      title={text}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      lineHeight={lineHeight}
      size={size}
      color={color}
      data-role={dataRole}
      onPointerEnter={expandable ? () => setIsHovered(true) : undefined}
      onPointerLeave={expandable ? () => setIsHovered(false) : undefined}
    >
      <div
        data-role="middle-elipsis-full-text"
        className={block({
          element: 'full-text',
          modifiers: {
            hidden: !expandable || (expandable && !isHovered),
          },
        })}
      >
        {text}
      </div>

      <Text
        className={block({
          element: 'truncated-start',
          modifiers: { hidden: isHovered },
        })}
      >
        {firstPart}
      </Text>
      <Text
        className={block({
          element: 'truncated-end',
          modifiers: { hidden: isHovered },
        })}
      >
        {ltrCharacter}
        {secondPart}
        {ltrCharacter}
      </Text>
    </Text>
  );
};

// Add LTR character to the beginning and end of secondPart
// secon part is in rtl direction so we need to add LTR otherwise
// non letter characters in the begginning and end of the text will be moved to the other side
const ltrCharacter = '\u200E';
