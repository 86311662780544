/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AvatarSourceEnum } from './AvatarSourceEnum';
import {
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
} from './AvatarSourceEnum';
import type { UserProfileLinksDTO } from './UserProfileLinksDTO';
import {
    UserProfileLinksDTOFromJSON,
    UserProfileLinksDTOFromJSONTyped,
    UserProfileLinksDTOToJSON,
} from './UserProfileLinksDTO';

/**
 * 
 * @export
 * @interface UserProfileDTO
 */
export interface UserProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    usernameHash: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDTO
     */
    hasLoggedToCli: boolean;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof UserProfileDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    shortInfo: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    userflowIdentityHash: string;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileDTO
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    intercomIdentityHash: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    biography: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDTO
     */
    hasCreatedExperiments: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    lastName: string;
    /**
     * 
     * @type {UserProfileLinksDTO}
     * @memberof UserProfileDTO
     */
    links: UserProfileLinksDTO;
}



/**
 * Check if a given object implements the UserProfileDTO interface.
 */
export function instanceOfUserProfileDTO(value: object): value is UserProfileDTO {
    if (!('usernameHash' in value) || value['usernameHash'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('hasLoggedToCli' in value) || value['hasLoggedToCli'] === undefined) return false;
    if (!('avatarSource' in value) || value['avatarSource'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('shortInfo' in value) || value['shortInfo'] === undefined) return false;
    if (!('userflowIdentityHash' in value) || value['userflowIdentityHash'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('intercomIdentityHash' in value) || value['intercomIdentityHash'] === undefined) return false;
    if (!('biography' in value) || value['biography'] === undefined) return false;
    if (!('hasCreatedExperiments' in value) || value['hasCreatedExperiments'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('avatarUrl' in value) || value['avatarUrl'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('links' in value) || value['links'] === undefined) return false;
    return true;
}

export function UserProfileDTOFromJSON(json: any): UserProfileDTO {
    return UserProfileDTOFromJSONTyped(json, false);
}

export function UserProfileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'usernameHash': json['usernameHash'],
        'email': json['email'],
        'hasLoggedToCli': json['hasLoggedToCli'],
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'firstName': json['firstName'],
        'shortInfo': json['shortInfo'],
        'userflowIdentityHash': json['userflowIdentityHash'],
        'created': (new Date(json['created'])),
        'intercomIdentityHash': json['intercomIdentityHash'],
        'biography': json['biography'],
        'hasCreatedExperiments': json['hasCreatedExperiments'],
        'username': json['username'],
        'avatarUrl': json['avatarUrl'],
        'lastName': json['lastName'],
        'links': UserProfileLinksDTOFromJSON(json['links']),
    };
}

export function UserProfileDTOToJSON(value?: UserProfileDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'usernameHash': value['usernameHash'],
        'email': value['email'],
        'hasLoggedToCli': value['hasLoggedToCli'],
        'avatarSource': AvatarSourceEnumToJSON(value['avatarSource']),
        'firstName': value['firstName'],
        'shortInfo': value['shortInfo'],
        'userflowIdentityHash': value['userflowIdentityHash'],
        'created': ((value['created']).toISOString()),
        'intercomIdentityHash': value['intercomIdentityHash'],
        'biography': value['biography'],
        'hasCreatedExperiments': value['hasCreatedExperiments'],
        'username': value['username'],
        'avatarUrl': value['avatarUrl'],
        'lastName': value['lastName'],
        'links': UserProfileLinksDTOToJSON(value['links']),
    };
}

