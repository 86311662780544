/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QueryLeaderboardParamsPaginationWithContinuationTokenDTO } from './QueryLeaderboardParamsPaginationWithContinuationTokenDTO';
import {
    QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSON,
    QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSONTyped,
    QueryLeaderboardParamsPaginationWithContinuationTokenDTOToJSON,
} from './QueryLeaderboardParamsPaginationWithContinuationTokenDTO';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO
 */
export interface QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO
     */
    openedGroup: string;
    /**
     * 
     * @type {QueryLeaderboardParamsPaginationWithContinuationTokenDTO}
     * @memberof QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO
     */
    pagination?: QueryLeaderboardParamsPaginationWithContinuationTokenDTO;
}

/**
 * Check if a given object implements the QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO interface.
 */
export function instanceOfQueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO(value: object): value is QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO {
    if (!('openedGroup' in value) || value['openedGroup'] === undefined) return false;
    return true;
}

export function QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSON(json: any): QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO {
    return QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'openedGroup': json['openedGroup'],
        'pagination': json['pagination'] == null ? undefined : QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSON(json['pagination']),
    };
}

export function QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOToJSON(value?: QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'openedGroup': value['openedGroup'],
        'pagination': QueryLeaderboardParamsPaginationWithContinuationTokenDTOToJSON(value['pagination']),
    };
}

