import React from 'react';

import { Button, Link } from '@neptune/shared/venus-ui';

import { Merge } from 'common/utility-types';

import { NormalizedLink } from './NormalizedLink';

type RouterLinkProps = Omit<
  Merge<React.ComponentProps<typeof Link>, React.ComponentProps<typeof NormalizedLink>>,
  'component'
>;

type RouterLinkButtonProps = Merge<RouterLinkProps, React.ComponentProps<typeof Button>>;

function RouterLink(props: RouterLinkProps) {
  return <Link {...props} component={NormalizedLink} />;
}

function RouterLinkButton(props: RouterLinkButtonProps) {
  return <Button {...props} component={NormalizedLink} />;
}

RouterLink.Button = RouterLinkButton;

export { RouterLink };
