import React from 'react';

import { namedColors } from '@neptune/shared/venus-domain';

import { bemBlock } from '../../modules/bem';
import { Dropdown } from '../dropdown/Dropdown';
import { DropdownToggleProps } from '../dropdown-toggle/DropdownToggle';
import { Layout } from '../layout/Layout';

import { ColorSwatch } from './ColorSwatch';

import './ColorPicker.less';

export type ColorPickerProps = {
  activeColor?: string;
  clearable?: boolean;
  colors?: string[];
  onColorChange(color?: string): void;
};

const block = bemBlock('n-color-picker');

export const ColorPicker: React.FC<ColorPickerProps> = ({
  activeColor,
  clearable = false,
  colors = namedColors,
  onColorChange,
}) => {
  function isActive(color?: string) {
    return color === activeColor;
  }

  return (
    <Dropdown
      toggle={<Toggle variant="basic" activeColor={activeColor} />}
      attachment="top left"
      targetAttachment="bottom left"
      constraints={[
        {
          to: 'window',
          attachment: 'together',
          pin: true,
        },
      ]}
    >
      {({ collapse }) => (
        <Dropdown.Menu data-role="color-picker">
          <Layout.Row className={block('swatches')} wrap="wrap">
            {(clearable ? [undefined, ...colors] : colors).map((color) => (
              <ColorSwatch
                className={block('swatch')}
                key={color || 'none'}
                color={color}
                isActive={isActive(color)}
                onClick={(color) => {
                  collapse();
                  onColorChange(color);
                }}
              />
            ))}
          </Layout.Row>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const Toggle = ({
  activeColor,
  elementRef,
  pressed,
  onToggle,
  variant,
}: DropdownToggleProps & { activeColor?: string }) => {
  const isNamedColor = activeColor?.[0] !== '#';

  return (
    <Dropdown.Toggle
      className={block('toggle')}
      data-role="color-picker-toggle"
      elementRef={elementRef}
      onToggle={onToggle}
      variant={variant}
    >
      <div
        className={block({
          element: 'activeColor',
          modifiers: {
            inactive: !activeColor,
            color: isNamedColor ? activeColor : undefined,
          },
        })}
        style={isNamedColor ? undefined : { backgroundColor: activeColor }}
      />
      <Dropdown.Toggle.Icon pressed={pressed} />
    </Dropdown.Toggle>
  );
};
