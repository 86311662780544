export function mapMapValues<K, V, RV>(map: Map<K, V>, fn: (v: V, k: K) => RV): Map<K, RV> {
  return new Map(
    (function* () {
      for (const entry of map.entries()) {
        const [k, v] = entry;
        const result: [K, V | RV] = entry;
        result[1] = fn(v, k);
        yield result as [K, RV];
      }
    })(),
  );
}

export function mapOfArraysPush<K, V>(map: Map<K, V[]>, key: K, value: V) {
  let keyValues = map.get(key);

  if (keyValues === undefined) {
    keyValues = [];
    map.set(key, keyValues);
  }

  keyValues.push(value);
}
