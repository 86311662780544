import React from 'react';

import { useDOMOnChange } from '../use-dom-on-change';

export const HiddenColorInput: React.FC<{
  defaultValue?: string;
  onChange?: (color: string) => void;
}> = ({ defaultValue, onChange }) => {
  const handleColorChange = React.useCallback(
    ({ target }: Event) => {
      if (!(target instanceof HTMLInputElement)) {
        return;
      }

      onChange?.(target.value);
    },
    [onChange],
  );
  const domOnChangeRef = useDOMOnChange(handleColorChange);

  return (
    <input
      style={{ width: 0, height: 0, visibility: 'hidden' }}
      ref={domOnChangeRef}
      type="color"
      defaultValue={defaultValue}
      onChange={undefined}
      data-role="hidden-color-input"
    />
  );
};
