/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeDTO } from './AttributeDTO';
import {
    AttributeDTOFromJSON,
    AttributeDTOFromJSONTyped,
    AttributeDTOToJSON,
} from './AttributeDTO';
import type { ExperimentTypeDTO } from './ExperimentTypeDTO';
import {
    ExperimentTypeDTOFromJSON,
    ExperimentTypeDTOFromJSONTyped,
    ExperimentTypeDTOToJSON,
} from './ExperimentTypeDTO';

/**
 * 
 * @export
 * @interface AttributesDTO
 */
export interface AttributesDTO {
    /**
     * 
     * @type {Array<AttributeDTO>}
     * @memberof AttributesDTO
     */
    attributes: Array<AttributeDTO>;
    /**
     * 
     * @type {string}
     * @memberof AttributesDTO
     */
    experimentId: string;
    /**
     * 
     * @type {string}
     * @memberof AttributesDTO
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof AttributesDTO
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof AttributesDTO
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof AttributesDTO
     */
    projectName: string;
    /**
     * 
     * @type {ExperimentTypeDTO}
     * @memberof AttributesDTO
     */
    type: ExperimentTypeDTO;
}



/**
 * Check if a given object implements the AttributesDTO interface.
 */
export function instanceOfAttributesDTO(value: object): value is AttributesDTO {
    if (!('attributes' in value) || value['attributes'] === undefined) return false;
    if (!('experimentId' in value) || value['experimentId'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function AttributesDTOFromJSON(json: any): AttributesDTO {
    return AttributesDTOFromJSONTyped(json, false);
}

export function AttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AttributeDTOFromJSON)),
        'experimentId': json['experimentId'],
        'organizationId': json['organizationId'],
        'organizationName': json['organizationName'],
        'projectId': json['projectId'],
        'projectName': json['projectName'],
        'type': ExperimentTypeDTOFromJSON(json['type']),
    };
}

export function AttributesDTOToJSON(value?: AttributesDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributes': ((value['attributes'] as Array<any>).map(AttributeDTOToJSON)),
        'experimentId': value['experimentId'],
        'organizationId': value['organizationId'],
        'organizationName': value['organizationName'],
        'projectId': value['projectId'],
        'projectName': value['projectName'],
        'type': ExperimentTypeDTOToJSON(value['type']),
    };
}

