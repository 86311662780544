/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubscriptionStateDTO } from './SubscriptionStateDTO';
import {
    SubscriptionStateDTOFromJSON,
    SubscriptionStateDTOFromJSONTyped,
    SubscriptionStateDTOToJSON,
} from './SubscriptionStateDTO';
import type { WorkspaceModeDTO } from './WorkspaceModeDTO';
import {
    WorkspaceModeDTOFromJSON,
    WorkspaceModeDTOFromJSONTyped,
    WorkspaceModeDTOToJSON,
} from './WorkspaceModeDTO';

/**
 * 
 * @export
 * @interface WorkspaceStatusDTO
 */
export interface WorkspaceStatusDTO {
    /**
     * 
     * @type {SubscriptionStateDTO}
     * @memberof WorkspaceStatusDTO
     */
    subscription?: SubscriptionStateDTO;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatusDTO
     */
    storageBytesLimit: number;
    /**
     * 
     * @type {WorkspaceModeDTO}
     * @memberof WorkspaceStatusDTO
     */
    mode: WorkspaceModeDTO;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatusDTO
     */
    monitoringSecondsAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatusDTO
     */
    activeProjectsUsage: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatusDTO
     */
    storageBytesAvailable: number;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceStatusDTO
     */
    trialEndDate?: Date;
}



/**
 * Check if a given object implements the WorkspaceStatusDTO interface.
 */
export function instanceOfWorkspaceStatusDTO(value: object): value is WorkspaceStatusDTO {
    if (!('storageBytesLimit' in value) || value['storageBytesLimit'] === undefined) return false;
    if (!('mode' in value) || value['mode'] === undefined) return false;
    if (!('activeProjectsUsage' in value) || value['activeProjectsUsage'] === undefined) return false;
    if (!('storageBytesAvailable' in value) || value['storageBytesAvailable'] === undefined) return false;
    return true;
}

export function WorkspaceStatusDTOFromJSON(json: any): WorkspaceStatusDTO {
    return WorkspaceStatusDTOFromJSONTyped(json, false);
}

export function WorkspaceStatusDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceStatusDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'subscription': json['subscription'] == null ? undefined : SubscriptionStateDTOFromJSON(json['subscription']),
        'storageBytesLimit': json['storageBytesLimit'],
        'mode': WorkspaceModeDTOFromJSON(json['mode']),
        'monitoringSecondsAvailable': json['monitoringSecondsAvailable'] == null ? undefined : json['monitoringSecondsAvailable'],
        'activeProjectsUsage': json['activeProjectsUsage'],
        'storageBytesAvailable': json['storageBytesAvailable'],
        'trialEndDate': json['trialEndDate'] == null ? undefined : (new Date(json['trialEndDate'])),
    };
}

export function WorkspaceStatusDTOToJSON(value?: WorkspaceStatusDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'subscription': SubscriptionStateDTOToJSON(value['subscription']),
        'storageBytesLimit': value['storageBytesLimit'],
        'mode': WorkspaceModeDTOToJSON(value['mode']),
        'monitoringSecondsAvailable': value['monitoringSecondsAvailable'],
        'activeProjectsUsage': value['activeProjectsUsage'],
        'storageBytesAvailable': value['storageBytesAvailable'],
        'trialEndDate': value['trialEndDate'] == null ? undefined : ((value['trialEndDate']).toISOString()),
    };
}

