import React from 'react';

import { Banner, bemBlock, Icon, Layout, Link } from '@neptune/shared/venus-ui';

import { CommandFrame } from '../command-frame/CommandFrame';

import './BannerNotice.less';

const block = bemBlock('banner-notice');

export const BannerNotice: React.FC<{
  command?: string;
  isVisible?: boolean;
  seeMoreHref?: string;
}> = ({ children, command, isVisible = false, seeMoreHref }) => {
  return (
    <Layout.Element
      className={block({ modifiers: { 'is-visible': isVisible } })}
      data-role="banner-notice"
    >
      <Banner.Info size="small" alignItems="start">
        {children}
        {command && <CommandFrame command={command} prefix="" theme="none" oneliner />}
        {seeMoreHref && (
          <Link className={block('link')} href={seeMoreHref} external>
            Learn more in the docs&nbsp;
            <Icon glyph="external-link" />
          </Link>
        )}
      </Banner.Info>
    </Layout.Element>
  );
};
