import React from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { bemBlock } from '../../modules/bem';

import './ScrollAreaThumb.less';

const block = bemBlock('n-scroll-area-thumb');

export const ScrollAreaThumb = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Thumb>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Thumb>
>(({ className, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.Thumb
      ref={ref}
      className={block({
        extra: className,
      })}
      {...props}
    />
  );
});
