import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { bemBlock } from '../../modules/bem';
import { Button } from '../button/Button';
import { Collapse } from '../collapse/Collapse';
import { Icon } from '../icon/Icon';
import { Layout } from '../layout/Layout';
import { Section } from '../section/Section';
import { Text } from '../text/Text';

import './BulkOperationsClipboard.less';

type BulkOperationsBaseProps = {
  children: React.ReactNode;
  className?: string;
  opened: boolean;
};

type BulkOperationsProps = {
  title: string;
  opened: boolean;
  className?: string;
  onClose: () => void;
  actions: React.ReactNode;
  wrap?: React.ComponentProps<typeof Layout.Row>['wrap'];
};

const block = bemBlock('bulk-operations-clipboard');

function BulkOperationsClipboardBase({ children, opened, className }: BulkOperationsBaseProps) {
  return (
    <CSSTransition
      timeout={300}
      mountOnEnter
      unmountOnExit
      in={opened}
      classNames={block('animation')}
    >
      <Section
        withBorder
        opaque="white"
        withPadding="sm"
        className={block({ extra: className })}
        data-role="bulk-operations-clipboard"
      >
        {children}
      </Section>
    </CSSTransition>
  );
}

type BulkOperationsClipboardCollapseProps = BulkOperationsProps & {
  children: React.ReactNode;
  id: string;
};

function BulkOperationsClipboardCollapse({
  className,
  children,
  onClose,
  actions,
  opened,
  title,
  wrap,
  id,
}: BulkOperationsClipboardCollapseProps) {
  const [collapsed, setCollapsed] = React.useState(true);

  const handleToggle = React.useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  return (
    <BulkOperationsClipboardBase opened={opened} className={className}>
      <Collapse
        title={<Text size="sm">{title}</Text>}
        collapsed={collapsed}
        icon="chevron-down"
        wrap={wrap}
        span="grow"
        extra={
          <Layout.Row span="shrink" spacedChildren="xs">
            {actions}
            <Button variant="secondary-text" square onClick={onClose}>
              <Icon glyph="cross" />
            </Button>
          </Layout.Row>
        }
        spacedChildren="md"
        onToggle={handleToggle}
        id={id}
      >
        {children}
      </Collapse>
    </BulkOperationsClipboardBase>
  );
}

function BulkOperationsClipboardCompact({
  className,
  onClose,
  actions,
  opened,
  title,
  wrap,
}: BulkOperationsProps) {
  return (
    <BulkOperationsClipboardBase opened={opened} className={className}>
      <Layout.Row span="greedy" wrap={wrap} spacedChildren="sm" alignItems="center">
        <Layout.Row spacedChildren="sm" alignItems="center">
          <Text size="sm" fontWeight="bold">
            {title}
          </Text>
        </Layout.Row>
        <Layout.Row span="auto" justifyContent="end" spacedChildren="xs">
          {actions}
          <Button square variant="secondary-text" onClick={onClose}>
            <Icon glyph="cross" />
          </Button>
        </Layout.Row>
      </Layout.Row>
    </BulkOperationsClipboardBase>
  );
}

export const BulkOperationsClipboard = {
  Collapse: BulkOperationsClipboardCollapse,
  Compact: BulkOperationsClipboardCompact,
};
