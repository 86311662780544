/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectVisibilityDTO } from './ProjectVisibilityDTO';
import {
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './ProjectVisibilityDTO';

/**
 * 
 * @export
 * @interface ProjectVisibilityRestrictedUpdateRequestDTO
 */
export interface ProjectVisibilityRestrictedUpdateRequestDTO {
    /**
     * 
     * @type {Array<ProjectVisibilityDTO>}
     * @memberof ProjectVisibilityRestrictedUpdateRequestDTO
     */
    disabledByAdmin: Array<ProjectVisibilityDTO>;
    /**
     * 
     * @type {ProjectVisibilityDTO}
     * @memberof ProjectVisibilityRestrictedUpdateRequestDTO
     */
    defaultValue: ProjectVisibilityDTO;
}



/**
 * Check if a given object implements the ProjectVisibilityRestrictedUpdateRequestDTO interface.
 */
export function instanceOfProjectVisibilityRestrictedUpdateRequestDTO(value: object): value is ProjectVisibilityRestrictedUpdateRequestDTO {
    if (!('disabledByAdmin' in value) || value['disabledByAdmin'] === undefined) return false;
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    return true;
}

export function ProjectVisibilityRestrictedUpdateRequestDTOFromJSON(json: any): ProjectVisibilityRestrictedUpdateRequestDTO {
    return ProjectVisibilityRestrictedUpdateRequestDTOFromJSONTyped(json, false);
}

export function ProjectVisibilityRestrictedUpdateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectVisibilityRestrictedUpdateRequestDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'disabledByAdmin': ((json['disabledByAdmin'] as Array<any>).map(ProjectVisibilityDTOFromJSON)),
        'defaultValue': ProjectVisibilityDTOFromJSON(json['defaultValue']),
    };
}

export function ProjectVisibilityRestrictedUpdateRequestDTOToJSON(value?: ProjectVisibilityRestrictedUpdateRequestDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'disabledByAdmin': ((value['disabledByAdmin'] as Array<any>).map(ProjectVisibilityDTOToJSON)),
        'defaultValue': ProjectVisibilityDTOToJSON(value['defaultValue']),
    };
}

