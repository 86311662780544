/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringTimeAndPackageHistoryPeriodDTO } from './MonitoringTimeAndPackageHistoryPeriodDTO';
import {
    MonitoringTimeAndPackageHistoryPeriodDTOFromJSON,
    MonitoringTimeAndPackageHistoryPeriodDTOFromJSONTyped,
    MonitoringTimeAndPackageHistoryPeriodDTOToJSON,
} from './MonitoringTimeAndPackageHistoryPeriodDTO';

/**
 * 
 * @export
 * @interface MonitoringTimeAndPackageHistoryDTO
 */
export interface MonitoringTimeAndPackageHistoryDTO {
    /**
     * 
     * @type {Array<MonitoringTimeAndPackageHistoryPeriodDTO>}
     * @memberof MonitoringTimeAndPackageHistoryDTO
     */
    results: Array<MonitoringTimeAndPackageHistoryPeriodDTO>;
}

/**
 * Check if a given object implements the MonitoringTimeAndPackageHistoryDTO interface.
 */
export function instanceOfMonitoringTimeAndPackageHistoryDTO(value: object): value is MonitoringTimeAndPackageHistoryDTO {
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function MonitoringTimeAndPackageHistoryDTOFromJSON(json: any): MonitoringTimeAndPackageHistoryDTO {
    return MonitoringTimeAndPackageHistoryDTOFromJSONTyped(json, false);
}

export function MonitoringTimeAndPackageHistoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTimeAndPackageHistoryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(MonitoringTimeAndPackageHistoryPeriodDTOFromJSON)),
    };
}

export function MonitoringTimeAndPackageHistoryDTOToJSON(value?: MonitoringTimeAndPackageHistoryDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': ((value['results'] as Array<any>).map(MonitoringTimeAndPackageHistoryPeriodDTOToJSON)),
    };
}

