import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Card } from '../card/Card';
import { LayoutFill } from '../layout-fill/LayoutFill';

import ModalClose from './ModalClose';

import './ModalHeader.less';

export interface ModalHeaderProps {
  className?: string;
  title?: React.ReactNode;
  onClose?: () => void;
}

const block = bemBlock('n-modal-header');

const ModalHeader: React.FC<ModalHeaderProps> = ({
  className,
  title,
  children,
  onClose,
  ...props
}) => {
  const cssClass = block({ extra: className });

  if (children) {
    return <Card.Section className={cssClass}>{children}</Card.Section>;
  }

  const titleElement = <Card.HeaderText className={block('title')}>{title}</Card.HeaderText>;

  const closeElement = onClose ? <ModalClose onClose={onClose} /> : null;

  return (
    <Card.Section className={cssClass} {...props} alignItems="baseline">
      {titleElement}
      <LayoutFill />
      {closeElement}
    </Card.Section>
  );
};

export default ModalHeader;
