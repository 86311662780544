import React from 'react';

import { bemBlock } from '../../modules/bem';

import './Switcher.less';

export type SwitcherProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (newState: boolean) => void;
  onClick?: React.MouseEventHandler;
  'data-role'?: string;
};

const block = bemBlock('switcher');

export const Switcher: React.FC<SwitcherProps> = React.memo(function Switcher({
  checked,
  disabled,
  onChange,
  onClick,
  'data-role': dataRole,
}) {
  function toggle() {
    if (!disabled && onChange) {
      onChange(!checked);
    }
  }

  const handleClick: React.MouseEventHandler = (e) => {
    onClick?.(e);

    if (e.defaultPrevented) {
      return;
    }

    toggle();
  };

  return (
    <div
      role="switch"
      data-role={dataRole}
      aria-checked={checked}
      data-checked={checked}
      className={block({
        modifiers: { checked, disabled },
      })}
      onClick={handleClick}
    />
  );
});
