/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardVersionDTO
 */
export interface DashboardVersionDTO {
    /**
     * 
     * @type {Date}
     * @memberof DashboardVersionDTO
     */
    createdTime: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardVersionDTO
     */
    draft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DashboardVersionDTO
     */
    lastUpdatedTime: Date;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    type: DashboardVersionDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    versionBranch: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    versionName?: string;
}


/**
 * @export
 */
export const DashboardVersionDTOTypeEnum = {
    Compare: 'compare',
    Experiment: 'experiment',
    Report: 'report'
} as const;
export type DashboardVersionDTOTypeEnum = typeof DashboardVersionDTOTypeEnum[keyof typeof DashboardVersionDTOTypeEnum];


/**
 * Check if a given object implements the DashboardVersionDTO interface.
 */
export function instanceOfDashboardVersionDTO(value: object): value is DashboardVersionDTO {
    if (!('createdTime' in value) || value['createdTime'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lastUpdatedTime' in value) || value['lastUpdatedTime'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('versionBranch' in value) || value['versionBranch'] === undefined) return false;
    return true;
}

export function DashboardVersionDTOFromJSON(json: any): DashboardVersionDTO {
    return DashboardVersionDTOFromJSONTyped(json, false);
}

export function DashboardVersionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardVersionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'createdTime': (new Date(json['createdTime'])),
        'draft': json['draft'] == null ? undefined : json['draft'],
        'id': json['id'],
        'lastUpdatedTime': (new Date(json['lastUpdatedTime'])),
        'name': json['name'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'type': json['type'],
        'versionBranch': json['versionBranch'],
        'versionDescription': json['versionDescription'] == null ? undefined : json['versionDescription'],
        'versionName': json['versionName'] == null ? undefined : json['versionName'],
    };
}

export function DashboardVersionDTOToJSON(value?: DashboardVersionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createdTime': ((value['createdTime']).toISOString()),
        'draft': value['draft'],
        'id': value['id'],
        'lastUpdatedTime': ((value['lastUpdatedTime']).toISOString()),
        'name': value['name'],
        'owner': value['owner'],
        'type': value['type'],
        'versionBranch': value['versionBranch'],
        'versionDescription': value['versionDescription'],
        'versionName': value['versionName'],
    };
}

