import React from 'react';

import { Text } from '@neptune/shared/venus-ui';

import config from 'config';

import { AttributeType } from './attribute-model';

const attributeTooltips: Partial<Record<AttributeType, React.ReactElement>> = {
  bool: (
    <span>
      You can track boolean values in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'] = boolean_value</Text>
    </span>
  ),
  datetime: (
    <span>
      You can track time in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'] = datetime_value</Text>
    </span>
  ),
  float: (
    <span>
      You can track floating point numeric values in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'] = num_value</Text>
    </span>
  ),
  floatSeries: (
    <span>
      You can log numeric series in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'].append(num_value)</Text>
    </span>
  ),
  file: (
    <span>
      You can track single files in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'].upload(path_to_file)</Text>
    </span>
  ),
  fileSet: (
    <span>
      You can track files and folders in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'].upload_files(path_to_folder)</Text>
    </span>
  ),
  imageSeries: (
    <span>
      You can log file series in python with{' '}
      <Text fontFamily="monospace">
        run['namespace/field_name'].append(neptune.types.File(image_or_path_to_file))
      </Text>
    </span>
  ),
  int: (
    <span>
      You can track integer numeric values in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'] = num_value</Text>
    </span>
  ),
  string: (
    <span>
      You can track text values in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'] = text</Text>
    </span>
  ),
  stringSeries: (
    <span>
      You can log text series in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'].append(text)</Text>
    </span>
  ),
  stringSet: (
    <span>
      You can add string set in python with{' '}
      <Text fontFamily="monospace">run['namespace/field_name'].add([text1, text2])</Text>
    </span>
  ),
};

export function getAttributeTooltip(attributeType: AttributeType): React.ReactElement | undefined {
  return config.withIngestApi ? undefined : attributeTooltips[attributeType];
}
