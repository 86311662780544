/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientVersionsConfigDTO
 */
export interface ClientVersionsConfigDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientVersionsConfigDTO
     */
    minRecommendedVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientVersionsConfigDTO
     */
    minCompatibleVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientVersionsConfigDTO
     */
    maxCompatibleVersion?: string;
}

/**
 * Check if a given object implements the ClientVersionsConfigDTO interface.
 */
export function instanceOfClientVersionsConfigDTO(value: object): value is ClientVersionsConfigDTO {
    return true;
}

export function ClientVersionsConfigDTOFromJSON(json: any): ClientVersionsConfigDTO {
    return ClientVersionsConfigDTOFromJSONTyped(json, false);
}

export function ClientVersionsConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientVersionsConfigDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'minRecommendedVersion': json['minRecommendedVersion'] == null ? undefined : json['minRecommendedVersion'],
        'minCompatibleVersion': json['minCompatibleVersion'] == null ? undefined : json['minCompatibleVersion'],
        'maxCompatibleVersion': json['maxCompatibleVersion'] == null ? undefined : json['maxCompatibleVersion'],
    };
}

export function ClientVersionsConfigDTOToJSON(value?: ClientVersionsConfigDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'minRecommendedVersion': value['minRecommendedVersion'],
        'minCompatibleVersion': value['minCompatibleVersion'],
        'maxCompatibleVersion': value['maxCompatibleVersion'],
    };
}

