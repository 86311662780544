/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Custom
 */
export interface Custom {
    /**
     * 
     * @type {string}
     * @memberof Custom
     */
    attribute?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Custom
     */
    attributes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Custom
     */
    customXFormula?: string;
}

/**
 * Check if a given object implements the Custom interface.
 */
export function instanceOfCustom(value: object): value is Custom {
    return true;
}

export function CustomFromJSON(json: any): Custom {
    return CustomFromJSONTyped(json, false);
}

export function CustomFromJSONTyped(json: any, ignoreDiscriminator: boolean): Custom {
    if (json == null) {
        return json;
    }
    return {
        
        'attribute': json['attribute'] == null ? undefined : json['attribute'],
        'attributes': json['attributes'] == null ? undefined : json['attributes'],
        'customXFormula': json['customXFormula'] == null ? undefined : json['customXFormula'],
    };
}

export function CustomToJSON(value?: Custom | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attribute': value['attribute'],
        'attributes': value['attributes'],
        'customXFormula': value['customXFormula'],
    };
}

