/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StringPointValueDTO } from './StringPointValueDTO';
import {
    StringPointValueDTOFromJSON,
    StringPointValueDTOFromJSONTyped,
    StringPointValueDTOToJSON,
} from './StringPointValueDTO';

/**
 * 
 * @export
 * @interface StringSeriesValuesDTO
 */
export interface StringSeriesValuesDTO {
    /**
     * 
     * @type {number}
     * @memberof StringSeriesValuesDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<StringPointValueDTO>}
     * @memberof StringSeriesValuesDTO
     */
    values: Array<StringPointValueDTO>;
}

/**
 * Check if a given object implements the StringSeriesValuesDTO interface.
 */
export function instanceOfStringSeriesValuesDTO(value: object): value is StringSeriesValuesDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function StringSeriesValuesDTOFromJSON(json: any): StringSeriesValuesDTO {
    return StringSeriesValuesDTOFromJSONTyped(json, false);
}

export function StringSeriesValuesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringSeriesValuesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'values': ((json['values'] as Array<any>).map(StringPointValueDTOFromJSON)),
    };
}

export function StringSeriesValuesDTOToJSON(value?: StringSeriesValuesDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalItemCount': value['totalItemCount'],
        'values': ((value['values'] as Array<any>).map(StringPointValueDTOToJSON)),
    };
}

