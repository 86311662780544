import { ScrollAreaCorner } from './ScrollAreaCorner';
import { ScrollAreaRoot } from './ScrollAreaRoot';
import { ScrollAreaScrollbar } from './ScrollAreaScrollbar';
import { ScrollAreaThumb } from './ScrollAreaThumb';
import { ScrollAreaViewport } from './ScrollAreaViewport';

export const ScrollArea = Object.assign(ScrollAreaRoot, {
  Viewport: ScrollAreaViewport,
  Scrollbar: ScrollAreaScrollbar,
  Thumb: ScrollAreaThumb,
  Corner: ScrollAreaCorner,
});
