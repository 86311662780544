// Libs
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';

import { Layout } from '@neptune/shared/venus-ui';

// App
import { CopyToClipboard } from '../../copy-to-clipboard/CopyToClipboard';
// Module
import type { PropertyDiffTableEntry } from '../types';

export const OldValueRenderer: React.FC<{ data: PropertyDiffTableEntry }> = ({ data }) => {
  const value = data.oldValue && data.renderer ? data.renderer(data.oldValue) : data.oldValue;
  return (
    <WithPaddingRenderer>
      <Layout.Row span="auto" alignItems="center" spacedChildren="xs">
        <span>{value || '-'}</span>
        {data.copyable && value && <CopyToClipboard text={`${value}`} />}
      </Layout.Row>
    </WithPaddingRenderer>
  );
};
