/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';

/**
 * 
 * @export
 * @interface ProjectServiceAccountsUpdateDTO
 */
export interface ProjectServiceAccountsUpdateDTO {
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ProjectServiceAccountsUpdateDTO
     */
    role: ProjectRoleDTO;
}



/**
 * Check if a given object implements the ProjectServiceAccountsUpdateDTO interface.
 */
export function instanceOfProjectServiceAccountsUpdateDTO(value: object): value is ProjectServiceAccountsUpdateDTO {
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function ProjectServiceAccountsUpdateDTOFromJSON(json: any): ProjectServiceAccountsUpdateDTO {
    return ProjectServiceAccountsUpdateDTOFromJSONTyped(json, false);
}

export function ProjectServiceAccountsUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectServiceAccountsUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'role': ProjectRoleDTOFromJSON(json['role']),
    };
}

export function ProjectServiceAccountsUpdateDTOToJSON(value?: ProjectServiceAccountsUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'role': ProjectRoleDTOToJSON(value['role']),
    };
}

