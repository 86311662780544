import { hexToRgba } from 'common/color';

import { EmblemColor, EmblemVariant } from '../types';

export const calculateColorsForVariant = (color: EmblemColor, variant: EmblemVariant) => {
  const black = '#111213';
  const white = '#fff';
  const active = '#018EF8';

  return {
    light: {
      emblemBackground: hexToRgba(color, 0.2),
      emblemBackgroundHover: hexToRgba(color, 0.3),
      emblemBackgroundActive: color,
      emblemBorder: 'transparent',
      emblemBorderHover: 'transparent',
      emblemBorderActive: 'transparent',
      emblemOutlineActive: 'transparent',
      emblemDetail: color,
      emblemDetailActive: white,
      emblemText: hexToRgba(black, 0.7),
      emblemTextActive: white,
      emblemEndDetailWrapperBackgroundHover: hexToRgba(color, 0.2),
      emblemEndDetailWrapperBackgroundHoverActive: hexToRgba(black, 0.2),
    },
    outlined: {
      emblemBackground: hexToRgba(color, 0.2),
      emblemBackgroundHover: hexToRgba(color, 0.3),
      emblemBackgroundActive: color,
      emblemBorder: hexToRgba(color, 0.7),
      emblemBorderHover: hexToRgba(color, 0.8),
      emblemBorderActive: hexToRgba(black, 0.2),
      emblemOutlineActive: 'transparent',
      emblemDetail: color,
      emblemDetailActive: white,
      emblemText: hexToRgba(black, 0.8),
      emblemTextActive: white,
      emblemEndDetailWrapperBackgroundHover: hexToRgba(color, 0.2),
      emblemEndDetailWrapperBackgroundHoverActive: hexToRgba(black, 0.2),
    },
    solid: {
      emblemBackground: hexToRgba(color, 0.9),
      emblemBackgroundHover: hexToRgba(color, 0.9),
      emblemBackgroundActive: color,
      emblemBorder: 'transparent',
      emblemBorderHover: 'transparent',
      emblemBorderActive: 'white',
      emblemOutlineActive: active,
      emblemDetail: white,
      emblemDetailActive: white,
      emblemText: white,
      emblemTextActive: white,
      emblemEndDetailWrapperBackgroundHover: hexToRgba(black, 0.2),
      emblemEndDetailWrapperBackgroundHoverActive: hexToRgba(black, 0.2),
    },
  }[variant];
};
