/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface AttributeDefinitionDTO
 */
export interface AttributeDefinitionDTO {
    /**
     * 
     * @type {string}
     * @memberof AttributeDefinitionDTO
     */
    name: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof AttributeDefinitionDTO
     */
    type: AttributeTypeDTO;
}



/**
 * Check if a given object implements the AttributeDefinitionDTO interface.
 */
export function instanceOfAttributeDefinitionDTO(value: object): value is AttributeDefinitionDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function AttributeDefinitionDTOFromJSON(json: any): AttributeDefinitionDTO {
    return AttributeDefinitionDTOFromJSONTyped(json, false);
}

export function AttributeDefinitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeDefinitionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': AttributeTypeDTOFromJSON(json['type']),
    };
}

export function AttributeDefinitionDTOToJSON(value?: AttributeDefinitionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'type': AttributeTypeDTOToJSON(value['type']),
    };
}

