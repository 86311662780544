/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeStyleIdentifierDTO } from './AttributeStyleIdentifierDTO';
import {
    AttributeStyleIdentifierDTOFromJSON,
    AttributeStyleIdentifierDTOFromJSONTyped,
    AttributeStyleIdentifierDTOToJSON,
} from './AttributeStyleIdentifierDTO';

/**
 * 
 * @export
 * @interface AttributeStyleSettingsDTO
 */
export interface AttributeStyleSettingsDTO {
    /**
     * 
     * @type {AttributeStyleIdentifierDTO}
     * @memberof AttributeStyleSettingsDTO
     */
    attribute: AttributeStyleIdentifierDTO;
    /**
     * font color of attribute value
     * @type {string}
     * @memberof AttributeStyleSettingsDTO
     */
    color: string;
}

/**
 * Check if a given object implements the AttributeStyleSettingsDTO interface.
 */
export function instanceOfAttributeStyleSettingsDTO(value: object): value is AttributeStyleSettingsDTO {
    if (!('attribute' in value) || value['attribute'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    return true;
}

export function AttributeStyleSettingsDTOFromJSON(json: any): AttributeStyleSettingsDTO {
    return AttributeStyleSettingsDTOFromJSONTyped(json, false);
}

export function AttributeStyleSettingsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeStyleSettingsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attribute': AttributeStyleIdentifierDTOFromJSON(json['attribute']),
        'color': json['color'],
    };
}

export function AttributeStyleSettingsDTOToJSON(value?: AttributeStyleSettingsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attribute': AttributeStyleIdentifierDTOToJSON(value['attribute']),
        'color': value['color'],
    };
}

