/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrganizationRoleDTO = {
    Member: 'member',
    Owner: 'owner'
} as const;
export type OrganizationRoleDTO = typeof OrganizationRoleDTO[keyof typeof OrganizationRoleDTO];


export function instanceOfOrganizationRoleDTO(value: any): boolean {
    for (const key in OrganizationRoleDTO) {
        if (Object.prototype.hasOwnProperty.call(OrganizationRoleDTO, key)) {
            if (OrganizationRoleDTO[key as keyof typeof OrganizationRoleDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrganizationRoleDTOFromJSON(json: any): OrganizationRoleDTO {
    return OrganizationRoleDTOFromJSONTyped(json, false);
}

export function OrganizationRoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationRoleDTO {
    return json as OrganizationRoleDTO;
}

export function OrganizationRoleDTOToJSON(value?: OrganizationRoleDTO | null): any {
    return value as any;
}

