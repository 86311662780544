import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';
import { Text } from '../text/Text';

import './InputSelectItem.less';

const block = bemBlock('input-select-item');

export const Item = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={block({ modifiers: { check: true }, extra: className })}
    {...props}
  >
    <SelectPrimitive.ItemText asChild>
      <Text size="sm">{children}</Text>
    </SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator>
      <Icon glyph="check" />
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));
