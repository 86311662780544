/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsFieldDTO
 */
export interface QueryLeaderboardParamsFieldDTO {
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsFieldDTO
     */
    aggregationMode?: QueryLeaderboardParamsFieldDTOAggregationModeEnum;
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsFieldDTO
     */
    name: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof QueryLeaderboardParamsFieldDTO
     */
    type: AttributeTypeDTO;
}


/**
 * @export
 */
export const QueryLeaderboardParamsFieldDTOAggregationModeEnum = {
    Last: 'last',
    Min: 'min',
    Max: 'max',
    Average: 'average',
    Variance: 'variance',
    Auto: 'auto'
} as const;
export type QueryLeaderboardParamsFieldDTOAggregationModeEnum = typeof QueryLeaderboardParamsFieldDTOAggregationModeEnum[keyof typeof QueryLeaderboardParamsFieldDTOAggregationModeEnum];


/**
 * Check if a given object implements the QueryLeaderboardParamsFieldDTO interface.
 */
export function instanceOfQueryLeaderboardParamsFieldDTO(value: object): value is QueryLeaderboardParamsFieldDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function QueryLeaderboardParamsFieldDTOFromJSON(json: any): QueryLeaderboardParamsFieldDTO {
    return QueryLeaderboardParamsFieldDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsFieldDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsFieldDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'aggregationMode': json['aggregationMode'] == null ? undefined : json['aggregationMode'],
        'name': json['name'],
        'type': AttributeTypeDTOFromJSON(json['type']),
    };
}

export function QueryLeaderboardParamsFieldDTOToJSON(value?: QueryLeaderboardParamsFieldDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aggregationMode': value['aggregationMode'],
        'name': value['name'],
        'type': AttributeTypeDTOToJSON(value['type']),
    };
}

