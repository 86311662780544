// Libs
import React from 'react';

import type { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { bemBlock, Icon, Layout, Link, Text } from '@neptune/shared/venus-ui';

import './EmptyInfo.less';
const block = bemBlock('empty-info');

type LinkData = {
  href: string;
  caption: string;
};

type EmptyInfoProps = {
  className?: string;
  glyph?: GlyphName;
  image?: string;
  description?: React.ReactNode;
  ctaButton?: React.ReactNode;
  ctaLink?: LinkData;
  footerLinks?: LinkData[];
  'data-role'?: string;
};

export const EmptyInfo: React.FC<EmptyInfoProps> = ({
  children,
  className,
  glyph = 'flask',
  image,
  description,
  ctaButton,
  ctaLink,
  footerLinks = [],
  'data-role': dataRole,
}) => {
  return (
    <Layout.Column overflow="auto" data-role={dataRole}>
      <Layout.Fill />
      <Layout.Column
        alignItems="center"
        className={block({ extra: className })}
        spacedChildren="md"
        withPadding={['md', 'none', 'none']}
        span="auto"
      >
        {image ? (
          <img className={block('image')} src={image} alt="" />
        ) : (
          <Icon glyph={glyph} color="decorator" size="2x" />
        )}

        {children && (
          <Text color="text-secondary" size="md" lineHeight="xl" align="center">
            {children}
          </Text>
        )}
        {(ctaButton || ctaLink || description) && (
          <Layout.Column alignItems="center" spacedChildren={description ? 'md' : 'xs'} span="auto">
            {description && <Text size="sm">{description}</Text>}
            {ctaButton}
            {ctaButton && ctaLink && <Text color="text-secondary" size="sm" children="or" />}
            {ctaLink && (
              <Link external href={ctaLink.href}>
                {ctaLink.caption}&nbsp;
                <Icon glyph="external-link" />
              </Link>
            )}
          </Layout.Column>
        )}
      </Layout.Column>
      <Layout.Fill />
      {footerLinks && footerLinks.length > 0 && (
        <Layout.Column alignItems="center" spacedChildren="sm" span="auto" withPadding="xl">
          <Text size="sm">What else you can do:</Text>
          <Layout.Row alignSelf="center" alignItems="center" spacedChildren="md">
            {footerLinks.map((link, index) => [
              index == 0 ? null : <Text color="decorator" children=" | " />,
              <Link external size="sm" fontWeight="normal" href={link.href}>
                {link.caption}&nbsp;
                <Icon glyph="external-link" />
              </Link>,
            ])}
          </Layout.Row>
        </Layout.Column>
      )}
    </Layout.Column>
  );
};
