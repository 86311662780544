/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryLeaderboardParamsSuggestionsParamsDTO
 */
export interface QueryLeaderboardParamsSuggestionsParamsDTO {
    /**
     * 
     * @type {boolean}
     * @memberof QueryLeaderboardParamsSuggestionsParamsDTO
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsSuggestionsParamsDTO
     */
    viewId?: string;
}

/**
 * Check if a given object implements the QueryLeaderboardParamsSuggestionsParamsDTO interface.
 */
export function instanceOfQueryLeaderboardParamsSuggestionsParamsDTO(value: object): value is QueryLeaderboardParamsSuggestionsParamsDTO {
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function QueryLeaderboardParamsSuggestionsParamsDTOFromJSON(json: any): QueryLeaderboardParamsSuggestionsParamsDTO {
    return QueryLeaderboardParamsSuggestionsParamsDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsSuggestionsParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsSuggestionsParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'viewId': json['viewId'] == null ? undefined : json['viewId'],
    };
}

export function QueryLeaderboardParamsSuggestionsParamsDTOToJSON(value?: QueryLeaderboardParamsSuggestionsParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
        'viewId': value['viewId'],
    };
}

