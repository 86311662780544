/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1TeamPricingDTO
 */
export interface V1TeamPricingDTO {
    /**
     * 
     * @type {number}
     * @memberof V1TeamPricingDTO
     */
    numberOfUsers: number;
    /**
     * 
     * @type {number}
     * @memberof V1TeamPricingDTO
     */
    userPriceInCents: number;
}

/**
 * Check if a given object implements the V1TeamPricingDTO interface.
 */
export function instanceOfV1TeamPricingDTO(value: object): value is V1TeamPricingDTO {
    if (!('numberOfUsers' in value) || value['numberOfUsers'] === undefined) return false;
    if (!('userPriceInCents' in value) || value['userPriceInCents'] === undefined) return false;
    return true;
}

export function V1TeamPricingDTOFromJSON(json: any): V1TeamPricingDTO {
    return V1TeamPricingDTOFromJSONTyped(json, false);
}

export function V1TeamPricingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1TeamPricingDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'numberOfUsers': json['numberOfUsers'],
        'userPriceInCents': json['userPriceInCents'],
    };
}

export function V1TeamPricingDTOToJSON(value?: V1TeamPricingDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numberOfUsers': value['numberOfUsers'],
        'userPriceInCents': value['userPriceInCents'],
    };
}

