import { AttributeDefinition } from 'domain/experiment/attribute';

import { isLocalStorageCompatibleShape } from './local-storage-compatible';

export type CompoundSearchCriterionType = 'regex' | 'operator';

export type CompoundSearchCriterion = {
  type: CompoundSearchCriterionType;
  value: string;
};

type ColumnType =
  | 'artifact'
  | 'bool'
  | 'complex'
  | 'datetime'
  | 'experimentState'
  | 'file'
  | 'fileSet'
  | 'float'
  | 'floatSeries'
  | 'gitRef'
  | 'imageSeries'
  | 'int'
  | 'string'
  | 'stringSeries'
  | 'stringSet'
  | 'notSupported';
type BaseColumn = {
  id: string;
  columnType: ColumnType;
  attributeName?: string;
  pinned: boolean;
  width: number;
  displayName?: string;
  color?: string;
  exportable: boolean;
  groupable: boolean;
  hideable: boolean;
  reorderable:
    | boolean
    | {
        draggable?: boolean;
        droppable?:
          | {
              before?: boolean;
              after?: boolean;
            }
          | boolean;
      };
  resizable: boolean;
  sortable: boolean;
};

export type Column =
  | BaseColumn
  | (BaseColumn & {
      columnType: 'float';
      displayMode: 'scientific' | 'decimal' | 'auto';
    })
  | (BaseColumn & {
      columnType: 'floatSeries';
      aggregationMode: 'last' | 'min' | 'max' | 'average' | 'variance' | 'auto';
      displayMode: 'scientific' | 'decimal' | 'auto';
    });

export interface SearchQuery {
  criteria: Array<SearchQuery | SearchCriterion>;
  operator: 'and' | 'or';
}

export type SearchCriterion = SearchCriterionWithValue | SearchCriterionWithoutValue;

type SearchCriterionWithValue =
  | ArtifactCriterion
  | BooleanCriterion
  | DateTimeCriterion
  | GitRefCriterion
  | IntegerCriterion
  | FloatCriterion
  | FloatSeriesCriterion
  | SingularExperimentStateCriterion
  | SingularStageCriterion
  | StringCriterion
  | StringSeriesCriterion
  | SearchCriterionWithArrayValue;

type SearchCriterionWithArrayValue =
  | OwnerCriterion
  | NonEmptyStringSetCriterion
  | PluralExperimentStateCriterion
  | PluralStageCriterion;

type SearchCriterionWithoutValue = EmptyCriterion | EmptyStringSetCriterion;

type BaseSearchCriterion = {
  // attribute name
  attribute: string;
  subproperty?: string;
};

type NonEmptyStringSetCriterion = BaseSearchCriterion & {
  type: 'stringSet';
  operator: 'oneOf' | '!oneOf' | 'allOf';
  value: string[];
};

type EmptyStringSetCriterion = BaseSearchCriterion & {
  type: 'stringSet';
  operator: 'empty' | '!exists';
};

type ArtifactCriterion = BaseSearchCriterion & {
  type: 'artifact';
  operator: '=';
  value: string;
};

type BooleanCriterion = BaseSearchCriterion & {
  type: 'bool';
  operator: '=' | '!=';
  value: boolean;
};

type GitRefCriterion = BaseSearchCriterion & {
  type: 'gitRef';
  operator: '=';
  value: string;
};

type IntegerCriterion = BaseSearchCriterion & {
  type: 'int';
  operator: '=' | '!=' | '>=' | '>' | '<=' | '<';
  value: number;
};

type StringCriterion = BaseSearchCriterion & {
  type: 'string';
  operator: '=' | '!=' | 'contains' | '!contains' | 'matches';
  value: string;
};

type StringSeriesCriterion = BaseSearchCriterion & {
  type: 'stringSeries';
  operator: '=' | '!=' | 'contains' | '!contains' | 'matches';
  value: string;
  subproperty: string;
};

type FloatCriterion = BaseSearchCriterion & {
  type: 'float';
  operator: '=' | '!=' | '>=' | '>' | '<=' | '<';
  value: number;
};

type FloatSeriesCriterion = BaseSearchCriterion & {
  type: 'floatSeries';
  operator: '=' | '!=' | '>=' | '>' | '<=' | '<' | '!exists';
  value: number;
  subproperty: string;
};

type DateTimeCriterion = BaseSearchCriterion & {
  type: 'datetime';
  // was: operator: 'after' | 'before'
  operator: 'after' | 'before' | 'last';
  value: string;
};

type SingularExperimentStateCriterion = BaseSearchCriterion & {
  type: 'experimentState';
  operator: '=';
  value: 'idle' | 'running';
};

type PluralExperimentStateCriterion = BaseSearchCriterion & {
  type: 'experimentState';
  operator: 'oneOf';
  value: Array<'idle' | 'running'>;
};

type OwnerCriterion = BaseSearchCriterion & {
  type: 'owner';
  operator: 'oneOf' | '!oneOf';
  value: string[];
};

type SingularStageCriterion = BaseSearchCriterion & {
  type: 'stage';
  operator: '=';
  value: string;
};

type PluralStageCriterion = BaseSearchCriterion & {
  type: 'stage';
  operator: 'oneOf' | '!oneOf';
  value: string[];
};

type EmptyCriterion = BaseSearchCriterion & {
  type: 'int' | 'float' | 'string' | 'datetime' | 'floatSeries';
  operator: 'exists' | '!exists';
};

export type AxisRange = [number | undefined, number | undefined];

export type AxisScale = 'linear' | 'logarithmic';

export type ChartGlobalConfig = {
  xAxisMetric?: AttributeDefinition;
  xAxisMode: 'step' | 'relativeTime' | 'absoluteTime' | 'timeSeries';
  xAxisScale: AxisScale;
  yAxisScale: AxisScale;
};

export type LocalStorageData = {
  compareTabByProject?: Record<string, string | undefined>;
  lastCompareDashboardIds?: { [projectPath: string]: string };
  chartsAxisScales?: { [chartId: string]: { x?: AxisScale; y?: AxisScale } | undefined };
  chartsXAxisMode?: Record<
    string,
    'step' | 'relativeTime' | 'absoluteTime' | 'timeSeries' | undefined
  >;
  chartsYAxisRange?: { [chartId: string]: AxisRange | undefined };
  chartsXAxisRange?: { [chartId: string]: AxisRange | undefined };
  chartsXAxisMetric?: Record<string, AttributeDefinition | undefined>;
  defaultOrganizationName?: string;
  dontShowInviteUsersBanner?: boolean;
  dontShowBrowserSupportInfo?: boolean;
  expandableLayout?: Record<string, Record<string, string | undefined> | undefined>;
  lastExperimentDashboardTab?: string;
  lastExperimentDashboardIds?: Record<string, string>; // warn: some values can be undefined
  orderedColumns?: Record<string, Record<string, Column[]>>;
  os?: 'windows' | 'mac' | 'linux';
  previewTheme?: 'light' | 'dark';
  sideBarsState?: { 'dashboard-menu'?: boolean };
  stringSeriesTimeScale?: Record<string, 'none' | 'relativeTime' | 'absoluteTime' | undefined>;
  shownHelp?: boolean;
  leaderboardPageSize?: Record<string, number | undefined>;
  bulkAcceptInvitationMuted?: {
    byId: Record<string, number | undefined>;
    projects: Record<string, number | undefined>;
    organizations: Record<string, number | undefined>;
  };
  lbViewIdByProject?: Record<string, string | undefined>;
  recentMemos?: Record<string, Record<string, string[] | undefined> | undefined>;
  recentSearchQueries?: Record<string, Record<string, SearchQuery[] | undefined> | undefined>;
  recentCompoundQueries?: Record<
    string,
    Record<string, CompoundSearchCriterion[][] | undefined> | undefined
  >;
  textWrapping?: Record<string, Record<string, string | undefined> | undefined>;
  projectLeaderboardSplitPx?: number;
  reportsTableSplitPx?: number;
  attributeViewerSplitRate?: Record<string, number | undefined> | undefined;
  lastProjectTab?: string;
  lastProjectRunsTab?: 'table' | 'details' | 'compare';
  structureAsideCollapsed?: boolean;
  chartGlobalConfig?: Record<string, ChartGlobalConfig>;
};
export const version = 7 as const;
export type LocalStorageV7 = {
  version: typeof version;
  data: LocalStorageData;
};

export function isLocalStorageV7(input: any): input is LocalStorageV7 {
  return isLocalStorageCompatibleShape(input) && input.version === version;
}
