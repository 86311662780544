/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorsConfigDTO } from './ColorsConfigDTO';
import {
    ColorsConfigDTOFromJSON,
    ColorsConfigDTOFromJSONTyped,
    ColorsConfigDTOToJSON,
} from './ColorsConfigDTO';
import type { DashboardLayoutsDTO } from './DashboardLayoutsDTO';
import {
    DashboardLayoutsDTOFromJSON,
    DashboardLayoutsDTOFromJSONTyped,
    DashboardLayoutsDTOToJSON,
} from './DashboardLayoutsDTO';
import type { DashboardConfigDTO } from './DashboardConfigDTO';
import {
    DashboardConfigDTOFromJSON,
    DashboardConfigDTOFromJSONTyped,
    DashboardConfigDTOToJSON,
} from './DashboardConfigDTO';
import type { TableViewParamsDTO } from './TableViewParamsDTO';
import {
    TableViewParamsDTOFromJSON,
    TableViewParamsDTOFromJSONTyped,
    TableViewParamsDTOToJSON,
} from './TableViewParamsDTO';
import type { WidgetDTO } from './WidgetDTO';
import {
    WidgetDTOFromJSON,
    WidgetDTOFromJSONTyped,
    WidgetDTOToJSON,
} from './WidgetDTO';

/**
 * 
 * @export
 * @interface UpdateDashboardDTO
 */
export interface UpdateDashboardDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateDashboardDTO
     */
    autoComparePool?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardDTO
     */
    color?: string;
    /**
     * 
     * @type {ColorsConfigDTO}
     * @memberof UpdateDashboardDTO
     */
    colorsConfig?: ColorsConfigDTO;
    /**
     * 
     * @type {DashboardConfigDTO}
     * @memberof UpdateDashboardDTO
     */
    config?: DashboardConfigDTO;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardDTO
     */
    icon?: string;
    /**
     * 
     * @type {DashboardLayoutsDTO}
     * @memberof UpdateDashboardDTO
     */
    layouts: DashboardLayoutsDTO;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDashboardDTO
     */
    runIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardDTO
     */
    type: UpdateDashboardDTOTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDashboardDTO
     */
    userExperimentNames?: Array<string>;
    /**
     * 
     * @type {TableViewParamsDTO}
     * @memberof UpdateDashboardDTO
     */
    viewParams?: TableViewParamsDTO;
    /**
     * 
     * @type {Array<WidgetDTO>}
     * @memberof UpdateDashboardDTO
     */
    widgets: Array<WidgetDTO>;
}


/**
 * @export
 */
export const UpdateDashboardDTOTypeEnum = {
    Compare: 'compare',
    Experiment: 'experiment',
    Report: 'report'
} as const;
export type UpdateDashboardDTOTypeEnum = typeof UpdateDashboardDTOTypeEnum[keyof typeof UpdateDashboardDTOTypeEnum];


/**
 * Check if a given object implements the UpdateDashboardDTO interface.
 */
export function instanceOfUpdateDashboardDTO(value: object): value is UpdateDashboardDTO {
    if (!('layouts' in value) || value['layouts'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('widgets' in value) || value['widgets'] === undefined) return false;
    return true;
}

export function UpdateDashboardDTOFromJSON(json: any): UpdateDashboardDTO {
    return UpdateDashboardDTOFromJSONTyped(json, false);
}

export function UpdateDashboardDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDashboardDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'autoComparePool': json['autoComparePool'] == null ? undefined : json['autoComparePool'],
        'color': json['color'] == null ? undefined : json['color'],
        'colorsConfig': json['colorsConfig'] == null ? undefined : ColorsConfigDTOFromJSON(json['colorsConfig']),
        'config': json['config'] == null ? undefined : DashboardConfigDTOFromJSON(json['config']),
        'icon': json['icon'] == null ? undefined : json['icon'],
        'layouts': DashboardLayoutsDTOFromJSON(json['layouts']),
        'name': json['name'],
        'runIds': json['runIds'] == null ? undefined : json['runIds'],
        'type': json['type'],
        'userExperimentNames': json['userExperimentNames'] == null ? undefined : json['userExperimentNames'],
        'viewParams': json['viewParams'] == null ? undefined : TableViewParamsDTOFromJSON(json['viewParams']),
        'widgets': ((json['widgets'] as Array<any>).map(WidgetDTOFromJSON)),
    };
}

export function UpdateDashboardDTOToJSON(value?: UpdateDashboardDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'autoComparePool': value['autoComparePool'],
        'color': value['color'],
        'colorsConfig': ColorsConfigDTOToJSON(value['colorsConfig']),
        'config': DashboardConfigDTOToJSON(value['config']),
        'icon': value['icon'],
        'layouts': DashboardLayoutsDTOToJSON(value['layouts']),
        'name': value['name'],
        'runIds': value['runIds'],
        'type': value['type'],
        'userExperimentNames': value['userExperimentNames'],
        'viewParams': TableViewParamsDTOToJSON(value['viewParams']),
        'widgets': ((value['widgets'] as Array<any>).map(WidgetDTOToJSON)),
    };
}

