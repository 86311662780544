/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenRangeDTO
 */
export interface OpenRangeDTO {
    /**
     * 
     * @type {number}
     * @memberof OpenRangeDTO
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof OpenRangeDTO
     */
    to?: number;
}

/**
 * Check if a given object implements the OpenRangeDTO interface.
 */
export function instanceOfOpenRangeDTO(value: object): value is OpenRangeDTO {
    return true;
}

export function OpenRangeDTOFromJSON(json: any): OpenRangeDTO {
    return OpenRangeDTOFromJSONTyped(json, false);
}

export function OpenRangeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenRangeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'from': json['from'] == null ? undefined : json['from'],
        'to': json['to'] == null ? undefined : json['to'],
    };
}

export function OpenRangeDTOToJSON(value?: OpenRangeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'from': value['from'],
        'to': value['to'],
    };
}

