/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectInvitationDTO } from './ProjectInvitationDTO';
import {
    ProjectInvitationDTOFromJSON,
    ProjectInvitationDTOFromJSONTyped,
    ProjectInvitationDTOToJSON,
} from './ProjectInvitationDTO';
import type { OrganizationInvitationDTO } from './OrganizationInvitationDTO';
import {
    OrganizationInvitationDTOFromJSON,
    OrganizationInvitationDTOFromJSONTyped,
    OrganizationInvitationDTOToJSON,
} from './OrganizationInvitationDTO';

/**
 * 
 * @export
 * @interface InvitationListDTO
 */
export interface InvitationListDTO {
    /**
     * 
     * @type {Array<ProjectInvitationDTO>}
     * @memberof InvitationListDTO
     */
    projectInvitations: Array<ProjectInvitationDTO>;
    /**
     * 
     * @type {Array<OrganizationInvitationDTO>}
     * @memberof InvitationListDTO
     */
    organizationInvitations: Array<OrganizationInvitationDTO>;
}

/**
 * Check if a given object implements the InvitationListDTO interface.
 */
export function instanceOfInvitationListDTO(value: object): value is InvitationListDTO {
    if (!('projectInvitations' in value) || value['projectInvitations'] === undefined) return false;
    if (!('organizationInvitations' in value) || value['organizationInvitations'] === undefined) return false;
    return true;
}

export function InvitationListDTOFromJSON(json: any): InvitationListDTO {
    return InvitationListDTOFromJSONTyped(json, false);
}

export function InvitationListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'projectInvitations': ((json['projectInvitations'] as Array<any>).map(ProjectInvitationDTOFromJSON)),
        'organizationInvitations': ((json['organizationInvitations'] as Array<any>).map(OrganizationInvitationDTOFromJSON)),
    };
}

export function InvitationListDTOToJSON(value?: InvitationListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectInvitations': ((value['projectInvitations'] as Array<any>).map(ProjectInvitationDTOToJSON)),
        'organizationInvitations': ((value['organizationInvitations'] as Array<any>).map(OrganizationInvitationDTOToJSON)),
    };
}

