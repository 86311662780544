// Libs
import React from 'react';
import ReactDOM from 'react-dom';

// App
import { bemBlock } from '../../modules/bem';
import { Card } from '../card/Card';
import { Icon } from '../icon/Icon';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutFill } from '../layout-fill/LayoutFill';
import { LayoutRow } from '../layout-row/LayoutRow';
import { LayoutSeparator } from '../layout-separator/LayoutSeparator';
import { Text } from '../text/Text';

// Module
import './OverlayView.less';

const block = bemBlock('overlay-view');

type OverlayViewProps = {
  title?: string;
  target?: Element;
  onClose?: () => void;
  isVisible: boolean;
  'data-role'?: string;
};

/**
 * @deprecated Avoid using this component, use a Modal instead.
 */
export const OverlayView: React.FC<OverlayViewProps> = ({
  target = document.body,
  title,
  children,
  isVisible = false,
  onClose,
}) => {
  const [visible, setVisible] = React.useState(isVisible);

  React.useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  const handleClose = React.useCallback(() => {
    setVisible(false);
    onClose?.();
  }, [onClose]);

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={block()}>
      <div className={block('backdrop')} onClick={handleClose} />
      <Card className={block('content')} data-role={'overlay-view'}>
        <LayoutRow span="auto" alignItems="center" withPadding={['md', 'lg']}>
          <Text
            className={block('title')}
            fontWeight="bold"
            size="xxl"
            title={title}
            data-role="overlay-view-title"
          >
            {title}
          </Text>
          <LayoutFill />
          {onClose && (
            <Icon data-role="close-overlay" glyph="cancel" size="lg" onClick={handleClose} />
          )}
        </LayoutRow>
        <LayoutSeparator />
        <LayoutColumn overflow="auto">{children}</LayoutColumn>
      </Card>
    </div>,
    target,
  );
};
