import React from 'react';

import { bemBlock } from '../../modules/bem';
import * as decorator from '../../modules/decorator';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutFill } from '../layout-fill/LayoutFill';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';
import { LayoutSeparator } from '../layout-separator/LayoutSeparator';
import { ToolbarItem } from '../toolbar-item/ToolbarItem';

import './Toolbar.less';

const block = bemBlock('n-Toolbar');

export type ToolbarProps = Omit<
  LayoutRowProps<{}>,
  'alignItems' | 'component' | 'height' | 'justifyContent' | 'width'
> & {
  opaque?: decorator.Opaque;
  size?: 'md' | 'lg';
  withLabels?: boolean;
  direction?: 'row' | 'column';
};

/**
 * ## Shorthands to toolbar related components
 *
 * All toolbar related components are exposed as properties of `Toolbar`,
 * so it is possible to import only `Toolbar` to use all those components.
 *
 * | Component      | Property name | Usage               |
 * | -------------- | ------------- |  ------------------ |
 * | `LayoutFill`   | `Fill`        | `<Toolbar.Fill />`  |
 * | `ToolbarItem`  | `Item`        | `<Toolbar.Item />`  |
 */
export class Toolbar extends React.Component<ToolbarProps> {
  public static defaultProps = {
    alignItems: 'start',
    justifyContent: 'start',
    spacedChildren: 'md',
    span: 'auto',
    size: 'lg',
    direction: 'row',
  };

  public static Fill = LayoutFill;

  public static Item = ToolbarItem;

  public static Separator = LayoutSeparator;

  render() {
    const { className, opaque, size, withLabels, ...props } = this.props;
    const ownProps = {
      className: block({
        modifiers: {
          size,
          withLabels,
        },
        extra: [className, decorator.opaqueClassName(opaque)],
      }),
    };

    if (this.props.direction === 'column') {
      return <LayoutColumn {...ownProps} {...props} />;
    }

    return <LayoutRow {...ownProps} {...props} />;
  }
}
