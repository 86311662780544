import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { ACTIONS_MENU_MAX_HEIGHT } from '@neptune/shared/venus-domain';

import { bemBlock } from '../../modules/bem';
import { ScrollArea } from '../scroll-area/ScrollArea';

import { ActionsMenuSeparator as Separator } from './ActionsMenuSeparator';

import './ActionsMenuSubContent.less';

const block = bemBlock('n-actions-menu-sub-content');

type SubContentOwnProps = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

type SubContentProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.SubContent> &
  SubContentOwnProps;

export const ActionsMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.SubContent>,
  SubContentProps
>(({ className, children, header, footer, ...props }, ref) => {
  return (
    <DropdownMenu.SubContent
      ref={ref}
      className={block({
        extra: className,
      })}
      {...props}
    >
      {header && (
        <>
          <div className={block('header')}>{header}</div>
          <Separator />
        </>
      )}
      <ScrollArea>
        <ScrollArea.Viewport style={{ maxHeight: ACTIONS_MENU_MAX_HEIGHT }}>
          {children}
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar orientation="vertical">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
        <ScrollArea.Scrollbar orientation="horizontal">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner />
      </ScrollArea>
      {footer && (
        <>
          <Separator />
          <div className={block('footer')}>{footer}</div>
        </>
      )}
    </DropdownMenu.SubContent>
  );
});
