/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringTimeStatusDTO
 */
export interface MonitoringTimeStatusDTO {
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeStatusDTO
     */
    availableSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeStatusDTO
     */
    nextTopUpSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeStatusDTO
     */
    surplusSeconds: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringTimeStatusDTO
     */
    nextTopUpDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeStatusDTO
     */
    last30DaysSeconds: number;
}

/**
 * Check if a given object implements the MonitoringTimeStatusDTO interface.
 */
export function instanceOfMonitoringTimeStatusDTO(value: object): value is MonitoringTimeStatusDTO {
    if (!('availableSeconds' in value) || value['availableSeconds'] === undefined) return false;
    if (!('nextTopUpSeconds' in value) || value['nextTopUpSeconds'] === undefined) return false;
    if (!('surplusSeconds' in value) || value['surplusSeconds'] === undefined) return false;
    if (!('last30DaysSeconds' in value) || value['last30DaysSeconds'] === undefined) return false;
    return true;
}

export function MonitoringTimeStatusDTOFromJSON(json: any): MonitoringTimeStatusDTO {
    return MonitoringTimeStatusDTOFromJSONTyped(json, false);
}

export function MonitoringTimeStatusDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTimeStatusDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'availableSeconds': json['availableSeconds'],
        'nextTopUpSeconds': json['nextTopUpSeconds'],
        'surplusSeconds': json['surplusSeconds'],
        'nextTopUpDate': json['nextTopUpDate'] == null ? undefined : (new Date(json['nextTopUpDate'])),
        'last30DaysSeconds': json['last30DaysSeconds'],
    };
}

export function MonitoringTimeStatusDTOToJSON(value?: MonitoringTimeStatusDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'availableSeconds': value['availableSeconds'],
        'nextTopUpSeconds': value['nextTopUpSeconds'],
        'surplusSeconds': value['surplusSeconds'],
        'nextTopUpDate': value['nextTopUpDate'] == null ? undefined : ((value['nextTopUpDate']).toISOString()),
        'last30DaysSeconds': value['last30DaysSeconds'],
    };
}

