import React from 'react';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { Link, LinkProps } from '@neptune/shared/venus-ui';

type ContactLinkProps<P extends object> = LinkProps<P> & {
  subject?: string;
};

export function ContactLink<P extends object>({ subject, ...props }: ContactLinkProps<P>) {
  const href = subject
    ? `mailto:${neptuneUrls.contactEmail}?subject=${subject}`
    : `mailto:${neptuneUrls.contactEmail}`;

  return <Link {...props} href={href} />;
}

export default ContactLink;
