/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotebooksDataDTO
 */
export interface NotebooksDataDTO {
    /**
     * 
     * @type {number}
     * @memberof NotebooksDataDTO
     */
    count?: number;
}

/**
 * Check if a given object implements the NotebooksDataDTO interface.
 */
export function instanceOfNotebooksDataDTO(value: object): value is NotebooksDataDTO {
    return true;
}

export function NotebooksDataDTOFromJSON(json: any): NotebooksDataDTO {
    return NotebooksDataDTOFromJSONTyped(json, false);
}

export function NotebooksDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotebooksDataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'] == null ? undefined : json['count'],
    };
}

export function NotebooksDataDTOToJSON(value?: NotebooksDataDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
    };
}

