/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AssignComplexOperation } from './AssignComplexOperation';
import {
    AssignComplexOperationFromJSON,
    AssignComplexOperationFromJSONTyped,
    AssignComplexOperationToJSON,
} from './AssignComplexOperation';
import type { AssignStringOperation } from './AssignStringOperation';
import {
    AssignStringOperationFromJSON,
    AssignStringOperationFromJSONTyped,
    AssignStringOperationToJSON,
} from './AssignStringOperation';
import type { LogStringsOperation } from './LogStringsOperation';
import {
    LogStringsOperationFromJSON,
    LogStringsOperationFromJSONTyped,
    LogStringsOperationToJSON,
} from './LogStringsOperation';
import type { AssignFloatOperation } from './AssignFloatOperation';
import {
    AssignFloatOperationFromJSON,
    AssignFloatOperationFromJSONTyped,
    AssignFloatOperationToJSON,
} from './AssignFloatOperation';
import type { LogImages } from './LogImages';
import {
    LogImagesFromJSON,
    LogImagesFromJSONTyped,
    LogImagesToJSON,
} from './LogImages';
import type { AssignDatetimeOperation } from './AssignDatetimeOperation';
import {
    AssignDatetimeOperationFromJSON,
    AssignDatetimeOperationFromJSONTyped,
    AssignDatetimeOperationToJSON,
} from './AssignDatetimeOperation';
import type { AssignBoolOperation } from './AssignBoolOperation';
import {
    AssignBoolOperationFromJSON,
    AssignBoolOperationFromJSONTyped,
    AssignBoolOperationToJSON,
} from './AssignBoolOperation';
import type { InsertStringsOperation } from './InsertStringsOperation';
import {
    InsertStringsOperationFromJSON,
    InsertStringsOperationFromJSONTyped,
    InsertStringsOperationToJSON,
} from './InsertStringsOperation';
import type { LogFloatsOperation } from './LogFloatsOperation';
import {
    LogFloatsOperationFromJSON,
    LogFloatsOperationFromJSONTyped,
    LogFloatsOperationToJSON,
} from './LogFloatsOperation';
import type { AssignIntOperation } from './AssignIntOperation';
import {
    AssignIntOperationFromJSON,
    AssignIntOperationFromJSONTyped,
    AssignIntOperationToJSON,
} from './AssignIntOperation';
import type { DeleteFilesOperation } from './DeleteFilesOperation';
import {
    DeleteFilesOperationFromJSON,
    DeleteFilesOperationFromJSONTyped,
    DeleteFilesOperationToJSON,
} from './DeleteFilesOperation';
import type { ConfigFloatSeries } from './ConfigFloatSeries';
import {
    ConfigFloatSeriesFromJSON,
    ConfigFloatSeriesFromJSONTyped,
    ConfigFloatSeriesToJSON,
} from './ConfigFloatSeries';
import type { RemoveStringsOperation } from './RemoveStringsOperation';
import {
    RemoveStringsOperationFromJSON,
    RemoveStringsOperationFromJSONTyped,
    RemoveStringsOperationToJSON,
} from './RemoveStringsOperation';

/**
 * 
 * @export
 * @interface Operation
 */
export interface Operation {
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    path?: string;
    /**
     * 
     * @type {AssignComplexOperation}
     * @memberof Operation
     */
    assignComplex?: AssignComplexOperation;
    /**
     * 
     * @type {AssignFloatOperation}
     * @memberof Operation
     */
    assignFloat?: AssignFloatOperation;
    /**
     * 
     * @type {AssignIntOperation}
     * @memberof Operation
     */
    assignInt?: AssignIntOperation;
    /**
     * 
     * @type {AssignBoolOperation}
     * @memberof Operation
     */
    assignBool?: AssignBoolOperation;
    /**
     * 
     * @type {AssignStringOperation}
     * @memberof Operation
     */
    assignString?: AssignStringOperation;
    /**
     * 
     * @type {AssignDatetimeOperation}
     * @memberof Operation
     */
    assignDatetime?: AssignDatetimeOperation;
    /**
     * 
     * @type {LogFloatsOperation}
     * @memberof Operation
     */
    logFloats?: LogFloatsOperation;
    /**
     * 
     * @type {LogStringsOperation}
     * @memberof Operation
     */
    logStrings?: LogStringsOperation;
    /**
     * 
     * @type {LogImages}
     * @memberof Operation
     */
    logImages?: LogImages;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    clearFloatSeries?: object;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    clearStringSeries?: object;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    clearImageSeries?: object;
    /**
     * 
     * @type {ConfigFloatSeries}
     * @memberof Operation
     */
    configFloatSeries?: ConfigFloatSeries;
    /**
     * 
     * @type {InsertStringsOperation}
     * @memberof Operation
     */
    insertStrings?: InsertStringsOperation;
    /**
     * 
     * @type {RemoveStringsOperation}
     * @memberof Operation
     */
    removeStrings?: RemoveStringsOperation;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    clearStringSet?: object;
    /**
     * 
     * @type {DeleteFilesOperation}
     * @memberof Operation
     */
    deleteFiles?: DeleteFilesOperation;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    deleteAttribute?: object;
}

/**
 * Check if a given object implements the Operation interface.
 */
export function instanceOfOperation(value: object): value is Operation {
    return true;
}

export function OperationFromJSON(json: any): Operation {
    return OperationFromJSONTyped(json, false);
}

export function OperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Operation {
    if (json == null) {
        return json;
    }
    return {
        
        'path': json['path'] == null ? undefined : json['path'],
        'assignComplex': json['assignComplex'] == null ? undefined : AssignComplexOperationFromJSON(json['assignComplex']),
        'assignFloat': json['assignFloat'] == null ? undefined : AssignFloatOperationFromJSON(json['assignFloat']),
        'assignInt': json['assignInt'] == null ? undefined : AssignIntOperationFromJSON(json['assignInt']),
        'assignBool': json['assignBool'] == null ? undefined : AssignBoolOperationFromJSON(json['assignBool']),
        'assignString': json['assignString'] == null ? undefined : AssignStringOperationFromJSON(json['assignString']),
        'assignDatetime': json['assignDatetime'] == null ? undefined : AssignDatetimeOperationFromJSON(json['assignDatetime']),
        'logFloats': json['logFloats'] == null ? undefined : LogFloatsOperationFromJSON(json['logFloats']),
        'logStrings': json['logStrings'] == null ? undefined : LogStringsOperationFromJSON(json['logStrings']),
        'logImages': json['logImages'] == null ? undefined : LogImagesFromJSON(json['logImages']),
        'clearFloatSeries': json['clearFloatSeries'] == null ? undefined : json['clearFloatSeries'],
        'clearStringSeries': json['clearStringSeries'] == null ? undefined : json['clearStringSeries'],
        'clearImageSeries': json['clearImageSeries'] == null ? undefined : json['clearImageSeries'],
        'configFloatSeries': json['configFloatSeries'] == null ? undefined : ConfigFloatSeriesFromJSON(json['configFloatSeries']),
        'insertStrings': json['insertStrings'] == null ? undefined : InsertStringsOperationFromJSON(json['insertStrings']),
        'removeStrings': json['removeStrings'] == null ? undefined : RemoveStringsOperationFromJSON(json['removeStrings']),
        'clearStringSet': json['clearStringSet'] == null ? undefined : json['clearStringSet'],
        'deleteFiles': json['deleteFiles'] == null ? undefined : DeleteFilesOperationFromJSON(json['deleteFiles']),
        'deleteAttribute': json['deleteAttribute'] == null ? undefined : json['deleteAttribute'],
    };
}

export function OperationToJSON(value?: Operation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'path': value['path'],
        'assignComplex': AssignComplexOperationToJSON(value['assignComplex']),
        'assignFloat': AssignFloatOperationToJSON(value['assignFloat']),
        'assignInt': AssignIntOperationToJSON(value['assignInt']),
        'assignBool': AssignBoolOperationToJSON(value['assignBool']),
        'assignString': AssignStringOperationToJSON(value['assignString']),
        'assignDatetime': AssignDatetimeOperationToJSON(value['assignDatetime']),
        'logFloats': LogFloatsOperationToJSON(value['logFloats']),
        'logStrings': LogStringsOperationToJSON(value['logStrings']),
        'logImages': LogImagesToJSON(value['logImages']),
        'clearFloatSeries': value['clearFloatSeries'],
        'clearStringSeries': value['clearStringSeries'],
        'clearImageSeries': value['clearImageSeries'],
        'configFloatSeries': ConfigFloatSeriesToJSON(value['configFloatSeries']),
        'insertStrings': InsertStringsOperationToJSON(value['insertStrings']),
        'removeStrings': RemoveStringsOperationToJSON(value['removeStrings']),
        'clearStringSet': value['clearStringSet'],
        'deleteFiles': DeleteFilesOperationToJSON(value['deleteFiles']),
        'deleteAttribute': value['deleteAttribute'],
    };
}

