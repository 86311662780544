/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FloatSeriesAttributeConfigDTO
 */
export interface FloatSeriesAttributeConfigDTO {
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeConfigDTO
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeConfigDTO
     */
    min?: number;
    /**
     * 
     * @type {string}
     * @memberof FloatSeriesAttributeConfigDTO
     */
    unit?: string;
}

/**
 * Check if a given object implements the FloatSeriesAttributeConfigDTO interface.
 */
export function instanceOfFloatSeriesAttributeConfigDTO(value: object): value is FloatSeriesAttributeConfigDTO {
    return true;
}

export function FloatSeriesAttributeConfigDTOFromJSON(json: any): FloatSeriesAttributeConfigDTO {
    return FloatSeriesAttributeConfigDTOFromJSONTyped(json, false);
}

export function FloatSeriesAttributeConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatSeriesAttributeConfigDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'max': json['max'] == null ? undefined : json['max'],
        'min': json['min'] == null ? undefined : json['min'],
        'unit': json['unit'] == null ? undefined : json['unit'],
    };
}

export function FloatSeriesAttributeConfigDTOToJSON(value?: FloatSeriesAttributeConfigDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'max': value['max'],
        'min': value['min'],
        'unit': value['unit'],
    };
}

