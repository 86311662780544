/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeaderboardViewSetDefaultDTO
 */
export interface LeaderboardViewSetDefaultDTO {
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewSetDefaultDTO
     */
    projectIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewSetDefaultDTO
     */
    viewId?: string;
}

/**
 * Check if a given object implements the LeaderboardViewSetDefaultDTO interface.
 */
export function instanceOfLeaderboardViewSetDefaultDTO(value: object): value is LeaderboardViewSetDefaultDTO {
    if (!('projectIdentifier' in value) || value['projectIdentifier'] === undefined) return false;
    return true;
}

export function LeaderboardViewSetDefaultDTOFromJSON(json: any): LeaderboardViewSetDefaultDTO {
    return LeaderboardViewSetDefaultDTOFromJSONTyped(json, false);
}

export function LeaderboardViewSetDefaultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardViewSetDefaultDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'projectIdentifier': json['projectIdentifier'],
        'viewId': json['viewId'] == null ? undefined : json['viewId'],
    };
}

export function LeaderboardViewSetDefaultDTOToJSON(value?: LeaderboardViewSetDefaultDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectIdentifier': value['projectIdentifier'],
        'viewId': value['viewId'],
    };
}

