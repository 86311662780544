/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WidgetAttributeDTO
 */
export interface WidgetAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof WidgetAttributeDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetAttributeDTO
     */
    subproperty?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetAttributeDTO
     */
    type: WidgetAttributeDTOTypeEnum;
}


/**
 * @export
 */
export const WidgetAttributeDTOTypeEnum = {
    ExperimentState: 'experimentState',
    Float: 'float',
    Int: 'int',
    Bool: 'bool',
    String: 'string',
    File: 'file',
    Datetime: 'datetime',
    FloatSeries: 'floatSeries',
    StringSeries: 'stringSeries',
    ImageSeries: 'imageSeries',
    StringSet: 'stringSet',
    FileSet: 'fileSet',
    GitRef: 'gitRef',
    NotebookRef: 'notebookRef',
    NotSupported: 'notSupported'
} as const;
export type WidgetAttributeDTOTypeEnum = typeof WidgetAttributeDTOTypeEnum[keyof typeof WidgetAttributeDTOTypeEnum];


/**
 * Check if a given object implements the WidgetAttributeDTO interface.
 */
export function instanceOfWidgetAttributeDTO(value: object): value is WidgetAttributeDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function WidgetAttributeDTOFromJSON(json: any): WidgetAttributeDTO {
    return WidgetAttributeDTOFromJSONTyped(json, false);
}

export function WidgetAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'subproperty': json['subproperty'] == null ? undefined : json['subproperty'],
        'type': json['type'],
    };
}

export function WidgetAttributeDTOToJSON(value?: WidgetAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'subproperty': value['subproperty'],
        'type': value['type'],
    };
}

