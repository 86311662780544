/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegisteredMemberInfoDTO } from './RegisteredMemberInfoDTO';
import {
    RegisteredMemberInfoDTOFromJSON,
    RegisteredMemberInfoDTOFromJSONTyped,
    RegisteredMemberInfoDTOToJSON,
} from './RegisteredMemberInfoDTO';

/**
 * 
 * @export
 * @interface OrganizationOwnerDTO
 */
export interface OrganizationOwnerDTO {
    /**
     * 
     * @type {RegisteredMemberInfoDTO}
     * @memberof OrganizationOwnerDTO
     */
    registeredMemberInfo: RegisteredMemberInfoDTO;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOwnerDTO
     */
    email: string;
}

/**
 * Check if a given object implements the OrganizationOwnerDTO interface.
 */
export function instanceOfOrganizationOwnerDTO(value: object): value is OrganizationOwnerDTO {
    if (!('registeredMemberInfo' in value) || value['registeredMemberInfo'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function OrganizationOwnerDTOFromJSON(json: any): OrganizationOwnerDTO {
    return OrganizationOwnerDTOFromJSONTyped(json, false);
}

export function OrganizationOwnerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationOwnerDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'registeredMemberInfo': RegisteredMemberInfoDTOFromJSON(json['registeredMemberInfo']),
        'email': json['email'],
    };
}

export function OrganizationOwnerDTOToJSON(value?: OrganizationOwnerDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'registeredMemberInfo': RegisteredMemberInfoDTOToJSON(value['registeredMemberInfo']),
        'email': value['email'],
    };
}

