/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface FileSetAttributeDTO
 */
export interface FileSetAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof FileSetAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof FileSetAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {number}
     * @memberof FileSetAttributeDTO
     */
    size: number;
}



/**
 * Check if a given object implements the FileSetAttributeDTO interface.
 */
export function instanceOfFileSetAttributeDTO(value: object): value is FileSetAttributeDTO {
    if (!('attributeName' in value) || value['attributeName'] === undefined) return false;
    if (!('attributeType' in value) || value['attributeType'] === undefined) return false;
    if (!('size' in value) || value['size'] === undefined) return false;
    return true;
}

export function FileSetAttributeDTOFromJSON(json: any): FileSetAttributeDTO {
    return FileSetAttributeDTOFromJSONTyped(json, false);
}

export function FileSetAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileSetAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'size': json['size'],
    };
}

export function FileSetAttributeDTOToJSON(value?: FileSetAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeName': value['attributeName'],
        'attributeType': AttributeTypeDTOToJSON(value['attributeType']),
        'size': value['size'],
    };
}

