import { Components } from 'react-markdown';

import { CodeRenderer } from './CodeRenderer';
import LinkRenderer from './LinkRenderer';
import TextRenderer from './TextRenderer';

const renderers: Components = {
  code: CodeRenderer,
  link: LinkRenderer as Components['link'],
  text: TextRenderer,
  pre: (({ children }) => {
    return children;
  }) as Components['pre'],
};

export default renderers;
