/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SeriesViewDTO } from './SeriesViewDTO';
import {
    SeriesViewDTOFromJSON,
    SeriesViewDTOFromJSONTyped,
    SeriesViewDTOToJSON,
} from './SeriesViewDTO';

/**
 * 
 * @export
 * @interface SeriesViewListDTO
 */
export interface SeriesViewListDTO {
    /**
     * 
     * @type {Array<SeriesViewDTO>}
     * @memberof SeriesViewListDTO
     */
    elements: Array<SeriesViewDTO>;
}

/**
 * Check if a given object implements the SeriesViewListDTO interface.
 */
export function instanceOfSeriesViewListDTO(value: object): value is SeriesViewListDTO {
    if (!('elements' in value) || value['elements'] === undefined) return false;
    return true;
}

export function SeriesViewListDTOFromJSON(json: any): SeriesViewListDTO {
    return SeriesViewListDTOFromJSONTyped(json, false);
}

export function SeriesViewListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesViewListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(SeriesViewDTOFromJSON)),
    };
}

export function SeriesViewListDTOToJSON(value?: SeriesViewListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'elements': ((value['elements'] as Array<any>).map(SeriesViewDTOToJSON)),
    };
}

