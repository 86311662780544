/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResendOrganizationInvitationDTO
 */
export interface ResendOrganizationInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof ResendOrganizationInvitationDTO
     */
    organizationIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof ResendOrganizationInvitationDTO
     */
    inviteeEmail: string;
}

/**
 * Check if a given object implements the ResendOrganizationInvitationDTO interface.
 */
export function instanceOfResendOrganizationInvitationDTO(value: object): value is ResendOrganizationInvitationDTO {
    if (!('organizationIdentifier' in value) || value['organizationIdentifier'] === undefined) return false;
    if (!('inviteeEmail' in value) || value['inviteeEmail'] === undefined) return false;
    return true;
}

export function ResendOrganizationInvitationDTOFromJSON(json: any): ResendOrganizationInvitationDTO {
    return ResendOrganizationInvitationDTOFromJSONTyped(json, false);
}

export function ResendOrganizationInvitationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendOrganizationInvitationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'organizationIdentifier': json['organizationIdentifier'],
        'inviteeEmail': json['inviteeEmail'],
    };
}

export function ResendOrganizationInvitationDTOToJSON(value?: ResendOrganizationInvitationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'organizationIdentifier': value['organizationIdentifier'],
        'inviteeEmail': value['inviteeEmail'],
    };
}

