/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MonitoringTimePackageDTO } from './MonitoringTimePackageDTO';
import {
    MonitoringTimePackageDTOFromJSON,
    MonitoringTimePackageDTOFromJSONTyped,
    MonitoringTimePackageDTOToJSON,
} from './MonitoringTimePackageDTO';

/**
 * 
 * @export
 * @interface MonitoringTimePackageListDTO
 */
export interface MonitoringTimePackageListDTO {
    /**
     * 
     * @type {Array<MonitoringTimePackageDTO>}
     * @memberof MonitoringTimePackageListDTO
     */
    packages: Array<MonitoringTimePackageDTO>;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimePackageListDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimePackageListDTO
     */
    matchingItemCount: number;
}

/**
 * Check if a given object implements the MonitoringTimePackageListDTO interface.
 */
export function instanceOfMonitoringTimePackageListDTO(value: object): value is MonitoringTimePackageListDTO {
    if (!('packages' in value) || value['packages'] === undefined) return false;
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('matchingItemCount' in value) || value['matchingItemCount'] === undefined) return false;
    return true;
}

export function MonitoringTimePackageListDTOFromJSON(json: any): MonitoringTimePackageListDTO {
    return MonitoringTimePackageListDTOFromJSONTyped(json, false);
}

export function MonitoringTimePackageListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTimePackageListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'packages': ((json['packages'] as Array<any>).map(MonitoringTimePackageDTOFromJSON)),
        'totalItemCount': json['totalItemCount'],
        'matchingItemCount': json['matchingItemCount'],
    };
}

export function MonitoringTimePackageListDTOToJSON(value?: MonitoringTimePackageListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'packages': ((value['packages'] as Array<any>).map(MonitoringTimePackageDTOToJSON)),
        'totalItemCount': value['totalItemCount'],
        'matchingItemCount': value['matchingItemCount'],
    };
}

