/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';

/**
 * 
 * @export
 * @interface OrganizationInvitationUpdateProjectEntryDTO
 */
export interface OrganizationInvitationUpdateProjectEntryDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationInvitationUpdateProjectEntryDTO
     */
    projectIdentity: string;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof OrganizationInvitationUpdateProjectEntryDTO
     */
    role?: ProjectRoleDTO;
}



/**
 * Check if a given object implements the OrganizationInvitationUpdateProjectEntryDTO interface.
 */
export function instanceOfOrganizationInvitationUpdateProjectEntryDTO(value: object): value is OrganizationInvitationUpdateProjectEntryDTO {
    if (!('projectIdentity' in value) || value['projectIdentity'] === undefined) return false;
    return true;
}

export function OrganizationInvitationUpdateProjectEntryDTOFromJSON(json: any): OrganizationInvitationUpdateProjectEntryDTO {
    return OrganizationInvitationUpdateProjectEntryDTOFromJSONTyped(json, false);
}

export function OrganizationInvitationUpdateProjectEntryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationInvitationUpdateProjectEntryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'projectIdentity': json['projectIdentity'],
        'role': json['role'] == null ? undefined : ProjectRoleDTOFromJSON(json['role']),
    };
}

export function OrganizationInvitationUpdateProjectEntryDTOToJSON(value?: OrganizationInvitationUpdateProjectEntryDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectIdentity': value['projectIdentity'],
        'role': ProjectRoleDTOToJSON(value['role']),
    };
}

