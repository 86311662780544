/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigFloatSeries
 */
export interface ConfigFloatSeries {
    /**
     * 
     * @type {number}
     * @memberof ConfigFloatSeries
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigFloatSeries
     */
    max?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfigFloatSeries
     */
    unit?: string;
}

/**
 * Check if a given object implements the ConfigFloatSeries interface.
 */
export function instanceOfConfigFloatSeries(value: object): value is ConfigFloatSeries {
    return true;
}

export function ConfigFloatSeriesFromJSON(json: any): ConfigFloatSeries {
    return ConfigFloatSeriesFromJSONTyped(json, false);
}

export function ConfigFloatSeriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigFloatSeries {
    if (json == null) {
        return json;
    }
    return {
        
        'min': json['min'] == null ? undefined : json['min'],
        'max': json['max'] == null ? undefined : json['max'],
        'unit': json['unit'] == null ? undefined : json['unit'],
    };
}

export function ConfigFloatSeriesToJSON(value?: ConfigFloatSeries | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'min': value['min'],
        'max': value['max'],
        'unit': value['unit'],
    };
}

