import React from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';
import { uniqueId } from 'lodash';

import { DropdownTetherContext } from '../dropdown-tether/DropdownTether';

import { UITooltip } from './UITooltip';
import { useTooltip } from './useTooltip';

export const SimpleTooltip: React.FC<{
  content?: React.ReactNode;
  noPointerEvents?: boolean;
  children?: React.ReactNode;
  placement?: PlacementType;
  strictPlacement?: boolean;
}> = ({
  content,
  noPointerEvents,
  children,
  placement = 'top-center',
  strictPlacement = false,
}) => {
  const [id] = React.useState(uniqueId('tooltip-'));
  const registerRef = React.useContext(DropdownTetherContext)?.handleNestedTether;

  const tooltipProps = useTooltip({
    placement,
    auto: !strictPlacement,
  });

  if (!content) {
    return <>{children}</>;
  }

  return (
    <span {...tooltipProps.triggerProps}>
      {children}
      {tooltipProps.isOpen &&
        tooltipProps.renderLayer(
          <UITooltip
            elementRef={(ref) => {
              const instanceWrapper = ref
                ? {
                    getTetherInstance: () => ({
                      element: ref,
                    }),
                  }
                : null;
              registerRef?.(id, instanceWrapper);
            }}
            {...tooltipProps.layerProps}
            noPointerEvents={noPointerEvents}
          >
            {content}
          </UITooltip>,
        )}
    </span>
  );
};
