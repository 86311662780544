/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileUpdateDTO
 */
export interface UserProfileUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDTO
     */
    biography?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileUpdateDTO
     */
    hasLoggedToCli?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDTO
     */
    shortInfo?: string;
}

/**
 * Check if a given object implements the UserProfileUpdateDTO interface.
 */
export function instanceOfUserProfileUpdateDTO(value: object): value is UserProfileUpdateDTO {
    return true;
}

export function UserProfileUpdateDTOFromJSON(json: any): UserProfileUpdateDTO {
    return UserProfileUpdateDTOFromJSONTyped(json, false);
}

export function UserProfileUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'biography': json['biography'] == null ? undefined : json['biography'],
        'hasLoggedToCli': json['hasLoggedToCli'] == null ? undefined : json['hasLoggedToCli'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'shortInfo': json['shortInfo'] == null ? undefined : json['shortInfo'],
    };
}

export function UserProfileUpdateDTOToJSON(value?: UserProfileUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'biography': value['biography'],
        'hasLoggedToCli': value['hasLoggedToCli'],
        'lastName': value['lastName'],
        'firstName': value['firstName'],
        'shortInfo': value['shortInfo'],
    };
}

