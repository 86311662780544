import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { bemBlock } from '../../modules/bem';

import './ActionsMenuSeparator.less';

const block = bemBlock('n-actions-menu-separator');

export const ActionsMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenu.Separator>
>(({ className, ...props }, ref) => {
  return (
    <DropdownMenu.Separator
      ref={ref}
      className={block({
        extra: className,
      })}
      {...props}
    />
  );
});
