import { type UseQueryResult } from '@tanstack/react-query';
import { find, some } from 'lodash';

import { isNotUndefined } from 'common/tsHelpers';

const EMPTY_ARRAY: never[] = [];

export function mergeUseQueriesResultsMetadata(results: UseQueryResult<any, any>[]) {
  return {
    isFetching: some(results, 'isFetching'),
    isLoading: some(results, 'isLoading'),
    isInitialLoading: some(results, 'isInitialLoading'),
    isError: some(results, 'isError'),
    error: find(results, 'isError')?.error, // First error.
  };
}

export function flattenUseQueriesResults<T>(results: UseQueryResult<T[], any>[]) {
  const data = results.flatMap(({ data }) => data ?? EMPTY_ARRAY);

  return {
    ...mergeUseQueriesResultsMetadata(results),
    data,
  };
}

export function mapMergeUseQueriesResults<T>(results: UseQueryResult<[string, T], any>[]) {
  const data = new Map<string, T>(results.map(({ data }) => data).filter(isNotUndefined));

  return {
    ...mergeUseQueriesResultsMetadata(results),
    data,
  };
}
