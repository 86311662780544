import React from 'react';
import ReactDOM from 'react-dom';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';

import { bemBlock } from '@neptune/shared/venus-ui';

import { useFullScreenAnimation } from './use-fullscreen-animation';

import './FullscreenElementPortal.less';

const block = bemBlock('fullscreen-element-portal');

export const FullscreenElementPortal: React.FC<{
  className?: string;
  fullscreen: boolean;
}> = ({ className, fullscreen, children }) => {
  const [renderInBody, setRenderInBody] = React.useState(false);

  const onMaximize = React.useCallback(() => setRenderInBody(true), []);
  const onRestore = React.useCallback(() => setRenderInBody(false), []);

  const setRef = useFullScreenAnimation(fullscreen, onMaximize, onRestore);

  const portalNodeRef = React.useRef(createHtmlPortalNode());
  const element = portalNodeRef.current.element;

  React.useEffect(() => {
    element.setAttribute('class', block({ extra: className }));
  }, [element, className]);

  React.useEffect(() => {
    setRef(element);
  }, [element, setRef]);

  const inPortal = <InPortal node={portalNodeRef.current} children={children} />;
  const outPortal = <OutPortal node={portalNodeRef.current} />;

  return (
    <>
      {inPortal}
      {renderInBody ? ReactDOM.createPortal(outPortal, document.body) : outPortal}
    </>
  );
};
