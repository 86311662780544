/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTagsParams
 */
export interface UpdateTagsParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateTagsParams
     */
    attributePathToBeUpdated?: UpdateTagsParamsAttributePathToBeUpdatedEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTagsParams
     */
    experimentIdentifiers: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTagsParams
     */
    tagsToAdd: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTagsParams
     */
    tagsToDelete: Array<string>;
}


/**
 * @export
 */
export const UpdateTagsParamsAttributePathToBeUpdatedEnum = {
    Tags: 'sys/tags',
    GroupTags: 'sys/group_tags'
} as const;
export type UpdateTagsParamsAttributePathToBeUpdatedEnum = typeof UpdateTagsParamsAttributePathToBeUpdatedEnum[keyof typeof UpdateTagsParamsAttributePathToBeUpdatedEnum];


/**
 * Check if a given object implements the UpdateTagsParams interface.
 */
export function instanceOfUpdateTagsParams(value: object): value is UpdateTagsParams {
    if (!('experimentIdentifiers' in value) || value['experimentIdentifiers'] === undefined) return false;
    if (!('tagsToAdd' in value) || value['tagsToAdd'] === undefined) return false;
    if (!('tagsToDelete' in value) || value['tagsToDelete'] === undefined) return false;
    return true;
}

export function UpdateTagsParamsFromJSON(json: any): UpdateTagsParams {
    return UpdateTagsParamsFromJSONTyped(json, false);
}

export function UpdateTagsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTagsParams {
    if (json == null) {
        return json;
    }
    return {
        
        'attributePathToBeUpdated': json['attributePathToBeUpdated'] == null ? undefined : json['attributePathToBeUpdated'],
        'experimentIdentifiers': json['experimentIdentifiers'],
        'tagsToAdd': json['tagsToAdd'],
        'tagsToDelete': json['tagsToDelete'],
    };
}

export function UpdateTagsParamsToJSON(value?: UpdateTagsParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributePathToBeUpdated': value['attributePathToBeUpdated'],
        'experimentIdentifiers': value['experimentIdentifiers'],
        'tagsToAdd': value['tagsToAdd'],
        'tagsToDelete': value['tagsToDelete'],
    };
}

