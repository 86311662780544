import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { bemBlock } from '../../modules/bem';

import './InputSelectSeparator.less';

const block = bemBlock('input-select-separator');

export const Separator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={block({ extra: className })} {...props} />
));
