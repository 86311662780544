import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { Button } from './InputSelectButton';
import { Content } from './InputSelectContent';
import { Item } from './InputSelectItem';
import { Label } from './InputSelectLabel';
import { Separator } from './InputSelectSeparator';
import { Trigger } from './InputSelectTrigger';
// import { Value } from './InputSelectValue';

const SelectPrimitiveRoot: React.FC<SelectPrimitive.SelectProps> = (props) => {
  return <SelectPrimitive.Root {...props} />;
};

export const InputSelect = Object.assign(SelectPrimitiveRoot, {
  Group: SelectPrimitive.Group,
  Value: SelectPrimitive.Value,
  Button,
  Trigger,
  Content,
  Label,
  Item,
  Separator,
});
