import React from 'react';

import { Link } from '@neptune/shared/venus-ui';

import { ErrorPage } from './ErrorPage';

export const UnavailableErrorPage = () => {
  return (
    <ErrorPage>
      <ErrorPage.Message>The page you are trying to access is unavailable now.</ErrorPage.Message>
      <ErrorPage.Footer>
        Please <Link onClick={() => window.location.reload()} children="reload page" /> to continue.
      </ErrorPage.Footer>
    </ErrorPage>
  );
};
