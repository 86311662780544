/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InvitationTypeEnumDTO } from './InvitationTypeEnumDTO';
import {
    InvitationTypeEnumDTOFromJSON,
    InvitationTypeEnumDTOFromJSONTyped,
    InvitationTypeEnumDTOToJSON,
} from './InvitationTypeEnumDTO';
import type { OrganizationRoleDTO } from './OrganizationRoleDTO';
import {
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
} from './OrganizationRoleDTO';
import type { NewOrganizationInvitationProjectEntryDTO } from './NewOrganizationInvitationProjectEntryDTO';
import {
    NewOrganizationInvitationProjectEntryDTOFromJSON,
    NewOrganizationInvitationProjectEntryDTOFromJSONTyped,
    NewOrganizationInvitationProjectEntryDTOToJSON,
} from './NewOrganizationInvitationProjectEntryDTO';

/**
 * 
 * @export
 * @interface NewOrganizationInvitationEntryDTO
 */
export interface NewOrganizationInvitationEntryDTO {
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    roleGrant: OrganizationRoleDTO;
    /**
     * 
     * @type {boolean}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    addToAllProjects?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    invitee: string;
    /**
     * 
     * @type {Array<NewOrganizationInvitationProjectEntryDTO>}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    addToProjects?: Array<NewOrganizationInvitationProjectEntryDTO>;
    /**
     * 
     * @type {InvitationTypeEnumDTO}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    invitationType: InvitationTypeEnumDTO;
}



/**
 * Check if a given object implements the NewOrganizationInvitationEntryDTO interface.
 */
export function instanceOfNewOrganizationInvitationEntryDTO(value: object): value is NewOrganizationInvitationEntryDTO {
    if (!('roleGrant' in value) || value['roleGrant'] === undefined) return false;
    if (!('invitee' in value) || value['invitee'] === undefined) return false;
    if (!('invitationType' in value) || value['invitationType'] === undefined) return false;
    return true;
}

export function NewOrganizationInvitationEntryDTOFromJSON(json: any): NewOrganizationInvitationEntryDTO {
    return NewOrganizationInvitationEntryDTOFromJSONTyped(json, false);
}

export function NewOrganizationInvitationEntryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewOrganizationInvitationEntryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'roleGrant': OrganizationRoleDTOFromJSON(json['roleGrant']),
        'addToAllProjects': json['addToAllProjects'] == null ? undefined : json['addToAllProjects'],
        'invitee': json['invitee'],
        'addToProjects': json['addToProjects'] == null ? undefined : ((json['addToProjects'] as Array<any>).map(NewOrganizationInvitationProjectEntryDTOFromJSON)),
        'invitationType': InvitationTypeEnumDTOFromJSON(json['invitationType']),
    };
}

export function NewOrganizationInvitationEntryDTOToJSON(value?: NewOrganizationInvitationEntryDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'roleGrant': OrganizationRoleDTOToJSON(value['roleGrant']),
        'addToAllProjects': value['addToAllProjects'],
        'invitee': value['invitee'],
        'addToProjects': value['addToProjects'] == null ? undefined : ((value['addToProjects'] as Array<any>).map(NewOrganizationInvitationProjectEntryDTOToJSON)),
        'invitationType': InvitationTypeEnumDTOToJSON(value['invitationType']),
    };
}

