/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface FloatAttributeDTO
 */
export interface FloatAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof FloatAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof FloatAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {number}
     * @memberof FloatAttributeDTO
     */
    value: number;
}



/**
 * Check if a given object implements the FloatAttributeDTO interface.
 */
export function instanceOfFloatAttributeDTO(value: object): value is FloatAttributeDTO {
    if (!('attributeName' in value) || value['attributeName'] === undefined) return false;
    if (!('attributeType' in value) || value['attributeType'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function FloatAttributeDTOFromJSON(json: any): FloatAttributeDTO {
    return FloatAttributeDTOFromJSONTyped(json, false);
}

export function FloatAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'value': json['value'],
    };
}

export function FloatAttributeDTOToJSON(value?: FloatAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeName': value['attributeName'],
        'attributeType': AttributeTypeDTOToJSON(value['attributeType']),
        'value': value['value'],
    };
}

