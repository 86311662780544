/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeNameFilterDTO } from './AttributeNameFilterDTO';
import {
    AttributeNameFilterDTOFromJSON,
    AttributeNameFilterDTOFromJSONTyped,
    AttributeNameFilterDTOToJSON,
} from './AttributeNameFilterDTO';

/**
 * 
 * @export
 * @interface QueryAttributeDefinitionsDTO
 */
export interface QueryAttributeDefinitionsDTO {
    /**
     * 
     * @type {AttributeNameFilterDTO}
     * @memberof QueryAttributeDefinitionsDTO
     */
    attributeNameFilter?: AttributeNameFilterDTO;
}

/**
 * Check if a given object implements the QueryAttributeDefinitionsDTO interface.
 */
export function instanceOfQueryAttributeDefinitionsDTO(value: object): value is QueryAttributeDefinitionsDTO {
    return true;
}

export function QueryAttributeDefinitionsDTOFromJSON(json: any): QueryAttributeDefinitionsDTO {
    return QueryAttributeDefinitionsDTOFromJSONTyped(json, false);
}

export function QueryAttributeDefinitionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeDefinitionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeNameFilter': json['attributeNameFilter'] == null ? undefined : AttributeNameFilterDTOFromJSON(json['attributeNameFilter']),
    };
}

export function QueryAttributeDefinitionsDTOToJSON(value?: QueryAttributeDefinitionsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeNameFilter': AttributeNameFilterDTOToJSON(value['attributeNameFilter']),
    };
}

