import React from 'react';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';

import { useInViewport, ViewportContext } from 'components/in-viewport';

const VirtualizationWrapperVisibleContext = React.createContext<boolean>(true);

export function useVirtualizationWrapperIsVisible() {
  return React.useContext(VirtualizationWrapperVisibleContext);
}

type VirtualizationWrapperProps = {
  children: React.ReactNode;
  className?: string;
  disableVirtualization?: boolean;
  visibleOverride?: boolean;
  placeholder: React.ReactNode;
};

export const VirtualizationWrapper: React.FC<VirtualizationWrapperProps> = ({
  children,
  disableVirtualization,
  ...restProps
}) => {
  if (disableVirtualization) {
    // Note: in case of nested VirtualizationWrappers this can result in slightly different
    // behavior then enabled path, as VirtualizationWrapperContext is inherited here.
    return <>{children}</>;
  }

  return <VirtualizationWrapperInner {...restProps} children={children} />;
};

const VirtualizationWrapperInner: React.FC<Omit<VirtualizationWrapperProps, 'active'>> = ({
  className = '',
  children,
  placeholder,
  visibleOverride,
}) => {
  const portalNode = React.useMemo(
    () =>
      createHtmlPortalNode({
        attributes: {
          class: className,
        },
      }),
    [className],
  );
  const viewport = ViewportContext.useCreateViewportContext();
  const [inViewport, setInViewport] = React.useState(false);
  const [rendered, setRendered] = React.useState(false);
  const [domContainer, setDomContainer] = React.useState<HTMLElement | null>(null);
  const onShow = React.useCallback(() => setInViewport(true), []);
  const onHide = React.useCallback(() => setInViewport(false), []);

  useInViewport({
    viewport,
    debounceMs: 64,
    onShow,
    onHide,
    domContainer,
  });

  const visible = visibleOverride ?? inViewport;

  React.useEffect(() => {
    if (visible && !rendered) {
      setRendered(true);
    }
  }, [visible, rendered]);

  return (
    <div className={className} ref={setDomContainer}>
      <VirtualizationWrapperVisibleContext.Provider value={visible}>
        <InPortal node={portalNode} children={rendered ? children : null} />
      </VirtualizationWrapperVisibleContext.Provider>
      {visible ? <OutPortal node={portalNode} visible /> : placeholder}
    </div>
  );
};
