/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NqlQueryParamsDTO } from './NqlQueryParamsDTO';
import {
    NqlQueryParamsDTOFromJSON,
    NqlQueryParamsDTOFromJSONTyped,
    NqlQueryParamsDTOToJSON,
} from './NqlQueryParamsDTO';
import type { QueryLeaderboardParamsGroupingParamsDTO } from './QueryLeaderboardParamsGroupingParamsDTO';
import {
    QueryLeaderboardParamsGroupingParamsDTOFromJSON,
    QueryLeaderboardParamsGroupingParamsDTOFromJSONTyped,
    QueryLeaderboardParamsGroupingParamsDTOToJSON,
} from './QueryLeaderboardParamsGroupingParamsDTO';
import type { QueryLeaderboardParamsSuggestionsParamsDTO } from './QueryLeaderboardParamsSuggestionsParamsDTO';
import {
    QueryLeaderboardParamsSuggestionsParamsDTOFromJSON,
    QueryLeaderboardParamsSuggestionsParamsDTOFromJSONTyped,
    QueryLeaderboardParamsSuggestionsParamsDTOToJSON,
} from './QueryLeaderboardParamsSuggestionsParamsDTO';
import type { QueryLeaderboardParamsSortingParamsDTO } from './QueryLeaderboardParamsSortingParamsDTO';
import {
    QueryLeaderboardParamsSortingParamsDTOFromJSON,
    QueryLeaderboardParamsSortingParamsDTOFromJSONTyped,
    QueryLeaderboardParamsSortingParamsDTOToJSON,
} from './QueryLeaderboardParamsSortingParamsDTO';
import type { QueryLeaderboardParamsAttributeFilterDTO } from './QueryLeaderboardParamsAttributeFilterDTO';
import {
    QueryLeaderboardParamsAttributeFilterDTOFromJSON,
    QueryLeaderboardParamsAttributeFilterDTOFromJSONTyped,
    QueryLeaderboardParamsAttributeFilterDTOToJSON,
} from './QueryLeaderboardParamsAttributeFilterDTO';
import type { QueryLeaderboardParamsPaginationDTO } from './QueryLeaderboardParamsPaginationDTO';
import {
    QueryLeaderboardParamsPaginationDTOFromJSON,
    QueryLeaderboardParamsPaginationDTOFromJSONTyped,
    QueryLeaderboardParamsPaginationDTOToJSON,
} from './QueryLeaderboardParamsPaginationDTO';

/**
 * 
 * @export
 * @interface SearchLeaderboardEntriesParamsDTO
 */
export interface SearchLeaderboardEntriesParamsDTO {
    /**
     * 
     * @type {Array<QueryLeaderboardParamsAttributeFilterDTO>}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    attributeFilters?: Array<QueryLeaderboardParamsAttributeFilterDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    experimentLeader?: boolean;
    /**
     * 
     * @type {QueryLeaderboardParamsGroupingParamsDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    grouping?: QueryLeaderboardParamsGroupingParamsDTO;
    /**
     * 
     * @type {QueryLeaderboardParamsPaginationDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    pagination?: QueryLeaderboardParamsPaginationDTO;
    /**
     * 
     * @type {NqlQueryParamsDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    query?: NqlQueryParamsDTO;
    /**
     * 
     * @type {QueryLeaderboardParamsSortingParamsDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    sorting?: QueryLeaderboardParamsSortingParamsDTO;
    /**
     * 
     * @type {QueryLeaderboardParamsSuggestionsParamsDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    suggestions?: QueryLeaderboardParamsSuggestionsParamsDTO;
    /**
     * 
     * @type {number}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    truncateStringTo?: number;
}

/**
 * Check if a given object implements the SearchLeaderboardEntriesParamsDTO interface.
 */
export function instanceOfSearchLeaderboardEntriesParamsDTO(value: object): value is SearchLeaderboardEntriesParamsDTO {
    return true;
}

export function SearchLeaderboardEntriesParamsDTOFromJSON(json: any): SearchLeaderboardEntriesParamsDTO {
    return SearchLeaderboardEntriesParamsDTOFromJSONTyped(json, false);
}

export function SearchLeaderboardEntriesParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchLeaderboardEntriesParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeFilters': json['attributeFilters'] == null ? undefined : ((json['attributeFilters'] as Array<any>).map(QueryLeaderboardParamsAttributeFilterDTOFromJSON)),
        'experimentLeader': json['experimentLeader'] == null ? undefined : json['experimentLeader'],
        'grouping': json['grouping'] == null ? undefined : QueryLeaderboardParamsGroupingParamsDTOFromJSON(json['grouping']),
        'pagination': json['pagination'] == null ? undefined : QueryLeaderboardParamsPaginationDTOFromJSON(json['pagination']),
        'query': json['query'] == null ? undefined : NqlQueryParamsDTOFromJSON(json['query']),
        'sorting': json['sorting'] == null ? undefined : QueryLeaderboardParamsSortingParamsDTOFromJSON(json['sorting']),
        'suggestions': json['suggestions'] == null ? undefined : QueryLeaderboardParamsSuggestionsParamsDTOFromJSON(json['suggestions']),
        'truncateStringTo': json['truncateStringTo'] == null ? undefined : json['truncateStringTo'],
    };
}

export function SearchLeaderboardEntriesParamsDTOToJSON(value?: SearchLeaderboardEntriesParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeFilters': value['attributeFilters'] == null ? undefined : ((value['attributeFilters'] as Array<any>).map(QueryLeaderboardParamsAttributeFilterDTOToJSON)),
        'experimentLeader': value['experimentLeader'],
        'grouping': QueryLeaderboardParamsGroupingParamsDTOToJSON(value['grouping']),
        'pagination': QueryLeaderboardParamsPaginationDTOToJSON(value['pagination']),
        'query': NqlQueryParamsDTOToJSON(value['query']),
        'sorting': QueryLeaderboardParamsSortingParamsDTOToJSON(value['sorting']),
        'suggestions': QueryLeaderboardParamsSuggestionsParamsDTOToJSON(value['suggestions']),
        'truncateStringTo': value['truncateStringTo'],
    };
}

