import React from 'react';

import { bemBlock, Icon } from '@neptune/shared/venus-ui';

import './FavoriteIcon.less';

type FavoriteIconProps = {
  isFavorite: boolean;
  onClick?(): void;
};

const block = bemBlock('favorite-icon');

export const FavoriteIcon: React.FC<FavoriteIconProps> = ({ isFavorite, onClick }) => {
  return (
    <Icon
      className={block({
        modifiers: {
          clickable: !!onClick,
          favorite: isFavorite,
        },
      })}
      data-role="favorite-icon"
      glyph="star"
      onClick={onClick}
    />
  );
};
