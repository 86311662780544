/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AvatarSourceEnum } from './AvatarSourceEnum';
import {
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
} from './AvatarSourceEnum';
import type { ProjectCodeAccessDTO } from './ProjectCodeAccessDTO';
import {
    ProjectCodeAccessDTOFromJSON,
    ProjectCodeAccessDTOFromJSONTyped,
    ProjectCodeAccessDTOToJSON,
} from './ProjectCodeAccessDTO';
import type { ProjectVisibilityDTO } from './ProjectVisibilityDTO';
import {
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './ProjectVisibilityDTO';

/**
 * 
 * @export
 * @interface ProjectUpdateDTO
 */
export interface ProjectUpdateDTO {
    /**
     * 
     * @type {ProjectCodeAccessDTO}
     * @memberof ProjectUpdateDTO
     */
    codeAccess?: ProjectCodeAccessDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDTO
     */
    avatarUrl?: string;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof ProjectUpdateDTO
     */
    avatarSource?: AvatarSourceEnum;
    /**
     * 
     * @type {ProjectVisibilityDTO}
     * @memberof ProjectUpdateDTO
     */
    visibility?: ProjectVisibilityDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDTO
     */
    displayClass?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDTO
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectUpdateDTO
     */
    archived?: boolean;
}



/**
 * Check if a given object implements the ProjectUpdateDTO interface.
 */
export function instanceOfProjectUpdateDTO(value: object): value is ProjectUpdateDTO {
    return true;
}

export function ProjectUpdateDTOFromJSON(json: any): ProjectUpdateDTO {
    return ProjectUpdateDTOFromJSONTyped(json, false);
}

export function ProjectUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'codeAccess': json['codeAccess'] == null ? undefined : ProjectCodeAccessDTOFromJSON(json['codeAccess']),
        'name': json['name'] == null ? undefined : json['name'],
        'avatarUrl': json['avatarUrl'] == null ? undefined : json['avatarUrl'],
        'avatarSource': json['avatarSource'] == null ? undefined : AvatarSourceEnumFromJSON(json['avatarSource']),
        'visibility': json['visibility'] == null ? undefined : ProjectVisibilityDTOFromJSON(json['visibility']),
        'displayClass': json['displayClass'] == null ? undefined : json['displayClass'],
        'description': json['description'] == null ? undefined : json['description'],
        'archived': json['archived'] == null ? undefined : json['archived'],
    };
}

export function ProjectUpdateDTOToJSON(value?: ProjectUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'codeAccess': ProjectCodeAccessDTOToJSON(value['codeAccess']),
        'name': value['name'],
        'avatarUrl': value['avatarUrl'],
        'avatarSource': AvatarSourceEnumToJSON(value['avatarSource']),
        'visibility': ProjectVisibilityDTOToJSON(value['visibility']),
        'displayClass': value['displayClass'],
        'description': value['description'],
        'archived': value['archived'],
    };
}

