import React from 'react';

import { NormalizedLink, NormalizedLinkProps } from '@neptune/shared/routing-ui';
import { Link } from '@neptune/shared/venus-ui';

type PaymentsLinkProps = {
  organizationName?: string;
};

export const PaymentsLink: React.FC<PaymentsLinkProps> = ({ organizationName, children }) => {
  if (!organizationName) {
    return null;
  }

  return (
    <Link<NormalizedLinkProps>
      component={NormalizedLink}
      routeName="organization.payments"
      routeParams={{ organizationName }}
      children={children}
    />
  );
};
