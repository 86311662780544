import React from 'react';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { bemBlock, Layout, Link } from '@neptune/shared/venus-ui';

import { KeycloakAside } from './KeycloakAside';
import { KeycloakLogo } from './KeycloakLogo';

import './KeycloakScene.less';

const block = bemBlock('keycloak-scene');

type KeycloakSceneProps = {
  aside?: React.ReactChild;
  'data-role'?: string;
};

export const KeycloakScene: React.FC<KeycloakSceneProps> = ({
  aside,
  'data-role': dataRole,
  children,
}) => {
  return (
    <Layout.Row className={block()} height="100%" data-role={dataRole}>
      <main className={block('main')}>
        <KeycloakLogo className={block('logo')} size={36} />
        <article className={block('content')}>{children}</article>
        <footer className={block('help')}>
          Any questions?{' '}
          <Link external href={neptuneUrls.gettingHelpDoc}>
            We're happy to help
          </Link>
        </footer>
      </main>
      {aside && <KeycloakAside className={block('aside')}>{aside}</KeycloakAside>}
    </Layout.Row>
  );
};
