import { logger } from '@neptune/shared/core-logger-util';

import { authClient } from './auth-client';

const REFRESH_TOKEN_MIN_VALIDITY = 30;

export async function updateAuthToken(): Promise<string | undefined> {
  if (!authClient.authenticated) {
    return;
  }

  await updateToken(REFRESH_TOKEN_MIN_VALIDITY);
  return authClient.token;
}

export async function refreshAuthToken(): Promise<string | undefined> {
  if (!authClient.authenticated) {
    return;
  }

  await updateToken(Number.POSITIVE_INFINITY);
  return authClient.token;
}

async function updateToken(refreshTokenMinValidityTime: number) {
  try {
    await authClient.updateToken(refreshTokenMinValidityTime);
  } catch (apiError) {
    logger.logMessage('REST_API_ERROR', { module: 'authClient', apiError });
    throw apiError;
  }
}
