import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { bemBlock } from '../../modules/bem';

import './SliderRange.less';

const block = bemBlock('n-slider-range');

export const Range = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Range>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Range>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Range ref={ref} className={block({ extra: className })} {...props} />
));
