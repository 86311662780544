import React from 'react';

import { calculateColorsForVariant } from './utils/calculateColorsForVariant';
import { EmblemContext } from './EmblemContext';
import { EmblemColor, EmblemSize, EmblemVariant } from './types';

declare module 'react' {
  interface CSSProperties {
    '--emblem-background'?: string;
    '--emblem-background-hover'?: string;
    '--emblem-background-active'?: string;
    '--emblem-border'?: string;
    '--emblem-border-hover'?: string;
    '--emblem-border-active'?: string;
    '--emblem-outline-active'?: string;
    '--emblem-detail'?: string;
    '--emblem-detail-active'?: string;
    '--emblem-text'?: string;
    '--emblem-text-active'?: string;
    '--emblem-end-detail-wrapper-background-hover'?: string;
    '--emblem-end-detail-wrapper-background-hover-active'?: string;
  }
}

type EmblemProviderProps = {
  emblemActive?: boolean;
  color: EmblemColor;
  variant: EmblemVariant;
  size: EmblemSize;
};

export const EmblemProvider: React.FC<EmblemProviderProps> = ({
  emblemActive = false,
  color,
  variant,
  size,
  children,
}) => {
  const variantColors = React.useMemo(
    () => calculateColorsForVariant(color, variant),
    [color, variant],
  );

  const styles: React.CSSProperties = {
    '--emblem-background': variantColors.emblemBackground,
    '--emblem-background-hover': variantColors.emblemBackgroundHover,
    '--emblem-background-active': variantColors.emblemBackgroundActive,
    '--emblem-border': variantColors.emblemBorder,
    '--emblem-border-hover': variantColors.emblemBorderHover,
    '--emblem-border-active': variantColors.emblemBorderActive,
    '--emblem-outline-active': variantColors.emblemOutlineActive,
    '--emblem-detail': variantColors.emblemDetail,
    '--emblem-detail-active': variantColors.emblemDetailActive,
    '--emblem-text': variantColors.emblemText,
    '--emblem-text-active': variantColors.emblemTextActive,
    '--emblem-end-detail-wrapper-background-hover':
      variantColors.emblemEndDetailWrapperBackgroundHover,
    '--emblem-end-detail-wrapper-background-hover-active':
      variantColors.emblemEndDetailWrapperBackgroundHoverActive,
  };

  const providerValue = React.useMemo(
    () => ({ color, variant, size, active: emblemActive }),
    [color, size, variant, emblemActive],
  );

  return (
    <EmblemContext.Provider value={providerValue}>
      <div style={styles}>{children}</div>
    </EmblemContext.Provider>
  );
};
