/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaderboardViewDTO } from './LeaderboardViewDTO';
import {
    LeaderboardViewDTOFromJSON,
    LeaderboardViewDTOFromJSONTyped,
    LeaderboardViewDTOToJSON,
} from './LeaderboardViewDTO';

/**
 * 
 * @export
 * @interface LeaderboardViewListDTO
 */
export interface LeaderboardViewListDTO {
    /**
     * Leaderpoard views list
     * @type {Array<LeaderboardViewDTO>}
     * @memberof LeaderboardViewListDTO
     */
    views: Array<LeaderboardViewDTO>;
}

/**
 * Check if a given object implements the LeaderboardViewListDTO interface.
 */
export function instanceOfLeaderboardViewListDTO(value: object): value is LeaderboardViewListDTO {
    if (!('views' in value) || value['views'] === undefined) return false;
    return true;
}

export function LeaderboardViewListDTOFromJSON(json: any): LeaderboardViewListDTO {
    return LeaderboardViewListDTOFromJSONTyped(json, false);
}

export function LeaderboardViewListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardViewListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'views': ((json['views'] as Array<any>).map(LeaderboardViewDTOFromJSON)),
    };
}

export function LeaderboardViewListDTOToJSON(value?: LeaderboardViewListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'views': ((value['views'] as Array<any>).map(LeaderboardViewDTOToJSON)),
    };
}

