import React from 'react';

import { bemBlock } from '../../../modules/bem';
import { LayoutRow } from '../../layout-row/LayoutRow';

import { ItemOwnProps } from './item-props';

import './ActionsMenuSlotItem.less';

const block = bemBlock('n-actions-menu-slot-item');

export const ActionsMenuSlotItem = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentProps<'div'> & Omit<ItemOwnProps, 'trailingElement' | 'leadingIcon'>
>(({ className, children, active, destructive, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={block({
        extra: className,
        modifiers: { active, destructive },
      })}
      {...props}
    >
      <LayoutRow spacedChildren="sm" alignItems="center">
        {children}
      </LayoutRow>
    </div>
  );
});
