import { Middleware } from 'generated/backend-client';

// Our current openapi-generator version throws errors as ResponseError, whose shape isn't handled properly by FE app. This middleware throws the response object instead as a workaround.
export const responseErrorMiddleware: Middleware = {
  async post({ response }) {
    if (response && response.status >= 200 && response.status < 300) {
      return response;
    }

    throw response;
  },
};
