/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileLinksDTO
 */
export interface UserProfileLinksDTO {
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinksDTO
     */
    github?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinksDTO
     */
    linkedin?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserProfileLinksDTO
     */
    others: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinksDTO
     */
    kaggle?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinksDTO
     */
    twitter?: string;
}

/**
 * Check if a given object implements the UserProfileLinksDTO interface.
 */
export function instanceOfUserProfileLinksDTO(value: object): value is UserProfileLinksDTO {
    if (!('others' in value) || value['others'] === undefined) return false;
    return true;
}

export function UserProfileLinksDTOFromJSON(json: any): UserProfileLinksDTO {
    return UserProfileLinksDTOFromJSONTyped(json, false);
}

export function UserProfileLinksDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileLinksDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'github': json['github'] == null ? undefined : json['github'],
        'linkedin': json['linkedin'] == null ? undefined : json['linkedin'],
        'others': json['others'],
        'kaggle': json['kaggle'] == null ? undefined : json['kaggle'],
        'twitter': json['twitter'] == null ? undefined : json['twitter'],
    };
}

export function UserProfileLinksDTOToJSON(value?: UserProfileLinksDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'github': value['github'],
        'linkedin': value['linkedin'],
        'others': value['others'],
        'kaggle': value['kaggle'],
        'twitter': value['twitter'],
    };
}

