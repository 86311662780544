/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NewOrganizationInvitationEntryDTO } from './NewOrganizationInvitationEntryDTO';
import {
    NewOrganizationInvitationEntryDTOFromJSON,
    NewOrganizationInvitationEntryDTOFromJSONTyped,
    NewOrganizationInvitationEntryDTOToJSON,
} from './NewOrganizationInvitationEntryDTO';

/**
 * 
 * @export
 * @interface NewOrganizationInvitationsDTO
 */
export interface NewOrganizationInvitationsDTO {
    /**
     * 
     * @type {string}
     * @memberof NewOrganizationInvitationsDTO
     */
    organizationIdentifier: string;
    /**
     * 
     * @type {Array<NewOrganizationInvitationEntryDTO>}
     * @memberof NewOrganizationInvitationsDTO
     */
    invitationsEntries: Array<NewOrganizationInvitationEntryDTO>;
}

/**
 * Check if a given object implements the NewOrganizationInvitationsDTO interface.
 */
export function instanceOfNewOrganizationInvitationsDTO(value: object): value is NewOrganizationInvitationsDTO {
    if (!('organizationIdentifier' in value) || value['organizationIdentifier'] === undefined) return false;
    if (!('invitationsEntries' in value) || value['invitationsEntries'] === undefined) return false;
    return true;
}

export function NewOrganizationInvitationsDTOFromJSON(json: any): NewOrganizationInvitationsDTO {
    return NewOrganizationInvitationsDTOFromJSONTyped(json, false);
}

export function NewOrganizationInvitationsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewOrganizationInvitationsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'organizationIdentifier': json['organizationIdentifier'],
        'invitationsEntries': ((json['invitationsEntries'] as Array<any>).map(NewOrganizationInvitationEntryDTOFromJSON)),
    };
}

export function NewOrganizationInvitationsDTOToJSON(value?: NewOrganizationInvitationsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'organizationIdentifier': value['organizationIdentifier'],
        'invitationsEntries': ((value['invitationsEntries'] as Array<any>).map(NewOrganizationInvitationEntryDTOToJSON)),
    };
}

