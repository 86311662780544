/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectChartMetricDTO
 */
export interface ProjectChartMetricDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectChartMetricDTO
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectChartMetricDTO
     */
    useFunction?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectChartMetricDTO
     */
    useValue?: boolean;
}

/**
 * Check if a given object implements the ProjectChartMetricDTO interface.
 */
export function instanceOfProjectChartMetricDTO(value: object): value is ProjectChartMetricDTO {
    return true;
}

export function ProjectChartMetricDTOFromJSON(json: any): ProjectChartMetricDTO {
    return ProjectChartMetricDTOFromJSONTyped(json, false);
}

export function ProjectChartMetricDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectChartMetricDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'useFunction': json['useFunction'] == null ? undefined : json['useFunction'],
        'useValue': json['useValue'] == null ? undefined : json['useValue'],
    };
}

export function ProjectChartMetricDTOToJSON(value?: ProjectChartMetricDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'useFunction': value['useFunction'],
        'useValue': value['useValue'],
    };
}

