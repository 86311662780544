/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationInvitationDTO } from './OrganizationInvitationDTO';
import {
    OrganizationInvitationDTOFromJSON,
    OrganizationInvitationDTOFromJSONTyped,
    OrganizationInvitationDTOToJSON,
} from './OrganizationInvitationDTO';

/**
 * 
 * @export
 * @interface OrganizationInvitationsDTO
 */
export interface OrganizationInvitationsDTO {
    /**
     * 
     * @type {Array<OrganizationInvitationDTO>}
     * @memberof OrganizationInvitationsDTO
     */
    invitations: Array<OrganizationInvitationDTO>;
}

/**
 * Check if a given object implements the OrganizationInvitationsDTO interface.
 */
export function instanceOfOrganizationInvitationsDTO(value: object): value is OrganizationInvitationsDTO {
    if (!('invitations' in value) || value['invitations'] === undefined) return false;
    return true;
}

export function OrganizationInvitationsDTOFromJSON(json: any): OrganizationInvitationsDTO {
    return OrganizationInvitationsDTOFromJSONTyped(json, false);
}

export function OrganizationInvitationsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationInvitationsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'invitations': ((json['invitations'] as Array<any>).map(OrganizationInvitationDTOFromJSON)),
    };
}

export function OrganizationInvitationsDTOToJSON(value?: OrganizationInvitationsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invitations': ((value['invitations'] as Array<any>).map(OrganizationInvitationDTOToJSON)),
    };
}

