import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { bemBlock } from '../../modules/bem';

import './InputSelectContent.less';

const block = bemBlock('input-select-content');

export const Content = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={block({ extra: className })}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport className={block({ element: 'viewport' })}>
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
