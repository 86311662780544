/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InvitationStatusEnumDTO } from './InvitationStatusEnumDTO';
import {
    InvitationStatusEnumDTOFromJSON,
    InvitationStatusEnumDTOFromJSONTyped,
    InvitationStatusEnumDTOToJSON,
} from './InvitationStatusEnumDTO';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';
import type { InvitationTypeEnumDTO } from './InvitationTypeEnumDTO';
import {
    InvitationTypeEnumDTOFromJSON,
    InvitationTypeEnumDTOFromJSONTyped,
    InvitationTypeEnumDTOToJSON,
} from './InvitationTypeEnumDTO';

/**
 * 
 * @export
 * @interface ProjectInvitationDTO
 */
export interface ProjectInvitationDTO {
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ProjectInvitationDTO
     */
    roleGrant: ProjectRoleDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    invitedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    invitee: string;
    /**
     * 
     * @type {InvitationStatusEnumDTO}
     * @memberof ProjectInvitationDTO
     */
    status: InvitationStatusEnumDTO;
    /**
     * 
     * @type {Date}
     * @memberof ProjectInvitationDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {InvitationTypeEnumDTO}
     * @memberof ProjectInvitationDTO
     */
    invitationType: InvitationTypeEnumDTO;
}



/**
 * Check if a given object implements the ProjectInvitationDTO interface.
 */
export function instanceOfProjectInvitationDTO(value: object): value is ProjectInvitationDTO {
    if (!('roleGrant' in value) || value['roleGrant'] === undefined) return false;
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('invitedBy' in value) || value['invitedBy'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('invitee' in value) || value['invitee'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('invitationType' in value) || value['invitationType'] === undefined) return false;
    return true;
}

export function ProjectInvitationDTOFromJSON(json: any): ProjectInvitationDTO {
    return ProjectInvitationDTOFromJSONTyped(json, false);
}

export function ProjectInvitationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInvitationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'roleGrant': ProjectRoleDTOFromJSON(json['roleGrant']),
        'projectName': json['projectName'],
        'invitedBy': json['invitedBy'],
        'organizationName': json['organizationName'],
        'id': json['id'] == null ? undefined : json['id'],
        'invitee': json['invitee'],
        'status': InvitationStatusEnumDTOFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
        'invitationType': InvitationTypeEnumDTOFromJSON(json['invitationType']),
    };
}

export function ProjectInvitationDTOToJSON(value?: ProjectInvitationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'roleGrant': ProjectRoleDTOToJSON(value['roleGrant']),
        'projectName': value['projectName'],
        'invitedBy': value['invitedBy'],
        'organizationName': value['organizationName'],
        'id': value['id'],
        'invitee': value['invitee'],
        'status': InvitationStatusEnumDTOToJSON(value['status']),
        'createdAt': ((value['createdAt']).toISOString()),
        'invitationType': InvitationTypeEnumDTOToJSON(value['invitationType']),
    };
}

