/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotebookDTO
 */
export interface NotebookDTO {
    /**
     * 
     * @type {Date}
     * @memberof NotebookDTO
     */
    creationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    lastCheckpointId: string;
    /**
     * 
     * @type {Date}
     * @memberof NotebookDTO
     */
    lastCheckpointTime: Date;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookDTO
     */
    projectName: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotebookDTO
     */
    removable: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotebookDTO
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof NotebookDTO
     */
    totalStorageSize: number;
}

/**
 * Check if a given object implements the NotebookDTO interface.
 */
export function instanceOfNotebookDTO(value: object): value is NotebookDTO {
    if (!('creationTime' in value) || value['creationTime'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lastCheckpointId' in value) || value['lastCheckpointId'] === undefined) return false;
    if (!('lastCheckpointTime' in value) || value['lastCheckpointTime'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('owner' in value) || value['owner'] === undefined) return false;
    if (!('path' in value) || value['path'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('removable' in value) || value['removable'] === undefined) return false;
    if (!('size' in value) || value['size'] === undefined) return false;
    if (!('totalStorageSize' in value) || value['totalStorageSize'] === undefined) return false;
    return true;
}

export function NotebookDTOFromJSON(json: any): NotebookDTO {
    return NotebookDTOFromJSONTyped(json, false);
}

export function NotebookDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotebookDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'creationTime': (new Date(json['creationTime'])),
        'description': json['description'],
        'id': json['id'],
        'lastCheckpointId': json['lastCheckpointId'],
        'lastCheckpointTime': (new Date(json['lastCheckpointTime'])),
        'name': json['name'],
        'organizationId': json['organizationId'],
        'organizationName': json['organizationName'],
        'owner': json['owner'],
        'path': json['path'],
        'projectId': json['projectId'],
        'projectName': json['projectName'],
        'removable': json['removable'],
        'size': json['size'],
        'totalStorageSize': json['totalStorageSize'],
    };
}

export function NotebookDTOToJSON(value?: NotebookDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'creationTime': ((value['creationTime']).toISOString()),
        'description': value['description'],
        'id': value['id'],
        'lastCheckpointId': value['lastCheckpointId'],
        'lastCheckpointTime': ((value['lastCheckpointTime']).toISOString()),
        'name': value['name'],
        'organizationId': value['organizationId'],
        'organizationName': value['organizationName'],
        'owner': value['owner'],
        'path': value['path'],
        'projectId': value['projectId'],
        'projectName': value['projectName'],
        'removable': value['removable'],
        'size': value['size'],
        'totalStorageSize': value['totalStorageSize'],
    };
}

