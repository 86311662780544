/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateSessionParamsDTO } from './CreateSessionParamsDTO';
import {
    CreateSessionParamsDTOFromJSON,
    CreateSessionParamsDTOFromJSONTyped,
    CreateSessionParamsDTOToJSON,
} from './CreateSessionParamsDTO';
import type { PricingPlanDTO } from './PricingPlanDTO';
import {
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './PricingPlanDTO';

/**
 * 
 * @export
 * @interface UpgradePurchaseRequestDTO
 */
export interface UpgradePurchaseRequestDTO {
    /**
     * 
     * @type {CreateSessionParamsDTO}
     * @memberof UpgradePurchaseRequestDTO
     */
    sessionParams: CreateSessionParamsDTO;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof UpgradePurchaseRequestDTO
     */
    pricingPlan: PricingPlanDTO;
}



/**
 * Check if a given object implements the UpgradePurchaseRequestDTO interface.
 */
export function instanceOfUpgradePurchaseRequestDTO(value: object): value is UpgradePurchaseRequestDTO {
    if (!('sessionParams' in value) || value['sessionParams'] === undefined) return false;
    if (!('pricingPlan' in value) || value['pricingPlan'] === undefined) return false;
    return true;
}

export function UpgradePurchaseRequestDTOFromJSON(json: any): UpgradePurchaseRequestDTO {
    return UpgradePurchaseRequestDTOFromJSONTyped(json, false);
}

export function UpgradePurchaseRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpgradePurchaseRequestDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionParams': CreateSessionParamsDTOFromJSON(json['sessionParams']),
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
    };
}

export function UpgradePurchaseRequestDTOToJSON(value?: UpgradePurchaseRequestDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionParams': CreateSessionParamsDTOToJSON(value['sessionParams']),
        'pricingPlan': PricingPlanDTOToJSON(value['pricingPlan']),
    };
}

