/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface SingleCustomTimeSeriesView
 */
export interface SingleCustomTimeSeriesView {
    /**
     * 
     * @type {string}
     * @memberof SingleCustomTimeSeriesView
     */
    customId: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof SingleCustomTimeSeriesView
     */
    values: Array<Point>;
}

/**
 * Check if a given object implements the SingleCustomTimeSeriesView interface.
 */
export function instanceOfSingleCustomTimeSeriesView(value: object): value is SingleCustomTimeSeriesView {
    if (!('customId' in value) || value['customId'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function SingleCustomTimeSeriesViewFromJSON(json: any): SingleCustomTimeSeriesView {
    return SingleCustomTimeSeriesViewFromJSONTyped(json, false);
}

export function SingleCustomTimeSeriesViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleCustomTimeSeriesView {
    if (json == null) {
        return json;
    }
    return {
        
        'customId': json['customId'],
        'values': ((json['values'] as Array<any>).map(PointFromJSON)),
    };
}

export function SingleCustomTimeSeriesViewToJSON(value?: SingleCustomTimeSeriesView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customId': value['customId'],
        'values': ((value['values'] as Array<any>).map(PointToJSON)),
    };
}

