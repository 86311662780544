/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeSeries } from './TimeSeries';
import {
    TimeSeriesFromJSON,
    TimeSeriesFromJSONTyped,
    TimeSeriesToJSON,
} from './TimeSeries';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface SingleTimeSeriesView
 */
export interface SingleTimeSeriesView {
    /**
     * 
     * @type {TimeSeries}
     * @memberof SingleTimeSeriesView
     */
    timeSeries: TimeSeries;
    /**
     * 
     * @type {Array<Point>}
     * @memberof SingleTimeSeriesView
     */
    values: Array<Point>;
}

/**
 * Check if a given object implements the SingleTimeSeriesView interface.
 */
export function instanceOfSingleTimeSeriesView(value: object): value is SingleTimeSeriesView {
    if (!('timeSeries' in value) || value['timeSeries'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function SingleTimeSeriesViewFromJSON(json: any): SingleTimeSeriesView {
    return SingleTimeSeriesViewFromJSONTyped(json, false);
}

export function SingleTimeSeriesViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleTimeSeriesView {
    if (json == null) {
        return json;
    }
    return {
        
        'timeSeries': TimeSeriesFromJSON(json['timeSeries']),
        'values': ((json['values'] as Array<any>).map(PointFromJSON)),
    };
}

export function SingleTimeSeriesViewToJSON(value?: SingleTimeSeriesView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timeSeries': TimeSeriesToJSON(value['timeSeries']),
        'values': ((value['values'] as Array<any>).map(PointToJSON)),
    };
}

