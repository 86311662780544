/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectChartDTO } from './ProjectChartDTO';
import {
    ProjectChartDTOFromJSON,
    ProjectChartDTOFromJSONTyped,
    ProjectChartDTOToJSON,
} from './ProjectChartDTO';

/**
 * 
 * @export
 * @interface ProjectChartListDTO
 */
export interface ProjectChartListDTO {
    /**
     * The entries matching the given criteria.
     * @type {Array<ProjectChartDTO>}
     * @memberof ProjectChartListDTO
     */
    entries: Array<ProjectChartDTO>;
    /**
     * The total number of entries matching the given criteria.
     * @type {number}
     * @memberof ProjectChartListDTO
     */
    matchingItemCount: number;
    /**
     * The total number of entries.
     * @type {number}
     * @memberof ProjectChartListDTO
     */
    totalItemCount: number;
}

/**
 * Check if a given object implements the ProjectChartListDTO interface.
 */
export function instanceOfProjectChartListDTO(value: object): value is ProjectChartListDTO {
    if (!('entries' in value) || value['entries'] === undefined) return false;
    if (!('matchingItemCount' in value) || value['matchingItemCount'] === undefined) return false;
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    return true;
}

export function ProjectChartListDTOFromJSON(json: any): ProjectChartListDTO {
    return ProjectChartListDTOFromJSONTyped(json, false);
}

export function ProjectChartListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectChartListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(ProjectChartDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'totalItemCount': json['totalItemCount'],
    };
}

export function ProjectChartListDTOToJSON(value?: ProjectChartListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entries': ((value['entries'] as Array<any>).map(ProjectChartDTOToJSON)),
        'matchingItemCount': value['matchingItemCount'],
        'totalItemCount': value['totalItemCount'],
    };
}

