// Libs
import React from 'react';
import { addDays, isAfter } from 'date-fns';

import { Layout, LegacyEmblem, Text } from '@neptune/shared/venus-ui';

// App

// Module
export type ReleaseEmblemProps = {
  released?: Date;
  expirationDays?: number;
};

export const ReleaseEmblem: React.FC<ReleaseEmblemProps> = ({ expirationDays = 60, released }) => {
  const currentDate = new Date();

  if (released && isAfter(currentDate, addDays(released, expirationDays))) {
    return null;
  }

  const text = released !== undefined && isAfter(currentDate, released) ? 'NEW' : 'COMING SOON';
  return (
    <Layout.Row data-role="release-emblem" height={14} span="auto">
      <LegacyEmblem success>
        <Text size="xxs" fontWeight="normal">
          {text}
        </Text>
      </LegacyEmblem>
    </Layout.Row>
  );
};
