// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { BreadcrumbsElement } from '../breadcrumbs-element/BreadcrumbsElement';
import { BreadcrumbsLevelUp } from '../breadcrumbs-level-up/BreadcrumbsLevelUp';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';

// Module
export type BreadcrumbsProps = Omit<
  LayoutRowProps<{}>,
  'alignItems' | 'overflow' | 'spacedChildren' | 'justifyContent' | 'reversed'
>;

const block = bemBlock('n-Breadcrumbs');

/**
 * `Breadcrumbs` is a container for all breadcrumbs related components.
 *
 * ## Shorthands to Breadcrumbs related components
 *
 * All breadcrumbs related components are exposed as properties of `Breadcrumbs`,
 * so it is possible to import only `Breadcrumbs` to use all those components.
 *
 * | Component            | Property name | Usage                     |
 * | -------------------- | ------------- | ------------------------- |
 * | `BreadcrumbsElement` | `Element`     | `<Breadcrumbs.Element />` |
 * | `BreadcrumbsLevelUp` | `LevelUp`     | `<Breadcrumbs.LevelUp />` |
 */
export class Breadcrumbs extends React.Component<BreadcrumbsProps> {
  public static LevelUp = BreadcrumbsLevelUp;

  public static Element = BreadcrumbsElement;

  public static defaultProps = {
    alignItems: 'center',
    overflow: 'hidden',
    spacedChildren: 'sm',
    span: 'auto',
  };

  render() {
    const { className, ...passProps } = this.props;

    return <LayoutRow className={block({ extra: className })} {...passProps} />;
  }
}
