/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorsConfigDTO } from './ColorsConfigDTO';
import {
    ColorsConfigDTOFromJSON,
    ColorsConfigDTOFromJSONTyped,
    ColorsConfigDTOToJSON,
} from './ColorsConfigDTO';
import type { DashboardLayoutsDTO } from './DashboardLayoutsDTO';
import {
    DashboardLayoutsDTOFromJSON,
    DashboardLayoutsDTOFromJSONTyped,
    DashboardLayoutsDTOToJSON,
} from './DashboardLayoutsDTO';
import type { DashboardConfigDTO } from './DashboardConfigDTO';
import {
    DashboardConfigDTOFromJSON,
    DashboardConfigDTOFromJSONTyped,
    DashboardConfigDTOToJSON,
} from './DashboardConfigDTO';
import type { TableViewParamsDTO } from './TableViewParamsDTO';
import {
    TableViewParamsDTOFromJSON,
    TableViewParamsDTOFromJSONTyped,
    TableViewParamsDTOToJSON,
} from './TableViewParamsDTO';
import type { WidgetDTO } from './WidgetDTO';
import {
    WidgetDTOFromJSON,
    WidgetDTOFromJSONTyped,
    WidgetDTOToJSON,
} from './WidgetDTO';

/**
 * 
 * @export
 * @interface DashboardDTO
 */
export interface DashboardDTO {
    /**
     * 
     * @type {number}
     * @memberof DashboardDTO
     */
    autoComparePool?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    color?: string;
    /**
     * 
     * @type {ColorsConfigDTO}
     * @memberof DashboardDTO
     */
    colorsConfig?: ColorsConfigDTO;
    /**
     * 
     * @type {DashboardConfigDTO}
     * @memberof DashboardDTO
     */
    config?: DashboardConfigDTO;
    /**
     * 
     * @type {Date}
     * @memberof DashboardDTO
     */
    createdTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardDTO
     */
    draft: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    experimentShortId?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DashboardDTO
     */
    lastUpdatedTime?: Date;
    /**
     * 
     * @type {DashboardLayoutsDTO}
     * @memberof DashboardDTO
     */
    layouts: DashboardLayoutsDTO;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    owner?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardDTO
     */
    runIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    type: DashboardDTOTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardDTO
     */
    userExperimentNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    versionBranch: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    versionName?: string;
    /**
     * 
     * @type {TableViewParamsDTO}
     * @memberof DashboardDTO
     */
    viewParams?: TableViewParamsDTO;
    /**
     * 
     * @type {Array<WidgetDTO>}
     * @memberof DashboardDTO
     */
    widgets: Array<WidgetDTO>;
}


/**
 * @export
 */
export const DashboardDTOTypeEnum = {
    Compare: 'compare',
    Experiment: 'experiment',
    Report: 'report'
} as const;
export type DashboardDTOTypeEnum = typeof DashboardDTOTypeEnum[keyof typeof DashboardDTOTypeEnum];


/**
 * Check if a given object implements the DashboardDTO interface.
 */
export function instanceOfDashboardDTO(value: object): value is DashboardDTO {
    if (!('draft' in value) || value['draft'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('layouts' in value) || value['layouts'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('versionBranch' in value) || value['versionBranch'] === undefined) return false;
    if (!('widgets' in value) || value['widgets'] === undefined) return false;
    return true;
}

export function DashboardDTOFromJSON(json: any): DashboardDTO {
    return DashboardDTOFromJSONTyped(json, false);
}

export function DashboardDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'autoComparePool': json['autoComparePool'] == null ? undefined : json['autoComparePool'],
        'color': json['color'] == null ? undefined : json['color'],
        'colorsConfig': json['colorsConfig'] == null ? undefined : ColorsConfigDTOFromJSON(json['colorsConfig']),
        'config': json['config'] == null ? undefined : DashboardConfigDTOFromJSON(json['config']),
        'createdTime': json['createdTime'] == null ? undefined : (new Date(json['createdTime'])),
        'draft': json['draft'],
        'experimentShortId': json['experimentShortId'] == null ? undefined : json['experimentShortId'],
        'icon': json['icon'] == null ? undefined : json['icon'],
        'id': json['id'],
        'lastUpdatedTime': json['lastUpdatedTime'] == null ? undefined : (new Date(json['lastUpdatedTime'])),
        'layouts': DashboardLayoutsDTOFromJSON(json['layouts']),
        'name': json['name'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'runIds': json['runIds'] == null ? undefined : json['runIds'],
        'type': json['type'],
        'userExperimentNames': json['userExperimentNames'] == null ? undefined : json['userExperimentNames'],
        'versionBranch': json['versionBranch'],
        'versionDescription': json['versionDescription'] == null ? undefined : json['versionDescription'],
        'versionName': json['versionName'] == null ? undefined : json['versionName'],
        'viewParams': json['viewParams'] == null ? undefined : TableViewParamsDTOFromJSON(json['viewParams']),
        'widgets': ((json['widgets'] as Array<any>).map(WidgetDTOFromJSON)),
    };
}

export function DashboardDTOToJSON(value?: DashboardDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'autoComparePool': value['autoComparePool'],
        'color': value['color'],
        'colorsConfig': ColorsConfigDTOToJSON(value['colorsConfig']),
        'config': DashboardConfigDTOToJSON(value['config']),
        'createdTime': value['createdTime'] == null ? undefined : ((value['createdTime']).toISOString()),
        'draft': value['draft'],
        'experimentShortId': value['experimentShortId'],
        'icon': value['icon'],
        'id': value['id'],
        'lastUpdatedTime': value['lastUpdatedTime'] == null ? undefined : ((value['lastUpdatedTime']).toISOString()),
        'layouts': DashboardLayoutsDTOToJSON(value['layouts']),
        'name': value['name'],
        'owner': value['owner'],
        'runIds': value['runIds'],
        'type': value['type'],
        'userExperimentNames': value['userExperimentNames'],
        'versionBranch': value['versionBranch'],
        'versionDescription': value['versionDescription'],
        'versionName': value['versionName'],
        'viewParams': TableViewParamsDTOToJSON(value['viewParams']),
        'widgets': ((value['widgets'] as Array<any>).map(WidgetDTOToJSON)),
    };
}

