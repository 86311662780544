import React from 'react';

import { bemBlock, Icon, IconStack } from '@neptune/shared/venus-ui';

import './IconWithIndicator.less';

const block = bemBlock('icon-with-indicator');

export function IconWithIndicator(props: {
  glyph: React.ComponentProps<typeof Icon>['glyph'];
  showIndicator: boolean;
}) {
  return (
    <IconStack className={block()}>
      <Icon color="text-alt" size="lg" fixedWidth glyph={props.glyph} />
      {props.showIndicator && (
        <Icon
          className={block('dot-indicator')}
          size="lg"
          transform={{ x: 0.4, y: -0.2 }}
          glyph="dot"
        />
      )}
    </IconStack>
  );
}
