// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutRow } from '../layout-row/LayoutRow';
import { TabSize, TabsMenuItem } from '../tabs-menu-item/TabsMenuItem';

const block = bemBlock('n-TabsMenu');

export type TabsMenuProps<P = number> = Omit<React.ComponentProps<typeof LayoutRow>, 'onChange'> & {
  onChange?: (event: React.MouseEvent, param?: P) => void;
  /** To make the component behave similarly to radio component, use the `value` prop. */
  size?: TabSize;
  vertical?: boolean;
  value?: P;
};

export class TabsMenu<P> extends React.Component<TabsMenuProps<P>> {
  public static Item = TabsMenuItem;

  render() {
    const { children, className, onChange, size, value, vertical, ...passProps } = this.props;

    const tabs = React.Children.toArray(children)
      .filter(onlyComponents)
      .map((child, idx) => {
        const childValue = child.props.value == undefined ? idx : child.props.value;
        const selected =
          child.props.selected == undefined ? value === childValue : child.props.selected;
        const childSize = child.props.size == undefined ? size : child.props.size;

        return React.cloneElement(child, {
          selected,
          size: childSize,
          value: childValue,
          vertical,
          onChange,
        });
      });

    const LayoutComponent = vertical ? LayoutColumn : LayoutRow;

    return (
      <LayoutComponent
        className={block({
          extra: className,
        })}
        spacedChildren="sm"
        span="auto"
        {...passProps}
      >
        {tabs}
      </LayoutComponent>
    );
  }
}

function onlyComponents(child: React.ReactNode): child is React.ReactElement {
  return (child as React.ReactElement).props !== undefined;
}
