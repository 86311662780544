/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Scale
 */
export interface Scale {
    /**
     * 
     * @type {object}
     * @memberof Scale
     */
    linear?: object;
    /**
     * 
     * @type {object}
     * @memberof Scale
     */
    log?: object;
}

/**
 * Check if a given object implements the Scale interface.
 */
export function instanceOfScale(value: object): value is Scale {
    return true;
}

export function ScaleFromJSON(json: any): Scale {
    return ScaleFromJSONTyped(json, false);
}

export function ScaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Scale {
    if (json == null) {
        return json;
    }
    return {
        
        'linear': json['linear'] == null ? undefined : json['linear'],
        'log': json['log'] == null ? undefined : json['log'],
    };
}

export function ScaleToJSON(value?: Scale | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'linear': value['linear'],
        'log': value['log'],
    };
}

