/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExperimentTypeDTO } from './ExperimentTypeDTO';
import {
    ExperimentTypeDTOFromJSON,
    ExperimentTypeDTOFromJSONTyped,
    ExperimentTypeDTOToJSON,
} from './ExperimentTypeDTO';

/**
 * 
 * @export
 * @interface Experiment
 */
export interface Experiment {
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    customId?: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    shortId: string;
    /**
     * 
     * @type {boolean}
     * @memberof Experiment
     */
    trashed: boolean;
    /**
     * 
     * @type {ExperimentTypeDTO}
     * @memberof Experiment
     */
    type: ExperimentTypeDTO;
}



/**
 * Check if a given object implements the Experiment interface.
 */
export function instanceOfExperiment(value: object): value is Experiment {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('parentId' in value) || value['parentId'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('shortId' in value) || value['shortId'] === undefined) return false;
    if (!('trashed' in value) || value['trashed'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function ExperimentFromJSON(json: any): Experiment {
    return ExperimentFromJSONTyped(json, false);
}

export function ExperimentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Experiment {
    if (json == null) {
        return json;
    }
    return {
        
        'customId': json['customId'] == null ? undefined : json['customId'],
        'id': json['id'],
        'organizationId': json['organizationId'],
        'organizationName': json['organizationName'],
        'parentId': json['parentId'],
        'projectId': json['projectId'],
        'projectName': json['projectName'],
        'shortId': json['shortId'],
        'trashed': json['trashed'],
        'type': ExperimentTypeDTOFromJSON(json['type']),
    };
}

export function ExperimentToJSON(value?: Experiment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customId': value['customId'],
        'id': value['id'],
        'organizationId': value['organizationId'],
        'organizationName': value['organizationName'],
        'parentId': value['parentId'],
        'projectId': value['projectId'],
        'projectName': value['projectName'],
        'shortId': value['shortId'],
        'trashed': value['trashed'],
        'type': ExperimentTypeDTOToJSON(value['type']),
    };
}

