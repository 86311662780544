/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportMetadataDTO } from './ReportMetadataDTO';
import {
    ReportMetadataDTOFromJSON,
    ReportMetadataDTOFromJSONTyped,
    ReportMetadataDTOToJSON,
} from './ReportMetadataDTO';

/**
 * 
 * @export
 * @interface ReportMetadataListDTO
 */
export interface ReportMetadataListDTO {
    /**
     * 
     * @type {Array<ReportMetadataDTO>}
     * @memberof ReportMetadataListDTO
     */
    reportsMetadata: Array<ReportMetadataDTO>;
}

/**
 * Check if a given object implements the ReportMetadataListDTO interface.
 */
export function instanceOfReportMetadataListDTO(value: object): value is ReportMetadataListDTO {
    if (!('reportsMetadata' in value) || value['reportsMetadata'] === undefined) return false;
    return true;
}

export function ReportMetadataListDTOFromJSON(json: any): ReportMetadataListDTO {
    return ReportMetadataListDTOFromJSONTyped(json, false);
}

export function ReportMetadataListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportMetadataListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'reportsMetadata': ((json['reportsMetadata'] as Array<any>).map(ReportMetadataDTOFromJSON)),
    };
}

export function ReportMetadataListDTOToJSON(value?: ReportMetadataListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reportsMetadata': ((value['reportsMetadata'] as Array<any>).map(ReportMetadataDTOToJSON)),
    };
}

