/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Custom } from './Custom';
import {
    CustomFromJSON,
    CustomFromJSONTyped,
    CustomToJSON,
} from './Custom';

/**
 * 
 * @export
 * @interface XAxis
 */
export interface XAxis {
    /**
     * 
     * @type {Custom}
     * @memberof XAxis
     */
    custom?: Custom;
    /**
     * 
     * @type {object}
     * @memberof XAxis
     */
    epochMillis?: object;
    /**
     * 
     * @type {object}
     * @memberof XAxis
     */
    relativeMillis?: object;
    /**
     * 
     * @type {object}
     * @memberof XAxis
     */
    steps?: object;
}

/**
 * Check if a given object implements the XAxis interface.
 */
export function instanceOfXAxis(value: object): value is XAxis {
    return true;
}

export function XAxisFromJSON(json: any): XAxis {
    return XAxisFromJSONTyped(json, false);
}

export function XAxisFromJSONTyped(json: any, ignoreDiscriminator: boolean): XAxis {
    if (json == null) {
        return json;
    }
    return {
        
        'custom': json['custom'] == null ? undefined : CustomFromJSON(json['custom']),
        'epochMillis': json['epochMillis'] == null ? undefined : json['epochMillis'],
        'relativeMillis': json['relativeMillis'] == null ? undefined : json['relativeMillis'],
        'steps': json['steps'] == null ? undefined : json['steps'],
    };
}

export function XAxisToJSON(value?: XAxis | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'custom': CustomToJSON(value['custom']),
        'epochMillis': value['epochMillis'],
        'relativeMillis': value['relativeMillis'],
        'steps': value['steps'],
    };
}

