import React from 'react';

import { bemBlock, Icon, IconStackProps } from '@neptune/shared/venus-ui';

import './SortIndicator.less';

type SortIndicatorProps = IconStackProps<{
  direction: 'none' | 'asc' | 'desc';
}>;

const block = bemBlock('sort-indicator');

export const SortIndicator: React.FC<SortIndicatorProps> = ({
  className,
  direction,
  ...restProps
}) => (
  <Icon.Stack
    className={block({
      extra: className,
    })}
    data-role="sort-indicator"
    data-direction={direction}
    {...restProps}
  >
    <Icon
      glyph="sort-up"
      className={block({
        element: 'asc',
        modifiers: {
          selected: direction === 'asc',
        },
      })}
    />
    <Icon
      glyph="sort-down"
      className={block({
        element: 'desc',
        modifiers: {
          selected: direction === 'desc',
        },
      })}
    />
  </Icon.Stack>
);
