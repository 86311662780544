import type { DefaultApi } from 'generated/leaderboard-client';

// note: this file is imported also by ts-node
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import type {
  HardcodedEndpointDefs,
  OnlyMethods,
} from '../../common/domain/hardcoded-endpoints/types';

type HardcodedEndpoints = Pick<
  OnlyMethods<DefaultApi>,
  // list hardcoded endpoint names here for strong type checking
  | 'getStringSeriesValuesCSV'
  | 'getFloatSeriesValuesCSV'
  | 'getImageSeriesValuesZip'
  | 'downloadAttribute'
>;

export const leaderboardHardcodedEndpoints: HardcodedEndpointDefs<HardcodedEndpoints> = {
  getStringSeriesValuesCSV: {
    pathTemplate: '/api/leaderboard/v1/attributes/stringSeries/csv',
    pathParamNames: [],
    queryParamNames: [
      'attribute',
      'expectedContentDisposition',
      'experimentId',
      'gzipped',
      'holderIdentifier',
      'holderType',
    ],
  },
  getFloatSeriesValuesCSV: {
    pathTemplate: '/api/leaderboard/v1/attributes/floatSeries/csv',
    pathParamNames: [],
    queryParamNames: [
      'attribute',
      'expectedContentDisposition',
      'experimentId',
      'gzipped',
      'holderIdentifier',
      'holderType',
    ],
  },
  getImageSeriesValuesZip: {
    pathTemplate: '/api/leaderboard/v1/attributes/imageSeries/zip',
    pathParamNames: [],
    queryParamNames: [
      'attribute',
      'expectedContentDisposition',
      'experimentId',
      'holderIdentifier',
      'holderType',
    ],
  },
  downloadAttribute: {
    pathTemplate: '/api/leaderboard/v1/attributes/download',
    pathParamNames: [],
    queryParamNames: [
      'attribute',
      'expectedContentDisposition',
      'experimentId',
      'holderIdentifier',
      'holderType',
      'path',
    ],
  },
};
