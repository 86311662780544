/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AvatarSourceEnum } from './AvatarSourceEnum';
import {
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
} from './AvatarSourceEnum';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';
import type { ProjectCodeAccessDTO } from './ProjectCodeAccessDTO';
import {
    ProjectCodeAccessDTOFromJSON,
    ProjectCodeAccessDTOFromJSONTyped,
    ProjectCodeAccessDTOToJSON,
} from './ProjectCodeAccessDTO';
import type { ProjectVisibilityDTO } from './ProjectVisibilityDTO';
import {
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './ProjectVisibilityDTO';
import type { OrganizationTypeDTO } from './OrganizationTypeDTO';
import {
    OrganizationTypeDTOFromJSON,
    OrganizationTypeDTOFromJSONTyped,
    OrganizationTypeDTOToJSON,
} from './OrganizationTypeDTO';
import type { OrganizationRoleDTO } from './OrganizationRoleDTO';
import {
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
} from './OrganizationRoleDTO';

/**
 * 
 * @export
 * @interface ProjectWithRoleDTO
 */
export interface ProjectWithRoleDTO {
    /**
     * 
     * @type {ProjectCodeAccessDTO}
     * @memberof ProjectWithRoleDTO
     */
    codeAccess: ProjectCodeAccessDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {OrganizationTypeDTO}
     * @memberof ProjectWithRoleDTO
     */
    organizationType: OrganizationTypeDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithRoleDTO
     */
    featured: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    organizationName: string;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof ProjectWithRoleDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithRoleDTO
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    projectKey: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    organizationId: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithRoleDTO
     */
    userCount: number;
    /**
     * 
     * @type {ProjectVisibilityDTO}
     * @memberof ProjectWithRoleDTO
     */
    visibility: ProjectVisibilityDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    displayClass?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectWithRoleDTO
     */
    lastActivity: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithRoleDTO
     */
    archived: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProjectWithRoleDTO
     */
    timeOfCreation: Date;
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof ProjectWithRoleDTO
     */
    userRoleInOrganization?: OrganizationRoleDTO;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ProjectWithRoleDTO
     */
    userRoleInProject: ProjectRoleDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    backgroundUrl?: string;
}



/**
 * Check if a given object implements the ProjectWithRoleDTO interface.
 */
export function instanceOfProjectWithRoleDTO(value: object): value is ProjectWithRoleDTO {
    if (!('codeAccess' in value) || value['codeAccess'] === undefined) return false;
    if (!('avatarUrl' in value) || value['avatarUrl'] === undefined) return false;
    if (!('organizationType' in value) || value['organizationType'] === undefined) return false;
    if (!('featured' in value) || value['featured'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('avatarSource' in value) || value['avatarSource'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('projectKey' in value) || value['projectKey'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('userCount' in value) || value['userCount'] === undefined) return false;
    if (!('visibility' in value) || value['visibility'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('lastActivity' in value) || value['lastActivity'] === undefined) return false;
    if (!('archived' in value) || value['archived'] === undefined) return false;
    if (!('timeOfCreation' in value) || value['timeOfCreation'] === undefined) return false;
    if (!('userRoleInProject' in value) || value['userRoleInProject'] === undefined) return false;
    return true;
}

export function ProjectWithRoleDTOFromJSON(json: any): ProjectWithRoleDTO {
    return ProjectWithRoleDTOFromJSONTyped(json, false);
}

export function ProjectWithRoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectWithRoleDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'codeAccess': ProjectCodeAccessDTOFromJSON(json['codeAccess']),
        'avatarUrl': json['avatarUrl'],
        'organizationType': OrganizationTypeDTOFromJSON(json['organizationType']),
        'featured': json['featured'],
        'organizationName': json['organizationName'],
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'version': json['version'],
        'id': json['id'],
        'projectKey': json['projectKey'],
        'organizationId': json['organizationId'],
        'userCount': json['userCount'],
        'visibility': ProjectVisibilityDTOFromJSON(json['visibility']),
        'displayClass': json['displayClass'] == null ? undefined : json['displayClass'],
        'name': json['name'],
        'lastActivity': (new Date(json['lastActivity'])),
        'description': json['description'] == null ? undefined : json['description'],
        'archived': json['archived'],
        'timeOfCreation': (new Date(json['timeOfCreation'])),
        'userRoleInOrganization': json['userRoleInOrganization'] == null ? undefined : OrganizationRoleDTOFromJSON(json['userRoleInOrganization']),
        'userRoleInProject': ProjectRoleDTOFromJSON(json['userRoleInProject']),
        'backgroundUrl': json['backgroundUrl'] == null ? undefined : json['backgroundUrl'],
    };
}

export function ProjectWithRoleDTOToJSON(value?: ProjectWithRoleDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'codeAccess': ProjectCodeAccessDTOToJSON(value['codeAccess']),
        'avatarUrl': value['avatarUrl'],
        'organizationType': OrganizationTypeDTOToJSON(value['organizationType']),
        'featured': value['featured'],
        'organizationName': value['organizationName'],
        'avatarSource': AvatarSourceEnumToJSON(value['avatarSource']),
        'version': value['version'],
        'id': value['id'],
        'projectKey': value['projectKey'],
        'organizationId': value['organizationId'],
        'userCount': value['userCount'],
        'visibility': ProjectVisibilityDTOToJSON(value['visibility']),
        'displayClass': value['displayClass'],
        'name': value['name'],
        'lastActivity': ((value['lastActivity']).toISOString()),
        'description': value['description'],
        'archived': value['archived'],
        'timeOfCreation': ((value['timeOfCreation']).toISOString()),
        'userRoleInOrganization': OrganizationRoleDTOToJSON(value['userRoleInOrganization']),
        'userRoleInProject': ProjectRoleDTOToJSON(value['userRoleInProject']),
        'backgroundUrl': value['backgroundUrl'],
    };
}

