/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceAccountDTO
 */
export interface ServiceAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceAccountDTO
     */
    keyGeneratedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    keyGeneratedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceAccountDTO
     */
    deactivated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    organizationId: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceAccountDTO
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    deactivatedBy?: string;
}

/**
 * Check if a given object implements the ServiceAccountDTO interface.
 */
export function instanceOfServiceAccountDTO(value: object): value is ServiceAccountDTO {
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    return true;
}

export function ServiceAccountDTOFromJSON(json: any): ServiceAccountDTO {
    return ServiceAccountDTOFromJSONTyped(json, false);
}

export function ServiceAccountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceAccountDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'name': json['name'],
        'keyGeneratedAt': json['keyGeneratedAt'] == null ? undefined : (new Date(json['keyGeneratedAt'])),
        'keyGeneratedBy': json['keyGeneratedBy'] == null ? undefined : json['keyGeneratedBy'],
        'deactivated': json['deactivated'] == null ? undefined : (new Date(json['deactivated'])),
        'id': json['id'],
        'organizationId': json['organizationId'],
        'created': (new Date(json['created'])),
        'createdBy': json['createdBy'],
        'deactivatedBy': json['deactivatedBy'] == null ? undefined : json['deactivatedBy'],
    };
}

export function ServiceAccountDTOToJSON(value?: ServiceAccountDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'displayName': value['displayName'],
        'name': value['name'],
        'keyGeneratedAt': value['keyGeneratedAt'] == null ? undefined : ((value['keyGeneratedAt']).toISOString()),
        'keyGeneratedBy': value['keyGeneratedBy'],
        'deactivated': value['deactivated'] == null ? undefined : ((value['deactivated']).toISOString()),
        'id': value['id'],
        'organizationId': value['organizationId'],
        'created': ((value['created']).toISOString()),
        'createdBy': value['createdBy'],
        'deactivatedBy': value['deactivatedBy'],
    };
}

