import React from 'react';
import { DebounceInput } from 'react-debounce-input';

import { bemBlock, Layout, SimpleTooltip } from '@neptune/shared/venus-ui';

import './RangeInput.less';

type RangeInputProps = {
  error?: boolean;
  maxValue?: number;
  minValue?: number;
  className?: string;
  debounceTimeout?: number;
  disabled?: boolean;
  disabledText?: string;
  onMaxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMinChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const block = bemBlock('range-input');

export const RangeInput: React.FC<RangeInputProps> = ({
  className,
  error,
  onMaxChange,
  onMinChange,
  maxValue,
  minValue,
  debounceTimeout = 300,
  disabled,
  disabledText,
}) => {
  return (
    <Layout.Column className={block()} span="shrink">
      <SimpleTooltip content={disabled ? disabledText : null}>
        <Layout.Row
          data-role="range-input"
          className={className}
          spacedChildren="sm"
          alignItems="center"
        >
          <DebounceInput
            className={block({
              element: 'input',
              modifiers: { error, 'hide-arrows': true, disabled },
            })}
            aria-label="Min value"
            data-role="min-value"
            placeholder="Min"
            value={minValue ?? ''}
            onChange={onMinChange}
            data-is-invalid={error}
            debounceTimeout={debounceTimeout}
            type="number"
            disabled={disabled}
          />
          <Layout.Element className={block({ element: 'separator', modifiers: { disabled } })}>
            &#8208;
          </Layout.Element>
          <DebounceInput
            className={block({
              element: 'input',
              modifiers: { error, 'hide-arrows': true, disabled },
            })}
            aria-label="Max value"
            data-role="max-value"
            placeholder="Max"
            value={maxValue ?? ''}
            onChange={onMaxChange}
            data-is-invalid={error}
            debounceTimeout={debounceTimeout}
            type="number"
            disabled={disabled}
          />
        </Layout.Row>
      </SimpleTooltip>
    </Layout.Column>
  );
};
