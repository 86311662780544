/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NextPageDTO
 */
export interface NextPageDTO {
    /**
     * Results limit, can be null on request (default applied)
     * @type {number}
     * @memberof NextPageDTO
     */
    limit?: number;
    /**
     * Next page token, can be null (first requested / last returned page)
     * @type {string}
     * @memberof NextPageDTO
     */
    nextPageToken?: string;
}

/**
 * Check if a given object implements the NextPageDTO interface.
 */
export function instanceOfNextPageDTO(value: object): value is NextPageDTO {
    return true;
}

export function NextPageDTOFromJSON(json: any): NextPageDTO {
    return NextPageDTOFromJSONTyped(json, false);
}

export function NextPageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextPageDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'] == null ? undefined : json['limit'],
        'nextPageToken': json['nextPageToken'] == null ? undefined : json['nextPageToken'],
    };
}

export function NextPageDTOToJSON(value?: NextPageDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'nextPageToken': value['nextPageToken'],
    };
}

