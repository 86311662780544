/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsertStringsOperation
 */
export interface InsertStringsOperation {
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertStringsOperation
     */
    values: Array<string>;
}

/**
 * Check if a given object implements the InsertStringsOperation interface.
 */
export function instanceOfInsertStringsOperation(value: object): value is InsertStringsOperation {
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function InsertStringsOperationFromJSON(json: any): InsertStringsOperation {
    return InsertStringsOperationFromJSONTyped(json, false);
}

export function InsertStringsOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsertStringsOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'values': json['values'],
    };
}

export function InsertStringsOperationToJSON(value?: InsertStringsOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'values': value['values'],
    };
}

