// Libs
import React from 'react';

// App
import { bemBlock } from '../../modules/bem';
import { Button, ButtonProps } from '../button/Button';
import { GlyphName, Icon, IconProps } from '../icon/Icon';

// Module
import './ToggleButton.less';

const block = bemBlock('n-toggle-button');

type ToggleButtonProps = Omit<ButtonProps<{}>, 'variant'> & {
  active?: boolean;
  glyph: GlyphName;
  glyphSize?: IconProps['size'];
  variant?: 'default' | 'subdued' | 'favorites';
};

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  className,
  glyph,
  active,
  variant = 'default',
  glyphSize,
  ...restProps
}) => {
  return (
    <Button
      className={block({
        modifiers: {
          active: !!active,
          variant,
        },
        extra: [className],
      })}
      size="lg"
      children={<Icon size={glyphSize} glyph={glyph} />}
      square
      {...restProps}
    />
  );
};
