/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Image } from './Image';
import {
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';

/**
 * 
 * @export
 * @interface LogImageEntry
 */
export interface LogImageEntry {
    /**
     * 
     * @type {Image}
     * @memberof LogImageEntry
     */
    value?: Image;
    /**
     * 
     * @type {number}
     * @memberof LogImageEntry
     */
    step?: number;
    /**
     * 
     * @type {number}
     * @memberof LogImageEntry
     */
    timestampMilliseconds: number;
}

/**
 * Check if a given object implements the LogImageEntry interface.
 */
export function instanceOfLogImageEntry(value: object): value is LogImageEntry {
    if (!('timestampMilliseconds' in value) || value['timestampMilliseconds'] === undefined) return false;
    return true;
}

export function LogImageEntryFromJSON(json: any): LogImageEntry {
    return LogImageEntryFromJSONTyped(json, false);
}

export function LogImageEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogImageEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : ImageFromJSON(json['value']),
        'step': json['step'] == null ? undefined : json['step'],
        'timestampMilliseconds': json['timestampMilliseconds'],
    };
}

export function LogImageEntryToJSON(value?: LogImageEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': ImageToJSON(value['value']),
        'step': value['step'],
        'timestampMilliseconds': value['timestampMilliseconds'],
    };
}

