// Libs
import React, { ReactNode } from 'react';

import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

import { bemBlock } from '../../modules/bem';
import * as layout from '../../modules/layout';
import * as uiState from '../../modules/ui-state';
import { Icon, IconProps } from '../icon/Icon';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';
import { Text } from '../text/Text';
// Module
import { UITooltip, useTooltip } from '../tooltip';

import './DropdownItem.less';

const block = bemBlock('n-DropdownItem');

export type IconDefinition = GlyphName | IconProps;
export type DropdownItemProps<P extends object> = Pick<
  LayoutRowProps<{}>,
  | 'alignItems'
  | 'alignSelf'
  | 'className'
  | 'component'
  | 'justifyContent'
  | 'onClick'
  | 'reversed'
  | 'selfSpacing'
  | 'spacedChildren'
  | 'span'
  | 'title'
  | 'width'
  | 'withGutter'
  | 'withPadding'
> &
  P & {
    children?: ReactNode;
    disabled?: boolean;
    tooltip?: string;
    icon?: IconDefinition | ReactNode;
    label?: ReactNode;
    description?: ReactNode;
    selected?: boolean;
    activeVariant?: 'primary' | 'secondary';
    active?: boolean;
    size?: 'md' | 'lg' | 'xl';
    'data-role'?: string;
    submenu?: boolean;
    radius?: 'xs' | 'sm' | 'md';
  };

export const DropdownItem = <P extends object>(props: DropdownItemProps<P>) => {
  const {
    activeVariant = 'primary',
    alignItems = 'center',
    size = 'md',
    spacedChildren = 'sm',
    span = 'auto',
    withGutter = 'sm',
    withPadding = props.description && props.label ? 'lg' : undefined,
    radius = 'xs',
    active,
    children,
    className,
    disabled,
    icon,
    label,
    description,
    selected,
    submenu,
    tooltip,
    ...passProps
  } = props;

  const tooltipProps = useTooltip({
    placement: 'top-center',
  });

  const clickable = !disabled;

  const ownProps: DropdownItemProps<{}> = {
    className: block({
      modifiers: {
        size,
        active: active && activeVariant,
        radius,
      },
      extra: [
        className,
        uiState.clickableClassName(clickable),
        uiState.disabledClassName(disabled),
      ],
    }),
  };

  const renderIcon = () => {
    if (!icon) {
      return null;
    }

    if (typeof icon === 'string') {
      return <Icon className={block('icon')} glyph={icon as GlyphName} fixedWidth />;
    }

    if ((icon as IconProps).glyph) {
      return <Icon className={block('icon')} {...(icon as IconProps)} fixedWidth />;
    }

    return icon;
  };

  const renderLabel = () => {
    if (label) {
      const title = typeof label === 'string' ? label : undefined;
      return (
        <Text className={layout.spanClassName('greedy')} ellipsis title={title} children={label} />
      );
    }
  };

  const renderDescription = () => {
    if (!description) {
      return null;
    }

    return (
      <Text
        size="xs"
        color="text-subdued"
        fontWeight="normal"
        className={layout.spanClassName('greedy')}
        ellipsis
        children={description}
      />
    );
  };

  const renderStateIcon = () => {
    if (submenu) {
      return <Icon glyph="chevron-right" size="xs" color="text-subdued" />;
    }

    if (selected) {
      return <Icon className={block('checkmark')} glyph="checkmark" fixedWidth />;
    }

    return null;
  };

  return (
    <span {...(tooltip ? tooltipProps.triggerProps : {})}>
      <LayoutRow
        data-role="menu-item"
        data-selected={selected ? 'true' : 'false'}
        data-active={active ? 'true' : 'false'}
        alignItems={alignItems}
        spacedChildren={spacedChildren}
        span={span}
        withGutter={withGutter}
        withPadding={withPadding}
        {...ownProps}
        {...passProps}
      >
        {renderIcon()}
        {(props.label || props.description) && (
          <LayoutColumn span="greedy" overflow="hidden">
            {renderLabel()}
            {renderDescription()}
          </LayoutColumn>
        )}
        {children}
        {renderStateIcon()}
        {tooltip &&
          tooltipProps.isOpen &&
          tooltipProps.renderLayer(
            <UITooltip className={block('tooltip')} {...tooltipProps.layerProps}>
              {tooltip}
            </UITooltip>,
          )}
      </LayoutRow>
    </span>
  );
};
