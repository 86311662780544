/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectInvitationDTO } from './ProjectInvitationDTO';
import {
    ProjectInvitationDTOFromJSON,
    ProjectInvitationDTOFromJSONTyped,
    ProjectInvitationDTOToJSON,
} from './ProjectInvitationDTO';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';
import type { RegisteredMemberInfoDTO } from './RegisteredMemberInfoDTO';
import {
    RegisteredMemberInfoDTOFromJSON,
    RegisteredMemberInfoDTOFromJSONTyped,
    RegisteredMemberInfoDTOToJSON,
} from './RegisteredMemberInfoDTO';
import type { OrganizationInvitationDTO } from './OrganizationInvitationDTO';
import {
    OrganizationInvitationDTOFromJSON,
    OrganizationInvitationDTOFromJSONTyped,
    OrganizationInvitationDTOToJSON,
} from './OrganizationInvitationDTO';

/**
 * 
 * @export
 * @interface ProjectMemberDTO
 */
export interface ProjectMemberDTO {
    /**
     * 
     * @type {OrganizationInvitationDTO}
     * @memberof ProjectMemberDTO
     */
    organizationInvitationInfo?: OrganizationInvitationDTO;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ProjectMemberDTO
     */
    role: ProjectRoleDTO;
    /**
     * 
     * @type {RegisteredMemberInfoDTO}
     * @memberof ProjectMemberDTO
     */
    registeredMemberInfo?: RegisteredMemberInfoDTO;
    /**
     * 
     * @type {ProjectInvitationDTO}
     * @memberof ProjectMemberDTO
     */
    invitationInfo?: ProjectInvitationDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectMemberDTO
     */
    editableRole: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectMemberDTO
     */
    canLeaveProject: boolean;
}



/**
 * Check if a given object implements the ProjectMemberDTO interface.
 */
export function instanceOfProjectMemberDTO(value: object): value is ProjectMemberDTO {
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('editableRole' in value) || value['editableRole'] === undefined) return false;
    if (!('canLeaveProject' in value) || value['canLeaveProject'] === undefined) return false;
    return true;
}

export function ProjectMemberDTOFromJSON(json: any): ProjectMemberDTO {
    return ProjectMemberDTOFromJSONTyped(json, false);
}

export function ProjectMemberDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectMemberDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'organizationInvitationInfo': json['organizationInvitationInfo'] == null ? undefined : OrganizationInvitationDTOFromJSON(json['organizationInvitationInfo']),
        'role': ProjectRoleDTOFromJSON(json['role']),
        'registeredMemberInfo': json['registeredMemberInfo'] == null ? undefined : RegisteredMemberInfoDTOFromJSON(json['registeredMemberInfo']),
        'invitationInfo': json['invitationInfo'] == null ? undefined : ProjectInvitationDTOFromJSON(json['invitationInfo']),
        'editableRole': json['editableRole'],
        'canLeaveProject': json['canLeaveProject'],
    };
}

export function ProjectMemberDTOToJSON(value?: ProjectMemberDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'organizationInvitationInfo': OrganizationInvitationDTOToJSON(value['organizationInvitationInfo']),
        'role': ProjectRoleDTOToJSON(value['role']),
        'registeredMemberInfo': RegisteredMemberInfoDTOToJSON(value['registeredMemberInfo']),
        'invitationInfo': ProjectInvitationDTOToJSON(value['invitationInfo']),
        'editableRole': value['editableRole'],
        'canLeaveProject': value['canLeaveProject'],
    };
}

