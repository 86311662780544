/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntercomEntryDTO
 */
export interface IntercomEntryDTO {
    /**
     * 
     * @type {string}
     * @memberof IntercomEntryDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof IntercomEntryDTO
     */
    value: string;
}

/**
 * Check if a given object implements the IntercomEntryDTO interface.
 */
export function instanceOfIntercomEntryDTO(value: object): value is IntercomEntryDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function IntercomEntryDTOFromJSON(json: any): IntercomEntryDTO {
    return IntercomEntryDTOFromJSONTyped(json, false);
}

export function IntercomEntryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntercomEntryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
    };
}

export function IntercomEntryDTOToJSON(value?: IntercomEntryDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'value': value['value'],
    };
}

