import React from 'react';

import { bemBlock } from '@neptune/shared/venus-ui';

import { CopyToClipboard } from '../copy-to-clipboard/CopyToClipboard';

import './CommandFrame.less';

const block = bemBlock('command-frame');

export const CommandFrame: React.FC<{
  command: string;
  expectedLog?: string;
  className?: string;
  prefix?: string;
  theme?: 'dark' | 'light' | 'none';
  oneliner?: boolean;
}> = ({ command, expectedLog, className, prefix = '$ ', theme = 'dark', oneliner }) => {
  const commandText = `${prefix}${command}`;

  const commandElement = command ? (
    <div className={block({ element: 'command', modifiers: { oneliner } })}>
      {commandText}
      <CopyToClipboard
        className={block({ element: 'copy', modifiers: { oneliner } })}
        text={command}
      />
    </div>
  ) : null;

  const expectedLogElement = expectedLog ? (
    <div className={block('expected-log')}>{expectedLog}</div>
  ) : null;

  return (
    <div className={block({ modifiers: { [theme]: true }, extra: className })}>
      {commandElement}
      {expectedLogElement}
    </div>
  );
};
