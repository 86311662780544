/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditInfo
 */
export interface AuditInfo {
    /**
     * 
     * @type {Date}
     * @memberof AuditInfo
     */
    accustomedExperimentCreatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AuditInfo
     */
    firstExperimentCreatedAt?: Date;
}

/**
 * Check if a given object implements the AuditInfo interface.
 */
export function instanceOfAuditInfo(value: object): value is AuditInfo {
    return true;
}

export function AuditInfoFromJSON(json: any): AuditInfo {
    return AuditInfoFromJSONTyped(json, false);
}

export function AuditInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'accustomedExperimentCreatedAt': json['accustomedExperimentCreatedAt'] == null ? undefined : (new Date(json['accustomedExperimentCreatedAt'])),
        'firstExperimentCreatedAt': json['firstExperimentCreatedAt'] == null ? undefined : (new Date(json['firstExperimentCreatedAt'])),
    };
}

export function AuditInfoToJSON(value?: AuditInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accustomedExperimentCreatedAt': value['accustomedExperimentCreatedAt'] == null ? undefined : ((value['accustomedExperimentCreatedAt']).toISOString()),
        'firstExperimentCreatedAt': value['firstExperimentCreatedAt'] == null ? undefined : ((value['firstExperimentCreatedAt']).toISOString()),
    };
}

