/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface DatetimeAttributeDTO
 */
export interface DatetimeAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof DatetimeAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof DatetimeAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {Date}
     * @memberof DatetimeAttributeDTO
     */
    value: Date;
}



/**
 * Check if a given object implements the DatetimeAttributeDTO interface.
 */
export function instanceOfDatetimeAttributeDTO(value: object): value is DatetimeAttributeDTO {
    if (!('attributeName' in value) || value['attributeName'] === undefined) return false;
    if (!('attributeType' in value) || value['attributeType'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function DatetimeAttributeDTOFromJSON(json: any): DatetimeAttributeDTO {
    return DatetimeAttributeDTOFromJSONTyped(json, false);
}

export function DatetimeAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatetimeAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'value': (new Date(json['value'])),
    };
}

export function DatetimeAttributeDTOToJSON(value?: DatetimeAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeName': value['attributeName'],
        'attributeType': AttributeTypeDTOToJSON(value['attributeType']),
        'value': ((value['value']).toISOString()),
    };
}

