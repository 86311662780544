import React from 'react';
import { FocusScope } from '@radix-ui/react-focus-scope';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import { uniqueId } from 'lodash';

import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';
import { LayoutRow } from '../layout-row/LayoutRow';
import { Popover } from '../popover/Popover';
import { ScrollArea } from '../scroll-area/ScrollArea';
import { Text } from '../text/Text';

import './ComboBox.less';

const block = bemBlock('combo-box');

type MenuContextType = {
  highlightedItemId: string | undefined;
  registeredItemIds: string[];
  registeredItemCallbacksById: Record<string, () => void>;
  registerItem: (itemId: string, selectFn: () => void) => () => void;
  highlightItem: (itemId: string | undefined) => void;
  menuId: string;
  isOpen: boolean;
  close: () => void;
};

const MenuContext = React.createContext<MenuContextType | null>(null);

const useComboBoxContext = () => {
  const context = React.useContext(MenuContext);

  if (!context) {
    throw new Error('Attempting to use ComboBox.Item outside ComboBox.Root');
  }

  return context;
};

type RootProps = {
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?(open: boolean): void;
};

const Root = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & RootProps
>(({ children, className, open: openProp, defaultOpen, onOpenChange, onKeyDown, ...rest }, ref) => {
  const [highlightedItemId, setHighlightedItemId] = React.useState<string>();
  const [registeredItemIds, setRegisteredItemIds] = React.useState<string[]>([]);
  const [registeredItemCallbacksById, setRegisteredItemCallbacksById] = React.useState<
    Record<string, () => void>
  >({});

  const menuId = React.useMemo(() => uniqueId('combo-box-'), []);

  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });

  const close = React.useCallback(() => {
    setOpen(false);
    setHighlightedItemId(undefined);
  }, [setOpen]);

  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      if (!open) {
        setHighlightedItemId(undefined);
      }

      setOpen(open);
    },
    [setOpen],
  );

  const registerItem = React.useCallback((itemId: string, selectFn: () => void) => {
    setRegisteredItemIds((ids) => [...ids, itemId]);
    setRegisteredItemCallbacksById((callbacksById) => ({ ...callbacksById, [itemId]: selectFn }));

    return () => {
      setRegisteredItemIds((ids) => ids.filter((id) => id !== itemId));
      setRegisteredItemCallbacksById(({ [itemId]: _, ...callbacksById }) => ({
        ...callbacksById,
      }));
    };
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = React.useCallback(
    (event) => {
      onKeyDown?.(event);

      if (event.defaultPrevented) {
        return;
      }

      const totalItems = registeredItemIds.length;
      const selectedItemIndex = registeredItemIds.findIndex((id) => id === highlightedItemId);

      if (event.key === 'ArrowUp') {
        if (selectedItemIndex <= 0) {
          setHighlightedItemId?.(registeredItemIds[totalItems - 1]);
          return;
        }

        setHighlightedItemId?.(registeredItemIds[selectedItemIndex - 1]);
      }

      if (event.key === 'ArrowDown') {
        if (selectedItemIndex === totalItems - 1) {
          setHighlightedItemId?.(registeredItemIds[0]);
          return;
        }

        setHighlightedItemId?.(registeredItemIds[selectedItemIndex + 1]);
      }

      const isItemHighlighted = highlightedItemId !== undefined;

      if (event.key === 'Enter' && isItemHighlighted) {
        registeredItemCallbacksById[highlightedItemId]?.();
        close();
      }
    },
    [onKeyDown, registeredItemIds, highlightedItemId, registeredItemCallbacksById, close],
  );

  return (
    <MenuContext.Provider
      value={{
        registeredItemIds,
        registeredItemCallbacksById,
        registerItem,
        highlightedItemId,
        highlightItem: setHighlightedItemId,
        menuId,
        isOpen: open,
        close,
      }}
    >
      <Popover.Root open={open} onOpenChange={handleOpenChange}>
        <div
          ref={ref}
          onKeyDown={handleKeyDown}
          role="combobox"
          aria-activedescendant={highlightedItemId}
          className={block({ extra: className })}
          {...rest}
        >
          {children}
        </div>
      </Popover.Root>
    </MenuContext.Provider>
  );
});

const Trigger = React.forwardRef<
  React.ElementRef<typeof Popover.Trigger>,
  React.ComponentPropsWithoutRef<typeof Popover.Trigger>
>((props, ref) => {
  const { isOpen, menuId } = useComboBoxContext();

  return (
    <Popover.Trigger
      ref={ref}
      aria-expanded={isOpen ? 'true' : undefined}
      aria-controls={menuId}
      {...props}
    />
  );
});

type MenuItemGroupProps = React.PropsWithChildren<{
  heading: string;
  className?: string;
}>;

const ItemGroup = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & MenuItemGroupProps
>(({ children, heading, className, ...rest }, ref) => {
  return (
    <div ref={ref} className={block({ element: 'item-group', extra: className })} {...rest}>
      <div className={block('group-heading')}>
        <Text size="xs" className={block('group-heading-text')}>
          {heading}
        </Text>
      </div>
      <ScrollArea>
        <ScrollArea.Viewport style={{ maxHeight: 300 }}>{children}</ScrollArea.Viewport>
        <ScrollArea.Scrollbar orientation="vertical">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
        <ScrollArea.Scrollbar orientation="horizontal">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner />
      </ScrollArea>
    </div>
  );
});

const Content = React.forwardRef<
  React.ElementRef<typeof Popover.Content>,
  React.ComponentPropsWithoutRef<typeof Popover.Content>
>(({ className, align = 'start', ...props }, ref) => (
  <Popover.Content
    ref={ref}
    align={align}
    className={block({ extra: className })}
    role="menu"
    {...props}
  />
));

const SearchInput = React.forwardRef<
  React.ElementRef<'input'>,
  React.ComponentPropsWithoutRef<'input'>
>(({ className, ...props }, ref) => (
  <Popover.Header>
    <FocusScope asChild loop trapped>
      <div className={block('search-input-wrapper')}>
        <Icon glyph="search" className={block('search-input-icon')} />
        <input
          ref={ref}
          className={block({ element: 'search-input-input', extra: className })}
          type="text"
          {...props}
        />
      </div>
    </FocusScope>
  </Popover.Header>
));

type MenuItemProps = React.PropsWithChildren<{
  leadingIcon?: React.ReactNode;
  onSelect?: () => void;
}>;

function Item({
  leadingIcon,
  children,
  onMouseEnter,
  onMouseLeave,
  onSelect,
  ...rest
}: React.ComponentPropsWithoutRef<'div'> & MenuItemProps) {
  const itemRef = React.useRef<HTMLDivElement>(null);
  const { registerItem, highlightedItemId, highlightItem, close } = useComboBoxContext();

  const id = React.useMemo(() => uniqueId('controlled-menu-item-'), []);

  const isSelected = highlightedItemId === id;

  React.useLayoutEffect(() => {
    if (!itemRef.current || !isSelected) {
      return;
    }

    if (itemRef.current.matches(':hover')) {
      return;
    }

    itemRef.current.scrollIntoView();
  }, [isSelected]);

  React.useEffect(() => {
    return registerItem(id, () => onSelect?.());
  }, [id, onSelect, registerItem]);

  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    (event) => {
      onMouseEnter?.(event);

      if (event.defaultPrevented) {
        return;
      }

      highlightItem(id);
    },
    [onMouseEnter, highlightItem, id],
  );

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    (event) => {
      onMouseLeave?.(event);

      if (event.defaultPrevented) {
        return;
      }

      highlightItem(undefined);
    },
    [highlightItem, onMouseLeave],
  );

  const handleClick: React.MouseEventHandler = React.useCallback(
    (e) => {
      e.stopPropagation();

      onSelect?.();
      close();
    },
    [close, onSelect],
  );

  return (
    <div
      ref={itemRef}
      role="menuitem"
      id={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      className={block({ element: 'item' })}
      data-highlighted={isSelected ? 'true' : undefined}
      {...rest}
    >
      <LayoutRow span="auto" alignItems="center" spacedChildren="sm">
        {leadingIcon}
        <Text size="sm">{children}</Text>
      </LayoutRow>
    </div>
  );
}

export const Separator = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={block({ element: 'separator', extra: className })} {...props} />
));

export const ComboBox = {
  Root,
  Trigger,
  Portal: Popover.Portal,
  Content,
  SearchInput,
  ItemGroup,
  Item,
  Separator,
};
