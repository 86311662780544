/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NqlQueryParamsDTO
 */
export interface NqlQueryParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof NqlQueryParamsDTO
     */
    query: string;
}

/**
 * Check if a given object implements the NqlQueryParamsDTO interface.
 */
export function instanceOfNqlQueryParamsDTO(value: object): value is NqlQueryParamsDTO {
    if (!('query' in value) || value['query'] === undefined) return false;
    return true;
}

export function NqlQueryParamsDTOFromJSON(json: any): NqlQueryParamsDTO {
    return NqlQueryParamsDTOFromJSONTyped(json, false);
}

export function NqlQueryParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NqlQueryParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
    };
}

export function NqlQueryParamsDTOToJSON(value?: NqlQueryParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
    };
}

