import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { bemBlock } from '../../modules/bem';
import { Text } from '../text/Text';

import './InputSelectLabel.less';

const block = bemBlock('input-select-label');

export const Label = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} className={block({ extra: className })} {...props}>
    <Text className={block('text')} size="xs">
      {children}
    </Text>
  </SelectPrimitive.Label>
));
