/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LoginActionDTO = {
    SetUsername: 'SET_USERNAME',
    Survey: 'SURVEY'
} as const;
export type LoginActionDTO = typeof LoginActionDTO[keyof typeof LoginActionDTO];


export function instanceOfLoginActionDTO(value: any): boolean {
    for (const key in LoginActionDTO) {
        if (Object.prototype.hasOwnProperty.call(LoginActionDTO, key)) {
            if (LoginActionDTO[key as keyof typeof LoginActionDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LoginActionDTOFromJSON(json: any): LoginActionDTO {
    return LoginActionDTOFromJSONTyped(json, false);
}

export function LoginActionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginActionDTO {
    return json as LoginActionDTO;
}

export function LoginActionDTOToJSON(value?: LoginActionDTO | null): any {
    return value as any;
}

