// Libs
import React from 'react';

import { Text } from '@neptune/shared/venus-ui';

// App

// Module
export const KeycloakLabel: React.FC<React.ComponentProps<typeof Text>> = (props) => {
  return <Text fontWeight="semibold" size="sm" color="text" {...props} />;
};
