/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignBoolOperation
 */
export interface AssignBoolOperation {
    /**
     * 
     * @type {boolean}
     * @memberof AssignBoolOperation
     */
    value: boolean;
}

/**
 * Check if a given object implements the AssignBoolOperation interface.
 */
export function instanceOfAssignBoolOperation(value: object): value is AssignBoolOperation {
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AssignBoolOperationFromJSON(json: any): AssignBoolOperation {
    return AssignBoolOperationFromJSONTyped(json, false);
}

export function AssignBoolOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignBoolOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function AssignBoolOperationToJSON(value?: AssignBoolOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

