import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { bemBlock } from '../../modules/bem';
import { LayoutRow } from '../layout-row/LayoutRow';
import { Text } from '../text/Text';

import './ActionsMenuGroup.less';

const block = bemBlock('n-actions-menu-group');

type GroupOwnProps = {
  trailingElement?: React.ReactNode;
  title: string;
};

type GroupProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.Group> & GroupOwnProps;

export const ActionsMenuGroup = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.Group>,
  GroupProps
>(({ className, children, title, trailingElement, ...props }, ref) => {
  return (
    <DropdownMenu.Group
      ref={ref}
      className={block({
        extra: className,
      })}
      {...props}
    >
      <LayoutRow
        span="auto"
        justifyContent="space-between"
        alignItems="center"
        className={block('group-heading')}
      >
        <Text className={block('group-heading-text')} size="xs">
          {title}
        </Text>
        {trailingElement && trailingElement}
      </LayoutRow>
      {children}
    </DropdownMenu.Group>
  );
});
