/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExperimentStateDTO = {
    Running: 'running',
    Idle: 'idle'
} as const;
export type ExperimentStateDTO = typeof ExperimentStateDTO[keyof typeof ExperimentStateDTO];


export function instanceOfExperimentStateDTO(value: any): boolean {
    for (const key in ExperimentStateDTO) {
        if (Object.prototype.hasOwnProperty.call(ExperimentStateDTO, key)) {
            if (ExperimentStateDTO[key as keyof typeof ExperimentStateDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ExperimentStateDTOFromJSON(json: any): ExperimentStateDTO {
    return ExperimentStateDTOFromJSONTyped(json, false);
}

export function ExperimentStateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentStateDTO {
    return json as ExperimentStateDTO;
}

export function ExperimentStateDTOToJSON(value?: ExperimentStateDTO | null): any {
    return value as any;
}

