/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeDefinitionDTO } from './AttributeDefinitionDTO';
import {
    AttributeDefinitionDTOFromJSON,
    AttributeDefinitionDTOFromJSONTyped,
    AttributeDefinitionDTOToJSON,
} from './AttributeDefinitionDTO';

/**
 * 
 * @export
 * @interface PrioritizedAttributeDefinitionDTO
 */
export interface PrioritizedAttributeDefinitionDTO {
    /**
     * 
     * @type {AttributeDefinitionDTO}
     * @memberof PrioritizedAttributeDefinitionDTO
     */
    attributeDefinitionDTO: AttributeDefinitionDTO;
    /**
     * 
     * @type {boolean}
     * @memberof PrioritizedAttributeDefinitionDTO
     */
    matchedPriorityQuery?: boolean;
}

/**
 * Check if a given object implements the PrioritizedAttributeDefinitionDTO interface.
 */
export function instanceOfPrioritizedAttributeDefinitionDTO(value: object): value is PrioritizedAttributeDefinitionDTO {
    if (!('attributeDefinitionDTO' in value) || value['attributeDefinitionDTO'] === undefined) return false;
    return true;
}

export function PrioritizedAttributeDefinitionDTOFromJSON(json: any): PrioritizedAttributeDefinitionDTO {
    return PrioritizedAttributeDefinitionDTOFromJSONTyped(json, false);
}

export function PrioritizedAttributeDefinitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrioritizedAttributeDefinitionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeDefinitionDTO': AttributeDefinitionDTOFromJSON(json['attributeDefinitionDTO']),
        'matchedPriorityQuery': json['matchedPriorityQuery'] == null ? undefined : json['matchedPriorityQuery'],
    };
}

export function PrioritizedAttributeDefinitionDTOToJSON(value?: PrioritizedAttributeDefinitionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeDefinitionDTO': AttributeDefinitionDTOToJSON(value['attributeDefinitionDTO']),
        'matchedPriorityQuery': value['matchedPriorityQuery'],
    };
}

