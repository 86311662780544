import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { bemBlock } from '../../modules/bem';

import './SliderThumb.less';

const block = bemBlock('n-slider-thumb');

export const Thumb = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Thumb>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Thumb>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Thumb ref={ref} className={block({ extra: className })} {...props} />
));
