/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GraduatedPricingDTO } from './GraduatedPricingDTO';
import {
    GraduatedPricingDTOFromJSON,
    GraduatedPricingDTOFromJSONTyped,
    GraduatedPricingDTOToJSON,
} from './GraduatedPricingDTO';

/**
 * 
 * @export
 * @interface PaidSubscriptionPricingDTO
 */
export interface PaidSubscriptionPricingDTO {
    /**
     * 
     * @type {number}
     * @memberof PaidSubscriptionPricingDTO
     */
    flatPriceInCents: number;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof PaidSubscriptionPricingDTO
     */
    storagePricing: GraduatedPricingDTO;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof PaidSubscriptionPricingDTO
     */
    monitoringTimePricing: GraduatedPricingDTO;
}

/**
 * Check if a given object implements the PaidSubscriptionPricingDTO interface.
 */
export function instanceOfPaidSubscriptionPricingDTO(value: object): value is PaidSubscriptionPricingDTO {
    if (!('flatPriceInCents' in value) || value['flatPriceInCents'] === undefined) return false;
    if (!('storagePricing' in value) || value['storagePricing'] === undefined) return false;
    if (!('monitoringTimePricing' in value) || value['monitoringTimePricing'] === undefined) return false;
    return true;
}

export function PaidSubscriptionPricingDTOFromJSON(json: any): PaidSubscriptionPricingDTO {
    return PaidSubscriptionPricingDTOFromJSONTyped(json, false);
}

export function PaidSubscriptionPricingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaidSubscriptionPricingDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'flatPriceInCents': json['flatPriceInCents'],
        'storagePricing': GraduatedPricingDTOFromJSON(json['storagePricing']),
        'monitoringTimePricing': GraduatedPricingDTOFromJSON(json['monitoringTimePricing']),
    };
}

export function PaidSubscriptionPricingDTOToJSON(value?: PaidSubscriptionPricingDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'flatPriceInCents': value['flatPriceInCents'],
        'storagePricing': GraduatedPricingDTOToJSON(value['storagePricing']),
        'monitoringTimePricing': GraduatedPricingDTOToJSON(value['monitoringTimePricing']),
    };
}

