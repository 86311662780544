import React from 'react';
import { isString } from 'lodash';

import { bemBlock, Layout } from '@neptune/shared/venus-ui';

import { Merge } from 'common/utility-types';

import { ErrorText } from './ErrorText';
import { Input, InputProps } from './Input';

const block = bemBlock('input-with-error');

type InputWithErrorProps = Merge<
  InputProps,
  {
    inputClassName?: string;
    error?: string;
  }
>;

export const InputWithError: React.FC<InputWithErrorProps> = ({
  error,
  className,
  onFocus,
  onBlur,
  inputClassName,
  ...rest
}) => {
  const [focus, setFocus] = React.useState(false);
  const handleFocus = React.useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocus(true);
      onFocus?.(ev);
    },
    [onFocus],
  );
  const handleBlur = React.useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocus(false);
      onBlur?.(ev);
    },
    [onBlur],
  );

  const hasError = isString(error) && error.length > 0;

  const modifiers = {
    error: hasError,
    focus,
  };

  return (
    <Layout.Column
      className={block({ modifiers, extra: className })}
      span="auto"
      spacedChildren="sm"
    >
      <Layout.Element>
        <Input
          error={hasError}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={inputClassName}
          {...rest}
        />
      </Layout.Element>
      <ErrorText active={!focus}>{error}</ErrorText>
    </Layout.Column>
  );
};
