/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GitCommitDTO
 */
export interface GitCommitDTO {
    /**
     * 
     * @type {string}
     * @memberof GitCommitDTO
     */
    authorEmail: string;
    /**
     * 
     * @type {string}
     * @memberof GitCommitDTO
     */
    authorName: string;
    /**
     * 
     * @type {Date}
     * @memberof GitCommitDTO
     */
    commitDate: Date;
    /**
     * 
     * @type {string}
     * @memberof GitCommitDTO
     */
    commitId: string;
    /**
     * 
     * @type {string}
     * @memberof GitCommitDTO
     */
    message: string;
}

/**
 * Check if a given object implements the GitCommitDTO interface.
 */
export function instanceOfGitCommitDTO(value: object): value is GitCommitDTO {
    if (!('authorEmail' in value) || value['authorEmail'] === undefined) return false;
    if (!('authorName' in value) || value['authorName'] === undefined) return false;
    if (!('commitDate' in value) || value['commitDate'] === undefined) return false;
    if (!('commitId' in value) || value['commitId'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function GitCommitDTOFromJSON(json: any): GitCommitDTO {
    return GitCommitDTOFromJSONTyped(json, false);
}

export function GitCommitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GitCommitDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'authorEmail': json['authorEmail'],
        'authorName': json['authorName'],
        'commitDate': (new Date(json['commitDate'])),
        'commitId': json['commitId'],
        'message': json['message'],
    };
}

export function GitCommitDTOToJSON(value?: GitCommitDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'authorEmail': value['authorEmail'],
        'authorName': value['authorName'],
        'commitDate': ((value['commitDate']).toISOString()),
        'commitId': value['commitId'],
        'message': value['message'],
    };
}

