import React, { type ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { noop } from 'lodash';

import { bemBlock } from '../../modules/bem';
import * as decorator from '../../modules/decorator';
import { Padding } from '../../modules/layout';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutRow } from '../layout-row/LayoutRow';

import { HorizontalTabsContext, type HorizontalTabsDragConfig } from './HorizontalTabsContext';
import { HorizontalTabsItem } from './HorizontalTabsItem';

import './HorizontalTabs.less';

const block = bemBlock('n-horizontal-tabs');

type HorizontalTabsProps = {
  children: React.ReactNode;
  activeTabId?: string;
  className?: string;
  onChange?: (tabName: string) => void;
  startOutlet?: React.ReactNode;
  endOutlet?: React.ReactNode;
  'data-role'?: string;
  withPadding?: Padding;
  opaque?: decorator.Opaque;
  withScrollbar?: boolean;
  dragConfig?: HorizontalTabsDragConfig;
};

const HorizontaTabsDNDWrapper: React.FC<{
  isDraggable: boolean;
  children: ReactNode;
}> = ({ isDraggable, children }) => {
  if (isDraggable) {
    return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
  }

  return <>{children}</>;
};

const HorizontalTabsRaw: React.FC<HorizontalTabsProps> = ({
  children,
  activeTabId = '',
  className,
  onChange = noop,
  startOutlet,
  endOutlet,
  withPadding = ['sm', 'sm', 'none', 'sm'],
  withScrollbar,
  opaque = 'white',
  'data-role': dataRole,
  dragConfig,
}) => {
  const tabIdsInOrder = React.useMemo(() => extractTabIds(children), [children]);

  const tabsContext = React.useMemo(
    () => ({ activeTabId, onChange, tabIdsInOrder, dragConfig }),
    [activeTabId, onChange, tabIdsInOrder, dragConfig],
  );

  return (
    <HorizontaTabsDNDWrapper isDraggable={!!dragConfig}>
      <LayoutColumn
        withPadding={withPadding}
        className={block({
          element: 'container',
          extra: className,
          modifiers: { opaque },
        })}
        data-role={dataRole}
      >
        <HorizontalTabsContext.Provider value={tabsContext}>
          <LayoutRow spacedChildren="sm" alignItems="center">
            {startOutlet}

            <LayoutRow
              className={block({
                element: 'tabs',
                modifiers: {
                  'with-scrollbar': withScrollbar,
                },
              })}
              span="shrink"
            >
              {children}
            </LayoutRow>

            {endOutlet}
          </LayoutRow>
        </HorizontalTabsContext.Provider>
      </LayoutColumn>
    </HorizontaTabsDNDWrapper>
  );
};

export const HorizontalTabs = Object.assign(HorizontalTabsRaw, {
  Item: HorizontalTabsItem,
});

function extractTabIds(children: React.ReactNode): string[] {
  let tabIds: string[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === HorizontalTabsItem) {
        tabIds.push(child.props.tabId);
      } else if (child.props.children) {
        tabIds = tabIds.concat(extractTabIds(child.props.children));
      }
    }
  });

  return tabIds;
}
