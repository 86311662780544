/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeriesViewRequestDTO
 */
export interface SeriesViewRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof SeriesViewRequestDTO
     */
    attributePath: string;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewRequestDTO
     */
    firstPointIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewRequestDTO
     */
    holderIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewRequestDTO
     */
    holderType: string;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewRequestDTO
     */
    lastPointIndex?: number;
}

/**
 * Check if a given object implements the SeriesViewRequestDTO interface.
 */
export function instanceOfSeriesViewRequestDTO(value: object): value is SeriesViewRequestDTO {
    if (!('attributePath' in value) || value['attributePath'] === undefined) return false;
    if (!('holderIdentifier' in value) || value['holderIdentifier'] === undefined) return false;
    if (!('holderType' in value) || value['holderType'] === undefined) return false;
    return true;
}

export function SeriesViewRequestDTOFromJSON(json: any): SeriesViewRequestDTO {
    return SeriesViewRequestDTOFromJSONTyped(json, false);
}

export function SeriesViewRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesViewRequestDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributePath': json['attributePath'],
        'firstPointIndex': json['firstPointIndex'] == null ? undefined : json['firstPointIndex'],
        'holderIdentifier': json['holderIdentifier'],
        'holderType': json['holderType'],
        'lastPointIndex': json['lastPointIndex'] == null ? undefined : json['lastPointIndex'],
    };
}

export function SeriesViewRequestDTOToJSON(value?: SeriesViewRequestDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributePath': value['attributePath'],
        'firstPointIndex': value['firstPointIndex'],
        'holderIdentifier': value['holderIdentifier'],
        'holderType': value['holderType'],
        'lastPointIndex': value['lastPointIndex'],
    };
}

