import { isNumber, isString } from 'lodash';

const FILE_SIZE_SUFFIX = ['TB', 'GB', 'MB', 'KB', 'B'];

// extra fileSize typings added for the sake of compatibility with old JS code - can't tell if such values are possible
export function getReadableFileSize(
  fileSize: string | number | undefined | object | null,
  decimal = false,
) {
  if (!isString(fileSize) && !isNumber(fileSize)) {
    return '';
  }

  if (isString(fileSize)) {
    fileSize = parseInt(fileSize, 10);
  }

  const multiplier = decimal ? 1000 : 1024;
  let i = FILE_SIZE_SUFFIX.length - 1;
  let showAsFloat = false;

  while (fileSize >= multiplier && i > 0) {
    fileSize /= multiplier;
    i -= 1;
    showAsFloat = true;
  }

  let result = String(fileSize);

  if (showAsFloat) {
    result = fileSize.toFixed(2);
  }

  return `${result}${FILE_SIZE_SUFFIX[i]}`;
}
