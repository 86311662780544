/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PricingPlanDTO } from './PricingPlanDTO';
import {
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './PricingPlanDTO';

/**
 * 
 * @export
 * @interface PricingPlanDefinitionDTO
 */
export interface PricingPlanDefinitionDTO {
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof PricingPlanDefinitionDTO
     */
    plan: PricingPlanDTO;
    /**
     * 
     * @type {string}
     * @memberof PricingPlanDefinitionDTO
     */
    displayName: string;
}



/**
 * Check if a given object implements the PricingPlanDefinitionDTO interface.
 */
export function instanceOfPricingPlanDefinitionDTO(value: object): value is PricingPlanDefinitionDTO {
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    return true;
}

export function PricingPlanDefinitionDTOFromJSON(json: any): PricingPlanDefinitionDTO {
    return PricingPlanDefinitionDTOFromJSONTyped(json, false);
}

export function PricingPlanDefinitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingPlanDefinitionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'plan': PricingPlanDTOFromJSON(json['plan']),
        'displayName': json['displayName'],
    };
}

export function PricingPlanDefinitionDTOToJSON(value?: PricingPlanDefinitionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'plan': PricingPlanDTOToJSON(value['plan']),
        'displayName': value['displayName'],
    };
}

