import { OverscanIndices, OverscanIndicesGetterParams } from 'react-virtualized';

/*
 * The purpose of this function is to force react-virtualized to overscan in
 * both directions and not only in the direction of scrolling.
 */
export const overscanIndicesGetter = ({
  cellCount,
  overscanCellsCount,
  startIndex,
  stopIndex,
}: OverscanIndicesGetterParams): OverscanIndices => {
  return {
    overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
    overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
  };
};
