// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';

// Module
import './ColorSwatch.less';

export type ColorSwatchProps = {
  color?: string;
  className?: string;
  isActive: boolean;
  onClick(color?: string): void;
};

const block = bemBlock('n-color-swatch');

export const ColorSwatch: React.FC<ColorSwatchProps> = ({
  className,
  color,
  isActive,
  onClick,
}) => {
  const isNamedColor = color?.[0] !== '#';

  return (
    <button
      className={block({
        extra: className,
        modifiers: {
          active: isActive,
          empty: !color,
          color: isNamedColor ? color : undefined,
        },
      })}
      style={isNamedColor || !color ? undefined : { backgroundColor: color }}
      data-role={`${color}-color-swatch`}
      onClick={() => onClick(color)}
      type="button"
    />
  );
};
