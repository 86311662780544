import { naturalStringComparator } from './naturalStringComparator';
import { getPathParts } from './path';

type NameGetter<T> = (item: T) => string;

export const getNestedNamespacesFromItems = <T>(items: T[], nameGetter: NameGetter<T>) =>
  getNamespacesFromItems(items, nameGetter, true);

export const getShallowNamespacesFromItems = <T>(items: T[], nameGetter: NameGetter<T>) =>
  getNamespacesFromItems(items, nameGetter, false);

function getNamespacesFromItems<T>(
  items: T[],
  nameGetter: NameGetter<T>,
  includeNestedNamespaces: boolean,
): string[] {
  return Array.from(
    items.reduce((result: Set<string>, item: T) => {
      const name = nameGetter(item);
      const parts = getPathParts(name);
      parts.pop();

      if (parts.length) {
        do {
          result.add(parts.join('/'));
          parts.pop();
        } while (parts.length > 0 && includeNestedNamespaces);
      }

      return result;
    }, new Set<string>()),
  ).sort(naturalStringComparator);
}
