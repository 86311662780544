/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeDurationDTO } from './TimeDurationDTO';
import {
    TimeDurationDTOFromJSON,
    TimeDurationDTOFromJSONTyped,
    TimeDurationDTOToJSON,
} from './TimeDurationDTO';

/**
 * 
 * @export
 * @interface ProjectChartFiltersDTO
 */
export interface ProjectChartFiltersDTO {
    /**
     * Filter experiments since
     * @type {Date}
     * @memberof ProjectChartFiltersDTO
     */
    from?: Date;
    /**
     * Filtering owners
     * @type {Array<string>}
     * @memberof ProjectChartFiltersDTO
     */
    owners: Array<string>;
    /**
     * Filtering states
     * @type {Array<string>}
     * @memberof ProjectChartFiltersDTO
     */
    states: Array<ProjectChartFiltersDTOStatesEnum>;
    /**
     * Filtering tags
     * @type {Array<string>}
     * @memberof ProjectChartFiltersDTO
     */
    tags: Array<string>;
    /**
     * 
     * @type {TimeDurationDTO}
     * @memberof ProjectChartFiltersDTO
     */
    timePeriod?: TimeDurationDTO;
    /**
     * Filter experiments up to
     * @type {Date}
     * @memberof ProjectChartFiltersDTO
     */
    to?: Date;
}


/**
 * @export
 */
export const ProjectChartFiltersDTOStatesEnum = {
    Running: 'running',
    Succeeded: 'succeeded',
    Aborted: 'aborted',
    Failed: 'failed'
} as const;
export type ProjectChartFiltersDTOStatesEnum = typeof ProjectChartFiltersDTOStatesEnum[keyof typeof ProjectChartFiltersDTOStatesEnum];


/**
 * Check if a given object implements the ProjectChartFiltersDTO interface.
 */
export function instanceOfProjectChartFiltersDTO(value: object): value is ProjectChartFiltersDTO {
    if (!('owners' in value) || value['owners'] === undefined) return false;
    if (!('states' in value) || value['states'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    return true;
}

export function ProjectChartFiltersDTOFromJSON(json: any): ProjectChartFiltersDTO {
    return ProjectChartFiltersDTOFromJSONTyped(json, false);
}

export function ProjectChartFiltersDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectChartFiltersDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'from': json['from'] == null ? undefined : (new Date(json['from'])),
        'owners': json['owners'],
        'states': json['states'],
        'tags': json['tags'],
        'timePeriod': json['timePeriod'] == null ? undefined : TimeDurationDTOFromJSON(json['timePeriod']),
        'to': json['to'] == null ? undefined : (new Date(json['to'])),
    };
}

export function ProjectChartFiltersDTOToJSON(value?: ProjectChartFiltersDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'from': value['from'] == null ? undefined : ((value['from']).toISOString()),
        'owners': value['owners'],
        'states': value['states'],
        'tags': value['tags'],
        'timePeriod': TimeDurationDTOToJSON(value['timePeriod']),
        'to': value['to'] == null ? undefined : ((value['to']).toISOString()),
    };
}

