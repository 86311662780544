/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationIntercomDataDTO } from './OrganizationIntercomDataDTO';
import {
    OrganizationIntercomDataDTOFromJSON,
    OrganizationIntercomDataDTOFromJSONTyped,
    OrganizationIntercomDataDTOToJSON,
} from './OrganizationIntercomDataDTO';
import type { UserIntercomDataDTO } from './UserIntercomDataDTO';
import {
    UserIntercomDataDTOFromJSON,
    UserIntercomDataDTOFromJSONTyped,
    UserIntercomDataDTOToJSON,
} from './UserIntercomDataDTO';

/**
 * 
 * @export
 * @interface IntercomDataDTO
 */
export interface IntercomDataDTO {
    /**
     * 
     * @type {UserIntercomDataDTO}
     * @memberof IntercomDataDTO
     */
    userData?: UserIntercomDataDTO;
    /**
     * 
     * @type {OrganizationIntercomDataDTO}
     * @memberof IntercomDataDTO
     */
    organizationData?: OrganizationIntercomDataDTO;
}

/**
 * Check if a given object implements the IntercomDataDTO interface.
 */
export function instanceOfIntercomDataDTO(value: object): value is IntercomDataDTO {
    return true;
}

export function IntercomDataDTOFromJSON(json: any): IntercomDataDTO {
    return IntercomDataDTOFromJSONTyped(json, false);
}

export function IntercomDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntercomDataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'userData': json['userData'] == null ? undefined : UserIntercomDataDTOFromJSON(json['userData']),
        'organizationData': json['organizationData'] == null ? undefined : OrganizationIntercomDataDTOFromJSON(json['organizationData']),
    };
}

export function IntercomDataDTOToJSON(value?: IntercomDataDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userData': UserIntercomDataDTOToJSON(value['userData']),
        'organizationData': OrganizationIntercomDataDTOToJSON(value['organizationData']),
    };
}

