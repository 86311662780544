/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaidSubscriptionPricingDTO } from './PaidSubscriptionPricingDTO';
import {
    PaidSubscriptionPricingDTOFromJSON,
    PaidSubscriptionPricingDTOFromJSONTyped,
    PaidSubscriptionPricingDTOToJSON,
} from './PaidSubscriptionPricingDTO';
import type { V1TeamPricingDTO } from './V1TeamPricingDTO';
import {
    V1TeamPricingDTOFromJSON,
    V1TeamPricingDTOFromJSONTyped,
    V1TeamPricingDTOToJSON,
} from './V1TeamPricingDTO';
import type { GraduatedPricingDTO } from './GraduatedPricingDTO';
import {
    GraduatedPricingDTOFromJSON,
    GraduatedPricingDTOFromJSONTyped,
    GraduatedPricingDTOToJSON,
} from './GraduatedPricingDTO';
import type { SimplePricingDTO } from './SimplePricingDTO';
import {
    SimplePricingDTOFromJSON,
    SimplePricingDTOFromJSONTyped,
    SimplePricingDTOToJSON,
} from './SimplePricingDTO';
import type { V2IndividualPricingDTO } from './V2IndividualPricingDTO';
import {
    V2IndividualPricingDTOFromJSON,
    V2IndividualPricingDTOFromJSONTyped,
    V2IndividualPricingDTOToJSON,
} from './V2IndividualPricingDTO';
import type { FlatPricingDTO } from './FlatPricingDTO';
import {
    FlatPricingDTOFromJSON,
    FlatPricingDTOFromJSONTyped,
    FlatPricingDTOToJSON,
} from './FlatPricingDTO';
import type { PricingPlanDTO } from './PricingPlanDTO';
import {
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './PricingPlanDTO';

/**
 * 
 * @export
 * @interface OrganizationPricingDTO
 */
export interface OrganizationPricingDTO {
    /**
     * 
     * @type {V2IndividualPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    v2IndividualPricing?: V2IndividualPricingDTO;
    /**
     * 
     * @type {PaidSubscriptionPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    organizationPlanPricing?: PaidSubscriptionPricingDTO;
    /**
     * 
     * @type {SimplePricingDTO}
     * @memberof OrganizationPricingDTO
     */
    users?: SimplePricingDTO;
    /**
     * 
     * @type {V1TeamPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    v1TeamPricing?: V1TeamPricingDTO;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    monitoringTime?: GraduatedPricingDTO;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    activeProjects?: GraduatedPricingDTO;
    /**
     * 
     * @type {PaidSubscriptionPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    v2TeamPricing?: PaidSubscriptionPricingDTO;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    storage?: GraduatedPricingDTO;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof OrganizationPricingDTO
     */
    pricingPlan: PricingPlanDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationPricingDTO
     */
    supportsPayments: boolean;
    /**
     * 
     * @type {FlatPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    flatFee?: FlatPricingDTO;
}



/**
 * Check if a given object implements the OrganizationPricingDTO interface.
 */
export function instanceOfOrganizationPricingDTO(value: object): value is OrganizationPricingDTO {
    if (!('pricingPlan' in value) || value['pricingPlan'] === undefined) return false;
    if (!('supportsPayments' in value) || value['supportsPayments'] === undefined) return false;
    return true;
}

export function OrganizationPricingDTOFromJSON(json: any): OrganizationPricingDTO {
    return OrganizationPricingDTOFromJSONTyped(json, false);
}

export function OrganizationPricingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationPricingDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'v2IndividualPricing': json['v2IndividualPricing'] == null ? undefined : V2IndividualPricingDTOFromJSON(json['v2IndividualPricing']),
        'organizationPlanPricing': json['organizationPlanPricing'] == null ? undefined : PaidSubscriptionPricingDTOFromJSON(json['organizationPlanPricing']),
        'users': json['users'] == null ? undefined : SimplePricingDTOFromJSON(json['users']),
        'v1TeamPricing': json['v1TeamPricing'] == null ? undefined : V1TeamPricingDTOFromJSON(json['v1TeamPricing']),
        'monitoringTime': json['monitoringTime'] == null ? undefined : GraduatedPricingDTOFromJSON(json['monitoringTime']),
        'activeProjects': json['activeProjects'] == null ? undefined : GraduatedPricingDTOFromJSON(json['activeProjects']),
        'v2TeamPricing': json['v2TeamPricing'] == null ? undefined : PaidSubscriptionPricingDTOFromJSON(json['v2TeamPricing']),
        'storage': json['storage'] == null ? undefined : GraduatedPricingDTOFromJSON(json['storage']),
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
        'supportsPayments': json['supportsPayments'],
        'flatFee': json['flatFee'] == null ? undefined : FlatPricingDTOFromJSON(json['flatFee']),
    };
}

export function OrganizationPricingDTOToJSON(value?: OrganizationPricingDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'v2IndividualPricing': V2IndividualPricingDTOToJSON(value['v2IndividualPricing']),
        'organizationPlanPricing': PaidSubscriptionPricingDTOToJSON(value['organizationPlanPricing']),
        'users': SimplePricingDTOToJSON(value['users']),
        'v1TeamPricing': V1TeamPricingDTOToJSON(value['v1TeamPricing']),
        'monitoringTime': GraduatedPricingDTOToJSON(value['monitoringTime']),
        'activeProjects': GraduatedPricingDTOToJSON(value['activeProjects']),
        'v2TeamPricing': PaidSubscriptionPricingDTOToJSON(value['v2TeamPricing']),
        'storage': GraduatedPricingDTOToJSON(value['storage']),
        'pricingPlan': PricingPlanDTOToJSON(value['pricingPlan']),
        'supportsPayments': value['supportsPayments'],
        'flatFee': FlatPricingDTOToJSON(value['flatFee']),
    };
}

