/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FloatPointValueDTO } from './FloatPointValueDTO';
import {
    FloatPointValueDTOFromJSON,
    FloatPointValueDTOFromJSONTyped,
    FloatPointValueDTOToJSON,
} from './FloatPointValueDTO';

/**
 * 
 * @export
 * @interface FloatSeriesValuesDTO
 */
export interface FloatSeriesValuesDTO {
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesValuesDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<FloatPointValueDTO>}
     * @memberof FloatSeriesValuesDTO
     */
    values: Array<FloatPointValueDTO>;
}

/**
 * Check if a given object implements the FloatSeriesValuesDTO interface.
 */
export function instanceOfFloatSeriesValuesDTO(value: object): value is FloatSeriesValuesDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function FloatSeriesValuesDTOFromJSON(json: any): FloatSeriesValuesDTO {
    return FloatSeriesValuesDTOFromJSONTyped(json, false);
}

export function FloatSeriesValuesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatSeriesValuesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'values': ((json['values'] as Array<any>).map(FloatPointValueDTOFromJSON)),
    };
}

export function FloatSeriesValuesDTOToJSON(value?: FloatSeriesValuesDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'totalItemCount': value['totalItemCount'],
        'values': ((value['values'] as Array<any>).map(FloatPointValueDTOToJSON)),
    };
}

