/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AvatarSourceEnum } from './AvatarSourceEnum';
import {
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
} from './AvatarSourceEnum';
import type { OrganizationTypeDTO } from './OrganizationTypeDTO';
import {
    OrganizationTypeDTOFromJSON,
    OrganizationTypeDTOFromJSONTyped,
    OrganizationTypeDTOToJSON,
} from './OrganizationTypeDTO';
import type { OrganizationRoleDTO } from './OrganizationRoleDTO';
import {
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
} from './OrganizationRoleDTO';
import type { PricingPlanDTO } from './PricingPlanDTO';
import {
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './PricingPlanDTO';
import type { OrganizationDTO } from './OrganizationDTO';
import {
    OrganizationDTOFromJSON,
    OrganizationDTOFromJSONTyped,
    OrganizationDTOToJSON,
} from './OrganizationDTO';

/**
 * 
 * @export
 * @interface OrganizationWithRoleDTO
 */
export interface OrganizationWithRoleDTO {
    /**
     * 
     * @type {OrganizationDTO}
     * @memberof OrganizationWithRoleDTO
     */
    organization: OrganizationDTO;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    name: string;
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof OrganizationWithRoleDTO
     */
    userRole: OrganizationRoleDTO;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    paymentStatus: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {OrganizationTypeDTO}
     * @memberof OrganizationWithRoleDTO
     */
    organizationType: OrganizationTypeDTO;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof OrganizationWithRoleDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationWithRoleDTO
     */
    limitsEnforced: boolean;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof OrganizationWithRoleDTO
     */
    pricingPlan: PricingPlanDTO;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationWithRoleDTO
     */
    created: Date;
}



/**
 * Check if a given object implements the OrganizationWithRoleDTO interface.
 */
export function instanceOfOrganizationWithRoleDTO(value: object): value is OrganizationWithRoleDTO {
    if (!('organization' in value) || value['organization'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('userRole' in value) || value['userRole'] === undefined) return false;
    if (!('paymentStatus' in value) || value['paymentStatus'] === undefined) return false;
    if (!('avatarUrl' in value) || value['avatarUrl'] === undefined) return false;
    if (!('organizationType' in value) || value['organizationType'] === undefined) return false;
    if (!('avatarSource' in value) || value['avatarSource'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('limitsEnforced' in value) || value['limitsEnforced'] === undefined) return false;
    if (!('pricingPlan' in value) || value['pricingPlan'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    return true;
}

export function OrganizationWithRoleDTOFromJSON(json: any): OrganizationWithRoleDTO {
    return OrganizationWithRoleDTOFromJSONTyped(json, false);
}

export function OrganizationWithRoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationWithRoleDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'organization': OrganizationDTOFromJSON(json['organization']),
        'name': json['name'],
        'userRole': OrganizationRoleDTOFromJSON(json['userRole']),
        'paymentStatus': json['paymentStatus'],
        'avatarUrl': json['avatarUrl'],
        'organizationType': OrganizationTypeDTOFromJSON(json['organizationType']),
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'info': json['info'] == null ? undefined : json['info'],
        'id': json['id'],
        'limitsEnforced': json['limitsEnforced'],
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
        'created': (new Date(json['created'])),
    };
}

export function OrganizationWithRoleDTOToJSON(value?: OrganizationWithRoleDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'organization': OrganizationDTOToJSON(value['organization']),
        'name': value['name'],
        'userRole': OrganizationRoleDTOToJSON(value['userRole']),
        'paymentStatus': value['paymentStatus'],
        'avatarUrl': value['avatarUrl'],
        'organizationType': OrganizationTypeDTOToJSON(value['organizationType']),
        'avatarSource': AvatarSourceEnumToJSON(value['avatarSource']),
        'info': value['info'],
        'id': value['id'],
        'limitsEnforced': value['limitsEnforced'],
        'pricingPlan': PricingPlanDTOToJSON(value['pricingPlan']),
        'created': ((value['created']).toISOString()),
    };
}

