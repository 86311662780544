/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringTimeHistoryPeriodDTO
 */
export interface MonitoringTimeHistoryPeriodDTO {
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeHistoryPeriodDTO
     */
    usedSeconds: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringTimeHistoryPeriodDTO
     */
    periodStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringTimeHistoryPeriodDTO
     */
    periodEnd: Date;
}

/**
 * Check if a given object implements the MonitoringTimeHistoryPeriodDTO interface.
 */
export function instanceOfMonitoringTimeHistoryPeriodDTO(value: object): value is MonitoringTimeHistoryPeriodDTO {
    if (!('usedSeconds' in value) || value['usedSeconds'] === undefined) return false;
    if (!('periodStart' in value) || value['periodStart'] === undefined) return false;
    if (!('periodEnd' in value) || value['periodEnd'] === undefined) return false;
    return true;
}

export function MonitoringTimeHistoryPeriodDTOFromJSON(json: any): MonitoringTimeHistoryPeriodDTO {
    return MonitoringTimeHistoryPeriodDTOFromJSONTyped(json, false);
}

export function MonitoringTimeHistoryPeriodDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTimeHistoryPeriodDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'usedSeconds': json['usedSeconds'],
        'periodStart': (new Date(json['periodStart'])),
        'periodEnd': (new Date(json['periodEnd'])),
    };
}

export function MonitoringTimeHistoryPeriodDTOToJSON(value?: MonitoringTimeHistoryPeriodDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'usedSeconds': value['usedSeconds'],
        'periodStart': ((value['periodStart']).toISOString()),
        'periodEnd': ((value['periodEnd']).toISOString()),
    };
}

