/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeDTO } from './AttributeDTO';
import {
    AttributeDTOFromJSON,
    AttributeDTOFromJSONTyped,
    AttributeDTOToJSON,
} from './AttributeDTO';

/**
 * 
 * @export
 * @interface QueryAttributeValuesResultDTO
 */
export interface QueryAttributeValuesResultDTO {
    /**
     * A list of unique attribute values
     * @type {Array<AttributeDTO>}
     * @memberof QueryAttributeValuesResultDTO
     */
    attributes: Array<AttributeDTO>;
}

/**
 * Check if a given object implements the QueryAttributeValuesResultDTO interface.
 */
export function instanceOfQueryAttributeValuesResultDTO(value: object): value is QueryAttributeValuesResultDTO {
    if (!('attributes' in value) || value['attributes'] === undefined) return false;
    return true;
}

export function QueryAttributeValuesResultDTOFromJSON(json: any): QueryAttributeValuesResultDTO {
    return QueryAttributeValuesResultDTOFromJSONTyped(json, false);
}

export function QueryAttributeValuesResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeValuesResultDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AttributeDTOFromJSON)),
    };
}

export function QueryAttributeValuesResultDTOToJSON(value?: QueryAttributeValuesResultDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributes': ((value['attributes'] as Array<any>).map(AttributeDTOToJSON)),
    };
}

