/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExperimentsDataDTO } from './ExperimentsDataDTO';
import {
    ExperimentsDataDTOFromJSON,
    ExperimentsDataDTOFromJSONTyped,
    ExperimentsDataDTOToJSON,
} from './ExperimentsDataDTO';
import type { NotebooksDataDTO } from './NotebooksDataDTO';
import {
    NotebooksDataDTOFromJSON,
    NotebooksDataDTOFromJSONTyped,
    NotebooksDataDTOToJSON,
} from './NotebooksDataDTO';

/**
 * 
 * @export
 * @interface TrackingDataDTO
 */
export interface TrackingDataDTO {
    /**
     * 
     * @type {ExperimentsDataDTO}
     * @memberof TrackingDataDTO
     */
    experiments?: ExperimentsDataDTO;
    /**
     * 
     * @type {string}
     * @memberof TrackingDataDTO
     */
    identifier?: string;
    /**
     * 
     * @type {NotebooksDataDTO}
     * @memberof TrackingDataDTO
     */
    notebooks?: NotebooksDataDTO;
}

/**
 * Check if a given object implements the TrackingDataDTO interface.
 */
export function instanceOfTrackingDataDTO(value: object): value is TrackingDataDTO {
    return true;
}

export function TrackingDataDTOFromJSON(json: any): TrackingDataDTO {
    return TrackingDataDTOFromJSONTyped(json, false);
}

export function TrackingDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingDataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'experiments': json['experiments'] == null ? undefined : ExperimentsDataDTOFromJSON(json['experiments']),
        'identifier': json['identifier'] == null ? undefined : json['identifier'],
        'notebooks': json['notebooks'] == null ? undefined : NotebooksDataDTOFromJSON(json['notebooks']),
    };
}

export function TrackingDataDTOToJSON(value?: TrackingDataDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'experiments': ExperimentsDataDTOToJSON(value['experiments']),
        'identifier': value['identifier'],
        'notebooks': NotebooksDataDTOToJSON(value['notebooks']),
    };
}

