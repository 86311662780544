import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';

import './InputSelectTrigger.less';

const block = bemBlock('input-select-trigger');

export const Trigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger ref={ref} className={block({ extra: className })} {...props}>
    {children}
    <SelectPrimitive.Icon asChild>
      <Icon glyph="chevron-down" className={block({ element: 'icon' })} />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
