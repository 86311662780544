/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface LeaderboardGroupParamsDTO
 */
export interface LeaderboardGroupParamsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardGroupParamsDTO
     */
    groupBy?: Array<string>;
    /**
     * 
     * @type {Array<AttributeTypeDTO>}
     * @memberof LeaderboardGroupParamsDTO
     */
    groupByFieldType?: Array<AttributeTypeDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardGroupParamsDTO
     */
    groupByFieldAggregationMode?: Array<LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardGroupParamsDTO
     */
    openedGroups?: Array<string>;
}


/**
 * @export
 */
export const LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum = {
    Last: 'last',
    Min: 'min',
    Max: 'max',
    Average: 'average',
    Variance: 'variance',
    Auto: 'auto'
} as const;
export type LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum = typeof LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum[keyof typeof LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum];


/**
 * Check if a given object implements the LeaderboardGroupParamsDTO interface.
 */
export function instanceOfLeaderboardGroupParamsDTO(value: object): value is LeaderboardGroupParamsDTO {
    return true;
}

export function LeaderboardGroupParamsDTOFromJSON(json: any): LeaderboardGroupParamsDTO {
    return LeaderboardGroupParamsDTOFromJSONTyped(json, false);
}

export function LeaderboardGroupParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardGroupParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'groupBy': json['groupBy'] == null ? undefined : json['groupBy'],
        'groupByFieldType': json['groupByFieldType'] == null ? undefined : ((json['groupByFieldType'] as Array<any>).map(AttributeTypeDTOFromJSON)),
        'groupByFieldAggregationMode': json['groupByFieldAggregationMode'] == null ? undefined : json['groupByFieldAggregationMode'],
        'openedGroups': json['openedGroups'] == null ? undefined : json['openedGroups'],
    };
}

export function LeaderboardGroupParamsDTOToJSON(value?: LeaderboardGroupParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'groupBy': value['groupBy'],
        'groupByFieldType': value['groupByFieldType'] == null ? undefined : ((value['groupByFieldType'] as Array<any>).map(AttributeTypeDTOToJSON)),
        'groupByFieldAggregationMode': value['groupByFieldAggregationMode'],
        'openedGroups': value['openedGroups'],
    };
}

