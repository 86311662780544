/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectWithRoleDTO } from './ProjectWithRoleDTO';
import {
    ProjectWithRoleDTOFromJSON,
    ProjectWithRoleDTOFromJSONTyped,
    ProjectWithRoleDTOToJSON,
} from './ProjectWithRoleDTO';

/**
 * 
 * @export
 * @interface ProjectListDTO
 */
export interface ProjectListDTO {
    /**
     * 
     * @type {Array<ProjectWithRoleDTO>}
     * @memberof ProjectListDTO
     */
    entries: Array<ProjectWithRoleDTO>;
    /**
     * 
     * @type {number}
     * @memberof ProjectListDTO
     */
    matchingItemCount: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListDTO
     */
    totalItemCount: number;
}

/**
 * Check if a given object implements the ProjectListDTO interface.
 */
export function instanceOfProjectListDTO(value: object): value is ProjectListDTO {
    if (!('entries' in value) || value['entries'] === undefined) return false;
    if (!('matchingItemCount' in value) || value['matchingItemCount'] === undefined) return false;
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    return true;
}

export function ProjectListDTOFromJSON(json: any): ProjectListDTO {
    return ProjectListDTOFromJSONTyped(json, false);
}

export function ProjectListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(ProjectWithRoleDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'totalItemCount': json['totalItemCount'],
    };
}

export function ProjectListDTOToJSON(value?: ProjectListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entries': ((value['entries'] as Array<any>).map(ProjectWithRoleDTOToJSON)),
        'matchingItemCount': value['matchingItemCount'],
        'totalItemCount': value['totalItemCount'],
    };
}

