/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FloatPointValueDTO
 */
export interface FloatPointValueDTO {
    /**
     * 
     * @type {number}
     * @memberof FloatPointValueDTO
     */
    step: number;
    /**
     * 
     * @type {number}
     * @memberof FloatPointValueDTO
     */
    timestampMillis: number;
    /**
     * 
     * @type {number}
     * @memberof FloatPointValueDTO
     */
    value: number;
}

/**
 * Check if a given object implements the FloatPointValueDTO interface.
 */
export function instanceOfFloatPointValueDTO(value: object): value is FloatPointValueDTO {
    if (!('step' in value) || value['step'] === undefined) return false;
    if (!('timestampMillis' in value) || value['timestampMillis'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function FloatPointValueDTOFromJSON(json: any): FloatPointValueDTO {
    return FloatPointValueDTOFromJSONTyped(json, false);
}

export function FloatPointValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatPointValueDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'step': json['step'],
        'timestampMillis': json['timestampMillis'],
        'value': json['value'],
    };
}

export function FloatPointValueDTOToJSON(value?: FloatPointValueDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'step': value['step'],
        'timestampMillis': value['timestampMillis'],
        'value': value['value'],
    };
}

