/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ImageSeriesAttributeDTO } from './ImageSeriesAttributeDTO';
import {
    ImageSeriesAttributeDTOFromJSON,
    ImageSeriesAttributeDTOFromJSONTyped,
    ImageSeriesAttributeDTOToJSON,
} from './ImageSeriesAttributeDTO';
import type { IntAttributeDTO } from './IntAttributeDTO';
import {
    IntAttributeDTOFromJSON,
    IntAttributeDTOFromJSONTyped,
    IntAttributeDTOToJSON,
} from './IntAttributeDTO';
import type { FloatAttributeDTO } from './FloatAttributeDTO';
import {
    FloatAttributeDTOFromJSON,
    FloatAttributeDTOFromJSONTyped,
    FloatAttributeDTOToJSON,
} from './FloatAttributeDTO';
import type { FileAttributeDTO } from './FileAttributeDTO';
import {
    FileAttributeDTOFromJSON,
    FileAttributeDTOFromJSONTyped,
    FileAttributeDTOToJSON,
} from './FileAttributeDTO';
import type { DatetimeAttributeDTO } from './DatetimeAttributeDTO';
import {
    DatetimeAttributeDTOFromJSON,
    DatetimeAttributeDTOFromJSONTyped,
    DatetimeAttributeDTOToJSON,
} from './DatetimeAttributeDTO';
import type { BoolAttributeDTO } from './BoolAttributeDTO';
import {
    BoolAttributeDTOFromJSON,
    BoolAttributeDTOFromJSONTyped,
    BoolAttributeDTOToJSON,
} from './BoolAttributeDTO';
import type { NotebookRefAttributeDTO } from './NotebookRefAttributeDTO';
import {
    NotebookRefAttributeDTOFromJSON,
    NotebookRefAttributeDTOFromJSONTyped,
    NotebookRefAttributeDTOToJSON,
} from './NotebookRefAttributeDTO';
import type { FileSetAttributeDTO } from './FileSetAttributeDTO';
import {
    FileSetAttributeDTOFromJSON,
    FileSetAttributeDTOFromJSONTyped,
    FileSetAttributeDTOToJSON,
} from './FileSetAttributeDTO';
import type { FloatSeriesAttributeDTO } from './FloatSeriesAttributeDTO';
import {
    FloatSeriesAttributeDTOFromJSON,
    FloatSeriesAttributeDTOFromJSONTyped,
    FloatSeriesAttributeDTOToJSON,
} from './FloatSeriesAttributeDTO';
import type { StringSetAttributeDTO } from './StringSetAttributeDTO';
import {
    StringSetAttributeDTOFromJSON,
    StringSetAttributeDTOFromJSONTyped,
    StringSetAttributeDTOToJSON,
} from './StringSetAttributeDTO';
import type { ExperimentStateAttributeDTO } from './ExperimentStateAttributeDTO';
import {
    ExperimentStateAttributeDTOFromJSON,
    ExperimentStateAttributeDTOFromJSONTyped,
    ExperimentStateAttributeDTOToJSON,
} from './ExperimentStateAttributeDTO';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';
import type { GitInfoDTO } from './GitInfoDTO';
import {
    GitInfoDTOFromJSON,
    GitInfoDTOFromJSONTyped,
    GitInfoDTOToJSON,
} from './GitInfoDTO';
import type { StringAttributeDTO } from './StringAttributeDTO';
import {
    StringAttributeDTOFromJSON,
    StringAttributeDTOFromJSONTyped,
    StringAttributeDTOToJSON,
} from './StringAttributeDTO';
import type { StringSeriesAttributeDTO } from './StringSeriesAttributeDTO';
import {
    StringSeriesAttributeDTOFromJSON,
    StringSeriesAttributeDTOFromJSONTyped,
    StringSeriesAttributeDTOToJSON,
} from './StringSeriesAttributeDTO';
import type { ComplexAttributeDTO } from './ComplexAttributeDTO';
import {
    ComplexAttributeDTOFromJSON,
    ComplexAttributeDTOFromJSONTyped,
    ComplexAttributeDTOToJSON,
} from './ComplexAttributeDTO';

/**
 * 
 * @export
 * @interface AttributeDTO
 */
export interface AttributeDTO {
    /**
     * 
     * @type {BoolAttributeDTO}
     * @memberof AttributeDTO
     */
    boolProperties?: BoolAttributeDTO;
    /**
     * 
     * @type {ComplexAttributeDTO}
     * @memberof AttributeDTO
     */
    complexProperties?: ComplexAttributeDTO;
    /**
     * 
     * @type {DatetimeAttributeDTO}
     * @memberof AttributeDTO
     */
    datetimeProperties?: DatetimeAttributeDTO;
    /**
     * 
     * @type {ExperimentStateAttributeDTO}
     * @memberof AttributeDTO
     */
    experimentStateProperties?: ExperimentStateAttributeDTO;
    /**
     * 
     * @type {FileAttributeDTO}
     * @memberof AttributeDTO
     */
    fileProperties?: FileAttributeDTO;
    /**
     * 
     * @type {FileSetAttributeDTO}
     * @memberof AttributeDTO
     */
    fileSetProperties?: FileSetAttributeDTO;
    /**
     * 
     * @type {FloatAttributeDTO}
     * @memberof AttributeDTO
     */
    floatProperties?: FloatAttributeDTO;
    /**
     * 
     * @type {FloatSeriesAttributeDTO}
     * @memberof AttributeDTO
     */
    floatSeriesProperties?: FloatSeriesAttributeDTO;
    /**
     * 
     * @type {GitInfoDTO}
     * @memberof AttributeDTO
     */
    gitRefProperties?: GitInfoDTO;
    /**
     * 
     * @type {ImageSeriesAttributeDTO}
     * @memberof AttributeDTO
     */
    imageSeriesProperties?: ImageSeriesAttributeDTO;
    /**
     * 
     * @type {IntAttributeDTO}
     * @memberof AttributeDTO
     */
    intProperties?: IntAttributeDTO;
    /**
     * 
     * @type {string}
     * @memberof AttributeDTO
     */
    name: string;
    /**
     * 
     * @type {NotebookRefAttributeDTO}
     * @memberof AttributeDTO
     */
    notebookRefProperties?: NotebookRefAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof AttributeDTO
     */
    stringProperties?: StringAttributeDTO;
    /**
     * 
     * @type {StringSeriesAttributeDTO}
     * @memberof AttributeDTO
     */
    stringSeriesProperties?: StringSeriesAttributeDTO;
    /**
     * 
     * @type {StringSetAttributeDTO}
     * @memberof AttributeDTO
     */
    stringSetProperties?: StringSetAttributeDTO;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof AttributeDTO
     */
    type: AttributeTypeDTO;
}



/**
 * Check if a given object implements the AttributeDTO interface.
 */
export function instanceOfAttributeDTO(value: object): value is AttributeDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function AttributeDTOFromJSON(json: any): AttributeDTO {
    return AttributeDTOFromJSONTyped(json, false);
}

export function AttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'boolProperties': json['boolProperties'] == null ? undefined : BoolAttributeDTOFromJSON(json['boolProperties']),
        'complexProperties': json['complexProperties'] == null ? undefined : ComplexAttributeDTOFromJSON(json['complexProperties']),
        'datetimeProperties': json['datetimeProperties'] == null ? undefined : DatetimeAttributeDTOFromJSON(json['datetimeProperties']),
        'experimentStateProperties': json['experimentStateProperties'] == null ? undefined : ExperimentStateAttributeDTOFromJSON(json['experimentStateProperties']),
        'fileProperties': json['fileProperties'] == null ? undefined : FileAttributeDTOFromJSON(json['fileProperties']),
        'fileSetProperties': json['fileSetProperties'] == null ? undefined : FileSetAttributeDTOFromJSON(json['fileSetProperties']),
        'floatProperties': json['floatProperties'] == null ? undefined : FloatAttributeDTOFromJSON(json['floatProperties']),
        'floatSeriesProperties': json['floatSeriesProperties'] == null ? undefined : FloatSeriesAttributeDTOFromJSON(json['floatSeriesProperties']),
        'gitRefProperties': json['gitRefProperties'] == null ? undefined : GitInfoDTOFromJSON(json['gitRefProperties']),
        'imageSeriesProperties': json['imageSeriesProperties'] == null ? undefined : ImageSeriesAttributeDTOFromJSON(json['imageSeriesProperties']),
        'intProperties': json['intProperties'] == null ? undefined : IntAttributeDTOFromJSON(json['intProperties']),
        'name': json['name'],
        'notebookRefProperties': json['notebookRefProperties'] == null ? undefined : NotebookRefAttributeDTOFromJSON(json['notebookRefProperties']),
        'stringProperties': json['stringProperties'] == null ? undefined : StringAttributeDTOFromJSON(json['stringProperties']),
        'stringSeriesProperties': json['stringSeriesProperties'] == null ? undefined : StringSeriesAttributeDTOFromJSON(json['stringSeriesProperties']),
        'stringSetProperties': json['stringSetProperties'] == null ? undefined : StringSetAttributeDTOFromJSON(json['stringSetProperties']),
        'type': AttributeTypeDTOFromJSON(json['type']),
    };
}

export function AttributeDTOToJSON(value?: AttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'boolProperties': BoolAttributeDTOToJSON(value['boolProperties']),
        'complexProperties': ComplexAttributeDTOToJSON(value['complexProperties']),
        'datetimeProperties': DatetimeAttributeDTOToJSON(value['datetimeProperties']),
        'experimentStateProperties': ExperimentStateAttributeDTOToJSON(value['experimentStateProperties']),
        'fileProperties': FileAttributeDTOToJSON(value['fileProperties']),
        'fileSetProperties': FileSetAttributeDTOToJSON(value['fileSetProperties']),
        'floatProperties': FloatAttributeDTOToJSON(value['floatProperties']),
        'floatSeriesProperties': FloatSeriesAttributeDTOToJSON(value['floatSeriesProperties']),
        'gitRefProperties': GitInfoDTOToJSON(value['gitRefProperties']),
        'imageSeriesProperties': ImageSeriesAttributeDTOToJSON(value['imageSeriesProperties']),
        'intProperties': IntAttributeDTOToJSON(value['intProperties']),
        'name': value['name'],
        'notebookRefProperties': NotebookRefAttributeDTOToJSON(value['notebookRefProperties']),
        'stringProperties': StringAttributeDTOToJSON(value['stringProperties']),
        'stringSeriesProperties': StringSeriesAttributeDTOToJSON(value['stringSeriesProperties']),
        'stringSetProperties': StringSetAttributeDTOToJSON(value['stringSetProperties']),
        'type': AttributeTypeDTOToJSON(value['type']),
    };
}

