/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkspaceModeDTO = {
    ReadOnly: 'read_only',
    Normal: 'normal'
} as const;
export type WorkspaceModeDTO = typeof WorkspaceModeDTO[keyof typeof WorkspaceModeDTO];


export function instanceOfWorkspaceModeDTO(value: any): boolean {
    for (const key in WorkspaceModeDTO) {
        if (Object.prototype.hasOwnProperty.call(WorkspaceModeDTO, key)) {
            if (WorkspaceModeDTO[key as keyof typeof WorkspaceModeDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkspaceModeDTOFromJSON(json: any): WorkspaceModeDTO {
    return WorkspaceModeDTOFromJSONTyped(json, false);
}

export function WorkspaceModeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceModeDTO {
    return json as WorkspaceModeDTO;
}

export function WorkspaceModeDTOToJSON(value?: WorkspaceModeDTO | null): any {
    return value as any;
}

