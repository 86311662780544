import React from 'react';

import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

import config from 'config';

import { UnavailableErrorPage } from '../error-page/UnavailableErrorPage';

let errorNotifier: (err: any) => void = () => undefined;

export function registerLazyErrorNotifier(fn: (err: any) => void) {
  errorNotifier = fn;
}

export function lazyWrap<P = {}>(
  componentImport: () => Promise<React.ComponentType<P>>,
): React.FC<React.PropsWithRef<P>> {
  const LazyComponent = React.lazy<React.ComponentType<P>>(async () => {
    try {
      return { default: await componentImport() };
    } catch (e) {
      /*
      If reached here, it means there were problems loading chunk. The most likely reasons are:
        1. Internet problems (you can simulate this by setting "offline" in the throttling options in devtools)
        2. The application has been updated, the user has not refreshed the application (either the toast has not
           appeared yet, or the user has ignored it), then the user provoked the download of chunk.a (js file),
           and consequently the index.html file was sent to him due to the lack of this file , which in turn for
           the app is a wrong javascript code (Syntax Error, Unexpected token '<' on line 1)
        3. Runtime error loading chunk.a (very unlikely, maybe some manual manipulation of the file on the server side)
        4. You've messed something up in development, see console to figure out what exactly ;)
       */
      errorNotifier(e);

      if (!config.isProduction) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      return { default: UnavailableErrorPage };
    }
  });

  return (props) => (
    <React.Suspense fallback={<LoadingIndicator isVisible centered />}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
}
