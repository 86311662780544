/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SingleCustomTimeSeriesView } from './SingleCustomTimeSeriesView';
import {
    SingleCustomTimeSeriesViewFromJSON,
    SingleCustomTimeSeriesViewFromJSONTyped,
    SingleCustomTimeSeriesViewToJSON,
} from './SingleCustomTimeSeriesView';
import type { SingleTimeSeriesView } from './SingleTimeSeriesView';
import {
    SingleTimeSeriesViewFromJSON,
    SingleTimeSeriesViewFromJSONTyped,
    SingleTimeSeriesViewToJSON,
} from './SingleTimeSeriesView';

/**
 * 
 * @export
 * @interface TimeSeriesViewResponse
 */
export interface TimeSeriesViewResponse {
    /**
     * 
     * @type {Array<SingleCustomTimeSeriesView>}
     * @memberof TimeSeriesViewResponse
     */
    customSeries: Array<SingleCustomTimeSeriesView>;
    /**
     * 
     * @type {Array<SingleTimeSeriesView>}
     * @memberof TimeSeriesViewResponse
     */
    elements: Array<SingleTimeSeriesView>;
}

/**
 * Check if a given object implements the TimeSeriesViewResponse interface.
 */
export function instanceOfTimeSeriesViewResponse(value: object): value is TimeSeriesViewResponse {
    if (!('customSeries' in value) || value['customSeries'] === undefined) return false;
    if (!('elements' in value) || value['elements'] === undefined) return false;
    return true;
}

export function TimeSeriesViewResponseFromJSON(json: any): TimeSeriesViewResponse {
    return TimeSeriesViewResponseFromJSONTyped(json, false);
}

export function TimeSeriesViewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeriesViewResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'customSeries': ((json['customSeries'] as Array<any>).map(SingleCustomTimeSeriesViewFromJSON)),
        'elements': ((json['elements'] as Array<any>).map(SingleTimeSeriesViewFromJSON)),
    };
}

export function TimeSeriesViewResponseToJSON(value?: TimeSeriesViewResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customSeries': ((value['customSeries'] as Array<any>).map(SingleCustomTimeSeriesViewToJSON)),
        'elements': ((value['elements'] as Array<any>).map(SingleTimeSeriesViewToJSON)),
    };
}

