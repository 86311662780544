const hexPattern = /^#([A-F0-9]{3}){1,2}$/i;

export function hexToRgbParts(hex: string): [number, number, number] | undefined {
  if (!hexPattern.test(hex)) {
    return;
  }

  const h = hex.substring(1);
  const l = h.length;
  const r = parseInt(h.substring(0, l / 3), 16);
  const g = parseInt(h.substring(l / 3, (2 * l) / 3), 16);
  const b = parseInt(h.substring((2 * l) / 3, (3 * l) / 3), 16);

  return [r, g, b];
}

export function hexToRgba(hex: string, alpha?: number): string | undefined {
  const parts = hexToRgbParts(hex);

  if (!parts) {
    return;
  }

  const [r, g, b] = parts;
  const formatPart = alpha !== undefined ? 'rgba' : 'rgb';
  const alphaPart = alpha !== undefined ? `, ${alpha}` : '';

  return `${formatPart}(${r}, ${g}, ${b}${alphaPart})`;
}
