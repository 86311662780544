/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProjectRoleDTO = {
    Viewer: 'viewer',
    Member: 'member',
    Manager: 'manager'
} as const;
export type ProjectRoleDTO = typeof ProjectRoleDTO[keyof typeof ProjectRoleDTO];


export function instanceOfProjectRoleDTO(value: any): boolean {
    for (const key in ProjectRoleDTO) {
        if (Object.prototype.hasOwnProperty.call(ProjectRoleDTO, key)) {
            if (ProjectRoleDTO[key as keyof typeof ProjectRoleDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProjectRoleDTOFromJSON(json: any): ProjectRoleDTO {
    return ProjectRoleDTOFromJSONTyped(json, false);
}

export function ProjectRoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleDTO {
    return json as ProjectRoleDTO;
}

export function ProjectRoleDTOToJSON(value?: ProjectRoleDTO | null): any {
    return value as any;
}

