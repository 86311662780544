import React from 'react';

import { FocusContextOptions } from '@neptune/shared/common-domain';

export const useFocusContext = (
  defaultValue = false,
  onFocus?: () => void,
  onBlur?: () => void,
) => {
  const [hasFocus, setHasFocus] = React.useState(defaultValue);

  const handleBlur = React.useCallback(() => {
    setHasFocus(false);
    onBlur?.();
  }, [setHasFocus, onBlur]);

  const handleFocus = React.useCallback(() => {
    setHasFocus(true);
    onFocus?.();
  }, [setHasFocus, onFocus]);

  const focusContext = React.useMemo<FocusContextOptions>(
    () => ({
      hasFocus,
      blur: handleBlur,
      focus: handleFocus,
    }),
    [hasFocus, handleBlur, handleFocus],
  );

  return {
    focusContext,
    hasFocus,
  };
};
