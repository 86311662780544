/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeriesViewPointDTO
 */
export interface SeriesViewPointDTO {
    /**
     * 
     * @type {boolean}
     * @memberof SeriesViewPointDTO
     */
    downsampled: boolean;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewPointDTO
     */
    firstPointIndex: number;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewPointDTO
     */
    lastPointIndex: number;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewPointDTO
     */
    maxY: number;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewPointDTO
     */
    minY: number;
    /**
     * 
     * @type {Date}
     * @memberof SeriesViewPointDTO
     */
    timestamp: Date;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewPointDTO
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewPointDTO
     */
    y: number;
}

/**
 * Check if a given object implements the SeriesViewPointDTO interface.
 */
export function instanceOfSeriesViewPointDTO(value: object): value is SeriesViewPointDTO {
    if (!('downsampled' in value) || value['downsampled'] === undefined) return false;
    if (!('firstPointIndex' in value) || value['firstPointIndex'] === undefined) return false;
    if (!('lastPointIndex' in value) || value['lastPointIndex'] === undefined) return false;
    if (!('maxY' in value) || value['maxY'] === undefined) return false;
    if (!('minY' in value) || value['minY'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('x' in value) || value['x'] === undefined) return false;
    if (!('y' in value) || value['y'] === undefined) return false;
    return true;
}

export function SeriesViewPointDTOFromJSON(json: any): SeriesViewPointDTO {
    return SeriesViewPointDTOFromJSONTyped(json, false);
}

export function SeriesViewPointDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesViewPointDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'downsampled': json['downsampled'],
        'firstPointIndex': json['firstPointIndex'],
        'lastPointIndex': json['lastPointIndex'],
        'maxY': json['maxY'],
        'minY': json['minY'],
        'timestamp': (new Date(json['timestamp'])),
        'x': json['x'],
        'y': json['y'],
    };
}

export function SeriesViewPointDTOToJSON(value?: SeriesViewPointDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'downsampled': value['downsampled'],
        'firstPointIndex': value['firstPointIndex'],
        'lastPointIndex': value['lastPointIndex'],
        'maxY': value['maxY'],
        'minY': value['minY'],
        'timestamp': ((value['timestamp']).toISOString()),
        'x': value['x'],
        'y': value['y'],
    };
}

