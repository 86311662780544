/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Signal
 */
export interface Signal {
    /**
     * 
     * @type {object}
     * @memberof Signal
     */
    body: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof Signal
     */
    experimentIdentifiers: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Signal
     */
    type: string;
}

/**
 * Check if a given object implements the Signal interface.
 */
export function instanceOfSignal(value: object): value is Signal {
    if (!('body' in value) || value['body'] === undefined) return false;
    if (!('experimentIdentifiers' in value) || value['experimentIdentifiers'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function SignalFromJSON(json: any): Signal {
    return SignalFromJSONTyped(json, false);
}

export function SignalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Signal {
    if (json == null) {
        return json;
    }
    return {
        
        'body': json['body'],
        'experimentIdentifiers': json['experimentIdentifiers'],
        'type': json['type'],
    };
}

export function SignalToJSON(value?: Signal | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'body': value['body'],
        'experimentIdentifiers': value['experimentIdentifiers'],
        'type': value['type'],
    };
}

