import {
  AttributeDefinitionDTO,
  AttributeDTO,
  AttributeTypeDTO,
  BoolAttributeDTO,
  ComplexAttributeDTO,
  DatetimeAttributeDTO,
  ExperimentStateAttributeDTO,
  FileAttributeDTO,
  FileSetAttributeDTO,
  FloatAttributeDTO,
  FloatSeriesAttributeDTO,
  GitInfoDTO,
  ImageSeriesAttributeDTO,
  IntAttributeDTO,
  StringAttributeDTO,
  StringSeriesAttributeDTO,
  StringSetAttributeDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';

import { ExperimentStateConverter } from 'domain/experiment/experiment-state';

import { AttributeDefinition } from './attribute-definition';
import {
  Attribute,
  AttributeType,
  BooleanAttribute,
  ComplexAttribute,
  DateTimeAttribute,
  ExperimentStateAttribute,
  FileAttribute,
  FileSetAttribute,
  FloatAttribute,
  FloatSeriesAttribute,
  GitRefAttribute,
  ImageSeriesAttribute,
  IntegerAttribute,
  StringAttribute,
  StringSeriesAttribute,
  StringSetAttribute,
} from './attribute-model';

type AttributeDtoWrapper<T extends keyof AttributeDTO> = Pick<AttributeDTO, 'name' | 'type'> &
  Required<Pick<AttributeDTO, T>>;

function isBoolAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'boolProperties'> {
  return attribute.type === AttributeTypeDTO.Bool && !!attribute.boolProperties;
}

function isComplexAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'complexProperties'> {
  return attribute.type === AttributeTypeDTO.Complex && !!attribute.complexProperties;
}

function isDatetimeAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'datetimeProperties'> {
  return attribute.type === AttributeTypeDTO.Datetime && !!attribute.datetimeProperties;
}

function isExperimentStateAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'experimentStateProperties'> {
  return (
    attribute.type === AttributeTypeDTO.ExperimentState && !!attribute.experimentStateProperties
  );
}

function isFileAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'fileProperties'> {
  return attribute.type === AttributeTypeDTO.File && !!attribute.fileProperties;
}

function isFileSetAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'fileSetProperties'> {
  return attribute.type === AttributeTypeDTO.FileSet && !!attribute.fileSetProperties;
}

function isFloatAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'floatProperties'> {
  return attribute.type === AttributeTypeDTO.Float && !!attribute.floatProperties;
}

function isFloatSeriesAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'floatSeriesProperties'> {
  return attribute.type === AttributeTypeDTO.FloatSeries && !!attribute.floatSeriesProperties;
}

function isGitInfoDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'gitRefProperties'> {
  return attribute.type === AttributeTypeDTO.GitRef && !!attribute.gitRefProperties;
}

function isImageSeriesAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'imageSeriesProperties'> {
  return attribute.type === AttributeTypeDTO.ImageSeries && !!attribute.imageSeriesProperties;
}

function isIntAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'intProperties'> {
  return attribute.type === AttributeTypeDTO.Int && !!attribute.intProperties;
}

function isStringAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'stringProperties'> {
  return attribute.type === AttributeTypeDTO.String && !!attribute.stringProperties;
}

function isStringSeriesAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'stringSeriesProperties'> {
  return attribute.type === AttributeTypeDTO.StringSeries && !!attribute.stringSeriesProperties;
}

function isStringSetAttributeDTOWrapper(
  attribute: AttributeDTO,
): attribute is AttributeDtoWrapper<'stringSetProperties'> {
  return attribute.type === AttributeTypeDTO.StringSet && !!attribute.stringSetProperties;
}

export abstract class AttributeModelConverter {
  static attributeFromApiToDomain(source: AttributeDTO): Attribute {
    if (isBoolAttributeDTOWrapper(source)) {
      return AttributeModelConverter.boolAttributeFromApiToDomain(source.boolProperties);
    }

    if (isComplexAttributeDTOWrapper(source)) {
      return AttributeModelConverter.complexAttributeFromApiToDomain(source.complexProperties);
    }

    if (isDatetimeAttributeDTOWrapper(source)) {
      return AttributeModelConverter.datetimeAttributeFromApiToDomain(source.datetimeProperties);
    }

    if (isExperimentStateAttributeDTOWrapper(source)) {
      return AttributeModelConverter.experimentStateAttributeFromApiToDomain(
        source.experimentStateProperties,
      );
    }

    if (isFileAttributeDTOWrapper(source)) {
      return AttributeModelConverter.fileAttributeFromApiToDomain(source.fileProperties);
    }

    if (isFileSetAttributeDTOWrapper(source)) {
      return AttributeModelConverter.fileSetAttributeFromApiToDomain(source.fileSetProperties);
    }

    if (isFloatAttributeDTOWrapper(source)) {
      return AttributeModelConverter.floatAttributeFromApiToDomain(source.floatProperties);
    }

    if (isFloatSeriesAttributeDTOWrapper(source)) {
      return AttributeModelConverter.floatSeriesAttributeFromApiToDomain(
        source.floatSeriesProperties,
      );
    }

    if (isGitInfoDTOWrapper(source)) {
      return AttributeModelConverter.gitRefAttributeFromApiToDomain(source.gitRefProperties);
    }

    if (isImageSeriesAttributeDTOWrapper(source)) {
      return AttributeModelConverter.imageSeriesAttributeFromApiToDomain(
        source.imageSeriesProperties,
      );
    }

    if (isIntAttributeDTOWrapper(source)) {
      return AttributeModelConverter.intAttributeFromApiToDomain(source.intProperties);
    }

    if (isStringAttributeDTOWrapper(source)) {
      return AttributeModelConverter.stringAttributeFromApiToDomain(source.stringProperties);
    }

    if (isStringSeriesAttributeDTOWrapper(source)) {
      return AttributeModelConverter.stringSeriesAttributeFromApiToDomain(
        source.stringSeriesProperties,
      );
    }

    if (isStringSetAttributeDTOWrapper(source)) {
      return AttributeModelConverter.stringSetAttributeFromApiToDomain(source.stringSetProperties);
    }

    return AttributeModelConverter.notSupportedAttributeFromApiToDomain(source);
  }

  private static notSupportedAttributeFromApiToDomain(source: { name: string }): Attribute {
    return {
      attributeType: 'notSupported',
      attributeName: source.name,
    };
  }

  static stringSetAttributeFromApiToDomain(source: StringSetAttributeDTO): StringSetAttribute {
    return {
      attributeType: 'stringSet',
      attributeName: source.attributeName,
      values: [...source.values],
    };
  }

  static stringSeriesAttributeFromApiToDomain(
    source: StringSeriesAttributeDTO,
  ): StringSeriesAttribute {
    return {
      attributeType: 'stringSeries',
      attributeName: source.attributeName,
      last: source.last,
      lastStep: source.lastStep,
    };
  }

  static stringAttributeFromApiToDomain(source: StringAttributeDTO): StringAttribute {
    return {
      attributeType: 'string',
      attributeName: source.attributeName,
      value: source.value,
    };
  }

  static intAttributeFromApiToDomain(source: IntAttributeDTO): IntegerAttribute {
    return {
      attributeType: 'int',
      attributeName: source.attributeName,
      value: source.value,
    };
  }

  static imageSeriesAttributeFromApiToDomain(
    source: ImageSeriesAttributeDTO,
  ): ImageSeriesAttribute {
    return {
      attributeType: 'imageSeries',
      attributeName: source.attributeName,
      channelId: source.channelId,
      lastStep: source.lastStep,
    };
  }

  static gitRefAttributeFromApiToDomain(source: GitInfoDTO): GitRefAttribute {
    return {
      attributeType: 'gitRef',
      attributeName: source.attributeName,
      commit: {
        authorEmail: source.commit.authorEmail,
        authorName: source.commit.authorName,
        commitDate: new Date(source.commit.commitDate),
        commitId: source.commit.commitId,
        message: source.commit.message,
      },
      currentBranch: source.currentBranch,
      remotes: source.remotes && [...source.remotes],
      repositoryDirty: source.repositoryDirty,
    };
  }

  static floatSeriesAttributeFromApiToDomain(
    source: FloatSeriesAttributeDTO,
  ): FloatSeriesAttribute {
    return {
      attributeType: 'floatSeries',
      attributeName: source.attributeName,
      average: source.average,
      config: {
        max: source.config.max,
        min: source.config.min,
        unit: source.config.unit,
      },
      last: source.last,
      lastStep: source.lastStep,
      max: source.max,
      min: source.min,
      variance: source.variance,
    };
  }

  static floatAttributeFromApiToDomain(source: FloatAttributeDTO): FloatAttribute {
    return {
      attributeType: 'float',
      attributeName: source.attributeName,
      value: source.value,
    };
  }

  static fileSetAttributeFromApiToDomain(source: FileSetAttributeDTO): FileSetAttribute {
    return {
      attributeType: 'fileSet',
      attributeName: source.attributeName,
      size: source.size,
    };
  }

  static fileAttributeFromApiToDomain(source: FileAttributeDTO): FileAttribute {
    return {
      attributeType: 'file',
      attributeName: source.attributeName,
      ext: source.ext,
      name: source.name,
      size: source.size,
    };
  }

  static experimentStateAttributeFromApiToDomain(
    source: ExperimentStateAttributeDTO,
  ): ExperimentStateAttribute {
    return {
      attributeType: 'experimentState',
      attributeName: source.attributeName,
      value: ExperimentStateConverter.experimentStateFromApiToDomain(source.value),
    };
  }

  static datetimeAttributeFromApiToDomain(source: DatetimeAttributeDTO): DateTimeAttribute {
    return {
      attributeType: 'datetime',
      attributeName: source.attributeName,
      value: new Date(source.value),
    };
  }

  static complexAttributeFromApiToDomain(source: ComplexAttributeDTO): ComplexAttribute {
    return {
      attributeType: 'complex',
      attributeName: source.attributeName,
      __type: { name: source.typeDefinition.name ?? '' },
      __content: source.content,
    };
  }

  static boolAttributeFromApiToDomain(source: BoolAttributeDTO): BooleanAttribute {
    return {
      attributeType: 'bool',
      attributeName: source.attributeName,
      value: source.value,
    };
  }
}

export abstract class AttributeDefinitionConverter {
  static attributeDefinitionFromApiToDomain(source: AttributeDefinitionDTO): AttributeDefinition {
    return {
      name: source.name,
      subproperty: undefined,
      type: AttributeDefinitionConverter.attributeTypeFromApiToDomain(source.type),
    };
  }

  static attributeDefinitionFromDomainToApi(
    source: AttributeDefinition,
  ): AttributeDefinitionDTO | undefined {
    const type = AttributeDefinitionConverter.attributeTypeToApi(source.type);

    return (
      type && {
        name: source.name,
        type,
      }
    );
  }

  static attributeTypeFromApiToDomain(sourceType: AttributeTypeDTO): AttributeType {
    switch (sourceType) {
      case AttributeTypeDTO.Bool:
        return 'bool';

      case AttributeTypeDTO.Datetime:
        return 'datetime';

      case AttributeTypeDTO.ExperimentState:
        return 'experimentState';

      case AttributeTypeDTO.FloatSeries:
        return 'floatSeries';

      case AttributeTypeDTO.Float:
        return 'float';

      case AttributeTypeDTO.String:
        return 'string';

      case AttributeTypeDTO.File:
        return 'file';

      case AttributeTypeDTO.FileSet:
        return 'fileSet';

      case AttributeTypeDTO.GitRef:
        return 'gitRef';

      case AttributeTypeDTO.ImageSeries:
        return 'imageSeries';

      case AttributeTypeDTO.Int:
        return 'int';

      case AttributeTypeDTO.StringSeries:
        return 'stringSeries';

      case AttributeTypeDTO.StringSet:
        return 'stringSet';

      default:
        return 'notSupported';
    }
  }

  static attributeTypeToApi(input: string): AttributeTypeDTO | undefined {
    switch (input) {
      case 'bool':
        return AttributeTypeDTO.Bool;

      case 'datetime':
        return AttributeTypeDTO.Datetime;

      case 'experimentState':
        return AttributeTypeDTO.ExperimentState;

      case 'floatSeries':
        return AttributeTypeDTO.FloatSeries;

      case 'float':
        return AttributeTypeDTO.Float;

      case 'string':
        return AttributeTypeDTO.String;

      case 'file':
        return AttributeTypeDTO.File;

      case 'fileSet':
        return AttributeTypeDTO.FileSet;

      case 'gitRef':
        return AttributeTypeDTO.GitRef;

      case 'imageSeries':
        return AttributeTypeDTO.ImageSeries;

      case 'int':
        return AttributeTypeDTO.Int;

      case 'stringSeries':
        return AttributeTypeDTO.StringSeries;

      case 'stringSet':
        return AttributeTypeDTO.StringSet;

      default:
        return undefined;
    }
  }
}
