// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import * as layout from '../../modules/layout';
import * as uiState from '../../modules/ui-state';
import { Layout } from '../layout/Layout';
import { Text } from '../text/Text';

import checkedImg from './assets/checked.svg';
import indeterminateImg from './assets/indeterminate.svg';
import { CheckboxBgIcon } from './CheckboxBgIcon';

// Module
import './Checkbox.less';

export type CheckboxProps = Omit<
  React.ComponentProps<'input'>,
  'onChange' | 'checked' | 'disabled'
> & {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  /** `indeterminate` has precedence over`checked` */
  indeterminate?: boolean;
  labelClassName?: string;
  name?: string;
  title?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, params: CheckboxEventParams) => void;
  error?: string;
  span?: layout.Span;
};

export type CheckboxEventParams = {
  checked: boolean;
  name?: string;
  role?: string;
  value?: string;
};

const block = bemBlock('n-Checkbox');

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  children,
  className,
  labelClassName,
  disabled = false,
  indeterminate = false,
  name,
  role,
  title,
  value = 'on',
  onChange,
  error,
  span = 'auto',
  ...passProps
}) => {
  const labelContent = children ? (
    <Text className={labelClassName} color="text" size="sm">
      {children}
    </Text>
  ) : null;

  const stateIcon = getStateIcon(checked, indeterminate);

  const changeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event, {
          checked: !checked,
          name,
          value,
        });
      }
    },
    [name, value, checked, onChange],
  );

  const hasError = !!error;

  return (
    <Layout.Column spacedChildren="sm" span={span}>
      {hasError && (
        <Text color="error" size="sm">
          {error}
        </Text>
      )}
      <Layout.Row
        component="label"
        alignItems="center"
        className={block({
          extra: [className, uiState.disabledClassName(disabled)],
        })}
        title={title}
        data-role={role}
        spacedChildren="sm"
      >
        <input
          className={block('native-input')}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          name={name}
          value={value}
          onChange={changeHandler}
          onClick={stopPropagation}
          {...passProps}
        />
        <span className={block('icon-container')}>
          <CheckboxBgIcon
            disabled={disabled}
            checked={checked}
            indeterminate={indeterminate}
            hasError={hasError}
          />
          {stateIcon}
        </span>
        {labelContent}
      </Layout.Row>
    </Layout.Column>
  );
};

function getStateIcon(checked: boolean, indeterminate: boolean) {
  if (indeterminate) {
    return <img src={indeterminateImg} className={block('icon')} alt="indeterminate icon" />;
  }

  if (checked) {
    return <img src={checkedImg} className={block('icon')} alt="checked icon" />;
  }

  return null;
}

function stopPropagation(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
  event.stopPropagation();
}
