/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';
import type { LeaderboardSortParamsDTO } from './LeaderboardSortParamsDTO';
import {
    LeaderboardSortParamsDTOFromJSON,
    LeaderboardSortParamsDTOFromJSONTyped,
    LeaderboardSortParamsDTOToJSON,
} from './LeaderboardSortParamsDTO';

/**
 * 
 * @export
 * @interface LeaderboardCsvExportParamsDTO
 */
export interface LeaderboardCsvExportParamsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    exportFieldAggregations: Array<LeaderboardCsvExportParamsDTOExportFieldAggregationsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    exportFieldNames: Array<string>;
    /**
     * 
     * @type {Array<AttributeTypeDTO>}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    exportFieldTypes: Array<AttributeTypeDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    exportFields: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    query?: string;
    /**
     * 
     * @type {LeaderboardSortParamsDTO}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    sorting?: LeaderboardSortParamsDTO;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    zoneOffset: number;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    zoneRegion?: string;
}


/**
 * @export
 */
export const LeaderboardCsvExportParamsDTOExportFieldAggregationsEnum = {
    Last: 'last',
    Min: 'min',
    Max: 'max',
    Average: 'average',
    Variance: 'variance',
    Auto: 'auto'
} as const;
export type LeaderboardCsvExportParamsDTOExportFieldAggregationsEnum = typeof LeaderboardCsvExportParamsDTOExportFieldAggregationsEnum[keyof typeof LeaderboardCsvExportParamsDTOExportFieldAggregationsEnum];


/**
 * Check if a given object implements the LeaderboardCsvExportParamsDTO interface.
 */
export function instanceOfLeaderboardCsvExportParamsDTO(value: object): value is LeaderboardCsvExportParamsDTO {
    if (!('exportFieldAggregations' in value) || value['exportFieldAggregations'] === undefined) return false;
    if (!('exportFieldNames' in value) || value['exportFieldNames'] === undefined) return false;
    if (!('exportFieldTypes' in value) || value['exportFieldTypes'] === undefined) return false;
    if (!('exportFields' in value) || value['exportFields'] === undefined) return false;
    if (!('zoneOffset' in value) || value['zoneOffset'] === undefined) return false;
    return true;
}

export function LeaderboardCsvExportParamsDTOFromJSON(json: any): LeaderboardCsvExportParamsDTO {
    return LeaderboardCsvExportParamsDTOFromJSONTyped(json, false);
}

export function LeaderboardCsvExportParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardCsvExportParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'exportFieldAggregations': json['exportFieldAggregations'],
        'exportFieldNames': json['exportFieldNames'],
        'exportFieldTypes': ((json['exportFieldTypes'] as Array<any>).map(AttributeTypeDTOFromJSON)),
        'exportFields': json['exportFields'],
        'query': json['query'] == null ? undefined : json['query'],
        'sorting': json['sorting'] == null ? undefined : LeaderboardSortParamsDTOFromJSON(json['sorting']),
        'zoneOffset': json['zoneOffset'],
        'zoneRegion': json['zoneRegion'] == null ? undefined : json['zoneRegion'],
    };
}

export function LeaderboardCsvExportParamsDTOToJSON(value?: LeaderboardCsvExportParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'exportFieldAggregations': value['exportFieldAggregations'],
        'exportFieldNames': value['exportFieldNames'],
        'exportFieldTypes': ((value['exportFieldTypes'] as Array<any>).map(AttributeTypeDTOToJSON)),
        'exportFields': value['exportFields'],
        'query': value['query'],
        'sorting': LeaderboardSortParamsDTOToJSON(value['sorting']),
        'zoneOffset': value['zoneOffset'],
        'zoneRegion': value['zoneRegion'],
    };
}

