/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDashboardVersionDTO
 */
export interface UpdateDashboardVersionDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDashboardVersionDTO
     */
    draft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardVersionDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardVersionDTO
     */
    versionName?: string;
}

/**
 * Check if a given object implements the UpdateDashboardVersionDTO interface.
 */
export function instanceOfUpdateDashboardVersionDTO(value: object): value is UpdateDashboardVersionDTO {
    return true;
}

export function UpdateDashboardVersionDTOFromJSON(json: any): UpdateDashboardVersionDTO {
    return UpdateDashboardVersionDTOFromJSONTyped(json, false);
}

export function UpdateDashboardVersionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDashboardVersionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'draft': json['draft'] == null ? undefined : json['draft'],
        'versionDescription': json['versionDescription'] == null ? undefined : json['versionDescription'],
        'versionName': json['versionName'] == null ? undefined : json['versionName'],
    };
}

export function UpdateDashboardVersionDTOToJSON(value?: UpdateDashboardVersionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'draft': value['draft'],
        'versionDescription': value['versionDescription'],
        'versionName': value['versionName'],
    };
}

