// Libs
import React from 'react';

import { bemBlock, Layout } from '@neptune/shared/venus-ui';

import { KeycloakAsideTitle } from 'components/keycloak/KeycloakAsideTitle';

// Module
import { KeycloakAsideText } from './KeycloakAsideText';

import './RegistrationAside.less';

const block = bemBlock('registration-aside');

export const RegistrationAside = () => {
  return (
    <Layout.Column
      className={block()}
      component="section"
      justifyContent="center"
      alignItems="center"
    >
      <KeycloakAsideTitle>Manage all your ML model metadata in a single place</KeycloakAsideTitle>
      <KeycloakAsideText>
        <span className={block('avoid-break')}>Join over 40k ML Engineers and Data Scientists</span>
        <span className={block('avoid-break')}>
          using Neptune to track experiments and version{' '}
          <span className={block('avoid-break')}>models.</span>
        </span>
      </KeycloakAsideText>
    </Layout.Column>
  );
};
