import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Button, ButtonVariant } from '../button/Button';
import { Icon } from '../icon/Icon';
import { InputWithSuffix } from '../input-with-suffix/InputWithSuffix';
import { Layout } from '../layout/Layout';
import { SimpleTooltip } from '../tooltip/SimpleTooltip';

import './StepperInput.less';

export type StepperInputProps = {
  className?: string;
  'data-role'?: string;
  disabled?: boolean;
  disabledDecreaseButton?: boolean;
  disabledDecreaseButtonReason?: string;
  disabledIncreaseButton?: boolean;
  disabledIncreaseButtonReason?: string;
  error?: boolean;
  formatValue?(value: number): string;
  min?: number;
  max?: number;
  onBlur?(): void;
  onChange(value?: number): void;
  onFocus?(): void;
  step?: number;
  suffix?: string;
  value?: number;
  size?: 'lg' | 'md';
  onClick?: (e: React.MouseEvent) => void;
  buttonVariant?: ButtonVariant;
};

const block = bemBlock('n-stepper-input');

export const StepperInput: React.FC<StepperInputProps> = ({
  className,
  'data-role': dataRole,
  disabled = false,
  disabledDecreaseButton = false,
  disabledDecreaseButtonReason,
  disabledIncreaseButton = false,
  disabledIncreaseButtonReason,
  error = false,
  min = -Infinity,
  max = Infinity,
  onBlur,
  onChange,
  onFocus,
  step = 1,
  suffix,
  value,
  size = 'lg',
  onClick,
  buttonVariant = 'secondary',
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [innerValue, setInnerValue] = React.useState<string | undefined>();

  React.useEffect(() => {
    setInnerValue(value != null ? String(value) : value);
  }, [value]);

  const changeValue = React.useCallback(
    (val: number | undefined) => {
      if (val === undefined) {
        onChange(undefined);
        setInnerValue(undefined);
        return;
      }

      const newValue = Math.max(min, Math.min(max, val));
      setInnerValue(String(newValue));
      onChange(newValue);
    },
    [onChange, max, min],
  );

  const handleBlur = React.useCallback(() => {
    setIsFocused(false);
    changeValue(parseFromString(innerValue));
    onBlur?.();
  }, [innerValue, onBlur, changeValue]);

  const handleFocus = React.useCallback(() => {
    setIsFocused(true);
    onFocus?.();
  }, [onFocus]);

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(event.currentTarget.value);
  }, []);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        changeValue(parseFromString(innerValue));
      }

      if (event.key === 'Escape') {
        setInnerValue(value != null ? String(value) : value);
      }
    },
    [changeValue, innerValue, value],
  );

  const decreaseValue = React.useCallback(
    (e) => {
      onClick?.(e);
      const initialValue = parseFromString(innerValue) || 0;
      changeValue(initialValue - (initialValue % step ? initialValue % step : step));
    },
    [changeValue, innerValue, onClick, step],
  );

  const increaseValue = React.useCallback(
    (e) => {
      onClick?.(e);
      const initialValue = parseFromString(innerValue) || 0;
      changeValue(initialValue + (step - (initialValue % step)));
    },
    [changeValue, innerValue, onClick, step],
  );

  const suffixElement =
    suffix != null ? (
      <Layout.Element
        data-role="stepper-sufix"
        className={block({ element: 'suffix', modifiers: { size } })}
        span="shrink"
      >
        {suffix}
      </Layout.Element>
    ) : undefined;

  return (
    <Layout.Row
      className={block({
        extra: className,
        modifiers: {
          error,
          focused: isFocused,
          size,
        },
      })}
      data-role={dataRole}
      alignItems="center"
      spacedChildren={size === 'lg' ? 'sm' : 'xs'}
    >
      <SimpleTooltip content={disabledDecreaseButton ? disabledDecreaseButtonReason : undefined}>
        <Button
          className={block('button')}
          disabled={disabledDecreaseButton || (value != null && value <= min) || disabled}
          data-role="top-up-decrease"
          onClick={decreaseValue}
          variant={buttonVariant}
          size={size}
          square
        >
          <Icon glyph="minus" />
        </Button>
      </SimpleTooltip>
      <Layout.Row className={block({ element: 'input-wrapper', modifiers: { size } })}>
        <InputWithSuffix
          data-role="stepper-input"
          disabled={disabled}
          error={error}
          inputProps={{
            className: block({ element: 'input', modifiers: { size } }),
            type: 'text',
            onFocus: handleFocus,
            onBlur: handleBlur,
            value: innerValue ?? '',
            onChange: handleChange,
            onKeyDown: handleKeyDown,
          }}
          suffix={suffixElement}
          onClick={onClick}
          withoutOutline
        />
      </Layout.Row>
      <SimpleTooltip content={disabledIncreaseButton ? disabledIncreaseButtonReason : undefined}>
        <Button
          className={block({ element: 'button', modifiers: { size } })}
          disabled={disabledIncreaseButton || (value != null && value >= max) || disabled}
          data-role="top-up-increase"
          onClick={increaseValue}
          variant={buttonVariant}
          size={size}
          square
        >
          <Icon glyph="plus" />
        </Button>
      </SimpleTooltip>
    </Layout.Row>
  );
};

function parseFromString(value: string | undefined): number | undefined {
  if (value == null) {
    return undefined;
  }

  const parsed = Number(value);

  return isNaN(parsed) ? undefined : parsed;
}
