import React from 'react';

import { bemBlock } from '@neptune/shared/venus-ui';

import { MiddleEllipsis } from '../middle-ellipsis/ui/MiddleEllipsis';

import { diffStrings, Token } from './diff';

import './TextDiff.less';

const block = bemBlock('text-diff');

export const TextDiff: React.FC<{
  limit?: number;
  value: string;
  referenceValue: string;
}> = ({ limit = 200, value, referenceValue }) => {
  const skip = value === referenceValue || value.length > limit || referenceValue.length > 200;

  const tokens = React.useMemo(
    () => (skip ? ([] as Token[]) : diffStrings(value, referenceValue)),
    [skip, value, referenceValue],
  );

  if (skip) {
    return (
      <span className={block({ modifiers: { empty: !value } })}>
        <MiddleEllipsis text={value} />
      </span>
    );
  }

  return (
    <>
      {tokens.map((token: Token) => {
        if (typeof token === 'string') {
          return <span children={token} />;
        }

        const cssClass = block({
          modifiers: {
            deletion: token[1] === 'd',
            insertion: token[1] === 'i',
            substitution: token[1] === 's',
          },
        });

        return <span children={token[0]} className={cssClass} />;
      })}
    </>
  );
};
