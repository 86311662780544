/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewReservationDTO
 */
export interface NewReservationDTO {
    /**
     * 
     * @type {string}
     * @memberof NewReservationDTO
     */
    name: string;
}

/**
 * Check if a given object implements the NewReservationDTO interface.
 */
export function instanceOfNewReservationDTO(value: object): value is NewReservationDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function NewReservationDTOFromJSON(json: any): NewReservationDTO {
    return NewReservationDTOFromJSONTyped(json, false);
}

export function NewReservationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewReservationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function NewReservationDTOToJSON(value?: NewReservationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

