// Libs
import React from 'react';
import elementResizeDetectorMaker from 'element-resize-detector';
import { debounce } from 'lodash';

const resizeDetector = elementResizeDetectorMaker({
  strategy: 'scroll',
});

const DEBOUNCE_WAIT = 50;

export function useResizeDetector<Element extends HTMLElement>(): {
  ref: React.Ref<Element>;
  element: Element | null;
  width?: number;
  height?: number;
} {
  const [width, setWidth] = React.useState<number | undefined>();
  const [height, setHeight] = React.useState<number | undefined>();
  const ref = React.useRef<Element | null>(null);

  const innerRef: React.Ref<Element> = React.useCallback((node: Element | null) => {
    if (ref.current) {
      resizeDetector.uninstall(ref.current);
    }

    if (node) {
      const onResize = debounce((node: HTMLElement) => {
        setWidth(node.offsetWidth);
        setHeight(node.offsetHeight);
      }, DEBOUNCE_WAIT);

      resizeDetector.listenTo(node, onResize);
    }

    ref.current = node;
  }, []);

  return {
    ref: innerRef,
    element: ref.current,
    width,
    height,
  };
}
