/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaderboardFieldDTO } from './LeaderboardFieldDTO';
import {
    LeaderboardFieldDTOFromJSON,
    LeaderboardFieldDTOFromJSONTyped,
    LeaderboardFieldDTOToJSON,
} from './LeaderboardFieldDTO';

/**
 * 
 * @export
 * @interface LeaderboardFieldWithValueDTO
 */
export interface LeaderboardFieldWithValueDTO {
    /**
     * 
     * @type {LeaderboardFieldDTO}
     * @memberof LeaderboardFieldWithValueDTO
     */
    field: LeaderboardFieldDTO;
    /**
     * 
     * @type {object}
     * @memberof LeaderboardFieldWithValueDTO
     */
    value?: object;
}

/**
 * Check if a given object implements the LeaderboardFieldWithValueDTO interface.
 */
export function instanceOfLeaderboardFieldWithValueDTO(value: object): value is LeaderboardFieldWithValueDTO {
    if (!('field' in value) || value['field'] === undefined) return false;
    return true;
}

export function LeaderboardFieldWithValueDTOFromJSON(json: any): LeaderboardFieldWithValueDTO {
    return LeaderboardFieldWithValueDTOFromJSONTyped(json, false);
}

export function LeaderboardFieldWithValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardFieldWithValueDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'field': LeaderboardFieldDTOFromJSON(json['field']),
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function LeaderboardFieldWithValueDTOToJSON(value?: LeaderboardFieldWithValueDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': LeaderboardFieldDTOToJSON(value['field']),
        'value': value['value'],
    };
}

