/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeDTO } from './AttributeDTO';
import {
    AttributeDTOFromJSON,
    AttributeDTOFromJSONTyped,
    AttributeDTOToJSON,
} from './AttributeDTO';
import type { SystemAttributesDTO } from './SystemAttributesDTO';
import {
    SystemAttributesDTOFromJSON,
    SystemAttributesDTOFromJSONTyped,
    SystemAttributesDTOToJSON,
} from './SystemAttributesDTO';

/**
 * 
 * @export
 * @interface ExperimentAttributesDTO
 */
export interface ExperimentAttributesDTO {
    /**
     * 
     * @type {Array<AttributeDTO>}
     * @memberof ExperimentAttributesDTO
     */
    attributes: Array<AttributeDTO>;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    projectName: string;
    /**
     * 
     * @type {SystemAttributesDTO}
     * @memberof ExperimentAttributesDTO
     */
    systemAttributes: SystemAttributesDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ExperimentAttributesDTO
     */
    trashed: boolean;
}

/**
 * Check if a given object implements the ExperimentAttributesDTO interface.
 */
export function instanceOfExperimentAttributesDTO(value: object): value is ExperimentAttributesDTO {
    if (!('attributes' in value) || value['attributes'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('systemAttributes' in value) || value['systemAttributes'] === undefined) return false;
    if (!('trashed' in value) || value['trashed'] === undefined) return false;
    return true;
}

export function ExperimentAttributesDTOFromJSON(json: any): ExperimentAttributesDTO {
    return ExperimentAttributesDTOFromJSONTyped(json, false);
}

export function ExperimentAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentAttributesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AttributeDTOFromJSON)),
        'id': json['id'],
        'organizationId': json['organizationId'],
        'organizationName': json['organizationName'],
        'projectId': json['projectId'],
        'projectName': json['projectName'],
        'systemAttributes': SystemAttributesDTOFromJSON(json['systemAttributes']),
        'trashed': json['trashed'],
    };
}

export function ExperimentAttributesDTOToJSON(value?: ExperimentAttributesDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributes': ((value['attributes'] as Array<any>).map(AttributeDTOToJSON)),
        'id': value['id'],
        'organizationId': value['organizationId'],
        'organizationName': value['organizationName'],
        'projectId': value['projectId'],
        'projectName': value['projectName'],
        'systemAttributes': SystemAttributesDTOToJSON(value['systemAttributes']),
        'trashed': value['trashed'],
    };
}

