import React from 'react';

import { bemBlock, joinClassNames } from '@neptune/shared/venus-ui';

import { fetchStatus as FetchStatus, isFetchStatusPending } from 'state/fetch-status';

import './LoadingIndicator.less';

export type LoadingIndicatorSize = 'big' | 'medium' | 'small' | 'xsmall';
export type LoadingIndicatorTheme = 'white' | 'dark';

type LoadingIndicatorProps = {
  className?: string;
  centered?: boolean;
  isVisible?: boolean;
  fetchStatus?: FetchStatus;
  size?: LoadingIndicatorSize;
  theme?: LoadingIndicatorTheme;
};

const block = bemBlock('loading-indicator');

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  className,
  isVisible,
  centered,
  fetchStatus,
  size = 'big',
  theme = 'dark',
}) => {
  const visible = (fetchStatus && isFetchStatusPending(fetchStatus)) || isVisible;

  const cssClasses = block({
    extra: joinClassNames(
      className,
      block({ modifiers: theme }),
      block({ modifiers: size }),
      visible ? block({ modifiers: 'visible' }) : null,
      centered ? block({ modifiers: 'centered' }) : null,
    ),
  });

  return <div className={cssClasses} data-role="loading-indicator" data-visible={visible} />;
};

export default LoadingIndicator;
