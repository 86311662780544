/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientVersionsConfigDTO } from './ClientVersionsConfigDTO';
import {
    ClientVersionsConfigDTOFromJSON,
    ClientVersionsConfigDTOFromJSONTyped,
    ClientVersionsConfigDTOToJSON,
} from './ClientVersionsConfigDTO';
import type { SecurityDTO } from './SecurityDTO';
import {
    SecurityDTOFromJSON,
    SecurityDTOFromJSONTyped,
    SecurityDTOToJSON,
} from './SecurityDTO';

/**
 * 
 * @export
 * @interface ClientConfig
 */
export interface ClientConfig {
    /**
     * 
     * @type {string}
     * @memberof ClientConfig
     */
    apiUrl: string;
    /**
     * 
     * @type {ClientVersionsConfigDTO}
     * @memberof ClientConfig
     */
    pyLibVersions: ClientVersionsConfigDTO;
    /**
     * 
     * @type {SecurityDTO}
     * @memberof ClientConfig
     */
    security: SecurityDTO;
    /**
     * 
     * @type {string}
     * @memberof ClientConfig
     */
    applicationUrl?: string;
}

/**
 * Check if a given object implements the ClientConfig interface.
 */
export function instanceOfClientConfig(value: object): value is ClientConfig {
    if (!('apiUrl' in value) || value['apiUrl'] === undefined) return false;
    if (!('pyLibVersions' in value) || value['pyLibVersions'] === undefined) return false;
    if (!('security' in value) || value['security'] === undefined) return false;
    return true;
}

export function ClientConfigFromJSON(json: any): ClientConfig {
    return ClientConfigFromJSONTyped(json, false);
}

export function ClientConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'apiUrl': json['apiUrl'],
        'pyLibVersions': ClientVersionsConfigDTOFromJSON(json['pyLibVersions']),
        'security': SecurityDTOFromJSON(json['security']),
        'applicationUrl': json['applicationUrl'] == null ? undefined : json['applicationUrl'],
    };
}

export function ClientConfigToJSON(value?: ClientConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'apiUrl': value['apiUrl'],
        'pyLibVersions': ClientVersionsConfigDTOToJSON(value['pyLibVersions']),
        'security': SecurityDTOToJSON(value['security']),
        'applicationUrl': value['applicationUrl'],
    };
}

