/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectTraitsSetDTO } from './ProjectTraitsSetDTO';
import {
    ProjectTraitsSetDTOFromJSON,
    ProjectTraitsSetDTOFromJSONTyped,
    ProjectTraitsSetDTOToJSON,
} from './ProjectTraitsSetDTO';
import type { ProjectVisibilityDTO } from './ProjectVisibilityDTO';
import {
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './ProjectVisibilityDTO';
import type { OrganizationTypeDTO } from './OrganizationTypeDTO';
import {
    OrganizationTypeDTOFromJSON,
    OrganizationTypeDTOFromJSONTyped,
    OrganizationTypeDTOToJSON,
} from './OrganizationTypeDTO';

/**
 * 
 * @export
 * @interface ProjectMetadataDTO
 */
export interface ProjectMetadataDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectMetadataDTO
     */
    archived: boolean;
    /**
     * 
     * @type {OrganizationTypeDTO}
     * @memberof ProjectMetadataDTO
     */
    organizationType: OrganizationTypeDTO;
    /**
     * 
     * @type {Date}
     * @memberof ProjectMetadataDTO
     */
    timeOfCreation: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    organizationName: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectMetadataDTO
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    projectKey: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    organizationId: string;
    /**
     * 
     * @type {ProjectTraitsSetDTO}
     * @memberof ProjectMetadataDTO
     */
    traits: ProjectTraitsSetDTO;
    /**
     * 
     * @type {number}
     * @memberof ProjectMetadataDTO
     */
    storageUsage: number;
    /**
     * 
     * @type {ProjectVisibilityDTO}
     * @memberof ProjectMetadataDTO
     */
    visibility: ProjectVisibilityDTO;
}



/**
 * Check if a given object implements the ProjectMetadataDTO interface.
 */
export function instanceOfProjectMetadataDTO(value: object): value is ProjectMetadataDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('archived' in value) || value['archived'] === undefined) return false;
    if (!('organizationType' in value) || value['organizationType'] === undefined) return false;
    if (!('timeOfCreation' in value) || value['timeOfCreation'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('projectKey' in value) || value['projectKey'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('traits' in value) || value['traits'] === undefined) return false;
    if (!('storageUsage' in value) || value['storageUsage'] === undefined) return false;
    if (!('visibility' in value) || value['visibility'] === undefined) return false;
    return true;
}

export function ProjectMetadataDTOFromJSON(json: any): ProjectMetadataDTO {
    return ProjectMetadataDTOFromJSONTyped(json, false);
}

export function ProjectMetadataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectMetadataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'archived': json['archived'],
        'organizationType': OrganizationTypeDTOFromJSON(json['organizationType']),
        'timeOfCreation': (new Date(json['timeOfCreation'])),
        'organizationName': json['organizationName'],
        'version': json['version'],
        'id': json['id'],
        'projectKey': json['projectKey'],
        'organizationId': json['organizationId'],
        'traits': ProjectTraitsSetDTOFromJSON(json['traits']),
        'storageUsage': json['storageUsage'],
        'visibility': ProjectVisibilityDTOFromJSON(json['visibility']),
    };
}

export function ProjectMetadataDTOToJSON(value?: ProjectMetadataDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'archived': value['archived'],
        'organizationType': OrganizationTypeDTOToJSON(value['organizationType']),
        'timeOfCreation': ((value['timeOfCreation']).toISOString()),
        'organizationName': value['organizationName'],
        'version': value['version'],
        'id': value['id'],
        'projectKey': value['projectKey'],
        'organizationId': value['organizationId'],
        'traits': ProjectTraitsSetDTOToJSON(value['traits']),
        'storageUsage': value['storageUsage'],
        'visibility': ProjectVisibilityDTOToJSON(value['visibility']),
    };
}

