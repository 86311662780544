/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CheckpointDTO } from './CheckpointDTO';
import {
    CheckpointDTOFromJSON,
    CheckpointDTOFromJSONTyped,
    CheckpointDTOToJSON,
} from './CheckpointDTO';

/**
 * 
 * @export
 * @interface CheckpointListDTO
 */
export interface CheckpointListDTO {
    /**
     * The entries matching the given criteria.
     * @type {Array<CheckpointDTO>}
     * @memberof CheckpointListDTO
     */
    entries: Array<CheckpointDTO>;
    /**
     * The total number of entries matching the given criteria.
     * @type {number}
     * @memberof CheckpointListDTO
     */
    matchingItemCount: number;
    /**
     * The total number of entries.
     * @type {number}
     * @memberof CheckpointListDTO
     */
    totalItemCount: number;
}

/**
 * Check if a given object implements the CheckpointListDTO interface.
 */
export function instanceOfCheckpointListDTO(value: object): value is CheckpointListDTO {
    if (!('entries' in value) || value['entries'] === undefined) return false;
    if (!('matchingItemCount' in value) || value['matchingItemCount'] === undefined) return false;
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    return true;
}

export function CheckpointListDTOFromJSON(json: any): CheckpointListDTO {
    return CheckpointListDTOFromJSONTyped(json, false);
}

export function CheckpointListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckpointListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(CheckpointDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'totalItemCount': json['totalItemCount'],
    };
}

export function CheckpointListDTOToJSON(value?: CheckpointListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entries': ((value['entries'] as Array<any>).map(CheckpointDTOToJSON)),
        'matchingItemCount': value['matchingItemCount'],
        'totalItemCount': value['totalItemCount'],
    };
}

