// Libs
import React from 'react';
import { clamp } from 'lodash';

import { bemBlock } from '@neptune/shared/venus-ui';

import './ProgressBar.less';
const block = bemBlock('progress-bar');

type ProgressBarProps = {
  ratio: number;
  size: 'xs' | 'sm' | 'md';
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ ratio = 0, size }) => {
  const full = ratio >= 1;

  const cssClass = block({
    modifiers: {
      full,
      size,
    },
  });

  // Always display minimal bar when ratio non-zero.
  const width = ratio > 0 ? clamp(Math.round(ratio * 100), 1, 100) : 0;

  return (
    <div className={cssClass}>
      {!full && <div className={block('blue-bar')} style={{ width: `${width}%` }} />}
    </div>
  );
};
