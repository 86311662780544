import React from 'react';

import { bemBlock } from '../../../modules/bem';
import { Icon } from '../../icon/Icon';
import { LayoutRow } from '../../layout-row/LayoutRow';
import { Text } from '../../text/Text';

import { ItemOwnProps } from './item-props';

import './ActionsMenuSubMenuItem.less';

const block = bemBlock('n-actions-menu-sub-menu-item');

export const ActionsMenuSubMenuItem = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentProps<'div'> & Omit<ItemOwnProps, 'trailingElement'>
>(({ className, children, leadingIcon, active, destructive, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={block({
        extra: className,
        modifiers: { active, destructive },
      })}
      {...props}
    >
      <LayoutRow span="auto" alignItems="center" spacedChildren="sm">
        {leadingIcon}
        <Text size="sm">{children}</Text>
      </LayoutRow>
      <Icon glyph="chevron-right" size="xs" />
    </div>
  );
});
