import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV8, SearchQuery } from './local-storage-v8';
import { upgrade as upgradeFromPrevious } from './local-storage-v8.upgrade';
import { LocalStorageV9 } from './local-storage-v9';

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV9> {
  const input = isLocalStorageV8(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const orderedColumnEntries = Object.entries(data.orderedColumns ?? {});

  // region migration: Remove all columns of type 'artifact'
  const orderedColumnsWithoutArtifactColumns: LocalStorageV9['data']['orderedColumns'] =
    orderedColumnEntries.reduce((acc, [key, columnsSet]) => {
      const columnsSetEntries = Object.entries(columnsSet ?? {});
      const columnsSetWithoutArtifactColumns = columnsSetEntries.reduce((acc, [key, columns]) => {
        const columnsWithoutArtifactColumns = columns.filter(
          (column) => column.columnType !== 'artifact',
        );
        return {
          ...acc,
          [key]: columnsWithoutArtifactColumns,
        };
      }, {});

      return {
        ...acc,
        [key]: columnsSetWithoutArtifactColumns,
      };
    }, {});
  // endregion

  // region migration: Remove queries containing criterion of type 'artifact'
  const recentSearchQueriesEntries = Object.entries(data.recentSearchQueries ?? {});
  const recentSearchQueriesWithoutRemovedFields: LocalStorageV9['data']['recentSearchQueries'] =
    recentSearchQueriesEntries.reduce((acc, [key, searchQueriesSet]) => {
      const searchQueriesSetEntries: [string, SearchQuery[] | undefined][] = Object.entries(
        searchQueriesSet ?? {},
      );
      const searchQueriesSetWithoutRemovedFields = searchQueriesSetEntries.reduce(
        (acc, [key, searchQueries = []]) => {
          const searchQueriesWithoutRemovedFields = searchQueries.filter(
            (query) => !hasArtifactCriterion(query),
          );

          return {
            ...acc,
            [key]: searchQueriesWithoutRemovedFields,
          };
        },
        {},
      );

      return {
        ...acc,
        [key]: searchQueriesSetWithoutRemovedFields,
      };
    }, {});
  // endregion

  const dataWithoutRemovedFields: LocalStorageV9['data'] = {
    ...data,
    orderedColumns: orderedColumnsWithoutArtifactColumns,
    recentSearchQueries: recentSearchQueriesWithoutRemovedFields,
  };

  return {
    version: 9,
    data: dataWithoutRemovedFields,
  };
}

function hasArtifactCriterion(searchQuery: SearchQuery): boolean {
  return searchQuery.criteria.some((criterion) => {
    if ('type' in criterion) {
      return criterion.type === 'artifact';
    }

    return hasArtifactCriterion(criterion);
  });
}
