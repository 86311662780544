import React from 'react';

import { RouterStateParams } from '@neptune/shared/routing-domain';

import { DistractionFreeButton } from './DistractionFreeButton';

type DistractionFreeToggleParams<T extends RouterStateParams = RouterStateParams> = {
  routeName: string;
  routeParams: T;
  active?: boolean;
};

export function DistractionFreeToggle<T extends RouterStateParams = RouterStateParams>({
  routeName,
  routeParams,
  active = false,
}: DistractionFreeToggleParams<T>) {
  if (active) {
    return (
      <DistractionFreeButton
        routeName={routeName}
        routeParams={routeParams}
        data-role="leave-distraction-free-view"
        icon="compress"
        tooltipContent="Leave distraction free view"
      />
    );
  }

  return (
    <DistractionFreeButton
      routeName={routeName}
      routeParams={routeParams}
      data-role="enter-distraction-free-view"
      icon="expand"
      tooltipContent="Enter distraction free view"
    />
  );
}
