/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectUsageDTO
 */
export interface ProjectUsageDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectUsageDTO
     */
    projectName: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUsageDTO
     */
    storageUsed: number;
    /**
     * 
     * @type {Date}
     * @memberof ProjectUsageDTO
     */
    deleted?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectUsageDTO
     */
    projectId: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUsageDTO
     */
    last30DaysSeconds: number;
}

/**
 * Check if a given object implements the ProjectUsageDTO interface.
 */
export function instanceOfProjectUsageDTO(value: object): value is ProjectUsageDTO {
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('storageUsed' in value) || value['storageUsed'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('last30DaysSeconds' in value) || value['last30DaysSeconds'] === undefined) return false;
    return true;
}

export function ProjectUsageDTOFromJSON(json: any): ProjectUsageDTO {
    return ProjectUsageDTOFromJSONTyped(json, false);
}

export function ProjectUsageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectUsageDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'projectName': json['projectName'],
        'storageUsed': json['storageUsed'],
        'deleted': json['deleted'] == null ? undefined : (new Date(json['deleted'])),
        'projectId': json['projectId'],
        'last30DaysSeconds': json['last30DaysSeconds'],
    };
}

export function ProjectUsageDTOToJSON(value?: ProjectUsageDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectName': value['projectName'],
        'storageUsed': value['storageUsed'],
        'deleted': value['deleted'] == null ? undefined : ((value['deleted']).toISOString()),
        'projectId': value['projectId'],
        'last30DaysSeconds': value['last30DaysSeconds'],
    };
}

