// Libs
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { bemBlock } from '@neptune/shared/venus-ui';

// Module
import renderers from './renderers';

import 'github-markdown-css/github-markdown.css';
import './MarkdownRenderer.less';

export type MarkdownRendererProps = {
  className?: string;
  content: string;
};

const block = bemBlock('markdown-renderer');

export const MarkdownRenderer = ({ content, className }: MarkdownRendererProps) => {
  const githubCssClassName = 'markdown-body';

  return (
    <ReactMarkdown
      className={block({ extra: [githubCssClassName, className] })}
      components={renderers}
    >
      {content}
    </ReactMarkdown>
  );
};
