/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AliasParams
 */
export interface AliasParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof AliasParams
     */
    channels: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AliasParams
     */
    name: string;
}

/**
 * Check if a given object implements the AliasParams interface.
 */
export function instanceOfAliasParams(value: object): value is AliasParams {
    if (!('channels' in value) || value['channels'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function AliasParamsFromJSON(json: any): AliasParams {
    return AliasParamsFromJSONTyped(json, false);
}

export function AliasParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AliasParams {
    if (json == null) {
        return json;
    }
    return {
        
        'channels': json['channels'],
        'name': json['name'],
    };
}

export function AliasParamsToJSON(value?: AliasParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'channels': value['channels'],
        'name': value['name'],
    };
}

