/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorsDTO } from './ColorsDTO';
import {
    ColorsDTOFromJSON,
    ColorsDTOFromJSONTyped,
    ColorsDTOToJSON,
} from './ColorsDTO';

/**
 * 
 * @export
 * @interface ColorsConfigDTO
 */
export interface ColorsConfigDTO {
    /**
     * 
     * @type {Array<ColorsDTO>}
     * @memberof ColorsConfigDTO
     */
    colors?: Array<ColorsDTO>;
}

/**
 * Check if a given object implements the ColorsConfigDTO interface.
 */
export function instanceOfColorsConfigDTO(value: object): value is ColorsConfigDTO {
    return true;
}

export function ColorsConfigDTOFromJSON(json: any): ColorsConfigDTO {
    return ColorsConfigDTOFromJSONTyped(json, false);
}

export function ColorsConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorsConfigDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'colors': json['colors'] == null ? undefined : ((json['colors'] as Array<any>).map(ColorsDTOFromJSON)),
    };
}

export function ColorsConfigDTOToJSON(value?: ColorsConfigDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'colors': value['colors'] == null ? undefined : ((value['colors'] as Array<any>).map(ColorsDTOToJSON)),
    };
}

