/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeDurationDTO
 */
export interface TimeDurationDTO {
    /**
     * 
     * @type {number}
     * @memberof TimeDurationDTO
     */
    days: number;
    /**
     * 
     * @type {number}
     * @memberof TimeDurationDTO
     */
    hours: number;
    /**
     * 
     * @type {number}
     * @memberof TimeDurationDTO
     */
    minutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimeDurationDTO
     */
    months: number;
    /**
     * 
     * @type {number}
     * @memberof TimeDurationDTO
     */
    seconds: number;
    /**
     * 
     * @type {number}
     * @memberof TimeDurationDTO
     */
    weeks: number;
    /**
     * 
     * @type {number}
     * @memberof TimeDurationDTO
     */
    years: number;
}

/**
 * Check if a given object implements the TimeDurationDTO interface.
 */
export function instanceOfTimeDurationDTO(value: object): value is TimeDurationDTO {
    if (!('days' in value) || value['days'] === undefined) return false;
    if (!('hours' in value) || value['hours'] === undefined) return false;
    if (!('minutes' in value) || value['minutes'] === undefined) return false;
    if (!('months' in value) || value['months'] === undefined) return false;
    if (!('seconds' in value) || value['seconds'] === undefined) return false;
    if (!('weeks' in value) || value['weeks'] === undefined) return false;
    if (!('years' in value) || value['years'] === undefined) return false;
    return true;
}

export function TimeDurationDTOFromJSON(json: any): TimeDurationDTO {
    return TimeDurationDTOFromJSONTyped(json, false);
}

export function TimeDurationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeDurationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'days': json['days'],
        'hours': json['hours'],
        'minutes': json['minutes'],
        'months': json['months'],
        'seconds': json['seconds'],
        'weeks': json['weeks'],
        'years': json['years'],
    };
}

export function TimeDurationDTOToJSON(value?: TimeDurationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'days': value['days'],
        'hours': value['hours'],
        'minutes': value['minutes'],
        'months': value['months'],
        'seconds': value['seconds'],
        'weeks': value['weeks'],
        'years': value['years'],
    };
}

