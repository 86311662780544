/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PropertyDTO } from './PropertyDTO';
import {
    PropertyDTOFromJSON,
    PropertyDTOFromJSONTyped,
    PropertyDTOToJSON,
} from './PropertyDTO';

/**
 * 
 * @export
 * @interface PropertyListDTO
 */
export interface PropertyListDTO {
    /**
     * 
     * @type {Array<PropertyDTO>}
     * @memberof PropertyListDTO
     */
    properties: Array<PropertyDTO>;
}

/**
 * Check if a given object implements the PropertyListDTO interface.
 */
export function instanceOfPropertyListDTO(value: object): value is PropertyListDTO {
    if (!('properties' in value) || value['properties'] === undefined) return false;
    return true;
}

export function PropertyListDTOFromJSON(json: any): PropertyListDTO {
    return PropertyListDTOFromJSONTyped(json, false);
}

export function PropertyListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'properties': ((json['properties'] as Array<any>).map(PropertyDTOFromJSON)),
    };
}

export function PropertyListDTOToJSON(value?: PropertyListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'properties': ((value['properties'] as Array<any>).map(PropertyDTOToJSON)),
    };
}

