import React from 'react';

import { Card } from '../card/Card';
import { Layout } from '../layout/Layout';
import { SimpleTooltip } from '../tooltip/SimpleTooltip';

import ModalButton from './ModalButton';

export interface ModalFooterProps {
  className?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  basicButtonLabel?: string;
  primaryButtonRole?: string;
  secondaryButtonRole?: string;
  basicButtonRole?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  onBasicButtonClick?: () => void;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  basicButtonDisabled?: boolean;
  primaryButtonDisabledReason?: string;
  secondaryButtonDisabledReason?: string;
  basicButtonDisabledReason?: string;
}

const ModalFooterNew: React.FC<ModalFooterProps> = ({
  className,
  children,
  primaryButtonLabel = 'OK',
  secondaryButtonLabel = 'Cancel',
  basicButtonLabel = 'Reset',
  primaryButtonRole,
  secondaryButtonRole,
  basicButtonRole,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onBasicButtonClick,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  basicButtonDisabled,
  primaryButtonDisabledReason,
  secondaryButtonDisabledReason,
  basicButtonDisabledReason,
}) => {
  if (children) {
    return <Card.Footer className={className}>{children}</Card.Footer>;
  }

  const primaryButtonElement = onPrimaryButtonClick ? (
    <SimpleTooltip content={primaryButtonDisabled ? primaryButtonDisabledReason : undefined}>
      <ModalButton
        variant="primary"
        data-role={primaryButtonRole}
        onClick={onPrimaryButtonClick}
        disabled={primaryButtonDisabled}
      >
        {primaryButtonLabel}
      </ModalButton>
    </SimpleTooltip>
  ) : null;

  const secondaryButtonElement = onSecondaryButtonClick ? (
    <SimpleTooltip content={secondaryButtonDisabled ? secondaryButtonDisabledReason : undefined}>
      <ModalButton
        variant="secondary-text"
        data-role={secondaryButtonRole}
        onClick={onSecondaryButtonClick}
        disabled={secondaryButtonDisabled}
      >
        {secondaryButtonLabel}
      </ModalButton>
    </SimpleTooltip>
  ) : null;

  const basicButtonElement = onBasicButtonClick ? (
    <SimpleTooltip content={basicButtonDisabled ? basicButtonDisabledReason : undefined}>
      <ModalButton
        variant="basic"
        data-role={basicButtonRole}
        onClick={onBasicButtonClick}
        disabled={basicButtonDisabled}
      >
        {basicButtonLabel}
      </ModalButton>
    </SimpleTooltip>
  ) : null;

  return (
    <Card.Footer span="shrink" className={className}>
      <Layout.Row justifyContent="end" spacedChildren="md">
        <Layout.Row span="shrink">{secondaryButtonElement}</Layout.Row>
        <Layout.Row spacedChildren="md" span="shrink">
          {basicButtonElement}
          {primaryButtonElement}
        </Layout.Row>
      </Layout.Row>
    </Card.Footer>
  );
};

export default ModalFooterNew;
