// Libs
import React from 'react';

import { bemBlock, Text } from '@neptune/shared/venus-ui';

// Module
import './KeycloakAsideTitle.less';

type KeycloakAsideTitleProps = {
  className?: string;
};

const block = bemBlock('keycloak-aside-title');

export const KeycloakAsideTitle: React.FC<KeycloakAsideTitleProps> = ({ children, className }) => {
  return (
    <Text className={block({ extra: className })} component="h3" fontWeight="bold" size="xxl">
      {children}
    </Text>
  );
};
