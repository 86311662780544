/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';

/**
 * 
 * @export
 * @interface NewProjectServiceAccountDTO
 */
export interface NewProjectServiceAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof NewProjectServiceAccountDTO
     */
    serviceAccountId: string;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof NewProjectServiceAccountDTO
     */
    role: ProjectRoleDTO;
}



/**
 * Check if a given object implements the NewProjectServiceAccountDTO interface.
 */
export function instanceOfNewProjectServiceAccountDTO(value: object): value is NewProjectServiceAccountDTO {
    if (!('serviceAccountId' in value) || value['serviceAccountId'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function NewProjectServiceAccountDTOFromJSON(json: any): NewProjectServiceAccountDTO {
    return NewProjectServiceAccountDTOFromJSONTyped(json, false);
}

export function NewProjectServiceAccountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewProjectServiceAccountDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'serviceAccountId': json['serviceAccountId'],
        'role': ProjectRoleDTOFromJSON(json['role']),
    };
}

export function NewProjectServiceAccountDTOToJSON(value?: NewProjectServiceAccountDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'serviceAccountId': value['serviceAccountId'],
        'role': ProjectRoleDTOToJSON(value['role']),
    };
}

