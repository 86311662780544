// Libs
import React, { ImgHTMLAttributes } from 'react';

import { joinClassNames } from '../../modules/bem';

import logoImage from './assets/neptune-logo.svg';

// Module
import './AppHeaderLogo.less';

type AppHeaderLogoProps = ImgHTMLAttributes<HTMLImageElement>;

/**
 * Neptune logo image to be used in AppHeader
 *
 * ## Import
 *
 * ```
 * import { AppHeader } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<AppHeader.Logo />`.
 */
export const AppHeaderLogo: React.FC<AppHeaderLogoProps> = ({
  className,
  alt = '',
  ...passProps
}) => {
  return (
    <img
      className={joinClassNames('n-AppHeaderLogo', className)}
      src={logoImage}
      alt={alt}
      {...passProps}
    />
  );
};
