import { Range } from './SliderRange';
import { Root } from './SliderRoot';
import { Thumb } from './SliderThumb';
import { Track } from './SliderTrack';

export const Slider = Object.assign(Root, {
  Track,
  Range,
  Thumb,
});
