/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionDTO
 */
export interface SessionDTO {
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    sessionId: string;
}

/**
 * Check if a given object implements the SessionDTO interface.
 */
export function instanceOfSessionDTO(value: object): value is SessionDTO {
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    return true;
}

export function SessionDTOFromJSON(json: any): SessionDTO {
    return SessionDTOFromJSONTyped(json, false);
}

export function SessionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
    };
}

export function SessionDTOToJSON(value?: SessionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionId': value['sessionId'],
    };
}

