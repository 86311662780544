/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WidgetLayoutDTO } from './WidgetLayoutDTO';
import {
    WidgetLayoutDTOFromJSON,
    WidgetLayoutDTOFromJSONTyped,
    WidgetLayoutDTOToJSON,
} from './WidgetLayoutDTO';

/**
 * 
 * @export
 * @interface DashboardLayoutsDTO
 */
export interface DashboardLayoutsDTO {
    /**
     * 
     * @type {Array<WidgetLayoutDTO>}
     * @memberof DashboardLayoutsDTO
     */
    lg: Array<WidgetLayoutDTO>;
    /**
     * 
     * @type {Array<WidgetLayoutDTO>}
     * @memberof DashboardLayoutsDTO
     */
    md: Array<WidgetLayoutDTO>;
    /**
     * 
     * @type {Array<WidgetLayoutDTO>}
     * @memberof DashboardLayoutsDTO
     */
    sm: Array<WidgetLayoutDTO>;
    /**
     * 
     * @type {Array<WidgetLayoutDTO>}
     * @memberof DashboardLayoutsDTO
     */
    xl: Array<WidgetLayoutDTO>;
    /**
     * 
     * @type {Array<WidgetLayoutDTO>}
     * @memberof DashboardLayoutsDTO
     */
    xs: Array<WidgetLayoutDTO>;
}

/**
 * Check if a given object implements the DashboardLayoutsDTO interface.
 */
export function instanceOfDashboardLayoutsDTO(value: object): value is DashboardLayoutsDTO {
    if (!('lg' in value) || value['lg'] === undefined) return false;
    if (!('md' in value) || value['md'] === undefined) return false;
    if (!('sm' in value) || value['sm'] === undefined) return false;
    if (!('xl' in value) || value['xl'] === undefined) return false;
    if (!('xs' in value) || value['xs'] === undefined) return false;
    return true;
}

export function DashboardLayoutsDTOFromJSON(json: any): DashboardLayoutsDTO {
    return DashboardLayoutsDTOFromJSONTyped(json, false);
}

export function DashboardLayoutsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardLayoutsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'lg': ((json['lg'] as Array<any>).map(WidgetLayoutDTOFromJSON)),
        'md': ((json['md'] as Array<any>).map(WidgetLayoutDTOFromJSON)),
        'sm': ((json['sm'] as Array<any>).map(WidgetLayoutDTOFromJSON)),
        'xl': ((json['xl'] as Array<any>).map(WidgetLayoutDTOFromJSON)),
        'xs': ((json['xs'] as Array<any>).map(WidgetLayoutDTOFromJSON)),
    };
}

export function DashboardLayoutsDTOToJSON(value?: DashboardLayoutsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lg': ((value['lg'] as Array<any>).map(WidgetLayoutDTOToJSON)),
        'md': ((value['md'] as Array<any>).map(WidgetLayoutDTOToJSON)),
        'sm': ((value['sm'] as Array<any>).map(WidgetLayoutDTOToJSON)),
        'xl': ((value['xl'] as Array<any>).map(WidgetLayoutDTOToJSON)),
        'xs': ((value['xs'] as Array<any>).map(WidgetLayoutDTOToJSON)),
    };
}

