/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProjectVisibilityDTO = {
    Priv: 'priv',
    Pub: 'pub',
    Workspace: 'workspace'
} as const;
export type ProjectVisibilityDTO = typeof ProjectVisibilityDTO[keyof typeof ProjectVisibilityDTO];


export function instanceOfProjectVisibilityDTO(value: any): boolean {
    for (const key in ProjectVisibilityDTO) {
        if (Object.prototype.hasOwnProperty.call(ProjectVisibilityDTO, key)) {
            if (ProjectVisibilityDTO[key as keyof typeof ProjectVisibilityDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProjectVisibilityDTOFromJSON(json: any): ProjectVisibilityDTO {
    return ProjectVisibilityDTOFromJSONTyped(json, false);
}

export function ProjectVisibilityDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectVisibilityDTO {
    return json as ProjectVisibilityDTO;
}

export function ProjectVisibilityDTOToJSON(value?: ProjectVisibilityDTO | null): any {
    return value as any;
}

