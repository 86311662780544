import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { bemBlock } from '../../../modules/bem';
import { Icon } from '../../icon/Icon';
import { LayoutRow } from '../../layout-row/LayoutRow';
import { Text } from '../../text/Text';

import './ActionsMenuRadioItem.less';

const block = bemBlock('n-actions-menu-radio-item');

type RadioItemOwnProps = {
  leadingIcon?: React.ReactNode;
};

type RadioItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.RadioItem> &
  RadioItemOwnProps;

export const ActionsMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.RadioItem>,
  RadioItemProps
>(({ className, children, leadingIcon, disabled, ...props }, ref) => {
  return (
    <DropdownMenu.RadioItem
      ref={ref}
      className={block({
        extra: className,
      })}
      disabled={disabled}
      {...props}
    >
      <LayoutRow span="auto" alignItems="center" spacedChildren="sm">
        {leadingIcon}
        <Text size="sm">{children}</Text>
      </LayoutRow>
      <DropdownMenu.ItemIndicator>
        <Icon glyph="dot" />
      </DropdownMenu.ItemIndicator>
    </DropdownMenu.RadioItem>
  );
});
