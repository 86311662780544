/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';

/**
 * 
 * @export
 * @interface ProjectInvitationUpdateDTO
 */
export interface ProjectInvitationUpdateDTO {
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ProjectInvitationUpdateDTO
     */
    roleGrant: ProjectRoleDTO;
}



/**
 * Check if a given object implements the ProjectInvitationUpdateDTO interface.
 */
export function instanceOfProjectInvitationUpdateDTO(value: object): value is ProjectInvitationUpdateDTO {
    if (!('roleGrant' in value) || value['roleGrant'] === undefined) return false;
    return true;
}

export function ProjectInvitationUpdateDTOFromJSON(json: any): ProjectInvitationUpdateDTO {
    return ProjectInvitationUpdateDTOFromJSONTyped(json, false);
}

export function ProjectInvitationUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInvitationUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'roleGrant': ProjectRoleDTOFromJSON(json['roleGrant']),
    };
}

export function ProjectInvitationUpdateDTOToJSON(value?: ProjectInvitationUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'roleGrant': ProjectRoleDTOToJSON(value['roleGrant']),
    };
}

