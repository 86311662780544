import React from 'react';
import { TriggerProps, useHover, UseHoverOptions, useLayer, UseLayerOptions } from 'react-laag';
import { UseHoverProps } from 'react-laag/dist/useHover';
import { UseLayerProps } from 'react-laag/dist/useLayer';

export type UseTooltipProps = UseHoverOptions &
  Omit<UseLayerOptions, 'isOpen'> & {
    disabled?: boolean;
  };

type UseTooltipValue = Omit<UseLayerProps, 'triggerProps' | 'arrowProps'> & {
  triggerProps: TriggerProps & UseHoverProps;
  isOpen: boolean;
};

export { PLACEMENT_TYPES } from 'react-laag';

/**
 * This hook can be used to calculate tooltip and its trigger props.
 * Based on react-laag library hooks. See its docs for more details.
 */
export function useTooltip({
  // hover props
  delayEnter = 50,
  delayLeave = 50,
  hideOnScroll = false,

  // layer props
  arrowOffset,
  auto = true,
  container,
  containerOffset,
  layerDimensions,
  onDisappear,
  onOutsideClick,
  onParentClose,
  overflowContainer,
  placement,
  possiblePlacements,
  preferX,
  preferY,
  snap,
  trigger,
  triggerOffset = 8,

  // general props
  disabled = false,
}: UseTooltipProps = {}): UseTooltipValue {
  const [isOver, hoverProps] = useHover({
    delayEnter,
    delayLeave,
    hideOnScroll,
  });

  const isOpen = isOver && !disabled;

  const { renderLayer, triggerProps, layerProps, triggerBounds, layerSide } = useLayer({
    isOpen,
    arrowOffset,
    auto,
    container,
    containerOffset,
    layerDimensions,
    onDisappear,
    onOutsideClick,
    onParentClose,
    placement,
    possiblePlacements,
    preferX,
    preferY,
    snap,
    trigger,
    triggerOffset,
    overflowContainer,
  });

  const finalTriggerProps = React.useMemo(() => {
    return {
      ...triggerProps,
      ...hoverProps,
    };
  }, [triggerProps, hoverProps]);

  return {
    layerProps,
    layerSide,
    isOpen,
    triggerBounds,
    triggerProps: finalTriggerProps,
    renderLayer,
  };
}
