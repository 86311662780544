/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UsernameValidationStatusEnumDTO = {
    Available: 'available',
    Invalid: 'invalid',
    Unavailable: 'unavailable'
} as const;
export type UsernameValidationStatusEnumDTO = typeof UsernameValidationStatusEnumDTO[keyof typeof UsernameValidationStatusEnumDTO];


export function instanceOfUsernameValidationStatusEnumDTO(value: any): boolean {
    for (const key in UsernameValidationStatusEnumDTO) {
        if (Object.prototype.hasOwnProperty.call(UsernameValidationStatusEnumDTO, key)) {
            if (UsernameValidationStatusEnumDTO[key as keyof typeof UsernameValidationStatusEnumDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function UsernameValidationStatusEnumDTOFromJSON(json: any): UsernameValidationStatusEnumDTO {
    return UsernameValidationStatusEnumDTOFromJSONTyped(json, false);
}

export function UsernameValidationStatusEnumDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsernameValidationStatusEnumDTO {
    return json as UsernameValidationStatusEnumDTO;
}

export function UsernameValidationStatusEnumDTOToJSON(value?: UsernameValidationStatusEnumDTO | null): any {
    return value as any;
}

