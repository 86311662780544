import { defineStorage } from 'common/storage-base';

export const neptuneStorageTopKey = 'neptune-store-state-v2';

export function createSessionStorageAccessor<SessionStorageState>() {
  return defineStorage<SessionStorageState>(loadSessionStorageState, persistSessionStorageState);
}

export function loadSessionStorageState<SessionStorageState>(): Partial<SessionStorageState> {
  try {
    const serializedState = window.sessionStorage.getItem(neptuneStorageTopKey);

    if (serializedState === null) {
      return {};
    }

    return JSON.parse(serializedState);
  } catch {
    return {};
  }
}

export function persistSessionStorageState<SessionStorageState>(
  stateChange: Partial<SessionStorageState>,
) {
  const oldState = loadSessionStorageState<SessionStorageState>();
  const newState: Partial<SessionStorageState> = { ...oldState, ...stateChange };

  try {
    const serializedState = JSON.stringify(newState);
    window.sessionStorage.setItem(neptuneStorageTopKey, serializedState);
  } catch {}
}
