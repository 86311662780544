/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationTraitsSetDTO } from './OrganizationTraitsSetDTO';
import {
    OrganizationTraitsSetDTOFromJSON,
    OrganizationTraitsSetDTOFromJSONTyped,
    OrganizationTraitsSetDTOToJSON,
} from './OrganizationTraitsSetDTO';
import type { AvatarSourceEnum } from './AvatarSourceEnum';
import {
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
} from './AvatarSourceEnum';
import type { PricingPlanDefinitionDTO } from './PricingPlanDefinitionDTO';
import {
    PricingPlanDefinitionDTOFromJSON,
    PricingPlanDefinitionDTOFromJSONTyped,
    PricingPlanDefinitionDTOToJSON,
} from './PricingPlanDefinitionDTO';
import type { OrganizationTypeDTO } from './OrganizationTypeDTO';
import {
    OrganizationTypeDTOFromJSON,
    OrganizationTypeDTOFromJSONTyped,
    OrganizationTypeDTOToJSON,
} from './OrganizationTypeDTO';
import type { PricingPlanDTO } from './PricingPlanDTO';
import {
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './PricingPlanDTO';

/**
 * 
 * @export
 * @interface OrganizationDTO
 */
export interface OrganizationDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    paymentStatus: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {OrganizationTypeDTO}
     * @memberof OrganizationDTO
     */
    organizationType: OrganizationTypeDTO;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof OrganizationDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDTO
     */
    limitsEnforced: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    userflowIdentityHash: string;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof OrganizationDTO
     */
    pricingPlan: PricingPlanDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDTO
     */
    supportsMonitoringHoursLimits: boolean;
    /**
     * 
     * @type {PricingPlanDefinitionDTO}
     * @memberof OrganizationDTO
     */
    pricingPlanDefinition: PricingPlanDefinitionDTO;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationDTO
     */
    created: Date;
    /**
     * 
     * @type {OrganizationTraitsSetDTO}
     * @memberof OrganizationDTO
     */
    traits: OrganizationTraitsSetDTO;
}



/**
 * Check if a given object implements the OrganizationDTO interface.
 */
export function instanceOfOrganizationDTO(value: object): value is OrganizationDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('paymentStatus' in value) || value['paymentStatus'] === undefined) return false;
    if (!('avatarUrl' in value) || value['avatarUrl'] === undefined) return false;
    if (!('organizationType' in value) || value['organizationType'] === undefined) return false;
    if (!('avatarSource' in value) || value['avatarSource'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('limitsEnforced' in value) || value['limitsEnforced'] === undefined) return false;
    if (!('userflowIdentityHash' in value) || value['userflowIdentityHash'] === undefined) return false;
    if (!('pricingPlan' in value) || value['pricingPlan'] === undefined) return false;
    if (!('supportsMonitoringHoursLimits' in value) || value['supportsMonitoringHoursLimits'] === undefined) return false;
    if (!('pricingPlanDefinition' in value) || value['pricingPlanDefinition'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('traits' in value) || value['traits'] === undefined) return false;
    return true;
}

export function OrganizationDTOFromJSON(json: any): OrganizationDTO {
    return OrganizationDTOFromJSONTyped(json, false);
}

export function OrganizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'paymentStatus': json['paymentStatus'],
        'avatarUrl': json['avatarUrl'],
        'organizationType': OrganizationTypeDTOFromJSON(json['organizationType']),
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'info': json['info'] == null ? undefined : json['info'],
        'id': json['id'],
        'limitsEnforced': json['limitsEnforced'],
        'userflowIdentityHash': json['userflowIdentityHash'],
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
        'supportsMonitoringHoursLimits': json['supportsMonitoringHoursLimits'],
        'pricingPlanDefinition': PricingPlanDefinitionDTOFromJSON(json['pricingPlanDefinition']),
        'created': (new Date(json['created'])),
        'traits': OrganizationTraitsSetDTOFromJSON(json['traits']),
    };
}

export function OrganizationDTOToJSON(value?: OrganizationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'paymentStatus': value['paymentStatus'],
        'avatarUrl': value['avatarUrl'],
        'organizationType': OrganizationTypeDTOToJSON(value['organizationType']),
        'avatarSource': AvatarSourceEnumToJSON(value['avatarSource']),
        'info': value['info'],
        'id': value['id'],
        'limitsEnforced': value['limitsEnforced'],
        'userflowIdentityHash': value['userflowIdentityHash'],
        'pricingPlan': PricingPlanDTOToJSON(value['pricingPlan']),
        'supportsMonitoringHoursLimits': value['supportsMonitoringHoursLimits'],
        'pricingPlanDefinition': PricingPlanDefinitionDTOToJSON(value['pricingPlanDefinition']),
        'created': ((value['created']).toISOString()),
        'traits': OrganizationTraitsSetDTOToJSON(value['traits']),
    };
}

