/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectMemberDTO } from './ProjectMemberDTO';
import {
    ProjectMemberDTOFromJSON,
    ProjectMemberDTOFromJSONTyped,
    ProjectMemberDTOToJSON,
} from './ProjectMemberDTO';

/**
 * 
 * @export
 * @interface ProjectMembersDTO
 */
export interface ProjectMembersDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectMembersDTO
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMembersDTO
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMembersDTO
     */
    organizationName: string;
    /**
     * 
     * @type {Array<ProjectMemberDTO>}
     * @memberof ProjectMembersDTO
     */
    members: Array<ProjectMemberDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProjectMembersDTO
     */
    organizationId: string;
}

/**
 * Check if a given object implements the ProjectMembersDTO interface.
 */
export function instanceOfProjectMembersDTO(value: object): value is ProjectMembersDTO {
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('members' in value) || value['members'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    return true;
}

export function ProjectMembersDTOFromJSON(json: any): ProjectMembersDTO {
    return ProjectMembersDTOFromJSONTyped(json, false);
}

export function ProjectMembersDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectMembersDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'projectName': json['projectName'],
        'projectId': json['projectId'],
        'organizationName': json['organizationName'],
        'members': ((json['members'] as Array<any>).map(ProjectMemberDTOFromJSON)),
        'organizationId': json['organizationId'],
    };
}

export function ProjectMembersDTOToJSON(value?: ProjectMembersDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectName': value['projectName'],
        'projectId': value['projectId'],
        'organizationName': value['organizationName'],
        'members': ((value['members'] as Array<any>).map(ProjectMemberDTOToJSON)),
        'organizationId': value['organizationId'],
    };
}

