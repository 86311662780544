import React from 'react';

import { bemBlock } from '../../modules/bem';

import './CheckboxBgIcon.less';

type CheckboxBgIconProps = {
  disabled: boolean;
  checked: boolean;
  hasError: boolean;
  indeterminate: boolean;
};

const block = bemBlock('bg-icon');

export const CheckboxBgIcon: React.FC<CheckboxBgIconProps> = ({
  disabled,
  checked,
  hasError,
  indeterminate,
}) => {
  return (
    <span
      data-checked={checked}
      data-disabled={disabled}
      data-has-error={hasError}
      data-indeterminate={indeterminate}
      data-role="checkbox-state"
      className={block()}
    />
  );
};
