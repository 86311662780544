// Libs
import React from 'react';
import { Emojione } from 'react-emoji-render';
import { Components } from 'react-markdown';

// Module
const TextRenderer: Components['text'] = (props) => {
  return <Emojione {...props} />;
};

export default TextRenderer;
