import React from 'react';

import { SimpleTooltip, ToggleSwitch } from '@neptune/shared/venus-ui';

type ScaleSwitchProps<T extends string> = {
  disabled?: boolean;
  selected: T;
  setScale: (scale: T) => void;
  'data-role': string;
  disabledText?: string | null;
  scaleArray: T[];
  scalesMap: Map<T, string>;
  predicate: (value: string) => value is T;
};

export const ScaleSwitch = <T extends string>({
  disabled,
  selected,
  scaleArray,
  scalesMap,
  setScale,
  predicate,
  'data-role': dataRole,
  disabledText,
}: ScaleSwitchProps<T>) => {
  return (
    <SimpleTooltip content={disabled && disabledText ? disabledText : null}>
      <ToggleSwitch
        data-role={dataRole}
        data-selected={selected}
        selected={selected}
        disabled={disabled}
        onChange={(value: string) => predicate(value) && setScale(value)}
        width="fit-content"
      >
        {scaleArray.map((value) => (
          <ToggleSwitch.Button key={value.toString()} value={value}>
            {scalesMap.get(value)}
          </ToggleSwitch.Button>
        ))}
      </ToggleSwitch>
    </SimpleTooltip>
  );
};
