import React from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { bemBlock } from '../../modules/bem';

import './ScrollAreaRoot.less';

const block = bemBlock('n-scroll-area-root');

export const ScrollAreaRoot = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={block({
        extra: className,
      })}
      {...props}
    />
  );
});
