import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Layout } from '../layout/Layout';
import { LayoutColumnProps } from '../layout-column/LayoutColumn';

import { CardFooter } from './CardFooter';
import { CardHeaderText } from './CardHeaderText';
import { CardLabelText } from './CardLabelText';
import { CardSection } from './CardSection';

import './Card.less';

const block = bemBlock('n-card');

export type CardProps = {
  children?: React.ReactNode;
  withBoxShadow?: boolean;
} & LayoutColumnProps<{}>;

export const Card = Object.assign(
  ({ className, withBoxShadow = true, children, ...passProps }: CardProps): React.ReactElement => {
    return (
      <Layout.Column
        span="auto"
        overflow="hidden"
        {...passProps}
        className={block({ modifiers: { withBoxShadow }, extra: className })}
        children={children}
      />
    );
  },
  {
    Section: CardSection,
    Column: Layout.Column,
    LabelText: CardLabelText,
    HeaderText: CardHeaderText,
    Footer: CardFooter,
  },
);
