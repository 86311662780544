/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeNameFilterDTO
 */
export interface AttributeNameFilterDTO {
    /**
     * An attribute must match all of the regexes from the list to be returned
     * @type {Array<string>}
     * @memberof AttributeNameFilterDTO
     */
    mustMatchRegexes?: Array<string>;
    /**
     * An attribute must match none of the regexes from the list to be returned
     * @type {Array<string>}
     * @memberof AttributeNameFilterDTO
     */
    mustNotMatchRegexes?: Array<string>;
}

/**
 * Check if a given object implements the AttributeNameFilterDTO interface.
 */
export function instanceOfAttributeNameFilterDTO(value: object): value is AttributeNameFilterDTO {
    return true;
}

export function AttributeNameFilterDTOFromJSON(json: any): AttributeNameFilterDTO {
    return AttributeNameFilterDTOFromJSONTyped(json, false);
}

export function AttributeNameFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeNameFilterDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'mustMatchRegexes': json['mustMatchRegexes'] == null ? undefined : json['mustMatchRegexes'],
        'mustNotMatchRegexes': json['mustNotMatchRegexes'] == null ? undefined : json['mustNotMatchRegexes'],
    };
}

export function AttributeNameFilterDTOToJSON(value?: AttributeNameFilterDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mustMatchRegexes': value['mustMatchRegexes'],
        'mustNotMatchRegexes': value['mustNotMatchRegexes'],
    };
}

