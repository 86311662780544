/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SeriesViewPointDTO } from './SeriesViewPointDTO';
import {
    SeriesViewPointDTOFromJSON,
    SeriesViewPointDTOFromJSONTyped,
    SeriesViewPointDTOToJSON,
} from './SeriesViewPointDTO';

/**
 * 
 * @export
 * @interface SeriesViewDTO
 */
export interface SeriesViewDTO {
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    attributePath: string;
    /**
     * 
     * @type {boolean}
     * @memberof SeriesViewDTO
     */
    downsampled: boolean;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewDTO
     */
    firstPointIndex: number;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    holderIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    holderType: string;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewDTO
     */
    lastPointIndex: number;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewDTO
     */
    maxY: number;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewDTO
     */
    minY: number;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    projectName: string;
    /**
     * 
     * @type {Array<SeriesViewPointDTO>}
     * @memberof SeriesViewDTO
     */
    values: Array<SeriesViewPointDTO>;
}

/**
 * Check if a given object implements the SeriesViewDTO interface.
 */
export function instanceOfSeriesViewDTO(value: object): value is SeriesViewDTO {
    if (!('attributePath' in value) || value['attributePath'] === undefined) return false;
    if (!('downsampled' in value) || value['downsampled'] === undefined) return false;
    if (!('firstPointIndex' in value) || value['firstPointIndex'] === undefined) return false;
    if (!('holderIdentifier' in value) || value['holderIdentifier'] === undefined) return false;
    if (!('holderType' in value) || value['holderType'] === undefined) return false;
    if (!('lastPointIndex' in value) || value['lastPointIndex'] === undefined) return false;
    if (!('maxY' in value) || value['maxY'] === undefined) return false;
    if (!('minY' in value) || value['minY'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('projectName' in value) || value['projectName'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function SeriesViewDTOFromJSON(json: any): SeriesViewDTO {
    return SeriesViewDTOFromJSONTyped(json, false);
}

export function SeriesViewDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesViewDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributePath': json['attributePath'],
        'downsampled': json['downsampled'],
        'firstPointIndex': json['firstPointIndex'],
        'holderIdentifier': json['holderIdentifier'],
        'holderType': json['holderType'],
        'lastPointIndex': json['lastPointIndex'],
        'maxY': json['maxY'],
        'minY': json['minY'],
        'projectId': json['projectId'],
        'projectName': json['projectName'],
        'values': ((json['values'] as Array<any>).map(SeriesViewPointDTOFromJSON)),
    };
}

export function SeriesViewDTOToJSON(value?: SeriesViewDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributePath': value['attributePath'],
        'downsampled': value['downsampled'],
        'firstPointIndex': value['firstPointIndex'],
        'holderIdentifier': value['holderIdentifier'],
        'holderType': value['holderType'],
        'lastPointIndex': value['lastPointIndex'],
        'maxY': value['maxY'],
        'minY': value['minY'],
        'projectId': value['projectId'],
        'projectName': value['projectName'],
        'values': ((value['values'] as Array<any>).map(SeriesViewPointDTOToJSON)),
    };
}

