/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';
import type { ServiceAccountDTO } from './ServiceAccountDTO';
import {
    ServiceAccountDTOFromJSON,
    ServiceAccountDTOFromJSONTyped,
    ServiceAccountDTOToJSON,
} from './ServiceAccountDTO';

/**
 * 
 * @export
 * @interface ServiceAccountWithRoleDTO
 */
export interface ServiceAccountWithRoleDTO {
    /**
     * 
     * @type {ServiceAccountDTO}
     * @memberof ServiceAccountWithRoleDTO
     */
    serviceAccountInfo: ServiceAccountDTO;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ServiceAccountWithRoleDTO
     */
    role: ProjectRoleDTO;
}



/**
 * Check if a given object implements the ServiceAccountWithRoleDTO interface.
 */
export function instanceOfServiceAccountWithRoleDTO(value: object): value is ServiceAccountWithRoleDTO {
    if (!('serviceAccountInfo' in value) || value['serviceAccountInfo'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function ServiceAccountWithRoleDTOFromJSON(json: any): ServiceAccountWithRoleDTO {
    return ServiceAccountWithRoleDTOFromJSONTyped(json, false);
}

export function ServiceAccountWithRoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceAccountWithRoleDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'serviceAccountInfo': ServiceAccountDTOFromJSON(json['serviceAccountInfo']),
        'role': ProjectRoleDTOFromJSON(json['role']),
    };
}

export function ServiceAccountWithRoleDTOToJSON(value?: ServiceAccountWithRoleDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'serviceAccountInfo': ServiceAccountDTOToJSON(value['serviceAccountInfo']),
        'role': ProjectRoleDTOToJSON(value['role']),
    };
}

