/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogFloatEntry
 */
export interface LogFloatEntry {
    /**
     * 
     * @type {number}
     * @memberof LogFloatEntry
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof LogFloatEntry
     */
    step?: number;
    /**
     * 
     * @type {number}
     * @memberof LogFloatEntry
     */
    timestampMilliseconds: number;
}

/**
 * Check if a given object implements the LogFloatEntry interface.
 */
export function instanceOfLogFloatEntry(value: object): value is LogFloatEntry {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('timestampMilliseconds' in value) || value['timestampMilliseconds'] === undefined) return false;
    return true;
}

export function LogFloatEntryFromJSON(json: any): LogFloatEntry {
    return LogFloatEntryFromJSONTyped(json, false);
}

export function LogFloatEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogFloatEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'step': json['step'] == null ? undefined : json['step'],
        'timestampMilliseconds': json['timestampMilliseconds'],
    };
}

export function LogFloatEntryToJSON(value?: LogFloatEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'step': value['step'],
        'timestampMilliseconds': value['timestampMilliseconds'],
    };
}

