/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WidgetLayoutDTO
 */
export interface WidgetLayoutDTO {
    /**
     * 
     * @type {number}
     * @memberof WidgetLayoutDTO
     */
    h: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetLayoutDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetLayoutDTO
     */
    isStatic?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WidgetLayoutDTO
     */
    w: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetLayoutDTO
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetLayoutDTO
     */
    y: number;
}

/**
 * Check if a given object implements the WidgetLayoutDTO interface.
 */
export function instanceOfWidgetLayoutDTO(value: object): value is WidgetLayoutDTO {
    if (!('h' in value) || value['h'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('w' in value) || value['w'] === undefined) return false;
    if (!('x' in value) || value['x'] === undefined) return false;
    if (!('y' in value) || value['y'] === undefined) return false;
    return true;
}

export function WidgetLayoutDTOFromJSON(json: any): WidgetLayoutDTO {
    return WidgetLayoutDTOFromJSONTyped(json, false);
}

export function WidgetLayoutDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetLayoutDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'h': json['h'],
        'id': json['id'],
        'isStatic': json['isStatic'] == null ? undefined : json['isStatic'],
        'w': json['w'],
        'x': json['x'],
        'y': json['y'],
    };
}

export function WidgetLayoutDTOToJSON(value?: WidgetLayoutDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'h': value['h'],
        'id': value['id'],
        'isStatic': value['isStatic'],
        'w': value['w'],
        'x': value['x'],
        'y': value['y'],
    };
}

