// Libs
import React from 'react';

import { bemBlock, Layout, Text } from '@neptune/shared/venus-ui';

import neptuneLogo from '../assets/neptune-logo.svg';

// Module
import './ErrorPage.less';

const block = bemBlock('error-page');

const ErrorPageTitle: React.FC = ({ children }) => (
  <Text size="xxl" fontWeight="bold" component="h2" color="text">
    {children}
  </Text>
);
const ErrorPageMessage: React.FC = ({ children }) => (
  <Text className={block('message')} size="lg" color="text">
    {children}
  </Text>
);
const ErrorPageFooter: React.FC = ({ children }) => (
  <Text size="base" color="text">
    {children}
  </Text>
);

export class ErrorPage extends React.Component<{ 'data-role'?: string }> {
  static defaultProps = { iconType: 'default' };

  static Title = ErrorPageTitle;
  static Message = ErrorPageMessage;
  static Footer = ErrorPageFooter;

  render() {
    const { children, 'data-role': dataRole } = this.props;

    return (
      <Layout.Column
        className={block()}
        data-role={dataRole}
        justifyContent="center"
        alignItems="center"
      >
        <img className={block('logo')} src={neptuneLogo} alt="Neptune logo" />
        <Layout.Column
          justifyContent="center"
          alignItems="center"
          overflow={{ vertical: 'auto' }}
          span="shrink"
          spacedChildren="md"
        >
          {children}
        </Layout.Column>
      </Layout.Column>
    );
  }
}
