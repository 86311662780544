/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntercomEntryDTO } from './IntercomEntryDTO';
import {
    IntercomEntryDTOFromJSON,
    IntercomEntryDTOFromJSONTyped,
    IntercomEntryDTOToJSON,
} from './IntercomEntryDTO';

/**
 * 
 * @export
 * @interface UserIntercomDataDTO
 */
export interface UserIntercomDataDTO {
    /**
     * 
     * @type {string}
     * @memberof UserIntercomDataDTO
     */
    username: string;
    /**
     * 
     * @type {Array<IntercomEntryDTO>}
     * @memberof UserIntercomDataDTO
     */
    userProperties: Array<IntercomEntryDTO>;
}

/**
 * Check if a given object implements the UserIntercomDataDTO interface.
 */
export function instanceOfUserIntercomDataDTO(value: object): value is UserIntercomDataDTO {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('userProperties' in value) || value['userProperties'] === undefined) return false;
    return true;
}

export function UserIntercomDataDTOFromJSON(json: any): UserIntercomDataDTO {
    return UserIntercomDataDTOFromJSONTyped(json, false);
}

export function UserIntercomDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserIntercomDataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'userProperties': ((json['userProperties'] as Array<any>).map(IntercomEntryDTOFromJSON)),
    };
}

export function UserIntercomDataDTOToJSON(value?: UserIntercomDataDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'userProperties': ((value['userProperties'] as Array<any>).map(IntercomEntryDTOToJSON)),
    };
}

