/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QueryLeaderboardParamsFieldDTO } from './QueryLeaderboardParamsFieldDTO';
import {
    QueryLeaderboardParamsFieldDTOFromJSON,
    QueryLeaderboardParamsFieldDTOFromJSONTyped,
    QueryLeaderboardParamsFieldDTOToJSON,
} from './QueryLeaderboardParamsFieldDTO';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsSortingParamsDTO
 */
export interface QueryLeaderboardParamsSortingParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsSortingParamsDTO
     */
    dir?: QueryLeaderboardParamsSortingParamsDTODirEnum;
    /**
     * 
     * @type {QueryLeaderboardParamsFieldDTO}
     * @memberof QueryLeaderboardParamsSortingParamsDTO
     */
    sortBy: QueryLeaderboardParamsFieldDTO;
}


/**
 * @export
 */
export const QueryLeaderboardParamsSortingParamsDTODirEnum = {
    Ascending: 'ascending',
    Descending: 'descending'
} as const;
export type QueryLeaderboardParamsSortingParamsDTODirEnum = typeof QueryLeaderboardParamsSortingParamsDTODirEnum[keyof typeof QueryLeaderboardParamsSortingParamsDTODirEnum];


/**
 * Check if a given object implements the QueryLeaderboardParamsSortingParamsDTO interface.
 */
export function instanceOfQueryLeaderboardParamsSortingParamsDTO(value: object): value is QueryLeaderboardParamsSortingParamsDTO {
    if (!('sortBy' in value) || value['sortBy'] === undefined) return false;
    return true;
}

export function QueryLeaderboardParamsSortingParamsDTOFromJSON(json: any): QueryLeaderboardParamsSortingParamsDTO {
    return QueryLeaderboardParamsSortingParamsDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsSortingParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsSortingParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'dir': json['dir'] == null ? undefined : json['dir'],
        'sortBy': QueryLeaderboardParamsFieldDTOFromJSON(json['sortBy']),
    };
}

export function QueryLeaderboardParamsSortingParamsDTOToJSON(value?: QueryLeaderboardParamsSortingParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dir': value['dir'],
        'sortBy': QueryLeaderboardParamsFieldDTOToJSON(value['sortBy']),
    };
}

