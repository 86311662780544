import React, { MouseEventHandler } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import '../../modules/icons';

import { joinClassNames } from '../../modules/bem';
import * as text from '../../modules/text';
import { clickableClassName } from '../../modules/ui-state';
import { IconStack } from '../icon-stack/IconStack';

import { getGlyph, GlyphName } from './glyphs';

export type IconProps = Omit<FontAwesomeIconProps, 'icon' | 'ref'> & {
  /** `color` overrides `inverse` */
  color?: text.Color;
  'data-role'?: string;
  glyph: GlyphName;
  onClick?: MouseEventHandler;
};

export type { GlyphName } from './glyphs';

/**
 * Icon is using `FontAwesomeIcon` component.
 *
 * See https://fontawesome.com/how-to-use/on-the-web/using-with/react for the full reference.
 */
export class Icon extends React.Component<IconProps> {
  public static Stack = IconStack;

  render() {
    const { className, color, glyph, onClick, ...props } = this.props;
    const iconDefinition = getGlyph(glyph);

    if (Array.isArray(iconDefinition)) {
      const { size, title, style } = props;

      return (
        <IconStack
          className={className}
          color={color}
          onClick={onClick}
          size={size}
          title={title}
          style={style}
        >
          {iconDefinition.map((stackedIcon, key) => (
            <FontAwesomeIcon key={key} {...stackedIcon} />
          ))}
        </IconStack>
      );
    }

    /*
     * We're messing around with spread operator which causes annoying react warnings:
     * - React does not recognize the `elementRef` prop on a DOM element
     * - Received `false` for a non-boolean attribute `pressed`.
     */
    const { elementRef, pressed, ...rest } = props as any;

    return (
      <FontAwesomeIcon
        {...iconDefinition}
        className={joinClassNames(
          clickableClassName(onClick),
          text.colorClassName(color),
          className,
          'v-icon',
        )}
        onClick={onClick}
        {...rest}
      />
    );
  }
}
