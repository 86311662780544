import { isNotUndefined } from 'common/tsHelpers';

import { LocalStorageCompatible } from './local-storage-compatible';
import {
  Column as ColumnV5,
  isLocalStorageV5,
  NotebookRefCriterion,
  SearchCriterion as SearchCriterionV5,
  SearchQuery as SearchQueryV5,
} from './local-storage-v5';
import { upgrade as upgradeFromPrevious } from './local-storage-v5.upgrade';
import {
  Column as ColumnV6,
  LocalStorageV6,
  SearchCriterion as SearchCriterionV6,
  SearchQuery as SearchQueryV6,
} from './local-storage-v6';

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV6> {
  const input = isLocalStorageV5(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const {
    recentSearchQueries,
    orderedColumns,
    notebookSidebarCollapsed,
    dontShowNotebookWelcomeNotice,
    ...rest
  } = data;

  return {
    version: 6,
    data: {
      ...rest,
      orderedColumns: orderedColumns ? convertOrderedColumns(orderedColumns) : undefined,
      recentSearchQueries: recentSearchQueries
        ? convertRecentSearchQueries(recentSearchQueries)
        : undefined,
    },
  };
}

function convertOrderedColumns(
  input: Record<string, Record<string, ColumnV5[]>>,
): Record<string, Record<string, ColumnV6[]>> {
  const filteredList: Record<string, Record<string, ColumnV6[]>> = {};

  for (const key in input) {
    filteredList[key] = {};

    for (const nestedKey in input[key]) {
      filteredList[key][nestedKey] = input[key][nestedKey].filter((column): column is ColumnV6 => {
        return column.columnType !== 'notebookRef';
      });
    }
  }

  return filteredList;
}

function convertRecentSearchQueries(
  input: Record<string, Record<string, SearchQueryV5[] | undefined> | undefined>,
): Record<string, Record<string, SearchQueryV6[] | undefined> | undefined> {
  const filteredList: Record<string, Record<string, SearchQueryV6[] | undefined> | undefined> = {};

  for (const key in input) {
    if (input[key]) {
      filteredList[key] = {};
      const row = input[key] as Record<string, SearchQueryV5[] | undefined>;

      for (const nestedKey in row) {
        if (row[nestedKey]) {
          // @ts-ignore filteredList[key] and row[nestedKey] are always objects
          filteredList[key][nestedKey] = row[nestedKey].map(convertSearchQuery);
        }
      }
    }
  }

  return filteredList;
}

function convertSearchQuery(input: SearchQueryV5): SearchQueryV6 {
  return {
    ...input,
    criteria: input.criteria
      .map((entry) =>
        isSearchQuery(entry) ? convertSearchQuery(entry) : convertSearchCriterion(entry),
      )
      .filter(isNotUndefined),
  };
}

function isSearchQuery(input: SearchQueryV5 | SearchCriterionV5): input is SearchQueryV5 {
  return Array.isArray((input as SearchQueryV5).criteria);
}

function convertSearchCriterion(input: SearchCriterionV5): SearchCriterionV6 | undefined {
  if (isNotebookCriterion(input)) {
    return undefined;
  }

  return input;
}

function isNotebookCriterion(input: SearchCriterionV5): input is NotebookRefCriterion {
  return input.type === 'notebookRef';
}
