/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeSeries } from './TimeSeries';
import {
    TimeSeriesFromJSON,
    TimeSeriesFromJSONTyped,
    TimeSeriesToJSON,
} from './TimeSeries';
import type { CustomMetric } from './CustomMetric';
import {
    CustomMetricFromJSON,
    CustomMetricFromJSONTyped,
    CustomMetricToJSON,
} from './CustomMetric';
import type { View } from './View';
import {
    ViewFromJSON,
    ViewFromJSONTyped,
    ViewToJSON,
} from './View';
import type { XAxis } from './XAxis';
import {
    XAxisFromJSON,
    XAxisFromJSONTyped,
    XAxisToJSON,
} from './XAxis';

/**
 * 
 * @export
 * @interface TimeSeriesViewRequest
 */
export interface TimeSeriesViewRequest {
    /**
     * 
     * @type {Array<CustomMetric>}
     * @memberof TimeSeriesViewRequest
     */
    metrics?: Array<CustomMetric>;
    /**
     * Deprecated, use metrics instead.
     * @type {Array<TimeSeries>}
     * @memberof TimeSeriesViewRequest
     */
    series?: Array<TimeSeries>;
    /**
     * 
     * @type {View}
     * @memberof TimeSeriesViewRequest
     */
    view: View;
    /**
     * 
     * @type {XAxis}
     * @memberof TimeSeriesViewRequest
     */
    xaxis: XAxis;
}

/**
 * Check if a given object implements the TimeSeriesViewRequest interface.
 */
export function instanceOfTimeSeriesViewRequest(value: object): value is TimeSeriesViewRequest {
    if (!('view' in value) || value['view'] === undefined) return false;
    if (!('xaxis' in value) || value['xaxis'] === undefined) return false;
    return true;
}

export function TimeSeriesViewRequestFromJSON(json: any): TimeSeriesViewRequest {
    return TimeSeriesViewRequestFromJSONTyped(json, false);
}

export function TimeSeriesViewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeriesViewRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'metrics': json['metrics'] == null ? undefined : ((json['metrics'] as Array<any>).map(CustomMetricFromJSON)),
        'series': json['series'] == null ? undefined : ((json['series'] as Array<any>).map(TimeSeriesFromJSON)),
        'view': ViewFromJSON(json['view']),
        'xaxis': XAxisFromJSON(json['xaxis']),
    };
}

export function TimeSeriesViewRequestToJSON(value?: TimeSeriesViewRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'metrics': value['metrics'] == null ? undefined : ((value['metrics'] as Array<any>).map(CustomMetricToJSON)),
        'series': value['series'] == null ? undefined : ((value['series'] as Array<any>).map(TimeSeriesToJSON)),
        'view': ViewToJSON(value['view']),
        'xaxis': XAxisToJSON(value['xaxis']),
    };
}

