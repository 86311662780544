/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FloatTimeSeriesValuesRequestSeries } from './FloatTimeSeriesValuesRequestSeries';
import {
    FloatTimeSeriesValuesRequestSeriesFromJSON,
    FloatTimeSeriesValuesRequestSeriesFromJSONTyped,
    FloatTimeSeriesValuesRequestSeriesToJSON,
} from './FloatTimeSeriesValuesRequestSeries';
import type { OpenRangeDTO } from './OpenRangeDTO';
import {
    OpenRangeDTOFromJSON,
    OpenRangeDTOFromJSONTyped,
    OpenRangeDTOToJSON,
} from './OpenRangeDTO';

/**
 * 
 * @export
 * @interface FloatTimeSeriesValuesRequest
 */
export interface FloatTimeSeriesValuesRequest {
    /**
     * 
     * @type {string}
     * @memberof FloatTimeSeriesValuesRequest
     */
    order?: FloatTimeSeriesValuesRequestOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof FloatTimeSeriesValuesRequest
     */
    perSeriesPointsLimit: number;
    /**
     * 
     * @type {Array<FloatTimeSeriesValuesRequestSeries>}
     * @memberof FloatTimeSeriesValuesRequest
     */
    requests: Array<FloatTimeSeriesValuesRequestSeries>;
    /**
     * 
     * @type {OpenRangeDTO}
     * @memberof FloatTimeSeriesValuesRequest
     */
    stepRange?: OpenRangeDTO;
}


/**
 * @export
 */
export const FloatTimeSeriesValuesRequestOrderEnum = {
    Ascending: 'ascending',
    Descending: 'descending'
} as const;
export type FloatTimeSeriesValuesRequestOrderEnum = typeof FloatTimeSeriesValuesRequestOrderEnum[keyof typeof FloatTimeSeriesValuesRequestOrderEnum];


/**
 * Check if a given object implements the FloatTimeSeriesValuesRequest interface.
 */
export function instanceOfFloatTimeSeriesValuesRequest(value: object): value is FloatTimeSeriesValuesRequest {
    if (!('perSeriesPointsLimit' in value) || value['perSeriesPointsLimit'] === undefined) return false;
    if (!('requests' in value) || value['requests'] === undefined) return false;
    return true;
}

export function FloatTimeSeriesValuesRequestFromJSON(json: any): FloatTimeSeriesValuesRequest {
    return FloatTimeSeriesValuesRequestFromJSONTyped(json, false);
}

export function FloatTimeSeriesValuesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatTimeSeriesValuesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'order': json['order'] == null ? undefined : json['order'],
        'perSeriesPointsLimit': json['perSeriesPointsLimit'],
        'requests': ((json['requests'] as Array<any>).map(FloatTimeSeriesValuesRequestSeriesFromJSON)),
        'stepRange': json['stepRange'] == null ? undefined : OpenRangeDTOFromJSON(json['stepRange']),
    };
}

export function FloatTimeSeriesValuesRequestToJSON(value?: FloatTimeSeriesValuesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'order': value['order'],
        'perSeriesPointsLimit': value['perSeriesPointsLimit'],
        'requests': ((value['requests'] as Array<any>).map(FloatTimeSeriesValuesRequestSeriesToJSON)),
        'stepRange': OpenRangeDTOToJSON(value['stepRange']),
    };
}

