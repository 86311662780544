/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchLeaderboardEntriesParamsDTO } from './SearchLeaderboardEntriesParamsDTO';
import {
    SearchLeaderboardEntriesParamsDTOFromJSON,
    SearchLeaderboardEntriesParamsDTOFromJSONTyped,
    SearchLeaderboardEntriesParamsDTOToJSON,
} from './SearchLeaderboardEntriesParamsDTO';

/**
 * 
 * @export
 * @interface MultiSearchLeaderboardEntriesParamsDTO
 */
export interface MultiSearchLeaderboardEntriesParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof MultiSearchLeaderboardEntriesParamsDTO
     */
    projectIdentifier: string;
    /**
     * 
     * @type {SearchLeaderboardEntriesParamsDTO}
     * @memberof MultiSearchLeaderboardEntriesParamsDTO
     */
    searchLeaderboardEntriesParamsDTO: SearchLeaderboardEntriesParamsDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof MultiSearchLeaderboardEntriesParamsDTO
     */
    type?: Array<string>;
}

/**
 * Check if a given object implements the MultiSearchLeaderboardEntriesParamsDTO interface.
 */
export function instanceOfMultiSearchLeaderboardEntriesParamsDTO(value: object): value is MultiSearchLeaderboardEntriesParamsDTO {
    if (!('projectIdentifier' in value) || value['projectIdentifier'] === undefined) return false;
    if (!('searchLeaderboardEntriesParamsDTO' in value) || value['searchLeaderboardEntriesParamsDTO'] === undefined) return false;
    return true;
}

export function MultiSearchLeaderboardEntriesParamsDTOFromJSON(json: any): MultiSearchLeaderboardEntriesParamsDTO {
    return MultiSearchLeaderboardEntriesParamsDTOFromJSONTyped(json, false);
}

export function MultiSearchLeaderboardEntriesParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiSearchLeaderboardEntriesParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'projectIdentifier': json['projectIdentifier'],
        'searchLeaderboardEntriesParamsDTO': SearchLeaderboardEntriesParamsDTOFromJSON(json['searchLeaderboardEntriesParamsDTO']),
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function MultiSearchLeaderboardEntriesParamsDTOToJSON(value?: MultiSearchLeaderboardEntriesParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectIdentifier': value['projectIdentifier'],
        'searchLeaderboardEntriesParamsDTO': SearchLeaderboardEntriesParamsDTOToJSON(value['searchLeaderboardEntriesParamsDTO']),
        'type': value['type'],
    };
}

