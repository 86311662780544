/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeStyleSettingsDTO } from './AttributeStyleSettingsDTO';
import {
    AttributeStyleSettingsDTOFromJSON,
    AttributeStyleSettingsDTOFromJSONTyped,
    AttributeStyleSettingsDTOToJSON,
} from './AttributeStyleSettingsDTO';

/**
 * 
 * @export
 * @interface AttributeStyleSettingsParamsDTO
 */
export interface AttributeStyleSettingsParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof AttributeStyleSettingsParamsDTO
     */
    projectIdentifier: string;
    /**
     * 
     * @type {AttributeStyleSettingsDTO}
     * @memberof AttributeStyleSettingsParamsDTO
     */
    styleSettings: AttributeStyleSettingsDTO;
}

/**
 * Check if a given object implements the AttributeStyleSettingsParamsDTO interface.
 */
export function instanceOfAttributeStyleSettingsParamsDTO(value: object): value is AttributeStyleSettingsParamsDTO {
    if (!('projectIdentifier' in value) || value['projectIdentifier'] === undefined) return false;
    if (!('styleSettings' in value) || value['styleSettings'] === undefined) return false;
    return true;
}

export function AttributeStyleSettingsParamsDTOFromJSON(json: any): AttributeStyleSettingsParamsDTO {
    return AttributeStyleSettingsParamsDTOFromJSONTyped(json, false);
}

export function AttributeStyleSettingsParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeStyleSettingsParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'projectIdentifier': json['projectIdentifier'],
        'styleSettings': AttributeStyleSettingsDTOFromJSON(json['styleSettings']),
    };
}

export function AttributeStyleSettingsParamsDTOToJSON(value?: AttributeStyleSettingsParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectIdentifier': value['projectIdentifier'],
        'styleSettings': AttributeStyleSettingsDTOToJSON(value['styleSettings']),
    };
}

