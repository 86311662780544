// Libs
import React from 'react';

import { joinClassNames } from '../../modules/bem';
import * as layout from '../../modules/layout';
import { createComponent, WithNonInterferingComponent } from '../../modules/utils';
import { makeRefProp } from '../make-ref-prop-util';

// Module
type LayoutElementOwnProps = {
  className: string;
  style: React.CSSProperties;
};

export type LayoutElementProps<P extends object> = WithNonInterferingComponent<
  P,
  LayoutElementOwnProps
> &
  React.HTMLAttributes<HTMLElement> & {
    alignSelf?: layout.AlignSelf;
    className?: string;
    elementRef?: React.Ref<any>;
    /**
     * `number` or one of length data type: %, px, em, ex, vh, vw, vmin or vmax
     *
     * **NOTE:** If `height` is set, `span` will be forced to `"auto"`
     */
    height?: string | number;
    overflow?: layout.Overflow;
    position?: layout.Position;
    selfSpacing?: layout.LayoutUnit;
    span?: layout.Span;
    style?: React.CSSProperties;
    /**
     * `number` or one of length data type: %, px, em, ex, vh, vw, vmin or vmax
     *
     * **NOTE:** If `width` is set, span will be forced to `"auto"`
     */
    width?: string | number;
    withCustomScrollbar?: boolean;
    withGutter?: layout.LayoutUnit;
    withPadding?: layout.Padding;
  };

/**
 * `LayoutElement` is a base layout component that can hold the content.
 *
 * ## Import
 *
 * ```
 * import { Layout } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Layout.Element />`
 */
export function LayoutElement<P extends object>({
  alignSelf,
  className,
  component = 'div',
  elementRef,
  height,
  overflow,
  selfSpacing,
  position,
  span = 'auto',
  style = {}, // to be able to pass custom style
  width,
  withCustomScrollbar,
  withGutter,
  withPadding,
  ...passProps
}: LayoutElementProps<P>): React.ReactElement {
  const isHeightSet = isSizeValueSet(height);
  const isWidthSet = isSizeValueSet(width);
  const isSizeSet = isHeightSet || isWidthSet;
  const ownProps: LayoutElementOwnProps = {
    className: joinClassNames(
      className,
      layout.spanClassName(isSizeSet ? 'auto' : span),
      layout.alignSelfClassName(alignSelf),
      layout.selfSpacingClassName(selfSpacing),
      layout.withGutterClassName(withGutter),
      layout.withPaddingClassName(withPadding),
      layout.overflowClassName(overflow),
      layout.withCustomScrollbarClassName(withCustomScrollbar),
      layout.positionClassName(position),
    ),
    style,
  };

  if (isHeightSet) {
    ownProps.style.height = height;
  }

  if (isWidthSet) {
    ownProps.style.width = width;
  }

  const ref = makeRefProp(component, elementRef);

  return createComponent(component, {
    ...ownProps,
    ...passProps,
    ...ref,
  });
}

function isSizeValueSet(value?: string | number) {
  return !!value || value === 0;
}
