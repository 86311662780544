/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TypeDefinitionDTO } from './TypeDefinitionDTO';
import {
    TypeDefinitionDTOFromJSON,
    TypeDefinitionDTOFromJSONTyped,
    TypeDefinitionDTOToJSON,
} from './TypeDefinitionDTO';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface ComplexAttributeDTO
 */
export interface ComplexAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof ComplexAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof ComplexAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof ComplexAttributeDTO
     */
    content: string;
    /**
     * 
     * @type {TypeDefinitionDTO}
     * @memberof ComplexAttributeDTO
     */
    typeDefinition: TypeDefinitionDTO;
}



/**
 * Check if a given object implements the ComplexAttributeDTO interface.
 */
export function instanceOfComplexAttributeDTO(value: object): value is ComplexAttributeDTO {
    if (!('attributeName' in value) || value['attributeName'] === undefined) return false;
    if (!('attributeType' in value) || value['attributeType'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('typeDefinition' in value) || value['typeDefinition'] === undefined) return false;
    return true;
}

export function ComplexAttributeDTOFromJSON(json: any): ComplexAttributeDTO {
    return ComplexAttributeDTOFromJSONTyped(json, false);
}

export function ComplexAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplexAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'content': json['content'],
        'typeDefinition': TypeDefinitionDTOFromJSON(json['typeDefinition']),
    };
}

export function ComplexAttributeDTOToJSON(value?: ComplexAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeName': value['attributeName'],
        'attributeType': AttributeTypeDTOToJSON(value['attributeType']),
        'content': value['content'],
        'typeDefinition': TypeDefinitionDTOToJSON(value['typeDefinition']),
    };
}

