/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LinkTypeDTO } from './LinkTypeDTO';
import {
    LinkTypeDTOFromJSON,
    LinkTypeDTOFromJSONTyped,
    LinkTypeDTOToJSON,
} from './LinkTypeDTO';

/**
 * 
 * @export
 * @interface UserProfileLinkDTO
 */
export interface UserProfileLinkDTO {
    /**
     * 
     * @type {LinkTypeDTO}
     * @memberof UserProfileLinkDTO
     */
    linkType: LinkTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinkDTO
     */
    url: string;
}



/**
 * Check if a given object implements the UserProfileLinkDTO interface.
 */
export function instanceOfUserProfileLinkDTO(value: object): value is UserProfileLinkDTO {
    if (!('linkType' in value) || value['linkType'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function UserProfileLinkDTOFromJSON(json: any): UserProfileLinkDTO {
    return UserProfileLinkDTOFromJSONTyped(json, false);
}

export function UserProfileLinkDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileLinkDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'linkType': LinkTypeDTOFromJSON(json['linkType']),
        'url': json['url'],
    };
}

export function UserProfileLinkDTOToJSON(value?: UserProfileLinkDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'linkType': LinkTypeDTOToJSON(value['linkType']),
        'url': value['url'],
    };
}

