/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NeptuneOauthToken
 */
export interface NeptuneOauthToken {
    /**
     * 
     * @type {string}
     * @memberof NeptuneOauthToken
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof NeptuneOauthToken
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof NeptuneOauthToken
     */
    username: string;
}

/**
 * Check if a given object implements the NeptuneOauthToken interface.
 */
export function instanceOfNeptuneOauthToken(value: object): value is NeptuneOauthToken {
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    if (!('refreshToken' in value) || value['refreshToken'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    return true;
}

export function NeptuneOauthTokenFromJSON(json: any): NeptuneOauthToken {
    return NeptuneOauthTokenFromJSONTyped(json, false);
}

export function NeptuneOauthTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): NeptuneOauthToken {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
        'username': json['username'],
    };
}

export function NeptuneOauthTokenToJSON(value?: NeptuneOauthToken | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accessToken': value['accessToken'],
        'refreshToken': value['refreshToken'],
        'username': value['username'],
    };
}

