import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { bemBlock } from '../../modules/bem';

import './SliderRoot.less';

const block = bemBlock('n-slider');

export const Root = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...rest }, ref) => (
  <SliderPrimitive.Root ref={ref} className={block({ extra: className })} {...rest} />
));
