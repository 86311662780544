/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PrioritizedAttributeDefinitionDTO } from './PrioritizedAttributeDefinitionDTO';
import {
    PrioritizedAttributeDefinitionDTOFromJSON,
    PrioritizedAttributeDefinitionDTOFromJSONTyped,
    PrioritizedAttributeDefinitionDTOToJSON,
} from './PrioritizedAttributeDefinitionDTO';

/**
 * 
 * @export
 * @interface QueryAttributeDefinitionsPrioritizedResultDTO
 */
export interface QueryAttributeDefinitionsPrioritizedResultDTO {
    /**
     * 
     * @type {Array<PrioritizedAttributeDefinitionDTO>}
     * @memberof QueryAttributeDefinitionsPrioritizedResultDTO
     */
    entries: Array<PrioritizedAttributeDefinitionDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof QueryAttributeDefinitionsPrioritizedResultDTO
     */
    hasMore: boolean;
}

/**
 * Check if a given object implements the QueryAttributeDefinitionsPrioritizedResultDTO interface.
 */
export function instanceOfQueryAttributeDefinitionsPrioritizedResultDTO(value: object): value is QueryAttributeDefinitionsPrioritizedResultDTO {
    if (!('entries' in value) || value['entries'] === undefined) return false;
    if (!('hasMore' in value) || value['hasMore'] === undefined) return false;
    return true;
}

export function QueryAttributeDefinitionsPrioritizedResultDTOFromJSON(json: any): QueryAttributeDefinitionsPrioritizedResultDTO {
    return QueryAttributeDefinitionsPrioritizedResultDTOFromJSONTyped(json, false);
}

export function QueryAttributeDefinitionsPrioritizedResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeDefinitionsPrioritizedResultDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(PrioritizedAttributeDefinitionDTOFromJSON)),
        'hasMore': json['hasMore'],
    };
}

export function QueryAttributeDefinitionsPrioritizedResultDTOToJSON(value?: QueryAttributeDefinitionsPrioritizedResultDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entries': ((value['entries'] as Array<any>).map(PrioritizedAttributeDefinitionDTOToJSON)),
        'hasMore': value['hasMore'],
    };
}

