import React from 'react';

import { Modal, ModalProps } from './Modal';
import type { ModalFooterProps } from './ModalFooter';
import type { ModalHeaderProps } from './ModalHeader';

type DialogProps = Pick<ModalProps, 'isOpen' | 'onClose'> &
  Pick<ModalHeaderProps, 'title'> &
  Pick<
    ModalFooterProps,
    | 'onPrimaryButtonClick'
    | 'onSecondaryButtonClick'
    | 'primaryButtonLabel'
    | 'primaryButtonRole'
    | 'secondaryButtonLabel'
    | 'secondaryButtonRole'
    | 'primaryButtonDisabled'
    | 'secondaryButtonDisabled'
    | 'primaryButtonDisabledReason'
    | 'secondaryButtonDisabledReason'
  > & {
    className?: string;
    closeOnBackgroundClick?: boolean;
    role?: string;
  };

const RawDialog: React.FC<DialogProps> = ({
  children,
  className,
  closeOnBackgroundClick = true,
  isOpen,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonDisabled,
  primaryButtonDisabledReason,
  primaryButtonLabel,
  primaryButtonRole,
  role,
  secondaryButtonDisabled,
  secondaryButtonDisabledReason,
  secondaryButtonLabel,
  secondaryButtonRole,
  title,
}) => {
  const backgroundHandler = closeOnBackgroundClick && onClose ? onClose : undefined;

  return (
    <Modal className={className} isOpen={isOpen} onClose={backgroundHandler} data={{ role }}>
      <Modal.Header title={title} onClose={onClose} />
      <Modal.Body>
        <Modal.Text>{children}</Modal.Text>
      </Modal.Body>
      {(onPrimaryButtonClick || onSecondaryButtonClick) && (
        <Modal.Footer
          primaryButtonLabel={primaryButtonLabel}
          secondaryButtonLabel={secondaryButtonLabel}
          primaryButtonRole={primaryButtonRole}
          secondaryButtonRole={secondaryButtonRole}
          primaryButtonDisabled={primaryButtonDisabled}
          secondaryButtonDisabled={secondaryButtonDisabled}
          primaryButtonDisabledReason={primaryButtonDisabledReason}
          secondaryButtonDisabledReason={secondaryButtonDisabledReason}
          onPrimaryButtonClick={onPrimaryButtonClick}
          onSecondaryButtonClick={onSecondaryButtonClick}
        />
      )}
    </Modal>
  );
};

const ExportableDialog = (props: DialogProps) => RawDialog(props);
ExportableDialog.Text = Modal.Text;

export const Dialog: React.FC<DialogProps> & {
  Text: typeof Modal.Text;
} = ExportableDialog;
