/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LogStringEntry } from './LogStringEntry';
import {
    LogStringEntryFromJSON,
    LogStringEntryFromJSONTyped,
    LogStringEntryToJSON,
} from './LogStringEntry';

/**
 * 
 * @export
 * @interface LogStringsOperation
 */
export interface LogStringsOperation {
    /**
     * 
     * @type {Array<LogStringEntry>}
     * @memberof LogStringsOperation
     */
    entries: Array<LogStringEntry>;
}

/**
 * Check if a given object implements the LogStringsOperation interface.
 */
export function instanceOfLogStringsOperation(value: object): value is LogStringsOperation {
    if (!('entries' in value) || value['entries'] === undefined) return false;
    return true;
}

export function LogStringsOperationFromJSON(json: any): LogStringsOperation {
    return LogStringsOperationFromJSONTyped(json, false);
}

export function LogStringsOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogStringsOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(LogStringEntryFromJSON)),
    };
}

export function LogStringsOperationToJSON(value?: LogStringsOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entries': ((value['entries'] as Array<any>).map(LogStringEntryToJSON)),
    };
}

