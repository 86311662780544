// Libs
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';

import { bemBlock, Layout } from '@neptune/shared/venus-ui';

import { CopyToClipboard } from '../../copy-to-clipboard/CopyToClipboard';
// Module
import { TextDiff } from '../../text-diff/TextDiff';
import type { PropertyDiffTableEntry } from '../types';

import './NewValueRenderer.less';

const block = bemBlock('new-value-renderer');

export const NewValueRenderer: React.FC<{ data: PropertyDiffTableEntry }> = ({ data }) => {
  const oldValue = data.oldValue && data.renderer ? data.renderer(data.oldValue) : data.oldValue;
  const newValue = data.newValue && data.renderer ? data.renderer(data.newValue) : data.newValue;
  const status = getStatus(data);
  const content =
    data.dataType === 'string' ? (
      <span>
        <TextDiff limit={200} value={`${newValue || ''}`} referenceValue={`${oldValue || ''}`} />
      </span>
    ) : (
      <span className={block({ element: 'content', modifiers: { status } })}>
        {newValue ? newValue : oldValue}
      </span>
    );
  return (
    <WithPaddingRenderer>
      <Layout.Row span="auto" alignItems="center">
        <Layout.Row withGutter="sm">{content}</Layout.Row>
        {data.copyable && newValue && <CopyToClipboard text={`${newValue}`} />}
      </Layout.Row>
    </WithPaddingRenderer>
  );
};

function getStatus(data: PropertyDiffTableEntry) {
  if (!data.oldValue) {
    return 'new';
  }

  if (!data.newValue) {
    return 'removed';
  }
}
