/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeStyleIdentifierDTO } from './AttributeStyleIdentifierDTO';
import {
    AttributeStyleIdentifierDTOFromJSON,
    AttributeStyleIdentifierDTOFromJSONTyped,
    AttributeStyleIdentifierDTOToJSON,
} from './AttributeStyleIdentifierDTO';

/**
 * 
 * @export
 * @interface SearchAttributesStyleSettingsParamsDTO
 */
export interface SearchAttributesStyleSettingsParamsDTO {
    /**
     * 
     * @type {Array<AttributeStyleIdentifierDTO>}
     * @memberof SearchAttributesStyleSettingsParamsDTO
     */
    attributes?: Array<AttributeStyleIdentifierDTO>;
    /**
     * 
     * @type {string}
     * @memberof SearchAttributesStyleSettingsParamsDTO
     */
    projectIdentifier: string;
}

/**
 * Check if a given object implements the SearchAttributesStyleSettingsParamsDTO interface.
 */
export function instanceOfSearchAttributesStyleSettingsParamsDTO(value: object): value is SearchAttributesStyleSettingsParamsDTO {
    if (!('projectIdentifier' in value) || value['projectIdentifier'] === undefined) return false;
    return true;
}

export function SearchAttributesStyleSettingsParamsDTOFromJSON(json: any): SearchAttributesStyleSettingsParamsDTO {
    return SearchAttributesStyleSettingsParamsDTOFromJSONTyped(json, false);
}

export function SearchAttributesStyleSettingsParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAttributesStyleSettingsParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributes': json['attributes'] == null ? undefined : ((json['attributes'] as Array<any>).map(AttributeStyleIdentifierDTOFromJSON)),
        'projectIdentifier': json['projectIdentifier'],
    };
}

export function SearchAttributesStyleSettingsParamsDTOToJSON(value?: SearchAttributesStyleSettingsParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributes': value['attributes'] == null ? undefined : ((value['attributes'] as Array<any>).map(AttributeStyleIdentifierDTOToJSON)),
        'projectIdentifier': value['projectIdentifier'],
    };
}

