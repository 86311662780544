import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutRow } from '../layout-row/LayoutRow';

import { useEmblemContext } from './EmblemContext';

import './EmblemWrapper.less';

const block = bemBlock('n-emblem-wrapper');

type EmblemWrapperProps = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onDoubleClick?: React.MouseEventHandler<HTMLElement>;
  'data-role'?: string;
  'data-id'?: string;
};

export const EmblemWrapper: React.FC<EmblemWrapperProps> = ({
  className,
  children,
  onClick,
  onDoubleClick,
  'data-role': dataRole,
  'data-id': dataId,
}) => {
  const { variant, size, active } = useEmblemContext();

  const cssClass = block({
    modifiers: {
      size,
      variant,
      active,
      clickable: Boolean(onClick),
    },
    extra: className,
  });

  return (
    <LayoutRow
      data-role={dataRole}
      data-id={dataId}
      className={cssClass}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      alignItems="center"
      span="auto"
    >
      {children}
    </LayoutRow>
  );
};
