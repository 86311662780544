/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OpenRangeDTO } from './OpenRangeDTO';
import {
    OpenRangeDTOFromJSON,
    OpenRangeDTOFromJSONTyped,
    OpenRangeDTOToJSON,
} from './OpenRangeDTO';

/**
 * 
 * @export
 * @interface PointFilters
 */
export interface PointFilters {
    /**
     * 
     * @type {OpenRangeDTO}
     * @memberof PointFilters
     */
    stepRange?: OpenRangeDTO;
}

/**
 * Check if a given object implements the PointFilters interface.
 */
export function instanceOfPointFilters(value: object): value is PointFilters {
    return true;
}

export function PointFiltersFromJSON(json: any): PointFilters {
    return PointFiltersFromJSONTyped(json, false);
}

export function PointFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'stepRange': json['stepRange'] == null ? undefined : OpenRangeDTOFromJSON(json['stepRange']),
    };
}

export function PointFiltersToJSON(value?: PointFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stepRange': OpenRangeDTOToJSON(value['stepRange']),
    };
}

