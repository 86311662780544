/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface LeaderboardSortParamsDTO
 */
export interface LeaderboardSortParamsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardSortParamsDTO
     */
    sortBy?: Array<string>;
    /**
     * 
     * @type {Array<AttributeTypeDTO>}
     * @memberof LeaderboardSortParamsDTO
     */
    sortFieldType?: Array<AttributeTypeDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardSortParamsDTO
     */
    sortFieldAggregationMode?: Array<LeaderboardSortParamsDTOSortFieldAggregationModeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardSortParamsDTO
     */
    sortDirection?: Array<LeaderboardSortParamsDTOSortDirectionEnum>;
}


/**
 * @export
 */
export const LeaderboardSortParamsDTOSortFieldAggregationModeEnum = {
    Last: 'last',
    Min: 'min',
    Max: 'max',
    Average: 'average',
    Variance: 'variance',
    Auto: 'auto'
} as const;
export type LeaderboardSortParamsDTOSortFieldAggregationModeEnum = typeof LeaderboardSortParamsDTOSortFieldAggregationModeEnum[keyof typeof LeaderboardSortParamsDTOSortFieldAggregationModeEnum];

/**
 * @export
 */
export const LeaderboardSortParamsDTOSortDirectionEnum = {
    Ascending: 'ascending',
    Descending: 'descending'
} as const;
export type LeaderboardSortParamsDTOSortDirectionEnum = typeof LeaderboardSortParamsDTOSortDirectionEnum[keyof typeof LeaderboardSortParamsDTOSortDirectionEnum];


/**
 * Check if a given object implements the LeaderboardSortParamsDTO interface.
 */
export function instanceOfLeaderboardSortParamsDTO(value: object): value is LeaderboardSortParamsDTO {
    return true;
}

export function LeaderboardSortParamsDTOFromJSON(json: any): LeaderboardSortParamsDTO {
    return LeaderboardSortParamsDTOFromJSONTyped(json, false);
}

export function LeaderboardSortParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardSortParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sortBy': json['sortBy'] == null ? undefined : json['sortBy'],
        'sortFieldType': json['sortFieldType'] == null ? undefined : ((json['sortFieldType'] as Array<any>).map(AttributeTypeDTOFromJSON)),
        'sortFieldAggregationMode': json['sortFieldAggregationMode'] == null ? undefined : json['sortFieldAggregationMode'],
        'sortDirection': json['sortDirection'] == null ? undefined : json['sortDirection'],
    };
}

export function LeaderboardSortParamsDTOToJSON(value?: LeaderboardSortParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sortBy': value['sortBy'],
        'sortFieldType': value['sortFieldType'] == null ? undefined : ((value['sortFieldType'] as Array<any>).map(AttributeTypeDTOToJSON)),
        'sortFieldAggregationMode': value['sortFieldAggregationMode'],
        'sortDirection': value['sortDirection'],
    };
}

