/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExperimentTypeDTO = {
    Run: 'run',
    Model: 'model',
    ModelVersion: 'modelVersion',
    Project: 'project',
    Report: 'report'
} as const;
export type ExperimentTypeDTO = typeof ExperimentTypeDTO[keyof typeof ExperimentTypeDTO];


export function instanceOfExperimentTypeDTO(value: any): boolean {
    for (const key in ExperimentTypeDTO) {
        if (Object.prototype.hasOwnProperty.call(ExperimentTypeDTO, key)) {
            if (ExperimentTypeDTO[key as keyof typeof ExperimentTypeDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ExperimentTypeDTOFromJSON(json: any): ExperimentTypeDTO {
    return ExperimentTypeDTOFromJSONTyped(json, false);
}

export function ExperimentTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentTypeDTO {
    return json as ExperimentTypeDTO;
}

export function ExperimentTypeDTOToJSON(value?: ExperimentTypeDTO | null): any {
    return value as any;
}

