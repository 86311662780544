import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { bemBlock } from '../../../modules/bem';
import { LayoutRow } from '../../layout-row/LayoutRow';
import { Switcher } from '../../switcher/Switcher';
import { Text } from '../../text/Text';

import './ActionsMenuSwitcherItem.less';

const block = bemBlock('n-actions-menu-item');

type SwitcherItemOwnProps = {
  leadingIcon?: React.ReactNode;
  checked?: boolean;
};

type SwitcherItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.CheckboxItem> &
  SwitcherItemOwnProps;

export const ActionsMenuSwitcherItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.Item>,
  SwitcherItemProps
>(({ className, children, leadingIcon, checked, disabled, onCheckedChange, ...props }, ref) => {
  return (
    <DropdownMenu.CheckboxItem
      ref={ref}
      className={block({
        extra: className,
      })}
      disabled={disabled}
      checked={checked}
      onCheckedChange={onCheckedChange}
      {...props}
    >
      <LayoutRow span="auto" alignItems="center" spacedChildren="sm">
        {leadingIcon}
        <Text size="sm">{children}</Text>
      </LayoutRow>
      <Switcher
        data-role="switcher-item-switcher"
        checked={checked}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={onCheckedChange}
      />
    </DropdownMenu.CheckboxItem>
  );
});
