/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AvatarSourceEnum } from './AvatarSourceEnum';
import {
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
} from './AvatarSourceEnum';
import type { UserProfileLinksDTO } from './UserProfileLinksDTO';
import {
    UserProfileLinksDTOFromJSON,
    UserProfileLinksDTOFromJSONTyped,
    UserProfileLinksDTOToJSON,
} from './UserProfileLinksDTO';

/**
 * 
 * @export
 * @interface PublicUserProfileDTO
 */
export interface PublicUserProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    biography: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    email?: string;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof PublicUserProfileDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    shortInfo: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    lastName?: string;
    /**
     * 
     * @type {UserProfileLinksDTO}
     * @memberof PublicUserProfileDTO
     */
    links: UserProfileLinksDTO;
}



/**
 * Check if a given object implements the PublicUserProfileDTO interface.
 */
export function instanceOfPublicUserProfileDTO(value: object): value is PublicUserProfileDTO {
    if (!('biography' in value) || value['biography'] === undefined) return false;
    if (!('avatarSource' in value) || value['avatarSource'] === undefined) return false;
    if (!('shortInfo' in value) || value['shortInfo'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('avatarUrl' in value) || value['avatarUrl'] === undefined) return false;
    if (!('links' in value) || value['links'] === undefined) return false;
    return true;
}

export function PublicUserProfileDTOFromJSON(json: any): PublicUserProfileDTO {
    return PublicUserProfileDTOFromJSONTyped(json, false);
}

export function PublicUserProfileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicUserProfileDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'biography': json['biography'],
        'email': json['email'] == null ? undefined : json['email'],
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'shortInfo': json['shortInfo'],
        'username': json['username'],
        'avatarUrl': json['avatarUrl'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'links': UserProfileLinksDTOFromJSON(json['links']),
    };
}

export function PublicUserProfileDTOToJSON(value?: PublicUserProfileDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'biography': value['biography'],
        'email': value['email'],
        'avatarSource': AvatarSourceEnumToJSON(value['avatarSource']),
        'firstName': value['firstName'],
        'shortInfo': value['shortInfo'],
        'username': value['username'],
        'avatarUrl': value['avatarUrl'],
        'lastName': value['lastName'],
        'links': UserProfileLinksDTOToJSON(value['links']),
    };
}

