// Libs
import React from 'react';

import { bemBlock } from '@neptune/shared/venus-ui';

// Module
import './KeycloakBrands.less';

const block = bemBlock('keycloak-brands');

export const KeycloakBrands: React.FC = () => {
  return (
    <footer className={block()}>
      <div className={block('image')} />
    </footer>
  );
};
