import React from 'react';

import { bemBlock } from '../../modules/bem';

import { ToggleSwitchContext } from './ToggleSwitchContext';

import './ToggleSwitchButton.less';

const block = bemBlock('n-toggle-switch-button');

type ToggleSwitchButtonProps = {
  'data-role'?: string;
  'data-active'?: boolean;
  value: string;
  children: React.ReactNode;
  className?: string;
};

export function ToggleSwitchButton({
  'data-role': dataRole,
  'data-active': dataActive,
  value,
  children,
  className,
}: ToggleSwitchButtonProps) {
  const context = React.useContext(ToggleSwitchContext);
  const { selectedValue, disabled, onChange } = context ?? {};

  if (context === undefined) {
    throw new Error('ToggleSwitchButton is missing ToggleSwitchContext');
  }

  const handleChange = React.useCallback(() => {
    if (!onChange || value === selectedValue) {
      return;
    }

    onChange(value);
  }, [onChange, selectedValue, value]);

  return (
    <button
      data-role={dataRole}
      data-option={value}
      className={block({
        modifiers: { selected: selectedValue === value, disabled },
        extra: className,
      })}
      disabled={disabled}
      onClick={handleChange}
      children={children}
      data-active={dataActive}
    />
  );
}
