/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectKeyAvailabilityDTO
 */
export interface ProjectKeyAvailabilityDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectKeyAvailabilityDTO
     */
    isKeyAvailable: boolean;
}

/**
 * Check if a given object implements the ProjectKeyAvailabilityDTO interface.
 */
export function instanceOfProjectKeyAvailabilityDTO(value: object): value is ProjectKeyAvailabilityDTO {
    if (!('isKeyAvailable' in value) || value['isKeyAvailable'] === undefined) return false;
    return true;
}

export function ProjectKeyAvailabilityDTOFromJSON(json: any): ProjectKeyAvailabilityDTO {
    return ProjectKeyAvailabilityDTOFromJSONTyped(json, false);
}

export function ProjectKeyAvailabilityDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectKeyAvailabilityDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'isKeyAvailable': json['isKeyAvailable'],
    };
}

export function ProjectKeyAvailabilityDTOToJSON(value?: ProjectKeyAvailabilityDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isKeyAvailable': value['isKeyAvailable'],
    };
}

