/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoginActionDTO } from './LoginActionDTO';
import {
    LoginActionDTOFromJSON,
    LoginActionDTOFromJSONTyped,
    LoginActionDTOToJSON,
} from './LoginActionDTO';

/**
 * 
 * @export
 * @interface LoginActionsListDTO
 */
export interface LoginActionsListDTO {
    /**
     * 
     * @type {Array<LoginActionDTO>}
     * @memberof LoginActionsListDTO
     */
    requiredActions: Array<LoginActionDTO>;
}

/**
 * Check if a given object implements the LoginActionsListDTO interface.
 */
export function instanceOfLoginActionsListDTO(value: object): value is LoginActionsListDTO {
    if (!('requiredActions' in value) || value['requiredActions'] === undefined) return false;
    return true;
}

export function LoginActionsListDTOFromJSON(json: any): LoginActionsListDTO {
    return LoginActionsListDTOFromJSONTyped(json, false);
}

export function LoginActionsListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginActionsListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'requiredActions': ((json['requiredActions'] as Array<any>).map(LoginActionDTOFromJSON)),
    };
}

export function LoginActionsListDTOToJSON(value?: LoginActionsListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requiredActions': ((value['requiredActions'] as Array<any>).map(LoginActionDTOToJSON)),
    };
}

