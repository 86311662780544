import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { bemBlock } from '../../../modules/bem';
import { Icon } from '../../icon/Icon';
import { LayoutRow } from '../../layout-row/LayoutRow';
import { Text } from '../../text/Text';

import './ActionsMenuCheckItem.less';

const block = bemBlock('n-actions-menu-check-item');

type CheckItemOwnProps = {
  leadingIcon?: React.ReactNode;
};

type CheckItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.CheckboxItem> &
  CheckItemOwnProps;

export const ActionsMenuCheckItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.CheckboxItem>,
  CheckItemProps
>(({ className, children, leadingIcon, ...props }, ref) => {
  return (
    <DropdownMenu.CheckboxItem
      ref={ref}
      className={block({
        extra: className,
        modifiers: {
          check: true,
        },
      })}
      {...props}
    >
      <LayoutRow span="auto" alignItems="center" spacedChildren="sm">
        {leadingIcon}
        <Text size="sm">{children}</Text>
      </LayoutRow>
      <DropdownMenu.ItemIndicator>
        <Icon glyph="check" />
      </DropdownMenu.ItemIndicator>
    </DropdownMenu.CheckboxItem>
  );
});
