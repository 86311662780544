/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface LeaderboardFieldSuggestionDTO
 */
export interface LeaderboardFieldSuggestionDTO {
    /**
     * 
     * @type {string}
     * @memberof LeaderboardFieldSuggestionDTO
     */
    name: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof LeaderboardFieldSuggestionDTO
     */
    type: AttributeTypeDTO;
}



/**
 * Check if a given object implements the LeaderboardFieldSuggestionDTO interface.
 */
export function instanceOfLeaderboardFieldSuggestionDTO(value: object): value is LeaderboardFieldSuggestionDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function LeaderboardFieldSuggestionDTOFromJSON(json: any): LeaderboardFieldSuggestionDTO {
    return LeaderboardFieldSuggestionDTOFromJSONTyped(json, false);
}

export function LeaderboardFieldSuggestionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardFieldSuggestionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': AttributeTypeDTOFromJSON(json['type']),
    };
}

export function LeaderboardFieldSuggestionDTOToJSON(value?: LeaderboardFieldSuggestionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'type': AttributeTypeDTOToJSON(value['type']),
    };
}

