import React from 'react';

import { RouterLink } from '@neptune/shared/routing-ui';
import { Text } from '@neptune/shared/venus-ui';

type BackLinkProps = {
  className?: string;
  routeName: string;
  routeParams?: Record<string, any>;
  text: string;
};

export const BackLink: React.FC<BackLinkProps> = ({ className, routeName, routeParams, text }) => (
  <RouterLink
    className={className}
    fontWeight="normal"
    size="sm"
    routeName={routeName}
    routeParams={routeParams}
    data-role="back-link"
  >
    <Text ellipsis>&lt; {text}</Text>
  </RouterLink>
);
