import React from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { bemBlock } from '../../modules/bem';

import './ScrollAreaScrollbar.less';

const block = bemBlock('n-scroll-area-scrollbar');

export const ScrollAreaScrollbar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Scrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Scrollbar>
>(({ className, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.Scrollbar
      ref={ref}
      className={block({
        extra: className,
      })}
      {...props}
    />
  );
});
