/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryLeaderboardParamsPaginationDTO
 */
export interface QueryLeaderboardParamsPaginationDTO {
    /**
     * 
     * @type {number}
     * @memberof QueryLeaderboardParamsPaginationDTO
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLeaderboardParamsPaginationDTO
     */
    offset?: number;
}

/**
 * Check if a given object implements the QueryLeaderboardParamsPaginationDTO interface.
 */
export function instanceOfQueryLeaderboardParamsPaginationDTO(value: object): value is QueryLeaderboardParamsPaginationDTO {
    return true;
}

export function QueryLeaderboardParamsPaginationDTOFromJSON(json: any): QueryLeaderboardParamsPaginationDTO {
    return QueryLeaderboardParamsPaginationDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsPaginationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsPaginationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'] == null ? undefined : json['limit'],
        'offset': json['offset'] == null ? undefined : json['offset'],
    };
}

export function QueryLeaderboardParamsPaginationDTOToJSON(value?: QueryLeaderboardParamsPaginationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
    };
}

