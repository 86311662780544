import React from 'react';

import { GlobalFullscreenContext } from '@neptune/shared/common-domain';

import { fullscreenActiveDefaultZIndex } from './fullscreen-active-default-zindex';

type CssPosition = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

const FULLSCREEN_POSITION: CssPosition = Object.freeze({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

function getLayerPosition(element: HTMLElement): CssPosition {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    left: rect.left,
    bottom: window.innerHeight - rect.bottom,
    right: window.innerWidth - rect.right,
  };
}

function setFixedPosition(el: HTMLElement, position: CssPosition, zIndex: string) {
  el.style.zIndex = zIndex;
  el.style.position = 'fixed';
  el.style.height = 'auto';
  el.style.width = 'auto';
  // We have to add scrolling capability to the wrapper layer due to tether.
  // Tether is configured to respect and place overlay inside layer with scrolling.
  // However we're changing position to fixed and visually escaping from parent
  // scrolling area, but tether is not aware of this, so we have to create a brand
  // new scrolling area especially for fullscreen to get rid of parent restrictions.
  el.style.overflowY = 'auto';
  el.style.top = `${position.top}px`;
  el.style.left = `${position.left}px`;
  el.style.bottom = `${position.bottom}px`;
  el.style.right = `${position.right}px`;
}

function resetStyles(element: HTMLElement) {
  element.style.top = '';
  element.style.left = '';
  element.style.bottom = '';
  element.style.right = '';
  element.style.position = '';
  element.style.height = '';
  element.style.width = '';
  element.style.overflowY = '';
  element.style.zIndex = '';
}

export function useFullScreenAnimation(
  fullscreen?: boolean,
  onMaximize?: () => void,
  onRestore?: () => void,
) {
  const layerPosition = React.useRef<CssPosition>();
  const elementRef = React.useRef<HTMLElement | null>(null);
  const { maximize, restore } = React.useContext(GlobalFullscreenContext);

  React.useLayoutEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const cssZIndex = `${fullscreenActiveDefaultZIndex}`;

    let timeout: ReturnType<typeof setTimeout>;

    if (fullscreen) {
      layerPosition.current = getLayerPosition(elementRef.current);

      onMaximize?.();
      setFixedPosition(elementRef.current, layerPosition.current, cssZIndex);
      // delay is crucial to make transition effect present
      timeout = setTimeout(() => {
        if (!elementRef.current) {
          return;
        }

        setFixedPosition(elementRef.current, FULLSCREEN_POSITION, cssZIndex);
      }, 50);
      maximize();
    } else {
      if (layerPosition.current) {
        // transition to previous position
        setFixedPosition(elementRef.current, layerPosition.current, cssZIndex);
      }

      // restore all styles (including `position: fixed`)
      timeout = setTimeout(
        () => {
          if (!elementRef.current) {
            return;
          }

          resetStyles(elementRef.current);
          onRestore?.();
          restore();
        },
        layerPosition.current ? 200 : 0,
      );
    }

    return () => clearTimeout(timeout);
  }, [fullscreen, maximize, onMaximize, onRestore, restore]);

  return React.useCallback((ref: HTMLElement) => {
    if (!ref) {
      return;
    }

    elementRef.current = ref;
  }, []);
}
