/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecurityDTO
 */
export interface SecurityDTO {
    /**
     * 
     * @type {string}
     * @memberof SecurityDTO
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityDTO
     */
    openIdDiscovery: string;
}

/**
 * Check if a given object implements the SecurityDTO interface.
 */
export function instanceOfSecurityDTO(value: object): value is SecurityDTO {
    if (!('clientId' in value) || value['clientId'] === undefined) return false;
    if (!('openIdDiscovery' in value) || value['openIdDiscovery'] === undefined) return false;
    return true;
}

export function SecurityDTOFromJSON(json: any): SecurityDTO {
    return SecurityDTOFromJSONTyped(json, false);
}

export function SecurityDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'openIdDiscovery': json['openIdDiscovery'],
    };
}

export function SecurityDTOToJSON(value?: SecurityDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clientId': value['clientId'],
        'openIdDiscovery': value['openIdDiscovery'],
    };
}

