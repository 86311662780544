/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewCheckpointDTO
 */
export interface NewCheckpointDTO {
    /**
     * 
     * @type {string}
     * @memberof NewCheckpointDTO
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof NewCheckpointDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCheckpointDTO
     */
    description?: string;
}

/**
 * Check if a given object implements the NewCheckpointDTO interface.
 */
export function instanceOfNewCheckpointDTO(value: object): value is NewCheckpointDTO {
    if (!('path' in value) || value['path'] === undefined) return false;
    return true;
}

export function NewCheckpointDTOFromJSON(json: any): NewCheckpointDTO {
    return NewCheckpointDTOFromJSONTyped(json, false);
}

export function NewCheckpointDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewCheckpointDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'path': json['path'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function NewCheckpointDTOToJSON(value?: NewCheckpointDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'path': value['path'],
        'name': value['name'],
        'description': value['description'],
    };
}

