/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlotMode
 */
export interface PlotMode {
    /**
     * 
     * @type {object}
     * @memberof PlotMode
     */
    line?: object;
    /**
     * 
     * @type {object}
     * @memberof PlotMode
     */
    scatter?: object;
}

/**
 * Check if a given object implements the PlotMode interface.
 */
export function instanceOfPlotMode(value: object): value is PlotMode {
    return true;
}

export function PlotModeFromJSON(json: any): PlotMode {
    return PlotModeFromJSONTyped(json, false);
}

export function PlotModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlotMode {
    if (json == null) {
        return json;
    }
    return {
        
        'line': json['line'] == null ? undefined : json['line'],
        'scatter': json['scatter'] == null ? undefined : json['scatter'],
    };
}

export function PlotModeToJSON(value?: PlotMode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'line': value['line'],
        'scatter': value['scatter'],
    };
}

