/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaderboardViewColumnDTO } from './LeaderboardViewColumnDTO';
import {
    LeaderboardViewColumnDTOFromJSON,
    LeaderboardViewColumnDTOFromJSONTyped,
    LeaderboardViewColumnDTOToJSON,
} from './LeaderboardViewColumnDTO';

/**
 * 
 * @export
 * @interface LeaderboardViewColumnListDTO
 */
export interface LeaderboardViewColumnListDTO {
    /**
     * 
     * @type {Array<LeaderboardViewColumnDTO>}
     * @memberof LeaderboardViewColumnListDTO
     */
    columns: Array<LeaderboardViewColumnDTO>;
}

/**
 * Check if a given object implements the LeaderboardViewColumnListDTO interface.
 */
export function instanceOfLeaderboardViewColumnListDTO(value: object): value is LeaderboardViewColumnListDTO {
    if (!('columns' in value) || value['columns'] === undefined) return false;
    return true;
}

export function LeaderboardViewColumnListDTOFromJSON(json: any): LeaderboardViewColumnListDTO {
    return LeaderboardViewColumnListDTOFromJSONTyped(json, false);
}

export function LeaderboardViewColumnListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardViewColumnListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'columns': ((json['columns'] as Array<any>).map(LeaderboardViewColumnDTOFromJSON)),
    };
}

export function LeaderboardViewColumnListDTOToJSON(value?: LeaderboardViewColumnListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'columns': ((value['columns'] as Array<any>).map(LeaderboardViewColumnDTOToJSON)),
    };
}

