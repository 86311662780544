import React from 'react';
import ReactModal from 'react-modal';

import { bemBlock } from '../../modules/bem';
import { Card } from '../card/Card';

import ModalBody from './ModalBody';
import ModalButton from './ModalButton';
import ModalClose from './ModalClose';
import ModalFooter from './ModalFooter';
import ModalFooterNew from './ModalFooterNew';
import ModalHeader from './ModalHeader';
import ModalText from './ModalText';

import './Modal.less';

export type ModalProps = ReactModal.Props & {
  className?: string;
  contentClassName?: string;
  data?: { [key: string]: string };
  variant?: 'full' | 'simple';
  size?: 'sm' | 'md' | 'lg' | 'adaptive';
  onClose?: () => void;
  shouldCloseOnEsc?: boolean;
};

const block = bemBlock('n-modal');

export class Modal extends React.Component<ModalProps> {
  static defaultProps = {
    isOpen: false,
    variant: 'full',
    size: 'md',
    shouldCloseOnEsc: true,
  };

  static Body = ModalBody;
  static Button = ModalButton;
  static Close = ModalClose;
  static Footer = ModalFooter;
  static FooterNew = ModalFooterNew;
  static Header = ModalHeader;
  static Text = ModalText;

  render() {
    const {
      className,
      children,
      isOpen,
      variant,
      size,
      onClose,
      contentClassName,
      shouldCloseOnEsc,
      ...restProps
    } = this.props;

    // Will prevent rendering ReactModalPortal
    // this will assure, that modals are displayed in order they are opened
    if (!isOpen) {
      return null;
    }

    const cssClass = block({
      extra: [className],
      modifiers: {
        simple: variant === 'simple',
        size,
      },
    });

    return (
      <ReactModal
        className={{
          base: cssClass,
          afterOpen: block({ modifiers: ['after-open'] }),
          beforeClose: block({ modifiers: ['before-close'] }),
        }}
        isOpen={isOpen}
        overlayClassName={{
          base: block('overlay'),
          afterOpen: block({ element: 'overlay', modifiers: ['after-open'] }),
          beforeClose: block({ element: 'overlay', modifiers: ['before-close'] }),
        }}
        contentLabel="neptune-modal" // even though we don't use aria, this prop is required
        ariaHideApp={false}
        closeTimeoutMS={150}
        onRequestClose={onClose}
        shouldCloseOnEsc={shouldCloseOnEsc}
        {...restProps}
      >
        <Card className={block({ element: 'card', extra: contentClassName })} span="shrink">
          {children}
        </Card>
      </ReactModal>
    );
  }
}
