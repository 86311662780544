/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectRoleDTO } from './ProjectRoleDTO';
import {
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './ProjectRoleDTO';
import type { InvitationTypeEnumDTO } from './InvitationTypeEnumDTO';
import {
    InvitationTypeEnumDTOFromJSON,
    InvitationTypeEnumDTOFromJSONTyped,
    InvitationTypeEnumDTOToJSON,
} from './InvitationTypeEnumDTO';

/**
 * 
 * @export
 * @interface NewProjectInvitationDTO
 */
export interface NewProjectInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof NewProjectInvitationDTO
     */
    projectIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectInvitationDTO
     */
    invitee: string;
    /**
     * 
     * @type {InvitationTypeEnumDTO}
     * @memberof NewProjectInvitationDTO
     */
    invitationType: InvitationTypeEnumDTO;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof NewProjectInvitationDTO
     */
    roleGrant: ProjectRoleDTO;
}



/**
 * Check if a given object implements the NewProjectInvitationDTO interface.
 */
export function instanceOfNewProjectInvitationDTO(value: object): value is NewProjectInvitationDTO {
    if (!('projectIdentifier' in value) || value['projectIdentifier'] === undefined) return false;
    if (!('invitee' in value) || value['invitee'] === undefined) return false;
    if (!('invitationType' in value) || value['invitationType'] === undefined) return false;
    if (!('roleGrant' in value) || value['roleGrant'] === undefined) return false;
    return true;
}

export function NewProjectInvitationDTOFromJSON(json: any): NewProjectInvitationDTO {
    return NewProjectInvitationDTOFromJSONTyped(json, false);
}

export function NewProjectInvitationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewProjectInvitationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'projectIdentifier': json['projectIdentifier'],
        'invitee': json['invitee'],
        'invitationType': InvitationTypeEnumDTOFromJSON(json['invitationType']),
        'roleGrant': ProjectRoleDTOFromJSON(json['roleGrant']),
    };
}

export function NewProjectInvitationDTOToJSON(value?: NewProjectInvitationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectIdentifier': value['projectIdentifier'],
        'invitee': value['invitee'],
        'invitationType': InvitationTypeEnumDTOToJSON(value['invitationType']),
        'roleGrant': ProjectRoleDTOToJSON(value['roleGrant']),
    };
}

