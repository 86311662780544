/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface NotebookRefAttributeDTO
 */
export interface NotebookRefAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof NotebookRefAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {Date}
     * @memberof NotebookRefAttributeDTO
     */
    checkpointCreated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NotebookRefAttributeDTO
     */
    checkpointDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    checkpointId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    checkpointName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotebookRefAttributeDTO
     */
    notebookDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    notebookId: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    notebookName?: string;
}



/**
 * Check if a given object implements the NotebookRefAttributeDTO interface.
 */
export function instanceOfNotebookRefAttributeDTO(value: object): value is NotebookRefAttributeDTO {
    if (!('attributeName' in value) || value['attributeName'] === undefined) return false;
    if (!('attributeType' in value) || value['attributeType'] === undefined) return false;
    if (!('notebookDeleted' in value) || value['notebookDeleted'] === undefined) return false;
    if (!('notebookId' in value) || value['notebookId'] === undefined) return false;
    return true;
}

export function NotebookRefAttributeDTOFromJSON(json: any): NotebookRefAttributeDTO {
    return NotebookRefAttributeDTOFromJSONTyped(json, false);
}

export function NotebookRefAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotebookRefAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'checkpointCreated': json['checkpointCreated'] == null ? undefined : (new Date(json['checkpointCreated'])),
        'checkpointDeleted': json['checkpointDeleted'] == null ? undefined : json['checkpointDeleted'],
        'checkpointId': json['checkpointId'] == null ? undefined : json['checkpointId'],
        'checkpointName': json['checkpointName'] == null ? undefined : json['checkpointName'],
        'notebookDeleted': json['notebookDeleted'],
        'notebookId': json['notebookId'],
        'notebookName': json['notebookName'] == null ? undefined : json['notebookName'],
    };
}

export function NotebookRefAttributeDTOToJSON(value?: NotebookRefAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeName': value['attributeName'],
        'attributeType': AttributeTypeDTOToJSON(value['attributeType']),
        'checkpointCreated': value['checkpointCreated'] == null ? undefined : ((value['checkpointCreated']).toISOString()),
        'checkpointDeleted': value['checkpointDeleted'],
        'checkpointId': value['checkpointId'],
        'checkpointName': value['checkpointName'],
        'notebookDeleted': value['notebookDeleted'],
        'notebookId': value['notebookId'],
        'notebookName': value['notebookName'],
    };
}

