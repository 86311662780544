/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PricingPlanDTO } from './PricingPlanDTO';
import {
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './PricingPlanDTO';

/**
 * 
 * @export
 * @interface OrganizationCreationParamsDTO
 */
export interface OrganizationCreationParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreationParamsDTO
     */
    organizationName: string;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof OrganizationCreationParamsDTO
     */
    pricingPlan: PricingPlanDTO;
}



/**
 * Check if a given object implements the OrganizationCreationParamsDTO interface.
 */
export function instanceOfOrganizationCreationParamsDTO(value: object): value is OrganizationCreationParamsDTO {
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('pricingPlan' in value) || value['pricingPlan'] === undefined) return false;
    return true;
}

export function OrganizationCreationParamsDTOFromJSON(json: any): OrganizationCreationParamsDTO {
    return OrganizationCreationParamsDTOFromJSONTyped(json, false);
}

export function OrganizationCreationParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationCreationParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'organizationName': json['organizationName'],
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
    };
}

export function OrganizationCreationParamsDTOToJSON(value?: OrganizationCreationParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'organizationName': value['organizationName'],
        'pricingPlan': PricingPlanDTOToJSON(value['pricingPlan']),
    };
}

