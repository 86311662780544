/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TypeDefinitionDTO
 */
export interface TypeDefinitionDTO {
    /**
     * 
     * @type {string}
     * @memberof TypeDefinitionDTO
     */
    name?: string;
}

/**
 * Check if a given object implements the TypeDefinitionDTO interface.
 */
export function instanceOfTypeDefinitionDTO(value: object): value is TypeDefinitionDTO {
    return true;
}

export function TypeDefinitionDTOFromJSON(json: any): TypeDefinitionDTO {
    return TypeDefinitionDTOFromJSONTyped(json, false);
}

export function TypeDefinitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeDefinitionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function TypeDefinitionDTOToJSON(value?: TypeDefinitionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

