/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeSeries } from './TimeSeries';
import {
    TimeSeriesFromJSON,
    TimeSeriesFromJSONTyped,
    TimeSeriesToJSON,
} from './TimeSeries';

/**
 * 
 * @export
 * @interface FloatTimeSeriesValuesRequestSeries
 */
export interface FloatTimeSeriesValuesRequestSeries {
    /**
     * 
     * @type {number}
     * @memberof FloatTimeSeriesValuesRequestSeries
     */
    afterStep?: number;
    /**
     * 
     * @type {string}
     * @memberof FloatTimeSeriesValuesRequestSeries
     */
    requestId: string;
    /**
     * 
     * @type {TimeSeries}
     * @memberof FloatTimeSeriesValuesRequestSeries
     */
    series: TimeSeries;
}

/**
 * Check if a given object implements the FloatTimeSeriesValuesRequestSeries interface.
 */
export function instanceOfFloatTimeSeriesValuesRequestSeries(value: object): value is FloatTimeSeriesValuesRequestSeries {
    if (!('requestId' in value) || value['requestId'] === undefined) return false;
    if (!('series' in value) || value['series'] === undefined) return false;
    return true;
}

export function FloatTimeSeriesValuesRequestSeriesFromJSON(json: any): FloatTimeSeriesValuesRequestSeries {
    return FloatTimeSeriesValuesRequestSeriesFromJSONTyped(json, false);
}

export function FloatTimeSeriesValuesRequestSeriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatTimeSeriesValuesRequestSeries {
    if (json == null) {
        return json;
    }
    return {
        
        'afterStep': json['afterStep'] == null ? undefined : json['afterStep'],
        'requestId': json['requestId'],
        'series': TimeSeriesFromJSON(json['series']),
    };
}

export function FloatTimeSeriesValuesRequestSeriesToJSON(value?: FloatTimeSeriesValuesRequestSeries | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'afterStep': value['afterStep'],
        'requestId': value['requestId'],
        'series': TimeSeriesToJSON(value['series']),
    };
}

