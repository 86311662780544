/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrganizationTypeDTO = {
    Individual: 'individual',
    Team: 'team'
} as const;
export type OrganizationTypeDTO = typeof OrganizationTypeDTO[keyof typeof OrganizationTypeDTO];


export function instanceOfOrganizationTypeDTO(value: any): boolean {
    for (const key in OrganizationTypeDTO) {
        if (Object.prototype.hasOwnProperty.call(OrganizationTypeDTO, key)) {
            if (OrganizationTypeDTO[key as keyof typeof OrganizationTypeDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrganizationTypeDTOFromJSON(json: any): OrganizationTypeDTO {
    return OrganizationTypeDTOFromJSONTyped(json, false);
}

export function OrganizationTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationTypeDTO {
    return json as OrganizationTypeDTO;
}

export function OrganizationTypeDTOToJSON(value?: OrganizationTypeDTO | null): any {
    return value as any;
}

