/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectChartFiltersDTO } from './ProjectChartFiltersDTO';
import {
    ProjectChartFiltersDTOFromJSON,
    ProjectChartFiltersDTOFromJSONTyped,
    ProjectChartFiltersDTOToJSON,
} from './ProjectChartFiltersDTO';
import type { ProjectChartMetricDTO } from './ProjectChartMetricDTO';
import {
    ProjectChartMetricDTOFromJSON,
    ProjectChartMetricDTOFromJSONTyped,
    ProjectChartMetricDTOToJSON,
} from './ProjectChartMetricDTO';

/**
 * 
 * @export
 * @interface ProjectChartDTO
 */
export interface ProjectChartDTO {
    /**
     * 
     * @type {ProjectChartFiltersDTO}
     * @memberof ProjectChartDTO
     */
    filters: ProjectChartFiltersDTO;
    /**
     * Chart id
     * @type {string}
     * @memberof ProjectChartDTO
     */
    id: string;
    /**
     * Chart metrics
     * @type {Array<ProjectChartMetricDTO>}
     * @memberof ProjectChartDTO
     */
    metrics: Array<ProjectChartMetricDTO>;
    /**
     * Chart name
     * @type {string}
     * @memberof ProjectChartDTO
     */
    name: string;
}

/**
 * Check if a given object implements the ProjectChartDTO interface.
 */
export function instanceOfProjectChartDTO(value: object): value is ProjectChartDTO {
    if (!('filters' in value) || value['filters'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('metrics' in value) || value['metrics'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ProjectChartDTOFromJSON(json: any): ProjectChartDTO {
    return ProjectChartDTOFromJSONTyped(json, false);
}

export function ProjectChartDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectChartDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'filters': ProjectChartFiltersDTOFromJSON(json['filters']),
        'id': json['id'],
        'metrics': ((json['metrics'] as Array<any>).map(ProjectChartMetricDTOFromJSON)),
        'name': json['name'],
    };
}

export function ProjectChartDTOToJSON(value?: ProjectChartDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filters': ProjectChartFiltersDTOToJSON(value['filters']),
        'id': value['id'],
        'metrics': ((value['metrics'] as Array<any>).map(ProjectChartMetricDTOToJSON)),
        'name': value['name'],
    };
}

