import React, { type RefObject, useRef } from 'react';
import { useDrag, useDrop, type XYCoord } from 'react-dnd';

import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

import { bemBlock } from '../../modules/bem';
import * as decorator from '../../modules/decorator';
import { Icon } from '../icon/Icon';
import { LayoutRow } from '../layout-row/LayoutRow';
import { Text } from '../text/Text';

import { type HorizontalTabsDragConfig, useHorizontalTabsContext } from './HorizontalTabsContext';

import './HorizontalTabsItem.less';

const block = bemBlock('n-horizontal-tabs-item');

type HorizontalTabsItemProps = {
  disabled?: boolean;
  tabId: string;
  'data-role'?: string;
  glyph?: GlyphName;
  variant?: 'basic' | 'full-content';
  opaque?: decorator.Opaque;
};

type DragItem = {
  id: string;
  index: number;
};

type UseHorizontalTabsItemUtilsProps = Pick<HorizontalTabsItemProps, 'tabId'>;

const useHorizontalTabsItemUtils = ({ tabId }: UseHorizontalTabsItemUtilsProps) => {
  const { activeTabId, onChange, tabIdsInOrder } = useHorizontalTabsContext();

  const active = activeTabId === tabId;

  const currentTabIndex = React.useMemo(() => tabIdsInOrder.indexOf(tabId), [tabIdsInOrder, tabId]);
  const activeTabIndex = React.useMemo(
    () => tabIdsInOrder.indexOf(activeTabId),
    [tabIdsInOrder, activeTabId],
  );

  const rightSiblingOfActiveTab = React.useMemo(
    () => currentTabIndex - activeTabIndex === 1,
    [currentTabIndex, activeTabIndex],
  );

  const firstTab = React.useMemo(() => currentTabIndex === 0, [currentTabIndex]);

  const handleChange = React.useCallback(() => {
    if (active) {
      return;
    }

    onChange?.(tabId);
  }, [active, onChange, tabId]);

  return {
    active,
    rightSiblingOfActiveTab,
    firstTab,
    handleChange,
    currentTabIndex,
  };
};

type HorizontalTabsItemContentProps = HorizontalTabsItemProps &
  ReturnType<typeof useHorizontalTabsItemUtils>;

const HorizontalTabsItemContent: React.FC<HorizontalTabsItemContentProps> = ({
  glyph,
  children,
  variant = 'basic',
  active,
}) => {
  return (
    <LayoutRow spacedChildren="sm" alignContent="center" alignItems="center" overflow="hidden">
      {glyph && (
        <Icon
          glyph={glyph}
          size="1x"
          className={block({ element: 'icon', modifiers: { active } })}
        />
      )}

      {children && (active || variant === 'full-content') && (
        <Text size="sm" fontWeight="semibold" color="text" className={block('text')}>
          {children}
        </Text>
      )}
    </LayoutRow>
  );
};

type NonDraggableHorizontalTabsItemProps = HorizontalTabsItemProps &
  ReturnType<typeof useHorizontalTabsItemUtils>;

const NonDraggableHorizontalTabsItem: React.FC<NonDraggableHorizontalTabsItemProps> = (props) => {
  const {
    disabled,
    tabId,
    'data-role': dataRole,
    opaque = 'white',
    active,
    firstTab,
    handleChange,
    rightSiblingOfActiveTab,
  } = props;
  return (
    <button
      data-role={dataRole}
      disabled={disabled}
      className={block({
        modifiers: {
          active,
          'right-sibling-of-active-tab': rightSiblingOfActiveTab,
          'first-tab': firstTab,
          opaque,
        },
      })}
      onClick={handleChange}
      data-tab-item={tabId}
      data-selected-tab={active}
    >
      <HorizontalTabsItemContent {...props} />
    </button>
  );
};

type UseHorizontalDragAndDropProps<T extends HTMLElement> = {
  ref: RefObject<T>;
  dndKey: string;
  id: string;
  index: number;
  onMove: (dragIndex: number, hoverIndex: number) => void;
};

function useHorizontalDragAndDrop<T extends HTMLElement>(props: UseHorizontalDragAndDropProps<T>) {
  const { ref, dndKey, id, index, onMove } = props;
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: string | symbol | null }>({
    accept: dndKey,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the left
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items width
      // When dragging right, only move when the cursor is right 50%
      // When dragging left, only move when the cursor is left 50%

      // Dragging left
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging right
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: dndKey,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return {
    isDragging,
    handlerId,
  };
}

type DraggableHorizontalTabsItemProps = HorizontalTabsItemProps &
  ReturnType<typeof useHorizontalTabsItemUtils> &
  HorizontalTabsDragConfig;

const DraggableHorizontalTabsItem: React.FC<DraggableHorizontalTabsItemProps> = (props) => {
  const {
    disabled,
    tabId,
    'data-role': dataRole,
    opaque = 'white',
    active,
    firstTab,
    handleChange,
    rightSiblingOfActiveTab,
    currentTabIndex,
    onMove,
  } = props;

  const ref = useRef<HTMLButtonElement>(null);

  const { handlerId, isDragging } = useHorizontalDragAndDrop({
    dndKey: 'HorizontalTabs',
    id: tabId,
    index: currentTabIndex,
    onMove,
    ref,
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <button
      ref={ref}
      data-role={dataRole}
      disabled={disabled}
      className={block({
        modifiers: {
          active,
          'right-sibling-of-active-tab': rightSiblingOfActiveTab,
          'first-tab': firstTab,
          opaque,
        },
      })}
      onClick={handleChange}
      data-tab-item={tabId}
      data-selected-tab={active}
      data-handler-id={handlerId}
      style={{
        opacity,
      }}
    >
      <HorizontalTabsItemContent {...props} />
    </button>
  );
};

export const HorizontalTabsItem: React.FC<HorizontalTabsItemProps> = (props) => {
  const { dragConfig } = useHorizontalTabsContext();

  const infoProps = useHorizontalTabsItemUtils({ tabId: props.tabId });

  if (dragConfig) {
    return <DraggableHorizontalTabsItem {...props} {...infoProps} {...dragConfig} />;
  }

  return <NonDraggableHorizontalTabsItem {...props} {...infoProps} />;
};
