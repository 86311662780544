// Libs
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Table } from 'neptune-core-ui/components';

import { bemBlock } from '@neptune/shared/venus-ui';

import { EqualityRenderer } from './renderers/EqualityRenderer';
import { LabelRenderer } from './renderers/LabelRenderer';
import { NewValueRenderer } from './renderers/NewValueRenderer';
import { OldValueRenderer } from './renderers/OldValueRenderer';
// Module
import type { PropertyDiffTableEntry } from './types';

import './PropertyDiffTable.less';

const block = bemBlock('property-diff-table');

type PropertyDiffTablePropertiesProps = {
  entries: PropertyDiffTableEntry[];
};

export const PropertyDiffTable: React.FC<PropertyDiffTablePropertiesProps> = ({ entries }) => {
  const columns = React.useMemo(() => {
    return [
      {
        body: { renderer: LabelRenderer },
      },
      {
        body: { renderer: OldValueRenderer },
      },
      {
        body: { renderer: EqualityRenderer },
      },
      {
        body: { renderer: NewValueRenderer },
      },
    ];
  }, []);

  return <Table className={block()} data={entries} columns={columns} renderParts={['body']} />;
};
