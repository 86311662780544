// Libs
import React from 'react';
import { withRouter } from 'react-router5';
import { isObject, isString } from 'lodash';
import { Router, State } from 'router5';

// App
import { RouterLink } from '@neptune/shared/routing-ui';
import { Link } from '@neptune/shared/venus-ui';

type LinkRendererProps = {
  router: Router;
  href?: string;
};

const LinkRenderer = (props: LinkRendererProps) => {
  const { href, ...restProps } = props;

  if (!isString(href) || href.length < 1) {
    return href;
  }

  /**
   * temporary anchor is needed for three reasons:
   *  1. When provided with the href attribute it builds complete URL instance
   *  2. URL instance contains info about origin so that we can detect if link is external or internal
   *  3. Because href is URL instance it allows to provide relative urls by the user and it will generate
   *    absolute url, so that we don't have to do it "by hand".
   */
  const anchor = document.createElement('a');
  anchor.href = href;
  const isExternal = window.location.origin !== anchor.origin;

  const matchedRoute: State | null = props.router?.matchUrl(anchor.href);

  if (!isExternal && isObject(matchedRoute)) {
    return (
      <RouterLink {...restProps} routeName={matchedRoute.name} routeParams={matchedRoute.params} />
    );
  }

  return <Link {...restProps} href={href} external />;
};

export default withRouter(LinkRenderer as React.ComponentType<LinkRendererProps>);
