// Libs
import React from 'react';

import { bemBlock, Layout } from '@neptune/shared/venus-ui';

import { KeycloakBrands } from './KeycloakBrands';
// App
// Module
import { KeycloakLogo } from './KeycloakLogo';

import './KeycloakAside.less';

const block = bemBlock('keycloak-aside');

type KeycloakAsideProps = {
  className?: string;
};

export const KeycloakAside: React.FC<KeycloakAsideProps> = ({ className, children }) => {
  return (
    <aside className={block({ extra: className })}>
      <Layout.Column className={block('aside-layout')} justifyContent="space-between">
        <KeycloakLogo className={block('logo')} size={54} theme="light" />
        {children}
        <KeycloakBrands />
      </Layout.Column>
    </aside>
  );
};
