import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutRow } from '../layout-row/LayoutRow';

import { useEmblemContext } from './EmblemContext';

import './EmblemEndDetailWrapper.less';

const block = bemBlock('n-emblem-end-detail-wrapper');

type EmblemEndDetailWrapperProps = {
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const EmblemEndDetailWrapper: React.FC<EmblemEndDetailWrapperProps> = ({
  children,
  onClick,
}) => {
  const { size, active } = useEmblemContext();

  const cssClass = block({ modifiers: { size, active } });

  return (
    <LayoutRow
      data-role="emblem-end-detail"
      justifyContent="center"
      alignItems="center"
      span="auto"
      className={cssClass}
      onClick={onClick}
    >
      {children}
    </LayoutRow>
  );
};
