/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegisteredMemberInfoDTO } from './RegisteredMemberInfoDTO';
import {
    RegisteredMemberInfoDTOFromJSON,
    RegisteredMemberInfoDTOFromJSONTyped,
    RegisteredMemberInfoDTOToJSON,
} from './RegisteredMemberInfoDTO';
import type { OrganizationRoleDTO } from './OrganizationRoleDTO';
import {
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
} from './OrganizationRoleDTO';
import type { OrganizationInvitationDTO } from './OrganizationInvitationDTO';
import {
    OrganizationInvitationDTOFromJSON,
    OrganizationInvitationDTOFromJSONTyped,
    OrganizationInvitationDTOToJSON,
} from './OrganizationInvitationDTO';

/**
 * 
 * @export
 * @interface OrganizationMemberDTO
 */
export interface OrganizationMemberDTO {
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof OrganizationMemberDTO
     */
    role: OrganizationRoleDTO;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationMemberDTO
     */
    joinedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationMemberDTO
     */
    editableRole: boolean;
    /**
     * 
     * @type {RegisteredMemberInfoDTO}
     * @memberof OrganizationMemberDTO
     */
    registeredMemberInfo?: RegisteredMemberInfoDTO;
    /**
     * 
     * @type {OrganizationInvitationDTO}
     * @memberof OrganizationMemberDTO
     */
    invitationInfo?: OrganizationInvitationDTO;
    /**
     * 
     * @type {number}
     * @memberof OrganizationMemberDTO
     */
    totalNumberOfProjects?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationMemberDTO
     */
    numberOfProjects?: number;
}



/**
 * Check if a given object implements the OrganizationMemberDTO interface.
 */
export function instanceOfOrganizationMemberDTO(value: object): value is OrganizationMemberDTO {
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('editableRole' in value) || value['editableRole'] === undefined) return false;
    return true;
}

export function OrganizationMemberDTOFromJSON(json: any): OrganizationMemberDTO {
    return OrganizationMemberDTOFromJSONTyped(json, false);
}

export function OrganizationMemberDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationMemberDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'role': OrganizationRoleDTOFromJSON(json['role']),
        'joinedAt': json['joinedAt'] == null ? undefined : (new Date(json['joinedAt'])),
        'editableRole': json['editableRole'],
        'registeredMemberInfo': json['registeredMemberInfo'] == null ? undefined : RegisteredMemberInfoDTOFromJSON(json['registeredMemberInfo']),
        'invitationInfo': json['invitationInfo'] == null ? undefined : OrganizationInvitationDTOFromJSON(json['invitationInfo']),
        'totalNumberOfProjects': json['totalNumberOfProjects'] == null ? undefined : json['totalNumberOfProjects'],
        'numberOfProjects': json['numberOfProjects'] == null ? undefined : json['numberOfProjects'],
    };
}

export function OrganizationMemberDTOToJSON(value?: OrganizationMemberDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'role': OrganizationRoleDTOToJSON(value['role']),
        'joinedAt': value['joinedAt'] == null ? undefined : ((value['joinedAt']).toISOString()),
        'editableRole': value['editableRole'],
        'registeredMemberInfo': RegisteredMemberInfoDTOToJSON(value['registeredMemberInfo']),
        'invitationInfo': OrganizationInvitationDTOToJSON(value['invitationInfo']),
        'totalNumberOfProjects': value['totalNumberOfProjects'],
        'numberOfProjects': value['numberOfProjects'],
    };
}

