/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotebookRefAttributeDTO } from './NotebookRefAttributeDTO';
import {
    NotebookRefAttributeDTOFromJSON,
    NotebookRefAttributeDTOFromJSONTyped,
    NotebookRefAttributeDTOToJSON,
} from './NotebookRefAttributeDTO';
import type { StringSetAttributeDTO } from './StringSetAttributeDTO';
import {
    StringSetAttributeDTOFromJSON,
    StringSetAttributeDTOFromJSONTyped,
    StringSetAttributeDTOToJSON,
} from './StringSetAttributeDTO';
import type { IntAttributeDTO } from './IntAttributeDTO';
import {
    IntAttributeDTOFromJSON,
    IntAttributeDTOFromJSONTyped,
    IntAttributeDTOToJSON,
} from './IntAttributeDTO';
import type { ExperimentStateAttributeDTO } from './ExperimentStateAttributeDTO';
import {
    ExperimentStateAttributeDTOFromJSON,
    ExperimentStateAttributeDTOFromJSONTyped,
    ExperimentStateAttributeDTOToJSON,
} from './ExperimentStateAttributeDTO';
import type { FloatAttributeDTO } from './FloatAttributeDTO';
import {
    FloatAttributeDTOFromJSON,
    FloatAttributeDTOFromJSONTyped,
    FloatAttributeDTOToJSON,
} from './FloatAttributeDTO';
import type { GitInfoDTO } from './GitInfoDTO';
import {
    GitInfoDTOFromJSON,
    GitInfoDTOFromJSONTyped,
    GitInfoDTOToJSON,
} from './GitInfoDTO';
import type { DatetimeAttributeDTO } from './DatetimeAttributeDTO';
import {
    DatetimeAttributeDTOFromJSON,
    DatetimeAttributeDTOFromJSONTyped,
    DatetimeAttributeDTOToJSON,
} from './DatetimeAttributeDTO';
import type { StringAttributeDTO } from './StringAttributeDTO';
import {
    StringAttributeDTOFromJSON,
    StringAttributeDTOFromJSONTyped,
    StringAttributeDTOToJSON,
} from './StringAttributeDTO';
import type { BoolAttributeDTO } from './BoolAttributeDTO';
import {
    BoolAttributeDTOFromJSON,
    BoolAttributeDTOFromJSONTyped,
    BoolAttributeDTOToJSON,
} from './BoolAttributeDTO';

/**
 * 
 * @export
 * @interface SystemAttributesDTO
 */
export interface SystemAttributesDTO {
    /**
     * 
     * @type {DatetimeAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    creationTime: DatetimeAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    customId: StringAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    description: StringAttributeDTO;
    /**
     * 
     * @type {BoolAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    failed: BoolAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    family: StringAttributeDTO;
    /**
     * 
     * @type {GitInfoDTO}
     * @memberof SystemAttributesDTO
     */
    gitRef?: GitInfoDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    hostname?: StringAttributeDTO;
    /**
     * 
     * @type {DatetimeAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    modificationTime: DatetimeAttributeDTO;
    /**
     * 
     * @type {IntAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    monitoringTime: IntAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    name: StringAttributeDTO;
    /**
     * 
     * @type {NotebookRefAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    notebookRef?: NotebookRefAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    owner?: StringAttributeDTO;
    /**
     * 
     * @type {DatetimeAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    pingTime: DatetimeAttributeDTO;
    /**
     * 
     * @type {FloatAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    runningTime: FloatAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    shortId: StringAttributeDTO;
    /**
     * 
     * @type {FloatAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    size: FloatAttributeDTO;
    /**
     * 
     * @type {ExperimentStateAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    state: ExperimentStateAttributeDTO;
    /**
     * 
     * @type {StringSetAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    tags: StringSetAttributeDTO;
    /**
     * 
     * @type {BoolAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    trashed: BoolAttributeDTO;
}

/**
 * Check if a given object implements the SystemAttributesDTO interface.
 */
export function instanceOfSystemAttributesDTO(value: object): value is SystemAttributesDTO {
    if (!('creationTime' in value) || value['creationTime'] === undefined) return false;
    if (!('customId' in value) || value['customId'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('failed' in value) || value['failed'] === undefined) return false;
    if (!('family' in value) || value['family'] === undefined) return false;
    if (!('modificationTime' in value) || value['modificationTime'] === undefined) return false;
    if (!('monitoringTime' in value) || value['monitoringTime'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('pingTime' in value) || value['pingTime'] === undefined) return false;
    if (!('runningTime' in value) || value['runningTime'] === undefined) return false;
    if (!('shortId' in value) || value['shortId'] === undefined) return false;
    if (!('size' in value) || value['size'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('trashed' in value) || value['trashed'] === undefined) return false;
    return true;
}

export function SystemAttributesDTOFromJSON(json: any): SystemAttributesDTO {
    return SystemAttributesDTOFromJSONTyped(json, false);
}

export function SystemAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemAttributesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'creationTime': DatetimeAttributeDTOFromJSON(json['creationTime']),
        'customId': StringAttributeDTOFromJSON(json['customId']),
        'description': StringAttributeDTOFromJSON(json['description']),
        'failed': BoolAttributeDTOFromJSON(json['failed']),
        'family': StringAttributeDTOFromJSON(json['family']),
        'gitRef': json['gitRef'] == null ? undefined : GitInfoDTOFromJSON(json['gitRef']),
        'hostname': json['hostname'] == null ? undefined : StringAttributeDTOFromJSON(json['hostname']),
        'modificationTime': DatetimeAttributeDTOFromJSON(json['modificationTime']),
        'monitoringTime': IntAttributeDTOFromJSON(json['monitoringTime']),
        'name': StringAttributeDTOFromJSON(json['name']),
        'notebookRef': json['notebookRef'] == null ? undefined : NotebookRefAttributeDTOFromJSON(json['notebookRef']),
        'owner': json['owner'] == null ? undefined : StringAttributeDTOFromJSON(json['owner']),
        'pingTime': DatetimeAttributeDTOFromJSON(json['pingTime']),
        'runningTime': FloatAttributeDTOFromJSON(json['runningTime']),
        'shortId': StringAttributeDTOFromJSON(json['shortId']),
        'size': FloatAttributeDTOFromJSON(json['size']),
        'state': ExperimentStateAttributeDTOFromJSON(json['state']),
        'tags': StringSetAttributeDTOFromJSON(json['tags']),
        'trashed': BoolAttributeDTOFromJSON(json['trashed']),
    };
}

export function SystemAttributesDTOToJSON(value?: SystemAttributesDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'creationTime': DatetimeAttributeDTOToJSON(value['creationTime']),
        'customId': StringAttributeDTOToJSON(value['customId']),
        'description': StringAttributeDTOToJSON(value['description']),
        'failed': BoolAttributeDTOToJSON(value['failed']),
        'family': StringAttributeDTOToJSON(value['family']),
        'gitRef': GitInfoDTOToJSON(value['gitRef']),
        'hostname': StringAttributeDTOToJSON(value['hostname']),
        'modificationTime': DatetimeAttributeDTOToJSON(value['modificationTime']),
        'monitoringTime': IntAttributeDTOToJSON(value['monitoringTime']),
        'name': StringAttributeDTOToJSON(value['name']),
        'notebookRef': NotebookRefAttributeDTOToJSON(value['notebookRef']),
        'owner': StringAttributeDTOToJSON(value['owner']),
        'pingTime': DatetimeAttributeDTOToJSON(value['pingTime']),
        'runningTime': FloatAttributeDTOToJSON(value['runningTime']),
        'shortId': StringAttributeDTOToJSON(value['shortId']),
        'size': FloatAttributeDTOToJSON(value['size']),
        'state': ExperimentStateAttributeDTOToJSON(value['state']),
        'tags': StringSetAttributeDTOToJSON(value['tags']),
        'trashed': BoolAttributeDTOToJSON(value['trashed']),
    };
}

