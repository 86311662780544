/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationInvitationUpdateProjectEntryDTO } from './OrganizationInvitationUpdateProjectEntryDTO';
import {
    OrganizationInvitationUpdateProjectEntryDTOFromJSON,
    OrganizationInvitationUpdateProjectEntryDTOFromJSONTyped,
    OrganizationInvitationUpdateProjectEntryDTOToJSON,
} from './OrganizationInvitationUpdateProjectEntryDTO';
import type { OrganizationRoleDTO } from './OrganizationRoleDTO';
import {
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
} from './OrganizationRoleDTO';

/**
 * 
 * @export
 * @interface OrganizationInvitationUpdateDTO
 */
export interface OrganizationInvitationUpdateDTO {
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof OrganizationInvitationUpdateDTO
     */
    roleGrant?: OrganizationRoleDTO;
    /**
     * 
     * @type {Array<OrganizationInvitationUpdateProjectEntryDTO>}
     * @memberof OrganizationInvitationUpdateDTO
     */
    projects?: Array<OrganizationInvitationUpdateProjectEntryDTO>;
}



/**
 * Check if a given object implements the OrganizationInvitationUpdateDTO interface.
 */
export function instanceOfOrganizationInvitationUpdateDTO(value: object): value is OrganizationInvitationUpdateDTO {
    return true;
}

export function OrganizationInvitationUpdateDTOFromJSON(json: any): OrganizationInvitationUpdateDTO {
    return OrganizationInvitationUpdateDTOFromJSONTyped(json, false);
}

export function OrganizationInvitationUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationInvitationUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'roleGrant': json['roleGrant'] == null ? undefined : OrganizationRoleDTOFromJSON(json['roleGrant']),
        'projects': json['projects'] == null ? undefined : ((json['projects'] as Array<any>).map(OrganizationInvitationUpdateProjectEntryDTOFromJSON)),
    };
}

export function OrganizationInvitationUpdateDTOToJSON(value?: OrganizationInvitationUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'roleGrant': OrganizationRoleDTOToJSON(value['roleGrant']),
        'projects': value['projects'] == null ? undefined : ((value['projects'] as Array<any>).map(OrganizationInvitationUpdateProjectEntryDTOToJSON)),
    };
}

