/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileEntry
 */
export interface FileEntry {
    /**
     * 
     * @type {string}
     * @memberof FileEntry
     */
    fileType: FileEntryFileTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof FileEntry
     */
    mtime: Date;
    /**
     * 
     * @type {string}
     * @memberof FileEntry
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FileEntry
     */
    size?: number;
}


/**
 * @export
 */
export const FileEntryFileTypeEnum = {
    File: 'file',
    Directory: 'directory'
} as const;
export type FileEntryFileTypeEnum = typeof FileEntryFileTypeEnum[keyof typeof FileEntryFileTypeEnum];


/**
 * Check if a given object implements the FileEntry interface.
 */
export function instanceOfFileEntry(value: object): value is FileEntry {
    if (!('fileType' in value) || value['fileType'] === undefined) return false;
    if (!('mtime' in value) || value['mtime'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function FileEntryFromJSON(json: any): FileEntry {
    return FileEntryFromJSONTyped(json, false);
}

export function FileEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'fileType': json['fileType'],
        'mtime': (new Date(json['mtime'])),
        'name': json['name'],
        'size': json['size'] == null ? undefined : json['size'],
    };
}

export function FileEntryToJSON(value?: FileEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fileType': value['fileType'],
        'mtime': ((value['mtime']).toISOString()),
        'name': value['name'],
        'size': value['size'],
    };
}

