// Libs
import React from 'react';

import { bemBlock } from '@neptune/shared/venus-ui';

import { Merge } from 'common/utility-types';

// Module
import './ComponentToggle.less';

type ComponentToggleOwnProps = {
  className?: string;
  expanded?: boolean;
  pressed?: boolean;
  disabled?: boolean;
  onToggle?: React.ReactEventHandler;
  onCollapse?: React.ReactEventHandler;
  onExpand?: React.ReactEventHandler;
  component: string | React.ComponentType<any>;
  submenu?: boolean;
};

type ComponentToggleProps<T extends object> = Merge<T, ComponentToggleOwnProps>;

const block = bemBlock('component-toggle');

export function ComponentToggle<T extends object = any>({
  className,
  component: Component,
  disabled,
  onToggle,
  onCollapse,
  onExpand,
  pressed,
  ...restProps
}: ComponentToggleProps<T>): React.ReactElement {
  const cssClass = block({
    extra: [className, { disabled }, { 'dropdown-expanded': pressed }],
  });

  return (
    <Component
      className={cssClass}
      data-role="dropdown-toggle"
      disabled={disabled}
      onClick={onToggle}
      pressed={pressed}
      {...restProps}
    />
  );
}
