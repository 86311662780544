/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RequestedAttributes } from './RequestedAttributes';
import {
    RequestedAttributesFromJSON,
    RequestedAttributesFromJSONTyped,
    RequestedAttributesToJSON,
} from './RequestedAttributes';

/**
 * 
 * @export
 * @interface AttributesRequestsListDTO
 */
export interface AttributesRequestsListDTO {
    /**
     * 
     * @type {Array<RequestedAttributes>}
     * @memberof AttributesRequestsListDTO
     */
    requests: Array<RequestedAttributes>;
}

/**
 * Check if a given object implements the AttributesRequestsListDTO interface.
 */
export function instanceOfAttributesRequestsListDTO(value: object): value is AttributesRequestsListDTO {
    if (!('requests' in value) || value['requests'] === undefined) return false;
    return true;
}

export function AttributesRequestsListDTOFromJSON(json: any): AttributesRequestsListDTO {
    return AttributesRequestsListDTOFromJSONTyped(json, false);
}

export function AttributesRequestsListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributesRequestsListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'requests': ((json['requests'] as Array<any>).map(RequestedAttributesFromJSON)),
    };
}

export function AttributesRequestsListDTOToJSON(value?: AttributesRequestsListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requests': ((value['requests'] as Array<any>).map(RequestedAttributesToJSON)),
    };
}

