/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectChartFiltersDTO } from './ProjectChartFiltersDTO';
import {
    ProjectChartFiltersDTOFromJSON,
    ProjectChartFiltersDTOFromJSONTyped,
    ProjectChartFiltersDTOToJSON,
} from './ProjectChartFiltersDTO';
import type { ProjectChartMetricDTO } from './ProjectChartMetricDTO';
import {
    ProjectChartMetricDTOFromJSON,
    ProjectChartMetricDTOFromJSONTyped,
    ProjectChartMetricDTOToJSON,
} from './ProjectChartMetricDTO';

/**
 * 
 * @export
 * @interface ProjectChartUpdateDTO
 */
export interface ProjectChartUpdateDTO {
    /**
     * 
     * @type {ProjectChartFiltersDTO}
     * @memberof ProjectChartUpdateDTO
     */
    filters: ProjectChartFiltersDTO;
    /**
     * Chart metrics
     * @type {Array<ProjectChartMetricDTO>}
     * @memberof ProjectChartUpdateDTO
     */
    metrics: Array<ProjectChartMetricDTO>;
    /**
     * Chart name
     * @type {string}
     * @memberof ProjectChartUpdateDTO
     */
    name: string;
}

/**
 * Check if a given object implements the ProjectChartUpdateDTO interface.
 */
export function instanceOfProjectChartUpdateDTO(value: object): value is ProjectChartUpdateDTO {
    if (!('filters' in value) || value['filters'] === undefined) return false;
    if (!('metrics' in value) || value['metrics'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ProjectChartUpdateDTOFromJSON(json: any): ProjectChartUpdateDTO {
    return ProjectChartUpdateDTOFromJSONTyped(json, false);
}

export function ProjectChartUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectChartUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'filters': ProjectChartFiltersDTOFromJSON(json['filters']),
        'metrics': ((json['metrics'] as Array<any>).map(ProjectChartMetricDTOFromJSON)),
        'name': json['name'],
    };
}

export function ProjectChartUpdateDTOToJSON(value?: ProjectChartUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filters': ProjectChartFiltersDTOToJSON(value['filters']),
        'metrics': ((value['metrics'] as Array<any>).map(ProjectChartMetricDTOToJSON)),
        'name': value['name'],
    };
}

