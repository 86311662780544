/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeDefinitionDTO } from './AttributeDefinitionDTO';
import {
    AttributeDefinitionDTOFromJSON,
    AttributeDefinitionDTOFromJSONTyped,
    AttributeDefinitionDTOToJSON,
} from './AttributeDefinitionDTO';
import type { OpenRangeDTO } from './OpenRangeDTO';
import {
    OpenRangeDTOFromJSON,
    OpenRangeDTOFromJSONTyped,
    OpenRangeDTOToJSON,
} from './OpenRangeDTO';

/**
 * 
 * @export
 * @interface DashboardConfigDTO
 */
export interface DashboardConfigDTO {
    /**
     * 
     * @type {OpenRangeDTO}
     * @memberof DashboardConfigDTO
     */
    metricsStepsRange?: OpenRangeDTO;
    /**
     * 
     * @type {number}
     * @memberof DashboardConfigDTO
     */
    smoothing?: number;
    /**
     * 
     * @type {AttributeDefinitionDTO}
     * @memberof DashboardConfigDTO
     */
    xaxisMetric?: AttributeDefinitionDTO;
    /**
     * 
     * @type {string}
     * @memberof DashboardConfigDTO
     */
    xaxisMode?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardConfigDTO
     */
    xaxisScale?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardConfigDTO
     */
    yaxisScale?: string;
}

/**
 * Check if a given object implements the DashboardConfigDTO interface.
 */
export function instanceOfDashboardConfigDTO(value: object): value is DashboardConfigDTO {
    return true;
}

export function DashboardConfigDTOFromJSON(json: any): DashboardConfigDTO {
    return DashboardConfigDTOFromJSONTyped(json, false);
}

export function DashboardConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardConfigDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'metricsStepsRange': json['metricsStepsRange'] == null ? undefined : OpenRangeDTOFromJSON(json['metricsStepsRange']),
        'smoothing': json['smoothing'] == null ? undefined : json['smoothing'],
        'xaxisMetric': json['xaxisMetric'] == null ? undefined : AttributeDefinitionDTOFromJSON(json['xaxisMetric']),
        'xaxisMode': json['xaxisMode'] == null ? undefined : json['xaxisMode'],
        'xaxisScale': json['xaxisScale'] == null ? undefined : json['xaxisScale'],
        'yaxisScale': json['yaxisScale'] == null ? undefined : json['yaxisScale'],
    };
}

export function DashboardConfigDTOToJSON(value?: DashboardConfigDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'metricsStepsRange': OpenRangeDTOToJSON(value['metricsStepsRange']),
        'smoothing': value['smoothing'],
        'xaxisMetric': AttributeDefinitionDTOToJSON(value['xaxisMetric']),
        'xaxisMode': value['xaxisMode'],
        'xaxisScale': value['xaxisScale'],
        'yaxisScale': value['yaxisScale'],
    };
}

