/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO } from './QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO';
import {
    QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSON,
    QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSONTyped,
    QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOToJSON,
} from './QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO';
import type { QueryLeaderboardParamsFieldDTO } from './QueryLeaderboardParamsFieldDTO';
import {
    QueryLeaderboardParamsFieldDTOFromJSON,
    QueryLeaderboardParamsFieldDTOFromJSONTyped,
    QueryLeaderboardParamsFieldDTOToJSON,
} from './QueryLeaderboardParamsFieldDTO';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsGroupingParamsDTO
 */
export interface QueryLeaderboardParamsGroupingParamsDTO {
    /**
     * 
     * @type {Array<QueryLeaderboardParamsFieldDTO>}
     * @memberof QueryLeaderboardParamsGroupingParamsDTO
     */
    groupBy: Array<QueryLeaderboardParamsFieldDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryLeaderboardParamsGroupingParamsDTO
     */
    openedGroups?: Array<string>;
    /**
     * 
     * @type {Array<QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO>}
     * @memberof QueryLeaderboardParamsGroupingParamsDTO
     */
    openedGroupsWithPagination?: Array<QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO>;
}

/**
 * Check if a given object implements the QueryLeaderboardParamsGroupingParamsDTO interface.
 */
export function instanceOfQueryLeaderboardParamsGroupingParamsDTO(value: object): value is QueryLeaderboardParamsGroupingParamsDTO {
    if (!('groupBy' in value) || value['groupBy'] === undefined) return false;
    return true;
}

export function QueryLeaderboardParamsGroupingParamsDTOFromJSON(json: any): QueryLeaderboardParamsGroupingParamsDTO {
    return QueryLeaderboardParamsGroupingParamsDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsGroupingParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsGroupingParamsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'groupBy': ((json['groupBy'] as Array<any>).map(QueryLeaderboardParamsFieldDTOFromJSON)),
        'openedGroups': json['openedGroups'] == null ? undefined : json['openedGroups'],
        'openedGroupsWithPagination': json['openedGroupsWithPagination'] == null ? undefined : ((json['openedGroupsWithPagination'] as Array<any>).map(QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSON)),
    };
}

export function QueryLeaderboardParamsGroupingParamsDTOToJSON(value?: QueryLeaderboardParamsGroupingParamsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'groupBy': ((value['groupBy'] as Array<any>).map(QueryLeaderboardParamsFieldDTOToJSON)),
        'openedGroups': value['openedGroups'],
        'openedGroupsWithPagination': value['openedGroupsWithPagination'] == null ? undefined : ((value['openedGroupsWithPagination'] as Array<any>).map(QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOToJSON)),
    };
}

