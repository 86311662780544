// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import * as decorator from '../../modules/decorator';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';
import { SectionTitle } from '../section-title/SectionTitle';

// Module
import './Section.less';

const block = bemBlock('n-Section');

export type SectionProps = LayoutColumnProps<{}> & {
  /**
   * Draw solid background.
   */
  opaque?: decorator.Opaque;
  title?: React.ReactNode;
  withBorder?: boolean;
};

export class Section extends React.Component<SectionProps> {
  public static defaultProps = {
    component: 'section',
    span: 'auto',
  };

  public static Title = SectionTitle;

  render() {
    const { children, className, opaque, title, withBorder, ...passProps } = this.props;
    const titleElement = title ? <SectionTitle>{title}</SectionTitle> : null;

    return (
      <LayoutColumn
        className={block({
          modifiers: {
            withBorder,
          },
          extra: [className, decorator.opaqueClassName(opaque)],
        })}
        {...passProps}
      >
        {titleElement}
        {children}
      </LayoutColumn>
    );
  }
}
