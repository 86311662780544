/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExperimentStateDTO } from './ExperimentStateDTO';
import {
    ExperimentStateDTOFromJSON,
    ExperimentStateDTOFromJSONTyped,
    ExperimentStateDTOToJSON,
} from './ExperimentStateDTO';

/**
 * 
 * @export
 * @interface LeaderboardViewQuickFilterDTO
 */
export interface LeaderboardViewQuickFilterDTO {
    /**
     * 
     * @type {Date}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    creationDateRangeEnd?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    creationDateRangeStart?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    owner?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    relativeTime?: LeaderboardViewQuickFilterDTORelativeTimeEnum;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    searchTerm?: string;
    /**
     * 
     * @type {Array<ExperimentStateDTO>}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    status?: Array<ExperimentStateDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    tags?: Array<string>;
}


/**
 * @export
 */
export const LeaderboardViewQuickFilterDTORelativeTimeEnum = {
    LastDay: 'lastDay',
    LastWeek: 'lastWeek',
    LastMonth: 'lastMonth',
    LastQuarter: 'lastQuarter'
} as const;
export type LeaderboardViewQuickFilterDTORelativeTimeEnum = typeof LeaderboardViewQuickFilterDTORelativeTimeEnum[keyof typeof LeaderboardViewQuickFilterDTORelativeTimeEnum];


/**
 * Check if a given object implements the LeaderboardViewQuickFilterDTO interface.
 */
export function instanceOfLeaderboardViewQuickFilterDTO(value: object): value is LeaderboardViewQuickFilterDTO {
    return true;
}

export function LeaderboardViewQuickFilterDTOFromJSON(json: any): LeaderboardViewQuickFilterDTO {
    return LeaderboardViewQuickFilterDTOFromJSONTyped(json, false);
}

export function LeaderboardViewQuickFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardViewQuickFilterDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'creationDateRangeEnd': json['creationDateRangeEnd'] == null ? undefined : (new Date(json['creationDateRangeEnd'])),
        'creationDateRangeStart': json['creationDateRangeStart'] == null ? undefined : (new Date(json['creationDateRangeStart'])),
        'owner': json['owner'] == null ? undefined : json['owner'],
        'relativeTime': json['relativeTime'] == null ? undefined : json['relativeTime'],
        'searchTerm': json['searchTerm'] == null ? undefined : json['searchTerm'],
        'status': json['status'] == null ? undefined : ((json['status'] as Array<any>).map(ExperimentStateDTOFromJSON)),
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

export function LeaderboardViewQuickFilterDTOToJSON(value?: LeaderboardViewQuickFilterDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'creationDateRangeEnd': value['creationDateRangeEnd'] == null ? undefined : ((value['creationDateRangeEnd']).toISOString()),
        'creationDateRangeStart': value['creationDateRangeStart'] == null ? undefined : ((value['creationDateRangeStart']).toISOString()),
        'owner': value['owner'],
        'relativeTime': value['relativeTime'],
        'searchTerm': value['searchTerm'],
        'status': value['status'] == null ? undefined : ((value['status'] as Array<any>).map(ExperimentStateDTOToJSON)),
        'tags': value['tags'],
    };
}

