/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaderboardViewQuickFilterDTO } from './LeaderboardViewQuickFilterDTO';
import {
    LeaderboardViewQuickFilterDTOFromJSON,
    LeaderboardViewQuickFilterDTOFromJSONTyped,
    LeaderboardViewQuickFilterDTOToJSON,
} from './LeaderboardViewQuickFilterDTO';
import type { LeaderboardSortParamsDTO } from './LeaderboardSortParamsDTO';
import {
    LeaderboardSortParamsDTOFromJSON,
    LeaderboardSortParamsDTOFromJSONTyped,
    LeaderboardSortParamsDTOToJSON,
} from './LeaderboardSortParamsDTO';
import type { LeaderboardViewColumnListDTO } from './LeaderboardViewColumnListDTO';
import {
    LeaderboardViewColumnListDTOFromJSON,
    LeaderboardViewColumnListDTOFromJSONTyped,
    LeaderboardViewColumnListDTOToJSON,
} from './LeaderboardViewColumnListDTO';
import type { LeaderboardGroupParamsDTO } from './LeaderboardGroupParamsDTO';
import {
    LeaderboardGroupParamsDTOFromJSON,
    LeaderboardGroupParamsDTOFromJSONTyped,
    LeaderboardGroupParamsDTOToJSON,
} from './LeaderboardGroupParamsDTO';

/**
 * 
 * @export
 * @interface LeaderboardViewDTO
 */
export interface LeaderboardViewDTO {
    /**
     * 
     * @type {LeaderboardViewColumnListDTO}
     * @memberof LeaderboardViewDTO
     */
    columnList: LeaderboardViewColumnListDTO;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardViewDTO
     */
    defaultView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardViewDTO
     */
    experimentsOnly: boolean;
    /**
     * 
     * @type {LeaderboardGroupParamsDTO}
     * @memberof LeaderboardViewDTO
     */
    groupOptions?: LeaderboardGroupParamsDTO;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewDTO
     */
    query?: string;
    /**
     * 
     * @type {LeaderboardViewQuickFilterDTO}
     * @memberof LeaderboardViewDTO
     */
    quickFilters?: LeaderboardViewQuickFilterDTO;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardViewDTO
     */
    runsLineage: boolean;
    /**
     * 
     * @type {LeaderboardSortParamsDTO}
     * @memberof LeaderboardViewDTO
     */
    sortOptions: LeaderboardSortParamsDTO;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardViewDTO
     */
    suggestionsEnabled: boolean;
}

/**
 * Check if a given object implements the LeaderboardViewDTO interface.
 */
export function instanceOfLeaderboardViewDTO(value: object): value is LeaderboardViewDTO {
    if (!('columnList' in value) || value['columnList'] === undefined) return false;
    if (!('defaultView' in value) || value['defaultView'] === undefined) return false;
    if (!('experimentsOnly' in value) || value['experimentsOnly'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('runsLineage' in value) || value['runsLineage'] === undefined) return false;
    if (!('sortOptions' in value) || value['sortOptions'] === undefined) return false;
    if (!('suggestionsEnabled' in value) || value['suggestionsEnabled'] === undefined) return false;
    return true;
}

export function LeaderboardViewDTOFromJSON(json: any): LeaderboardViewDTO {
    return LeaderboardViewDTOFromJSONTyped(json, false);
}

export function LeaderboardViewDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardViewDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'columnList': LeaderboardViewColumnListDTOFromJSON(json['columnList']),
        'defaultView': json['defaultView'],
        'experimentsOnly': json['experimentsOnly'],
        'groupOptions': json['groupOptions'] == null ? undefined : LeaderboardGroupParamsDTOFromJSON(json['groupOptions']),
        'id': json['id'],
        'name': json['name'],
        'query': json['query'] == null ? undefined : json['query'],
        'quickFilters': json['quickFilters'] == null ? undefined : LeaderboardViewQuickFilterDTOFromJSON(json['quickFilters']),
        'runsLineage': json['runsLineage'],
        'sortOptions': LeaderboardSortParamsDTOFromJSON(json['sortOptions']),
        'suggestionsEnabled': json['suggestionsEnabled'],
    };
}

export function LeaderboardViewDTOToJSON(value?: LeaderboardViewDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'columnList': LeaderboardViewColumnListDTOToJSON(value['columnList']),
        'defaultView': value['defaultView'],
        'experimentsOnly': value['experimentsOnly'],
        'groupOptions': LeaderboardGroupParamsDTOToJSON(value['groupOptions']),
        'id': value['id'],
        'name': value['name'],
        'query': value['query'],
        'quickFilters': LeaderboardViewQuickFilterDTOToJSON(value['quickFilters']),
        'runsLineage': value['runsLineage'],
        'sortOptions': LeaderboardSortParamsDTOToJSON(value['sortOptions']),
        'suggestionsEnabled': value['suggestionsEnabled'],
    };
}

