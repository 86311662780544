import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { bemBlock } from '../../modules/bem';

import './Popover.less';

const block = bemBlock('popover');

const Content = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, ...props }, ref) => (
  <PopoverPrimitive.Content ref={ref} className={block({ extra: className })} {...props} />
));

const Header = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => (
    <>
      <div ref={ref} className={block({ element: 'header', extra: className })} {...props} />
      <div className={block('separator')} />
    </>
  ),
);

export const Popover = {
  Anchor: PopoverPrimitive.Anchor,
  Close: PopoverPrimitive.Close,
  Content,
  Header,
  Portal: PopoverPrimitive.Portal,
  Root: PopoverPrimitive.Root,
  Trigger: PopoverPrimitive.Trigger,
};
