/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntercomEntryDTO } from './IntercomEntryDTO';
import {
    IntercomEntryDTOFromJSON,
    IntercomEntryDTOFromJSONTyped,
    IntercomEntryDTOToJSON,
} from './IntercomEntryDTO';

/**
 * 
 * @export
 * @interface OrganizationIntercomDataDTO
 */
export interface OrganizationIntercomDataDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationIntercomDataDTO
     */
    organizationIdentifier: string;
    /**
     * 
     * @type {Array<IntercomEntryDTO>}
     * @memberof OrganizationIntercomDataDTO
     */
    organizationProperties: Array<IntercomEntryDTO>;
}

/**
 * Check if a given object implements the OrganizationIntercomDataDTO interface.
 */
export function instanceOfOrganizationIntercomDataDTO(value: object): value is OrganizationIntercomDataDTO {
    if (!('organizationIdentifier' in value) || value['organizationIdentifier'] === undefined) return false;
    if (!('organizationProperties' in value) || value['organizationProperties'] === undefined) return false;
    return true;
}

export function OrganizationIntercomDataDTOFromJSON(json: any): OrganizationIntercomDataDTO {
    return OrganizationIntercomDataDTOFromJSONTyped(json, false);
}

export function OrganizationIntercomDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationIntercomDataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'organizationIdentifier': json['organizationIdentifier'],
        'organizationProperties': ((json['organizationProperties'] as Array<any>).map(IntercomEntryDTOFromJSON)),
    };
}

export function OrganizationIntercomDataDTOToJSON(value?: OrganizationIntercomDataDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'organizationIdentifier': value['organizationIdentifier'],
        'organizationProperties': ((value['organizationProperties'] as Array<any>).map(IntercomEntryDTOToJSON)),
    };
}

