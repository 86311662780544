/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface FileAttributeDTO
 */
export interface FileAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof FileAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof FileAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof FileAttributeDTO
     */
    ext: string;
    /**
     * 
     * @type {string}
     * @memberof FileAttributeDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FileAttributeDTO
     */
    size: number;
}



/**
 * Check if a given object implements the FileAttributeDTO interface.
 */
export function instanceOfFileAttributeDTO(value: object): value is FileAttributeDTO {
    if (!('attributeName' in value) || value['attributeName'] === undefined) return false;
    if (!('attributeType' in value) || value['attributeType'] === undefined) return false;
    if (!('ext' in value) || value['ext'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('size' in value) || value['size'] === undefined) return false;
    return true;
}

export function FileAttributeDTOFromJSON(json: any): FileAttributeDTO {
    return FileAttributeDTOFromJSONTyped(json, false);
}

export function FileAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'ext': json['ext'],
        'name': json['name'],
        'size': json['size'],
    };
}

export function FileAttributeDTOToJSON(value?: FileAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeName': value['attributeName'],
        'attributeType': AttributeTypeDTOToJSON(value['attributeType']),
        'ext': value['ext'],
        'name': value['name'],
        'size': value['size'],
    };
}

