/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectStorageLimitTraitDTO
 */
export interface ProjectStorageLimitTraitDTO {
    /**
     * 
     * @type {number}
     * @memberof ProjectStorageLimitTraitDTO
     */
    maxStorageSize: number;
}

/**
 * Check if a given object implements the ProjectStorageLimitTraitDTO interface.
 */
export function instanceOfProjectStorageLimitTraitDTO(value: object): value is ProjectStorageLimitTraitDTO {
    if (!('maxStorageSize' in value) || value['maxStorageSize'] === undefined) return false;
    return true;
}

export function ProjectStorageLimitTraitDTOFromJSON(json: any): ProjectStorageLimitTraitDTO {
    return ProjectStorageLimitTraitDTOFromJSONTyped(json, false);
}

export function ProjectStorageLimitTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStorageLimitTraitDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'maxStorageSize': json['maxStorageSize'],
    };
}

export function ProjectStorageLimitTraitDTOToJSON(value?: ProjectStorageLimitTraitDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'maxStorageSize': value['maxStorageSize'],
    };
}

