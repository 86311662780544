/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributesHolderIdentifier } from './AttributesHolderIdentifier';
import {
    AttributesHolderIdentifierFromJSON,
    AttributesHolderIdentifierFromJSONTyped,
    AttributesHolderIdentifierToJSON,
} from './AttributesHolderIdentifier';

/**
 * 
 * @export
 * @interface CustomMetric
 */
export interface CustomMetric {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomMetric
     */
    attributes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomMetric
     */
    customId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomMetric
     */
    customYFormula?: string;
    /**
     * 
     * @type {AttributesHolderIdentifier}
     * @memberof CustomMetric
     */
    holder: AttributesHolderIdentifier;
    /**
     * 
     * @type {string}
     * @memberof CustomMetric
     */
    lineage?: CustomMetricLineageEnum;
}


/**
 * @export
 */
export const CustomMetricLineageEnum = {
    Full: 'FULL',
    None: 'NONE'
} as const;
export type CustomMetricLineageEnum = typeof CustomMetricLineageEnum[keyof typeof CustomMetricLineageEnum];


/**
 * Check if a given object implements the CustomMetric interface.
 */
export function instanceOfCustomMetric(value: object): value is CustomMetric {
    if (!('attributes' in value) || value['attributes'] === undefined) return false;
    if (!('customId' in value) || value['customId'] === undefined) return false;
    if (!('holder' in value) || value['holder'] === undefined) return false;
    return true;
}

export function CustomMetricFromJSON(json: any): CustomMetric {
    return CustomMetricFromJSONTyped(json, false);
}

export function CustomMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomMetric {
    if (json == null) {
        return json;
    }
    return {
        
        'attributes': json['attributes'],
        'customId': json['customId'],
        'customYFormula': json['customYFormula'] == null ? undefined : json['customYFormula'],
        'holder': AttributesHolderIdentifierFromJSON(json['holder']),
        'lineage': json['lineage'] == null ? undefined : json['lineage'],
    };
}

export function CustomMetricToJSON(value?: CustomMetric | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributes': value['attributes'],
        'customId': value['customId'],
        'customYFormula': value['customYFormula'],
        'holder': AttributesHolderIdentifierToJSON(value['holder']),
        'lineage': value['lineage'],
    };
}

