/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { YOutput } from './YOutput';
import {
    YOutputFromJSON,
    YOutputFromJSONTyped,
    YOutputToJSON,
} from './YOutput';

/**
 * 
 * @export
 * @interface PointValueDTO
 */
export interface PointValueDTO {
    /**
     * 
     * @type {number}
     * @memberof PointValueDTO
     */
    timestampMillis: number;
    /**
     * 
     * @type {number}
     * @memberof PointValueDTO
     */
    x?: number;
    /**
     * 
     * @type {YOutput}
     * @memberof PointValueDTO
     */
    y: YOutput;
}

/**
 * Check if a given object implements the PointValueDTO interface.
 */
export function instanceOfPointValueDTO(value: object): value is PointValueDTO {
    if (!('timestampMillis' in value) || value['timestampMillis'] === undefined) return false;
    if (!('y' in value) || value['y'] === undefined) return false;
    return true;
}

export function PointValueDTOFromJSON(json: any): PointValueDTO {
    return PointValueDTOFromJSONTyped(json, false);
}

export function PointValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointValueDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'timestampMillis': json['timestampMillis'],
        'x': json['x'] == null ? undefined : json['x'],
        'y': YOutputFromJSON(json['y']),
    };
}

export function PointValueDTOToJSON(value?: PointValueDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timestampMillis': value['timestampMillis'],
        'x': value['x'],
        'y': YOutputToJSON(value['y']),
    };
}

