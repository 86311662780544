/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperationError
 */
export interface OperationError {
    /**
     * 
     * @type {string}
     * @memberof OperationError
     */
    errorDescription: string;
    /**
     * 
     * @type {string}
     * @memberof OperationError
     */
    errorType: OperationErrorErrorTypeEnum;
}


/**
 * @export
 */
export const OperationErrorErrorTypeEnum = {
    MetadataInconsistency: 'metadata_inconsistency',
    LogSeriesValueError: 'logSeriesValueError',
    AssignFloatValueError: 'assignFloatValueError'
} as const;
export type OperationErrorErrorTypeEnum = typeof OperationErrorErrorTypeEnum[keyof typeof OperationErrorErrorTypeEnum];


/**
 * Check if a given object implements the OperationError interface.
 */
export function instanceOfOperationError(value: object): value is OperationError {
    if (!('errorDescription' in value) || value['errorDescription'] === undefined) return false;
    if (!('errorType' in value) || value['errorType'] === undefined) return false;
    return true;
}

export function OperationErrorFromJSON(json: any): OperationError {
    return OperationErrorFromJSONTyped(json, false);
}

export function OperationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationError {
    if (json == null) {
        return json;
    }
    return {
        
        'errorDescription': json['errorDescription'],
        'errorType': json['errorType'],
    };
}

export function OperationErrorToJSON(value?: OperationError | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'errorDescription': value['errorDescription'],
        'errorType': value['errorType'],
    };
}

