/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportVersionMetadataDTO } from './ReportVersionMetadataDTO';
import {
    ReportVersionMetadataDTOFromJSON,
    ReportVersionMetadataDTOFromJSONTyped,
    ReportVersionMetadataDTOToJSON,
} from './ReportVersionMetadataDTO';

/**
 * 
 * @export
 * @interface ReportMetadataDTO
 */
export interface ReportMetadataDTO {
    /**
     * 
     * @type {Date}
     * @memberof ReportMetadataDTO
     */
    creationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportMetadataDTO
     */
    initialAuthor: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportMetadataDTO
     */
    latestPublishTime: Date;
    /**
     * 
     * @type {ReportVersionMetadataDTO}
     * @memberof ReportMetadataDTO
     */
    latestVersionMetadata: ReportVersionMetadataDTO;
    /**
     * 
     * @type {string}
     * @memberof ReportMetadataDTO
     */
    reportId: string;
}

/**
 * Check if a given object implements the ReportMetadataDTO interface.
 */
export function instanceOfReportMetadataDTO(value: object): value is ReportMetadataDTO {
    if (!('creationTime' in value) || value['creationTime'] === undefined) return false;
    if (!('initialAuthor' in value) || value['initialAuthor'] === undefined) return false;
    if (!('latestPublishTime' in value) || value['latestPublishTime'] === undefined) return false;
    if (!('latestVersionMetadata' in value) || value['latestVersionMetadata'] === undefined) return false;
    if (!('reportId' in value) || value['reportId'] === undefined) return false;
    return true;
}

export function ReportMetadataDTOFromJSON(json: any): ReportMetadataDTO {
    return ReportMetadataDTOFromJSONTyped(json, false);
}

export function ReportMetadataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportMetadataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'creationTime': (new Date(json['creationTime'])),
        'initialAuthor': json['initialAuthor'],
        'latestPublishTime': (new Date(json['latestPublishTime'])),
        'latestVersionMetadata': ReportVersionMetadataDTOFromJSON(json['latestVersionMetadata']),
        'reportId': json['reportId'],
    };
}

export function ReportMetadataDTOToJSON(value?: ReportMetadataDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'creationTime': ((value['creationTime']).toISOString()),
        'initialAuthor': value['initialAuthor'],
        'latestPublishTime': ((value['latestPublishTime']).toISOString()),
        'latestVersionMetadata': ReportVersionMetadataDTOToJSON(value['latestVersionMetadata']),
        'reportId': value['reportId'],
    };
}

