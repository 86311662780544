/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DashboardDTO } from './DashboardDTO';
import {
    DashboardDTOFromJSON,
    DashboardDTOFromJSONTyped,
    DashboardDTOToJSON,
} from './DashboardDTO';

/**
 * 
 * @export
 * @interface DashboardListDTO
 */
export interface DashboardListDTO {
    /**
     * Dashboard list
     * @type {Array<DashboardDTO>}
     * @memberof DashboardListDTO
     */
    dashboards: Array<DashboardDTO>;
}

/**
 * Check if a given object implements the DashboardListDTO interface.
 */
export function instanceOfDashboardListDTO(value: object): value is DashboardListDTO {
    if (!('dashboards' in value) || value['dashboards'] === undefined) return false;
    return true;
}

export function DashboardListDTOFromJSON(json: any): DashboardListDTO {
    return DashboardListDTOFromJSONTyped(json, false);
}

export function DashboardListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'dashboards': ((json['dashboards'] as Array<any>).map(DashboardDTOFromJSON)),
    };
}

export function DashboardListDTOToJSON(value?: DashboardListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dashboards': ((value['dashboards'] as Array<any>).map(DashboardDTOToJSON)),
    };
}

