import React from 'react';
import { Components } from 'react-markdown';

import {
  SyntaxHighlighter,
  SyntaxHighlighterLanguage,
} from '../../syntax-highlighter/SyntaxHighlighter';

export const CodeRenderer: Components['code'] = ({ children, className }) => {
  if (typeof children !== 'string') {
    return null;
  }

  const match = /language-(\w+)/.exec(className ?? '');

  return match ? (
    <SyntaxHighlighter
      language={match ? (match[1] as SyntaxHighlighterLanguage) : undefined}
      codeToHighlight={children}
      theme="github"
    />
  ) : (
    <code className={className}>{children}</code>
  );
};
