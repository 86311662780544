/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LinkTypeDTO = {
    Github: 'github',
    Twitter: 'twitter',
    Kaggle: 'kaggle',
    Linkedin: 'linkedin',
    Other: 'other'
} as const;
export type LinkTypeDTO = typeof LinkTypeDTO[keyof typeof LinkTypeDTO];


export function instanceOfLinkTypeDTO(value: any): boolean {
    for (const key in LinkTypeDTO) {
        if (Object.prototype.hasOwnProperty.call(LinkTypeDTO, key)) {
            if (LinkTypeDTO[key as keyof typeof LinkTypeDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LinkTypeDTOFromJSON(json: any): LinkTypeDTO {
    return LinkTypeDTOFromJSONTyped(json, false);
}

export function LinkTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkTypeDTO {
    return json as LinkTypeDTO;
}

export function LinkTypeDTOToJSON(value?: LinkTypeDTO | null): any {
    return value as any;
}

