import React from 'react';

import { bemBlock } from '@neptune/shared/venus-ui';

import { Merge } from 'common/utility-types';

import './Textarea.less';

type TextAreaProps = {
  className?: string;
  disabled?: boolean;
  withCustomScrollbar?: boolean;
  resize?: 'none' | 'vertical' | 'horizontal' | 'both';
};

const block = bemBlock('textarea');

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  Merge<React.TextareaHTMLAttributes<HTMLTextAreaElement>, TextAreaProps>
>(({ className, disabled, withCustomScrollbar = true, resize = 'both', ...props }, ref) => {
  const ownProps = {
    className: block({
      modifiers: {
        disabled,
        withCustomScrollbar,
        [`resize-${resize}`]: true,
      },
      extra: className,
    }),
    disabled,
  };

  return <textarea ref={ref} {...props} {...ownProps} />;
});
