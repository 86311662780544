import React from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { bemBlock } from '../../modules/bem';

import './ScrollAreaViewport.less';

const block = bemBlock('n-scroll-area-viewport');

export const ScrollAreaViewport = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Viewport>
>(({ className, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.Viewport
      ref={ref}
      className={block({
        extra: className,
      })}
      {...props}
    />
  );
});
