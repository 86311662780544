import React from 'react';

// React does not allow accessing DOM onChange event, uses onInput event/behaviour instead.
export function useDOMOnChange(handleOnChange?: (e: Event) => any) {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!handleOnChange) {
      return;
    }

    const stored = ref.current;

    ref.current?.addEventListener('change', handleOnChange);

    return () => {
      stored?.removeEventListener('change', handleOnChange);
    };
  }, [handleOnChange]);

  return ref;
}
