/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GraduatedPricingDTO } from './GraduatedPricingDTO';
import {
    GraduatedPricingDTOFromJSON,
    GraduatedPricingDTOFromJSONTyped,
    GraduatedPricingDTOToJSON,
} from './GraduatedPricingDTO';

/**
 * 
 * @export
 * @interface V2IndividualPricingDTO
 */
export interface V2IndividualPricingDTO {
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof V2IndividualPricingDTO
     */
    storagePricing: GraduatedPricingDTO;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof V2IndividualPricingDTO
     */
    monitoringTimePricing: GraduatedPricingDTO;
}

/**
 * Check if a given object implements the V2IndividualPricingDTO interface.
 */
export function instanceOfV2IndividualPricingDTO(value: object): value is V2IndividualPricingDTO {
    if (!('storagePricing' in value) || value['storagePricing'] === undefined) return false;
    if (!('monitoringTimePricing' in value) || value['monitoringTimePricing'] === undefined) return false;
    return true;
}

export function V2IndividualPricingDTOFromJSON(json: any): V2IndividualPricingDTO {
    return V2IndividualPricingDTOFromJSONTyped(json, false);
}

export function V2IndividualPricingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): V2IndividualPricingDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'storagePricing': GraduatedPricingDTOFromJSON(json['storagePricing']),
        'monitoringTimePricing': GraduatedPricingDTOFromJSON(json['monitoringTimePricing']),
    };
}

export function V2IndividualPricingDTOToJSON(value?: V2IndividualPricingDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'storagePricing': GraduatedPricingDTOToJSON(value['storagePricing']),
        'monitoringTimePricing': GraduatedPricingDTOToJSON(value['monitoringTimePricing']),
    };
}

