/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvitationTypeEnumDTO = {
    User: 'user',
    EmailRecipient: 'emailRecipient'
} as const;
export type InvitationTypeEnumDTO = typeof InvitationTypeEnumDTO[keyof typeof InvitationTypeEnumDTO];


export function instanceOfInvitationTypeEnumDTO(value: any): boolean {
    for (const key in InvitationTypeEnumDTO) {
        if (Object.prototype.hasOwnProperty.call(InvitationTypeEnumDTO, key)) {
            if (InvitationTypeEnumDTO[key as keyof typeof InvitationTypeEnumDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function InvitationTypeEnumDTOFromJSON(json: any): InvitationTypeEnumDTO {
    return InvitationTypeEnumDTOFromJSONTyped(json, false);
}

export function InvitationTypeEnumDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationTypeEnumDTO {
    return json as InvitationTypeEnumDTO;
}

export function InvitationTypeEnumDTOToJSON(value?: InvitationTypeEnumDTO | null): any {
    return value as any;
}

