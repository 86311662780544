/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringTimePackageDTO
 */
export interface MonitoringTimePackageDTO {
    /**
     * 
     * @type {Date}
     * @memberof MonitoringTimePackageDTO
     */
    expirationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTimePackageDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimePackageDTO
     */
    secondsInPackage: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringTimePackageDTO
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimePackageDTO
     */
    secondsUsed: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringTimePackageDTO
     */
    creationDate: Date;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimePackageDTO
     */
    priceInCents: number;
}

/**
 * Check if a given object implements the MonitoringTimePackageDTO interface.
 */
export function instanceOfMonitoringTimePackageDTO(value: object): value is MonitoringTimePackageDTO {
    if (!('expirationDate' in value) || value['expirationDate'] === undefined) return false;
    if (!('secondsInPackage' in value) || value['secondsInPackage'] === undefined) return false;
    if (!('secondsUsed' in value) || value['secondsUsed'] === undefined) return false;
    if (!('creationDate' in value) || value['creationDate'] === undefined) return false;
    if (!('priceInCents' in value) || value['priceInCents'] === undefined) return false;
    return true;
}

export function MonitoringTimePackageDTOFromJSON(json: any): MonitoringTimePackageDTO {
    return MonitoringTimePackageDTOFromJSONTyped(json, false);
}

export function MonitoringTimePackageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTimePackageDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'expirationDate': (new Date(json['expirationDate'])),
        'description': json['description'] == null ? undefined : json['description'],
        'secondsInPackage': json['secondsInPackage'],
        'label': json['label'] == null ? undefined : json['label'],
        'secondsUsed': json['secondsUsed'],
        'creationDate': (new Date(json['creationDate'])),
        'priceInCents': json['priceInCents'],
    };
}

export function MonitoringTimePackageDTOToJSON(value?: MonitoringTimePackageDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'expirationDate': ((value['expirationDate']).toISOString()),
        'description': value['description'],
        'secondsInPackage': value['secondsInPackage'],
        'label': value['label'],
        'secondsUsed': value['secondsUsed'],
        'creationDate': ((value['creationDate']).toISOString()),
        'priceInCents': value['priceInCents'],
    };
}

