/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeNameFilterDTO } from './AttributeNameFilterDTO';
import {
    AttributeNameFilterDTOFromJSON,
    AttributeNameFilterDTOFromJSONTyped,
    AttributeNameFilterDTOToJSON,
} from './AttributeNameFilterDTO';
import type { AttributeFilterDTO } from './AttributeFilterDTO';
import {
    AttributeFilterDTOFromJSON,
    AttributeFilterDTOFromJSONTyped,
    AttributeFilterDTOToJSON,
} from './AttributeFilterDTO';

/**
 * 
 * @export
 * @interface FilterQueryAttributeDefinitionsDTO
 */
export interface FilterQueryAttributeDefinitionsDTO {
    /**
     * Filter by attribute (match any), if null no type or property value filtering is applied
     * @type {Array<AttributeFilterDTO>}
     * @memberof FilterQueryAttributeDefinitionsDTO
     */
    attributeFilter?: Array<AttributeFilterDTO>;
    /**
     * 
     * @type {AttributeNameFilterDTO}
     * @memberof FilterQueryAttributeDefinitionsDTO
     */
    attributeNameFilter?: AttributeNameFilterDTO;
    /**
     * Filter by attribute name with regex, if null no name filtering is applied;deprecated, use attributeNameFilter instead; if attributeNameFilter is set, this field is ignored
     * @type {string}
     * @memberof FilterQueryAttributeDefinitionsDTO
     */
    attributeNameRegex?: string;
    /**
     * Project identifier; deprecated, use projectIdentifiers instead
     * @type {string}
     * @memberof FilterQueryAttributeDefinitionsDTO
     */
    projectIdentifier?: string;
}

/**
 * Check if a given object implements the FilterQueryAttributeDefinitionsDTO interface.
 */
export function instanceOfFilterQueryAttributeDefinitionsDTO(value: object): value is FilterQueryAttributeDefinitionsDTO {
    return true;
}

export function FilterQueryAttributeDefinitionsDTOFromJSON(json: any): FilterQueryAttributeDefinitionsDTO {
    return FilterQueryAttributeDefinitionsDTOFromJSONTyped(json, false);
}

export function FilterQueryAttributeDefinitionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterQueryAttributeDefinitionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeFilter': json['attributeFilter'] == null ? undefined : ((json['attributeFilter'] as Array<any>).map(AttributeFilterDTOFromJSON)),
        'attributeNameFilter': json['attributeNameFilter'] == null ? undefined : AttributeNameFilterDTOFromJSON(json['attributeNameFilter']),
        'attributeNameRegex': json['attributeNameRegex'] == null ? undefined : json['attributeNameRegex'],
        'projectIdentifier': json['projectIdentifier'] == null ? undefined : json['projectIdentifier'],
    };
}

export function FilterQueryAttributeDefinitionsDTOToJSON(value?: FilterQueryAttributeDefinitionsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeFilter': value['attributeFilter'] == null ? undefined : ((value['attributeFilter'] as Array<any>).map(AttributeFilterDTOToJSON)),
        'attributeNameFilter': AttributeNameFilterDTOToJSON(value['attributeNameFilter']),
        'attributeNameRegex': value['attributeNameRegex'],
        'projectIdentifier': value['projectIdentifier'],
    };
}

