/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkOpErrorDTO
 */
export interface BulkOpErrorDTO {
    /**
     * Same format as sent in request.
     * @type {string}
     * @memberof BulkOpErrorDTO
     */
    experimentIdentifier: string;
    /**
     * Error message.
     * @type {string}
     * @memberof BulkOpErrorDTO
     */
    message: string;
    /**
     * Error reason.
     * @type {string}
     * @memberof BulkOpErrorDTO
     */
    reason: BulkOpErrorDTOReasonEnum;
}


/**
 * @export
 */
export const BulkOpErrorDTOReasonEnum = {
    Trashed: 'TRASHED',
    AlreadyTrashed: 'ALREADY_TRASHED',
    NotExists: 'NOT_EXISTS',
    NotInTrash: 'NOT_IN_TRASH',
    AlreadyFinished: 'ALREADY_FINISHED',
    IsProject: 'IS_PROJECT'
} as const;
export type BulkOpErrorDTOReasonEnum = typeof BulkOpErrorDTOReasonEnum[keyof typeof BulkOpErrorDTOReasonEnum];


/**
 * Check if a given object implements the BulkOpErrorDTO interface.
 */
export function instanceOfBulkOpErrorDTO(value: object): value is BulkOpErrorDTO {
    if (!('experimentIdentifier' in value) || value['experimentIdentifier'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('reason' in value) || value['reason'] === undefined) return false;
    return true;
}

export function BulkOpErrorDTOFromJSON(json: any): BulkOpErrorDTO {
    return BulkOpErrorDTOFromJSONTyped(json, false);
}

export function BulkOpErrorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkOpErrorDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'experimentIdentifier': json['experimentIdentifier'],
        'message': json['message'],
        'reason': json['reason'],
    };
}

export function BulkOpErrorDTOToJSON(value?: BulkOpErrorDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'experimentIdentifier': value['experimentIdentifier'],
        'message': value['message'],
        'reason': value['reason'],
    };
}

