/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignStringOperation
 */
export interface AssignStringOperation {
    /**
     * 
     * @type {string}
     * @memberof AssignStringOperation
     */
    currentValueCond?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignStringOperation
     */
    value: string;
}

/**
 * Check if a given object implements the AssignStringOperation interface.
 */
export function instanceOfAssignStringOperation(value: object): value is AssignStringOperation {
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AssignStringOperationFromJSON(json: any): AssignStringOperation {
    return AssignStringOperationFromJSONTyped(json, false);
}

export function AssignStringOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignStringOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'currentValueCond': json['currentValueCond'] == null ? undefined : json['currentValueCond'],
        'value': json['value'],
    };
}

export function AssignStringOperationToJSON(value?: AssignStringOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currentValueCond': value['currentValueCond'],
        'value': value['value'],
    };
}

