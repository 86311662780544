/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectChartFiltersDTO } from './ProjectChartFiltersDTO';
import {
    ProjectChartFiltersDTOFromJSON,
    ProjectChartFiltersDTOFromJSONTyped,
    ProjectChartFiltersDTOToJSON,
} from './ProjectChartFiltersDTO';
import type { ProjectChartMetricDTO } from './ProjectChartMetricDTO';
import {
    ProjectChartMetricDTOFromJSON,
    ProjectChartMetricDTOFromJSONTyped,
    ProjectChartMetricDTOToJSON,
} from './ProjectChartMetricDTO';

/**
 * 
 * @export
 * @interface NewProjectChartDTO
 */
export interface NewProjectChartDTO {
    /**
     * 
     * @type {ProjectChartFiltersDTO}
     * @memberof NewProjectChartDTO
     */
    filters: ProjectChartFiltersDTO;
    /**
     * Chart metrics
     * @type {Array<ProjectChartMetricDTO>}
     * @memberof NewProjectChartDTO
     */
    metrics: Array<ProjectChartMetricDTO>;
    /**
     * Chart name
     * @type {string}
     * @memberof NewProjectChartDTO
     */
    name: string;
}

/**
 * Check if a given object implements the NewProjectChartDTO interface.
 */
export function instanceOfNewProjectChartDTO(value: object): value is NewProjectChartDTO {
    if (!('filters' in value) || value['filters'] === undefined) return false;
    if (!('metrics' in value) || value['metrics'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function NewProjectChartDTOFromJSON(json: any): NewProjectChartDTO {
    return NewProjectChartDTOFromJSONTyped(json, false);
}

export function NewProjectChartDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewProjectChartDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'filters': ProjectChartFiltersDTOFromJSON(json['filters']),
        'metrics': ((json['metrics'] as Array<any>).map(ProjectChartMetricDTOFromJSON)),
        'name': json['name'],
    };
}

export function NewProjectChartDTOToJSON(value?: NewProjectChartDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filters': ProjectChartFiltersDTOToJSON(value['filters']),
        'metrics': ((value['metrics'] as Array<any>).map(ProjectChartMetricDTOToJSON)),
        'name': value['name'],
    };
}

