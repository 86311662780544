/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlotMode } from './PlotMode';
import {
    PlotModeFromJSON,
    PlotModeFromJSONTyped,
    PlotModeToJSON,
} from './PlotMode';
import type { Scale } from './Scale';
import {
    ScaleFromJSON,
    ScaleFromJSONTyped,
    ScaleToJSON,
} from './Scale';
import type { PointFilters } from './PointFilters';
import {
    PointFiltersFromJSON,
    PointFiltersFromJSONTyped,
    PointFiltersToJSON,
} from './PointFilters';

/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {number}
     * @memberof View
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof View
     */
    maxUniqueX: number;
    /**
     * 
     * @type {PlotMode}
     * @memberof View
     */
    plotMode: PlotMode;
    /**
     * 
     * @type {PointFilters}
     * @memberof View
     */
    pointFilters?: PointFilters;
    /**
     * 
     * @type {number}
     * @memberof View
     */
    to?: number;
    /**
     * 
     * @type {Scale}
     * @memberof View
     */
    xscale: Scale;
    /**
     * 
     * @type {Scale}
     * @memberof View
     */
    yscale: Scale;
}

/**
 * Check if a given object implements the View interface.
 */
export function instanceOfView(value: object): value is View {
    if (!('maxUniqueX' in value) || value['maxUniqueX'] === undefined) return false;
    if (!('plotMode' in value) || value['plotMode'] === undefined) return false;
    if (!('xscale' in value) || value['xscale'] === undefined) return false;
    if (!('yscale' in value) || value['yscale'] === undefined) return false;
    return true;
}

export function ViewFromJSON(json: any): View {
    return ViewFromJSONTyped(json, false);
}

export function ViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): View {
    if (json == null) {
        return json;
    }
    return {
        
        'from': json['from'] == null ? undefined : json['from'],
        'maxUniqueX': json['maxUniqueX'],
        'plotMode': PlotModeFromJSON(json['plotMode']),
        'pointFilters': json['pointFilters'] == null ? undefined : PointFiltersFromJSON(json['pointFilters']),
        'to': json['to'] == null ? undefined : json['to'],
        'xscale': ScaleFromJSON(json['xscale']),
        'yscale': ScaleFromJSON(json['yscale']),
    };
}

export function ViewToJSON(value?: View | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'from': value['from'],
        'maxUniqueX': value['maxUniqueX'],
        'plotMode': PlotModeToJSON(value['plotMode']),
        'pointFilters': PointFiltersToJSON(value['pointFilters']),
        'to': value['to'],
        'xscale': ScaleToJSON(value['xscale']),
        'yscale': ScaleToJSON(value['yscale']),
    };
}

