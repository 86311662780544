/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StorageUsage
 */
export interface StorageUsage {
    /**
     * 
     * @type {number}
     * @memberof StorageUsage
     */
    usage: number;
}

/**
 * Check if a given object implements the StorageUsage interface.
 */
export function instanceOfStorageUsage(value: object): value is StorageUsage {
    if (!('usage' in value) || value['usage'] === undefined) return false;
    return true;
}

export function StorageUsageFromJSON(json: any): StorageUsage {
    return StorageUsageFromJSONTyped(json, false);
}

export function StorageUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): StorageUsage {
    if (json == null) {
        return json;
    }
    return {
        
        'usage': json['usage'],
    };
}

export function StorageUsageToJSON(value?: StorageUsage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'usage': value['usage'],
    };
}

