/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubscriptionStatusDTO } from './SubscriptionStatusDTO';
import {
    SubscriptionStatusDTOFromJSON,
    SubscriptionStatusDTOFromJSONTyped,
    SubscriptionStatusDTOToJSON,
} from './SubscriptionStatusDTO';

/**
 * 
 * @export
 * @interface SubscriptionStateDTO
 */
export interface SubscriptionStateDTO {
    /**
     * 
     * @type {SubscriptionStatusDTO}
     * @memberof SubscriptionStateDTO
     */
    status: SubscriptionStatusDTO;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionStateDTO
     */
    failedChargeAttempt?: Date;
}



/**
 * Check if a given object implements the SubscriptionStateDTO interface.
 */
export function instanceOfSubscriptionStateDTO(value: object): value is SubscriptionStateDTO {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function SubscriptionStateDTOFromJSON(json: any): SubscriptionStateDTO {
    return SubscriptionStateDTOFromJSONTyped(json, false);
}

export function SubscriptionStateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionStateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'status': SubscriptionStatusDTOFromJSON(json['status']),
        'failedChargeAttempt': json['failedChargeAttempt'] == null ? undefined : (new Date(json['failedChargeAttempt'])),
    };
}

export function SubscriptionStateDTOToJSON(value?: SubscriptionStateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': SubscriptionStatusDTOToJSON(value['status']),
        'failedChargeAttempt': value['failedChargeAttempt'] == null ? undefined : ((value['failedChargeAttempt']).toISOString()),
    };
}

